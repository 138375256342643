import React from "react";
import { FaInfoCircle, FaSkullCrossbones } from "react-icons/fa";
import { Form, Alert, Row, Col } from "react-bootstrap";
import { Reference, FetchResult } from "@apollo/client";
import {
  ADD_RISK,
  EDIT_RISK,
  DELETE_RISK,
} from "../../../graphql-client/queries/project";
import type {
  Scalars,
  Risk,
  Project,
  AddRiskMutation,
} from "../../../graphql-client/codegen-types";
import { useProjectMutation } from "../../components/project-context";
import DynamicList from "../../components/DynamicList";
import Explanation from "../../../components/Explanation";
import EntradaTexto from "../../../components/EntradaTexto";
import client from "../../../graphql-client/connection";
import Pregunta from "../../../components/Pregunta";

const { cache } = client;

type ID = Scalars["ID"];

type Props = {
  project: Project;
  readOnly: boolean;
};

function addRiskCallback({ data }: FetchResult<AddRiskMutation>): Risk {
  const ret = data?.asUser?.editProject?.addRisk?.newRisk;
  if (!ret) throw new Error("Error creating risk");
  return ret;
}

const textCellWidth = 3;
const impactCellWidth = 2;
const probabilityCellWidth = 2;
const actionsCellWidth = 3;
const responsibleCellWidth = 2;

const Risks = ({ project, readOnly }: Props) => {
  const addRisk = useProjectMutation<AddRiskMutation, { projectId: ID }>(
    ADD_RISK
  );
  const editRisk = useProjectMutation(EDIT_RISK);
  const deleteRisk = useProjectMutation(DELETE_RISK);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorDetail, setErrorDetail] = React.useState("");
  const [sorting, setSorting] = React.useState(false);

  const reorderByWeight = () =>
    cache.modify({
      id: cache.identify(project),
      fields: {
        risks: (riskRefs, { readField }) => {
          const getWeight = (riskRef: Reference) =>
            (readField("impact", riskRef) as number) *
            (readField("probability", riskRef) as number);
          const sortedRefs = [...riskRefs].sort(
            (a: Reference, b: Reference) => getWeight(b) - getWeight(a)
          );
          // compare arrays
          if (JSON.stringify(riskRefs) !== JSON.stringify(sortedRefs)) {
            setTimeout(() => setSorting(true), 2000);
            setTimeout(() => setSorting(false), 9000);
            return sortedRefs;
          }
          return riskRefs;
        },
      },
    });

  const renderItem = (risk: Risk, isNew: boolean) => {
    const onImpactChange = (e: React.BaseSyntheticEvent) => {
      const impact = parseInt(e.target.value, 10);
      editRisk({
        variables: { projectId: project.id, input: { id: risk.id, impact } },
      });
      cache.modify({
        id: cache.identify(risk),
        fields: {
          impact: () => impact,
        },
      });
      reorderByWeight();
    };
    const onProbabilityChange = (e: React.BaseSyntheticEvent) => {
      const probability = parseInt(e.target.value, 10);
      editRisk({
        variables: {
          projectId: project.id,
          input: { id: risk.id, probability },
        },
      });
      cache.modify({
        id: cache.identify(risk),
        fields: {
          probability: () => probability,
        },
      });
      reorderByWeight();
    };
    const onResponsibleChange = (e: React.BaseSyntheticEvent) => {
      const responsibleId = e.target.value;
      editRisk({
        variables: {
          projectId: project.id,
          input: { id: risk.id, responsibleId },
        },
      });
      cache.modify({
        id: cache.identify(risk),
        fields: {
          responsibleId: () => responsibleId,
        },
      });
    };

    const getWeightDescription = () => {
      const weight = (risk.impact || 0) * (risk.probability || 0);
      if (weight === 0) return null;
      if (weight >= 4 * 4)
        return (
          <span className="text-muted">
            {" "}
            <FaSkullCrossbones style={{ color: "red" }} /> Riesgo Alto{" "}
          </span>
        );
      if (weight >= 2 * 2)
        return (
          <span className="text-muted">
            {" "}
            <FaSkullCrossbones style={{ color: "#999900" }} /> Riesgo Medio{" "}
          </span>
        );
      return (
        <span className="text-muted">
          {" "}
          <FaSkullCrossbones style={{ color: "green" }} /> Riesgo Bajo{" "}
        </span>
      );
    };

    return (
      <Row style={{ background: "whitesmoke", padding: "5px 0 5px 0" }}>
        <Col xs={textCellWidth}>
          <EntradaTexto
            ejemplo="Que la encuesta no tenga respuestas suficientes."
            maxLetras={280}
            autoFocus={isNew}
            saveText={
              readOnly
                ? () => {}
                : (text: string) => {
                    cache.modify({
                      id: cache.identify(risk),
                      fields: {
                        text: () => text,
                      },
                    });
                    editRisk({
                      variables: {
                        projectId: project.id,
                        input: { id: risk.id, text },
                      },
                    });
                  }
            }
            textoInicial={risk.text ?? ""}
            rows={2}
            readOnly={readOnly}
          />
        </Col>
        <Col
          xs={impactCellWidth + probabilityCellWidth}
          style={{ padding: "0" }}
        >
          <Row style={{ width: "100%", margin: "0" }}>
            <div style={{ width: "50%", padding: "5px" }}>
              <Form.Control
                as="select"
                onChange={onImpactChange}
                value={risk.impact || 0}
              >
                {["", "Muy Bajo", "Bajo", "Medio", "Alto", "Muy Alto"].map(
                  (label, idx) => (
                    <option key={label} value={idx} disabled={idx === 0}>
                      {label}
                    </option>
                  )
                )}
              </Form.Control>
            </div>
            <div style={{ width: "50%", padding: "5px" }}>
              <Form.Control
                as="select"
                onChange={onProbabilityChange}
                value={risk.probability || 0}
              >
                {["", "Muy Baja", "Baja", "Media", "Alta", "Muy Alta"].map(
                  (label, idx) => (
                    <option key={label} value={idx} disabled={idx === 0}>
                      {label}
                    </option>
                  )
                )}
              </Form.Control>
            </div>
          </Row>
          <div style={{ width: "100%", padding: "5px" }}>
            {" "}
            {getWeightDescription()}{" "}
          </div>
        </Col>
        <Col xs={actionsCellWidth}>
          <EntradaTexto
            ejemplo="Usar encuestas externas."
            maxLetras={280}
            saveText={
              readOnly
                ? () => {}
                : (actions: string) => {
                    cache.modify({
                      id: cache.identify(risk),
                      fields: {
                        actions: () => actions,
                      },
                    });
                    editRisk({
                      variables: {
                        projectId: project.id,
                        input: { id: risk.id, actions },
                      },
                    });
                  }
            }
            textoInicial={risk.actions ?? ""}
            rows={2}
            readOnly={readOnly}
          />
        </Col>
        <Col xs={responsibleCellWidth}>
          <Form.Control
            as="select"
            onChange={onResponsibleChange}
            value={risk.responsibleId || -1}
            style={{ marginTop: "10px" }}
          >
            <option value="-1" disabled>
              {" "}
            </option>
            {project.members?.map((member, idx) => (
              <option key={member.id} value={member.id}>
                {member?.user?.handle || member.name}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <div style={{ overflowX: "scroll" }}>
        <div style={{ minWidth: "600px" }}>
          <DynamicList
            title={
              <div>
                Tabla de{" "}
                <b>
                  <FaSkullCrossbones /> Riesgos
                </b>
                <hr />
                <Row style={{ margin: "0 31px 0 2px" }}>
                  <Col xs={textCellWidth}>
                    {" "}
                    <Pregunta
                      pregunta="Riesgo"
                      descripcion="Describe la preocupación o amenaza"
                    />
                  </Col>
                  <Col xs={impactCellWidth}>
                    {" "}
                    <Pregunta
                      pregunta="Impacto"
                      descripcion="Qué tanto afectaría al proyecto si llega a ocurrir"
                    />{" "}
                  </Col>
                  <Col xs={probabilityCellWidth}>
                    {" "}
                    <Pregunta
                      pregunta="Probabilidad"
                      descripcion="Qué tan probable es que suceda"
                    />
                  </Col>
                  <Col xs={actionsCellWidth}>
                    {" "}
                    <Pregunta
                      pregunta="Acciones"
                      descripcion="Las acciones para reducir la probabilidad de ocurrencia o para minimizar el impacto"
                    />
                  </Col>
                  <Col xs={responsibleCellWidth}>
                    {" "}
                    <Pregunta
                      pregunta="Responsable"
                      descripcion="Quién se va a hacer cargo de las acciones"
                    />{" "}
                  </Col>
                </Row>
              </div>
            }
            deleteInfo={
              readOnly
                ? undefined
                : {
                    onDelete: ({ id }: Risk) => {
                      cache.modify({
                        id: cache.identify(project),
                        fields: {
                          risks: (oldRisks, { readField }) =>
                            oldRisks.filter(
                              (riskRef: Reference) =>
                                id !== readField("id", riskRef)
                            ),
                        },
                      });
                      return deleteRisk({
                        variables: { projectId: project.id, id },
                      }).then(() => true);
                    },
                    deleteWarnTitle: "¿Eliminar Riesgo?",
                    deleteWarnMsg: "No se podrá recuperar.",
                  }
            }
            renderItem={renderItem}
            getItemId={({ id }: Risk) => id}
            initial={project.risks || []}
            newItem={
              readOnly
                ? undefined
                : () =>
                    addRisk({
                      variables: { projectId: project.id },
                    })
                      .then(addRiskCallback)
                      .then((newRisk: Risk) => {
                        cache.modify({
                          id: cache.identify(project),
                          fields: {
                            risks: (oldRisks) =>
                              oldRisks.concat({
                                __ref: cache.identify(newRisk),
                              }),
                          },
                        });
                        return newRisk;
                      })
                      .catch((e) => {
                        const applicationErrorDetail =
                          e?.graphQLErrors?.[0]?.extensions
                            ?.applicationErrorDetail;
                        const projectError =
                          applicationErrorDetail?.projectError;
                        if (projectError?.risksLimitExceeded) {
                          setErrorMessage(
                            `No más de ${projectError.risksLimitExceeded} riesgos por proyecto`
                          );
                          setErrorDetail(
                            "Demasiados riesgos son difíciles de monitorear"
                          );
                          setTimeout(() => {
                            setErrorMessage("");
                            setErrorDetail("");
                          }, 7000);
                          return null;
                        }
                        throw e;
                      })
            }
          />
        </div>
      </div>
      {errorMessage !== "" && (
        <Alert variant="warning">
          <Explanation text={errorMessage} detail={errorDetail} />
        </Alert>
      )}
      {sorting && (
        <Alert
          variant="info"
          style={{ alignItems: "center", textAlign: "right" }}
        >
          {" "}
          <FaInfoCircle /> Los riesgos se ordenan en automático por prioridad.
          (Prioridad = Impacto x Probabilidad){" "}
        </Alert>
      )}
    </div>
  );
};

export default Risks;
