import { useParams } from "react-router-dom";
import React from "react";
import { useMutation } from "@apollo/client";
import { Card, Alert, Form, Button } from "react-bootstrap";
import useSessionToken from "../graphql-client/session";
import { CONFIRM } from "../graphql-client/queries/account";
import Loader from "../components/Loader";
import {
  textInputChange,
  textInputChangeRegexFilter,
} from "../components/utils";
import type { UserMetadata } from "../graphql-client/codegen-types";

const FILE_SIZE_LIMIT_IN_MB = 4;

const ConfirmAccount = () => {
  const [confirmAccount] = useMutation(CONFIRM);
  const params = useParams<{ token: string }>();
  const [, setSessionToken] = useSessionToken();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [handle, setHandle] = React.useState("");
  const [name, setName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [lastNames, setLastNames] = React.useState("");
  const [institutionName, setInstitutionName] = React.useState("");
  const [agreedToMarketing, setAgreedToMarketing] = React.useState(true);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const profilePictureRef = React.createRef<HTMLInputElement>();
  const submit = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (handle === "") {
      setErrorMessage("Escoge un nombre de usuario");
    } else if (name === "") {
      setErrorMessage("Ingresa tu nombre");
    } else if (lastNames === "") {
      setErrorMessage("Ingresa tus apellidos");
    } else if (
      (profilePictureRef?.current?.files?.[0]?.size || 0) >
      FILE_SIZE_LIMIT_IN_MB * 1000000
    ) {
      setErrorMessage(
        `La imagen pesa demasiado. Máximo ${FILE_SIZE_LIMIT_IN_MB} MB, por favor.`
      );
    } else {
      setErrorMessage("");
      setLoading(true);
      const profilePicture = profilePictureRef.current?.files?.[0] || null;
      const userMetadata: UserMetadata = {
        institutionName: institutionName.length > 0 ? institutionName : null,
        agreedToMarketing,
        phoneNumber: phoneNumber.length > 0 ? phoneNumber : null,
        jobTitle: jobTitle.length > 0 ? jobTitle : null,
        zipCode: zipCode.length > 0 ? zipCode : null,
      };
      confirmAccount({
        variables: {
          token: params.token,
          handle,
          name,
          lastNames,
          profilePicture,
          userMetadata,
        },
      })
        .then(({ data }) => {
          setSessionToken(data.confirmAccount.sessionToken);
          setLoading(false);
        })
        .catch((e) => {
          const errorDetail =
            e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail;
          const accountError = errorDetail?.accountError;
          const fileError = errorDetail?.fileError;
          const exceededLimit = errorDetail?.exceededLimit;
          setLoading(false);
          if (accountError) {
            const {
              expiredToken,
              invalidToken,
              userAlreadyExists,
              handleAlreadyExists,
            } = accountError;
            if (expiredToken)
              setErrorMessage(
                "El enlace ha expirado. Por favor, vuelve a registrarte."
              );
            if (invalidToken)
              setErrorMessage(
                "El enlace es inválido o ha expirado. Asegura que lo recibiste de una cuenta autorizada de Coordinate."
              );
            if (userAlreadyExists)
              setErrorMessage(
                "La cuenta ya existe y ya está confirmada. Haz login o cambia de contraseña."
              );
            if (handleAlreadyExists)
              setErrorMessage(
                "El nombre de usuario ya existe. Por favor, escoge otro."
              );
          } else if (fileError) {
            const { sizeLimitExceeded, invalidFileType } = fileError;
            if (sizeLimitExceeded)
              setErrorMessage(
                `La imagen pesa demasiado. Máximo ${FILE_SIZE_LIMIT_IN_MB} MB, por favor.`
              );
            if (invalidFileType)
              setErrorMessage("El archivo seleccionado no es una imagen.");
          } else {
            console.log("Unknown error");
            console.log(e);
          }
        });
    }
  };

  return (
    <div style={{ width: "80%" }}>
      <Card className="text-center" style={{ background: "aliceblue" }}>
        <Card.Header>
          Llena la siguiente información y ¡Tu cuenta estará lista!
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group className="form-row">
              <Form.Label className="col-sm-2">Nombre</Form.Label>
              <Form.Control
                className="col-sm-9"
                placeholder="Juan"
                value={name}
                onChange={textInputChange(setName)}
              />
            </Form.Group>
            <Form.Group className="form-row">
              <Form.Label className="col-sm-2">Apellidos</Form.Label>
              <Form.Control
                className="col-sm-9"
                placeholder="Pérez"
                value={lastNames}
                onChange={textInputChange(setLastNames)}
              />
            </Form.Group>
            <Form.Group className="form-row">
              <Form.Label className="col-sm-2">Usuario</Form.Label>
              <Form.Control
                className="col-sm-9"
                placeholder="juan_perez"
                value={handle}
                onChange={textInputChange(setHandle)}
              />
            </Form.Group>
            <Form.Group className="form-row">
              <Form.Label className="col-sm-2">Ocupación o Puesto</Form.Label>
              <Form.Control
                className="col-sm-9"
                placeholder="Ingeniero químico, estudiante de carrera, ..."
                value={jobTitle}
                onChange={textInputChange(setJobTitle)}
              />
            </Form.Group>
            <Form.Group className="form-row">
              <Form.Label className="col-sm-2">
                Empresa o Institución
              </Form.Label>
              <Form.Control
                className="col-sm-9"
                placeholder="Grupo Heineken, Tecnológico de Monterrey, etc."
                value={institutionName}
                onChange={textInputChange(setInstitutionName)}
              />
            </Form.Group>
            <Form.Group className="form-row">
              <Form.Label className="col-sm-2">Teléfono</Form.Label>
              <Form.Control
                className="col-sm-9"
                placeholder="(Opcional) con lada, ejemplo +52 81 8888 8888"
                value={phoneNumber}
                onChange={textInputChangeRegexFilter(
                  setPhoneNumber,
                  "[0-9, ,+,-]*"
                )}
              />
            </Form.Group>
            <Form.Group className="form-row">
              <Form.Label className="col-sm-2">Código Postal</Form.Label>
              <Form.Control
                className="col-sm-9"
                placeholder="(Opcional)"
                value={zipCode}
                onChange={textInputChangeRegexFilter(setZipCode, "[0-9,-]*")}
              />
            </Form.Group>
            <Form.Group className="form-row">
              <Form.Label className="col-sm-2">
                Foto de perfil <p style={{ color: "grey" }}>(opcional)</p>
              </Form.Label>
              <Form.File
                className="col-sm-9"
                id="exampleFormControlFile1"
                ref={profilePictureRef}
              />
            </Form.Group>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <Form.Check
                type="checkbox"
                checked={agreedToMarketing}
                onChange={() => setAgreedToMarketing(!agreedToMarketing)}
              />
              <span style={{ marginRight: "4px" }}>
                {" "}
                Acepto recibir noticias y promociones de Coordinate{" "}
              </span>
            </div>
            {!loading && (
              <Button variant="primary" type="submit" onClick={submit}>
                Confirmar mi cuenta
              </Button>
            )}
            {loading && <Loader size={60} message="Procesando tus datos" />}
            {errorMessage !== "" && (
              <Alert variant="danger" style={{ marginTop: "20px" }}>
                {"  "}
                {errorMessage}
              </Alert>
            )}
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ConfirmAccount;
