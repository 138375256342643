import React from "react";
import { useQuery } from "@apollo/client";
import { Col, Form, Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import styled from "styled-components";
import { FaSearch } from "react-icons/fa";
import { GET_FEEDBACK, GET_TRAFFIC } from "../graphql-client/queries/admin";
import type {
  ProjectFeedback,
  ProjectTrafficEntry,
  User,
} from "../graphql-client/codegen-types";
import Loader from "../components/Loader";
import UseChart from "../components/UseChart";
import { SEARCH_USERS } from "../graphql-client/queries/project";

const StyledAutosuggestWrapper = styled.div`
  size: 40%;
  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    width: 100%;
    height: 30px;
    padding: 10px 20px;
    font-size: 16px;
    color: #053d56;
    border: 1px solid #aaa;
    border-radius: 4px;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-size: 16px;
    color: #053d56;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

  .container {
    display: flex;
  }
`;

const Feedback = () => {
  const { data, loading, error } = useQuery(GET_FEEDBACK, {
    variables: { input: {} },
    errorPolicy: "all",
  });
  if (loading) return <Loader size={60} message="Cargando retros" />;
  if (error)
    return (
      <span>
        {" "}
        Error al cargar. Asegúrate de que tu cuenta tenga derechos de
        administrador.{" "}
      </span>
    );
  const { getProjectFeedback } = data.asAdmin;
  const entries = getProjectFeedback;

  return (
    <div
      style={{
        margin: "20px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: "whitesmoke",
      }}
    >
      <b style={{ textAlign: "center" }}> Retroalimentación </b>
      <br />
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Usuario</th>
            <th>Proyecto</th>
            <th>Reusaría</th>
            <th>Recomendaría</th>
            <th>Aprendió</th>
            <th>Comentarios</th>
          </tr>
        </thead>
        <tbody style={{ textAlign: "center" }}>
          {entries.map(
            ({
              user,
              project,
              recommendable,
              reusable,
              learnable,
              comment,
            }: ProjectFeedback) => (
              <tr key={`${user?.id}-${project?.id}`}>
                <td>{user?.handle}</td>
                <td>{project?.name}</td>
                <td>{reusable} / 10</td>
                <td>{recommendable} / 10</td>
                <td>{learnable} / 10</td>
                <td>{comment}</td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    </div>
  );
};

const TopUsers = () => {
  const periodOptionLabels = [
    "este mes",
    "esta semana",
    "hoy",
    "la última hora",
    "los últimos 10 minutos",
  ];
  const periodOptionLengths = [30 * 24 * 60, 7 * 24 * 60, 24 * 60, 60, 10];
  const history = useHistory();
  const [periodOptionIndex, setPeriodOptionIndex] = React.useState(1);
  const { data, loading, error } = useQuery(GET_TRAFFIC, {
    variables: {
      periodLength: periodOptionLengths[periodOptionIndex],
      input: { userId: null },
    },
    errorPolicy: "all",
  });
  if (loading) return <Loader size={60} message="Cargando usuarios" />;
  if (error)
    return (
      <span>
        {" "}
        Error al cargar. Asegúrate de que tu cuenta tenga derechos de
        administrador.{" "}
      </span>
    );
  const { getProjectTraffic } = data.asAdmin;
  // Solo considerar los de la última semana
  const recentEntries = getProjectTraffic.filter(
    (entry: ProjectTrafficEntry) => entry.periodsSince === 0
  );
  // Ordenar de mas activo a menos activo
  const sortedEntries = [...recentEntries].sort(
    (a: ProjectTrafficEntry, b: ProjectTrafficEntry) =>
      b.frequency - a.frequency
  );
  const onChange = (e: React.BaseSyntheticEvent) => {
    const idx = e.target.value;
    setPeriodOptionIndex(idx);
  };

  return (
    <div
      style={{
        margin: "20px",
        display: "flex",
        padding: "10px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "whitesmoke",
      }}
    >
      <b> Usuarios más activos de </b>
      <Form.Control
        style={{ width: "auto" }}
        as="select"
        onChange={onChange}
        value={periodOptionIndex}
      >
        {periodOptionLabels.map((label: string, idx: number) => (
          <option key={label} value={idx}>
            {label}{" "}
          </option>
        ))}
      </Form.Control>
      <br />
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Operaciones</th>
            <th>Usuario</th>
            <th>Nombre</th>
            <th>Apellidos</th>
            <th>Correo</th>
          </tr>
        </thead>
        <tbody>
          {sortedEntries.map(
            ({ user, frequency }: ProjectTrafficEntry, idx: number) => {
              if (!user) return null;
              const { handle, name, lastNames, email } = user;
              return (
                <tr key={user.id} style={{ cursor: "pointer" }}>
                  <td>{idx + 1}</td>
                  <td>{frequency}</td>
                  <td>
                    <Link to={`/profile/${user.id}`}>{handle}</Link>
                  </td>
                  <td>{name}</td>
                  <td>{lastNames}</td>
                  <td>{email}</td>
                </tr>
              );
            }
          )}
        </tbody>
      </Table>
    </div>
  );
};

const UserSearch = () => {
  const [suggestions, setSuggestions] = React.useState<Array<User>>([]);
  const [text, setText] = React.useState("");
  const { data, refetch } = useQuery(SEARCH_USERS, {
    variables: { query: text },
  });
  React.useEffect(() => setSuggestions(data?.asUser?.searchUsers || []), [
    setSuggestions,
    data,
  ]);
  const history = useHistory();
  const inputProps = {
    placeholder: "Buscar usuario",
    value: text,
    onChange: (event: unknown, { newValue }: { newValue: string }) =>
      setText(newValue),
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <FaSearch style={{ marginRight: "5px" }} />
      <StyledAutosuggestWrapper>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={() => refetch()}
          onSuggestionsClearRequested={() => setSuggestions([])}
          getSuggestionValue={(suggestion) => suggestion.handle || "<usuario>"}
          renderSuggestion={(suggestion) => suggestion.handle}
          inputProps={inputProps}
          onSuggestionSelected={(event: unknown, { suggestion }) => {
            history.push(`/profile/${suggestion.id}`);
          }}
        />
      </StyledAutosuggestWrapper>
    </div>
  );
};

const Admin = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Col xs={12} lg={9}>
        <h4 style={{ textAlign: "center" }}> Administrador </h4>
        <UseChart input={{}} />
        <UserSearch />
        <TopUsers />
        <Feedback />
      </Col>
    </div>
  );
};

export default Admin;
