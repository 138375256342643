import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FaCalendar, FaUserShield, FaList } from "react-icons/fa";
import { MdCreateNewFolder } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useUserContext } from "./components/user-context";
import Logo from "./components/Logo";
import UsageCarousel from "./components/UsageCarousel";
import FeedbackMessage from "./components/FeedbackMessage";
import TaskDashboard from "./components/TaskDashboard";
import NewProjectModal from "./components/NewProjectModal";
import { PlanDescription } from "./plan";

const InnerHome = () => {
  const [newProjectModalOpen, setNewProjectModalOpen] = React.useState(false);
  const history = useHistory();
  const userInfo = useUserContext();
  const handle = userInfo?.handle;

  return (
    <div
      style={{
        background: "aliceblue",
        margin: "0",
        textAlign: "center",
        borderRadius: "10px",
        border: "1px solid gainsboro",
        padding: "10px",
      }}
    >
      <h5 style={{ margin: "10px 0 10px 0" }}>
        Hola <b>{handle}</b>, Bienvenido(a) a
      </h5>
      <Logo />
      <PlanDescription />
      <hr />
      <span>
        {" "}
        <b>Opciones</b>{" "}
      </span>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Button
          onClick={() => setNewProjectModalOpen(true)}
          variant="info"
          style={{ margin: "10px" }}
        >
          {" "}
          <MdCreateNewFolder
            style={{ height: "80px", width: "80px" }}
          /> <br /> Crear un Proyecto{" "}
        </Button>
        <Button
          onClick={() => history.push("/projects")}
          variant="info"
          style={{ margin: "10px" }}
        >
          {" "}
          <FaList style={{ height: "80px", width: "80px" }} /> <br /> Ver mis
          Proyectos{" "}
        </Button>
        <Button
          onClick={() => history.push("/calendar")}
          variant="info"
          style={{ margin: "10px" }}
        >
          {" "}
          <FaCalendar style={{ height: "80px", width: "80px" }} /> <br /> Ir a
          mi Calendario
        </Button>
        {userInfo?.isAdmin && (
          <Button
            onClick={() => history.push("/admin")}
            variant="info"
            style={{ margin: "10px" }}
          >
            {" "}
            <FaUserShield style={{ height: "80px", width: "80px" }} /> <br />
            Administrador
          </Button>
        )}
      </div>
      <hr style={{ marginBottom: "0" }} />
      <TaskDashboard />
      <hr />
      <UsageCarousel />
      <hr />
      <span>
        Gracias por usar <b>Coordinate</b>.{" "}
      </span>
      <h3> ¡Queremos escucharte!</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <FeedbackMessage showArrow={false} />
      </div>
      {newProjectModalOpen && (
        <NewProjectModal close={() => setNewProjectModalOpen(false)} />
      )}
      <br />
    </div>
  );
};

const Home = () => (
  <Container fluid>
    <Row>
      <Col xs={12} md={{ span: 10, offset: 1 }} lg={10}>
        <InnerHome />
      </Col>
    </Row>
  </Container>
);

export default Home;
