import React from "react";
import PuffLoader from "react-spinners/PuffLoader";

type Props = {
  size: number;
  message: string;
};

const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loader = ({ size, message }: Props) => {
  const puffLoader = (
    <PuffLoader size={size} css={override} loading color="coral" />
  );
  if (message === "") {
    return puffLoader;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {puffLoader}
      <span style={{ color: "grey", textAlign: "center" }}>{message}</span>
      <span
        className="text-muted"
        style={{ fontSize: "11px", textAlign: "center" }}
      >
        {" "}
        Espera, por favor{" "}
      </span>
    </div>
  );
};

export default Loader;
