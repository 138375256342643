import React from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { useQuery, useMutation } from "@apollo/client";
import { FaGlobeAmericas } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import {
  FILTER_PROJECTS,
  CREATE_PROJECT,
} from "../graphql-client/queries/project";
import Pregunta from "./Pregunta";
import EntradaTexto from "./EntradaTexto";
import client from "../graphql-client/connection";
import Explanation from "./Explanation";
import Loader from "./Loader";
import { useProVerifier } from "../plan";

const { cache } = client;

type Props = {
  close: () => void;
};

const NewProjectModal = ({ close }: Props) => {
  const projectListData = useQuery(FILTER_PROJECTS, {
    variables: {
      textFilters: [],
    },
    errorPolicy: "all",
    fetchPolicy: "cache-first",
  }).data;
  const [createProject] = useMutation(CREATE_PROJECT);
  const [name, setName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorDetail, setErrorDetail] = React.useState("");
  const history = useHistory();
  const [confirmProAccess] = useProVerifier();
  const submit = () => {
    const projCount = projectListData?.asUser?.getProjects?.length;
    const maxProj = 3;
    if (projCount !== undefined && projCount !== null && projCount >= maxProj) {
      if (
        !confirmProAccess(
          `Para tener más de ${maxProj} proyectos ¡Cámbiate a Pro!`
        )
      )
        return;
    }
    setLoading(true);
    createProject({ variables: { name } })
      .then((res) => {
        const { data } = res;
        setLoading(false);
        const { newProject } = data.asUser.createProject;
        const projectListQuery = client.readQuery({
          query: FILTER_PROJECTS,
          variables: { textFilters: [] },
        });
        if (projectListQuery) {
          const { asUser } = projectListQuery;
          cache.modify({
            id: cache.identify(asUser),
            fields: {
              getProjects: (oldProjects) => oldProjects.concat(newProject),
            },
          });
        }
        close();
        history.push(`/projects/${newProject.id}/que`);
      })
      .catch((e) => {
        setLoading(false);
        const licenseError =
          e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail
            ?.licenseError;
        if (licenseError?.projectLimitExceeded) {
          setErrorMessage(
            `Puedes pertenecer a hasta ${licenseError.projectLimitExceeded} proyectos. Para crear uno nuevo, borra o abandona algún proyecto pasado.`
          );
          setErrorDetail(
            "Es inusual tener tantos proyectos activos. Si necesitas que aumentemos el límite de proyectos, mándanos un Whatsapp."
          );
          setTimeout(() => {
            setErrorMessage("");
            setErrorDetail("");
          }, 20000);
          return null;
        }
        throw e;
      });
  };
  if (loading)
    return (
      <Modal show onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#0086ba" }}>
            <FaGlobeAmericas /> {name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loader size={80} message="Creando Proyecto..." />
        </Modal.Body>
      </Modal>
    );
  return (
    <Modal show onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "#0086ba" }}>
          {" "}
          <FaGlobeAmericas /> Nuevo Proyecto{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Pregunta
          pregunta="1. ¿Cuál será el nombre del proyecto?"
          descripcion="Elige un título corto, representativo y muy atractivo"
        >
          <EntradaTexto
            ejemplo="Ventas Campaña 2020"
            maxLetras={30}
            saveText={setName}
            textoInicial={name}
            rows={1}
          />
        </Pregunta>
        {errorMessage !== "" && (
          <Alert variant="warning">
            <Explanation text={errorMessage} detail={errorDetail} />
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Cancelar
        </Button>
        <Button variant="info" onClick={submit}>
          Crear Proyecto
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewProjectModal;
