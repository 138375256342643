import React from "react";
import { differenceInDays, isBefore } from "date-fns";
import ReactDOMServer from "react-dom/server";
import styled from "styled-components";
import type { WbsNode } from "../../../graphql-client/codegen-types";
import type { ComoterminoNodeExtraAttributes, ComoterminoNode } from "./types";
import BaselineGanttChart from "../../components/gantt/BaselineGanttChart";
import {
  parseStartDate,
  parseEndDate,
  formatStartDate,
  formatEndDate,
  getBasicGanttNodeAttributes,
} from "../../components/gantt/utils";

// Transforma JSX (React Components) en strings de html.
// Esto es útil porque dhtmlx usa html crudo para renderear.
// Documentación: https://reactjs.org/docs/react-dom-server.html
const jsxToStr = ReactDOMServer.renderToString;

const ComoterminoGanttStyleWrapper = styled.div`
  .gantt_side_content.gantt_right {
    bottom: 0;
  }
`;

class ComoterminoGanttChart extends BaselineGanttChart<
  Record<string, unknown>,
  ComoterminoNodeExtraAttributes
> {
  toWbsNode({
    id,
    text,
    start_date,
    end_date,
    planned_start,
    planned_end,
  }: ComoterminoNode) {
    return {
      id,
      text,
      startDate: formatStartDate(start_date),
      endDate: formatEndDate(end_date),
      plannedStart: formatStartDate(planned_start),
      plannedEnd: formatEndDate(planned_end),
    };
  }

  styleGantt() {
    super.styleGantt();
    this.myGantt.templates.rightside_text = (start, end, { planned_end }) => {
      if (isBefore(planned_end, end)) {
        const overdue = differenceInDays(end, planned_end);
        return jsxToStr(
          <b>
            Se atrasó: {overdue} {overdue === 1 ? "día" : "días"}
          </b>
        );
      }
      return "";
    };
  }

  toGanttNode(node: WbsNode, parent: string, level: number) {
    const { projectDefaultStart } = this.props;
    const { startDate, endDate, plannedStart, plannedEnd } = node;
    const type = this.myGantt.config.types.task;
    return {
      ...getBasicGanttNodeAttributes(node, parent, level),
      type,
      start_date: parseStartDate(
        startDate || plannedStart || projectDefaultStart
      ),
      end_date: parseEndDate(endDate || plannedEnd || projectDefaultStart),
      planned_start: parseStartDate(plannedStart || projectDefaultStart),
      planned_end: parseEndDate(plannedEnd || projectDefaultStart),
    };
  }

  render() {
    return (
      <ComoterminoGanttStyleWrapper>
        {super.render()}
      </ComoterminoGanttStyleWrapper>
    );
  }
}
export default ComoterminoGanttChart;
