import React from "react";
import { Form } from "react-bootstrap";
import type { GanttStatic } from "dhtmlx-gantt";
import { FaPlus, FaTasks } from "react-icons/fa";
import Pregunta from "../../../components/Pregunta";
import type { GanttNode } from "../../components/gantt/types";
import { ActLabel } from "../../../components/utils";
import { InlineList, InlineListItem } from "../../../components/InlineList";

const LinkEditor = ({
  node,
  gantt,
}: {
  node: GanttNode;
  gantt: GanttStatic;
}) => {
  const { id } = node;
  const links = gantt.getLinks().filter(({ target }) => target === id);
  const isInLink = (task: GanttNode) =>
    links.find(({ source }) => source === task.id) !== undefined;
  const actNodes = gantt.getTaskByTime().filter(({ level }) => level === 2);
  const availableNodes = actNodes.filter(
    (task) => task.id !== id && !isInLink(task)
  );
  const onChange = (e: React.BaseSyntheticEvent) => {
    const source = e.target.value;
    // TODO: Manejar los demas tipos de enlaces
    gantt.addLink({
      source,
      target: id,
      type: gantt.config.links.finish_to_start,
    });
  };
  const renderLinks = links.map((link) => (
    <InlineListItem key={link.id} onDelete={() => gantt.deleteLink(link.id)}>
      {" "}
      <FaTasks style={{ marginRight: "3px", color: "#999900" }} />{" "}
      {gantt.getTask(link.source).text}{" "}
    </InlineListItem>
  ));
  return (
    <div style={{ margin: "20px" }}>
      <Pregunta
        pregunta={
          <span>
            {" "}
            Para poder iniciar esta <ActLabel />, <br />
            <b>
              ¿Qué otras <ActLabel plural /> <br />
              deben completarse antes?{" "}
            </b>
          </span>
        }
        descripcion={`Ej. La actividad "Mandar invitaciones" depende de la actividad "Imprimir invitaciones".`}
      >
        <div
          style={{
            background: "aliceblue",
            borderRadius: "10px",
            padding: "5px",
          }}
        >
          <InlineList>
            {renderLinks}
            <div
              style={{
                background: "powderblue",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "2px",
              }}
            >
              {" "}
              <FaPlus style={{ margin: "0 3px 0 5px" }} />{" "}
              <Form.Control
                style={{ height: "30px", width: "30px" }}
                as="select"
                onChange={onChange}
                defaultValue="-1"
              >
                <option value="-1" disabled>
                  {" "}
                </option>
                {availableNodes.map((task: GanttNode) => (
                  <option key={task.id} value={task.id}>
                    {" "}
                    {task.text}{" "}
                  </option>
                ))}
              </Form.Control>
            </div>
          </InlineList>
          {links.length === 0 && (
            <Form.Text muted>
              Si la respuesta es <i>ninguna</i>, <br />
              ve a la siguiente pestaña <b> 2. Fechas</b>
            </Form.Text>
          )}
        </div>
      </Pregunta>
    </div>
  );
};

export default LinkEditor;
