import type { Step } from "./types";
import Que from "../que/Que";
import Como from "../como/Como";
import Quien from "../quien/Quien";
import Cuando from "../cuando/Cuando";
import Comova from "../comova/Comova";
import Comotermino from "../comotermino/Comotermino";

const steps: Array<Step> = [
  {
    path: "que",
    Component: Que,
    title: "¿Qué y por qué?",
    articleLink: "https://www.coordinate.si/6+1/paso-1-que-y-por-que/",
  },
  {
    path: "como",
    Component: Como,
    title: "¿Cómo?",
    articleLink: "https://www.coordinate.si/6+1/seis-sencillos-pasos/",
  },
  {
    path: "quien",
    Component: Quien,
    title: "¿Quién?",
    articleLink: "https://www.coordinate.si/6+1/seis-sencillos-pasos/",
  },
  {
    path: "cuando",
    Component: Cuando,
    title: "¿Cuándo?",
    articleLink: "https://www.coordinate.si/6+1/seis-sencillos-pasos/",
  },
  {
    path: "comova",
    Component: Comova,
    title: "¿Cómo va?",
    articleLink: "https://www.coordinate.si/6+1/seis-sencillos-pasos/",
  },
  {
    path: "comotermino",
    Component: Comotermino,
    title: "¿Cómo terminó?",
    articleLink: "https://www.coordinate.si/6+1/seis-sencillos-pasos/",
  },
];

export default steps;
