import React from "react";
import { Text, Image, Page, View, Document } from "@react-pdf/renderer";
import type { Member, Project } from "../../../graphql-client/codegen-types";
import PDFQuestion from "../../components/pdf/PDFQuestion";
import PDFHeader from "../../components/pdf/PDFHeader";
import PDFTitle from "../../components/pdf/PDFTitle";

type Props = {
  project: Project;
  ganttPNGScreenshot: string;
};

const getResponsibleText = (
  id: string | undefined | null,
  members: Array<Member>
) => {
  const member = members.find((currentMember) => currentMember.id === id);
  return member?.user?.handle || member?.name || "";
};

const RisksTable = ({ project }: { project: Project }) => {
  const { risks, members } = project;
  return (
    <View
      style={{
        fontSize: "12px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          fontFamily: "Helvetica-Bold",
        }}
      >
        <View
          style={{ width: "20%", border: "1px solid black", padding: "3px" }}
        >
          <Text> Riesgo </Text>
        </View>
        <View
          style={{ width: "20%", border: "1px solid black", padding: "3px" }}
        >
          <Text>Impacto</Text>
        </View>
        <View
          style={{ width: "20%", border: "1px solid black", padding: "3px" }}
        >
          <Text>Probabilidad</Text>
        </View>
        <View
          style={{ width: "20%", border: "1px solid black", padding: "3px" }}
        >
          <Text>Acciones</Text>
        </View>
        <View
          style={{ width: "20%", border: "1px solid black", padding: "3px" }}
        >
          <Text>Responsable</Text>
        </View>
      </View>
      {risks?.map((risk) => (
        <View
          key={risk.id}
          style={{ width: "100%", display: "flex", flexDirection: "row" }}
        >
          <View
            style={{ border: "1px solid black", width: "20%", padding: "3px" }}
          >
            <Text>{risk.text}</Text>
          </View>
          <View
            style={{ border: "1px solid black", width: "20%", padding: "3px" }}
          >
            <Text>{risk.impact}</Text>
          </View>
          <View
            style={{ border: "1px solid black", width: "20%", padding: "3px" }}
          >
            <Text>{risk.probability}</Text>
          </View>
          <View
            style={{ border: "1px solid black", width: "20%", padding: "3px" }}
          >
            <Text>{risk.actions}</Text>
          </View>
          <View
            style={{ border: "1px solid black", width: "20%", padding: "3px" }}
          >
            <Text>{getResponsibleText(risk.responsibleId, members || [])}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};

const PDFReport = ({ project, ganttPNGScreenshot }: Props) => {
  const { name, assumptions, restrictions } = project;

  return (
    <Document>
      <Page size="A4" style={{ padding: "40px", color: "#053d56" }}>
        <PDFHeader name={name || "[Proyecto sin nombre]"} />
        <PDFTitle title="¿Cuándo?" />
        <View style={{ fontSize: "17px" }}>
          <PDFQuestion question="Diagrama de Gantt: Planeación del Proyecto">
            <Image
              src={ganttPNGScreenshot}
              style={{
                marginTop: "10px",
                maxHeight: "750px",
                width: "auto",
                objectFit: "contain",
              }}
            />
          </PDFQuestion>
        </View>
        <View style={{ fontSize: "13px" }}>
          <PDFQuestion question="¿Qué suposiciones hay para que el proyecto avance?">
            {assumptions?.map(({ id, text }) => (
              <Text style={{ marginBottom: "2px" }} key={id}>
                - {text}
              </Text>
            ))}
          </PDFQuestion>
          <PDFQuestion question="¿Qué restricciones existen que pueden limitar el avance del proyecto?">
            {restrictions?.map(({ id, text }) => (
              <Text style={{ marginBottom: "2px" }} key={id}>
                - {text}
              </Text>
            ))}
          </PDFQuestion>
          <PDFQuestion question="¿Cuáles son los riesgos que pueden afectar el proyecto?">
            <RisksTable project={project} />
          </PDFQuestion>
        </View>
      </Page>
    </Document>
  );
};

export default PDFReport;
