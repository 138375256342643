import React from "react";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";

const StyledTimes = styled(FaTimes)`
  cursor: pointer;
  height: 12px;
  width: 12px;
  margin-left: 3px;
  :hover {
    opacity: 0.5;
  }
`;

export function InlineListItem({
  onDelete,
  children,
}: React.PropsWithChildren<{ onDelete?: () => void }>): React.ReactElement {
  return (
    <div
      style={{
        margin: "0",
        border: "1px solid steelblue",
        borderRadius: "15px",
        width: "auto",
        padding: "0px 5px 0px 5px",
        marginRight: "5px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {children}
      {onDelete !== undefined && <StyledTimes onClick={onDelete} />}
    </div>
  );
}

export function InlineList({
  children,
}: React.PropsWithChildren<unknown>): React.ReactElement {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {children}
    </div>
  );
}
