import React from "react";
import { Page, Text, View, Document } from "@react-pdf/renderer";
import type {
  Member,
  Duty,
  WbsNode,
  Project,
} from "../../../graphql-client/codegen-types";
import PDFQuestion from "../../components/pdf/PDFQuestion";
import PDFHeader from "../../components/pdf/PDFHeader";
import PDFTitle from "../../components/pdf/PDFTitle";

const splitToChunks = (str: string, chunkSize: number): Array<string> => {
  if (str.length <= chunkSize) return [str];
  const head = str.slice(0, chunkSize);
  const rest = splitToChunks(str.slice(chunkSize), chunkSize);
  return [head].concat(rest);
};

type Props = {
  project: Project;
};

const PDFReport = ({ project }: Props) => {
  const { name, wbsRoot, members } = project;
  const firstColumnWidth = "150px";
  const cellWidth = "50px";
  const getRow = (duties: Array<Duty>, level: number) =>
    (members || []).map(({ id }: Member) => {
      const getContent = () => {
        if (duties.some(({ memberId }: Duty) => memberId === id)) {
          return ["C", "R", "E"][level];
        }
        return "-";
      };
      return (
        <Text
          key={id}
          style={{
            border: "1px solid gray",
            textAlign: "center",
            width: cellWidth,
            padding: "4px",
          }}
        >
          {" "}
          {getContent()}{" "}
        </Text>
      );
    });
  const memberNameCellWidth = 7;
  const tableHeader = (
    <View style={{ display: "flex", flexDirection: "row" }}>
      <View style={{ width: firstColumnWidth }} />
      {(members || []).map((member: Member) => (
        <Text
          key={member.id}
          hyphenationCallback={(word) =>
            splitToChunks(word, memberNameCellWidth)
          }
          style={{
            textAlign: "center",
            border: "1px solid gray",
            width: cellWidth,
            textOverflow: "ellipsis",
            overflow: "hidden",
            padding: "4px",
          }}
        >
          {member.name || member.user?.handle}{" "}
        </Text>
      ))}
    </View>
  );
  const renderWbsNode = (
    { id, text, children, duties }: WbsNode,
    level: number,
    wbsCode = "",
    padding = 5
  ) => (
    <View key={id}>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <Text
          hyphenationCallback={(word) => [word]}
          style={{
            border: "1px solid gray",
            textAlign: "left",
            width: firstColumnWidth,
            paddingLeft: `${padding}px`,
            padding: "4px",
          }}
        >
          {wbsCode} {text}{" "}
        </Text>
        {getRow(duties || [], level)}
      </View>
      {(children || []).map((child, idx) =>
        renderWbsNode(child, level + 1, `${wbsCode}${idx + 1}.`, padding + 5)
      )}
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={{ padding: "40px", color: "#053d56" }}>
        <PDFHeader name={name || "[Proyecto sin nombre]"} />
        <PDFTitle title="¿Quién?" />
        <View style={{ fontSize: "17px" }}>
          <PDFQuestion question="¿Cuáles son las responsabilidades de cada miembro del equipo?">
            <View style={{ textAlign: "right", fontSize: "12px" }}>
              <View>
                <Text> C Coordinador </Text>
                <Text> R Responsable </Text>
                <Text> E Ejecutor </Text>
                <Text> - Sin rol </Text>
              </View>
              {tableHeader}
              {wbsRoot !== null &&
                wbsRoot !== undefined &&
                renderWbsNode(wbsRoot, 0)}
            </View>
          </PDFQuestion>
        </View>
      </Page>
    </Document>
  );
};

export default PDFReport;
