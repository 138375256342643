import React from "react";
import { Button, InputGroup, Col, Form } from "react-bootstrap";
import type { Project, Tag } from "../graphql-client/codegen-types";

type Props = {
  tags: Array<Tag>;
  setFilter: (
    filterFunction: { matchesFilter: (project: Project) => boolean } | null
  ) => void;
  close: () => void;
};

const TextFilter = React.forwardRef(
  ({ tags, setFilter, close }: Props, ref) => {
    const onChange = (e: React.BaseSyntheticEvent) => {
      const tagId = e.target.value;
      setFilter({
        matchesFilter: (project: Project) => {
          if (!project?.tags) return false;
          return project.tags.some((tag) => tag.id === tagId);
        },
      });
    };
    return (
      <Col>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="inputGroup-sizing-default">
              Grupo:
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            as="select"
            onChange={onChange}
            defaultValue="-1"
            ref={ref as any}
          >
            <option value="-1" disabled>
              {" "}
            </option>
            {tags.map((tag: Tag) => (
              <option key={tag.id} value={tag.id}>
                {" "}
                {tag.name}{" "}
              </option>
            ))}
          </Form.Control>
          <InputGroup.Append>
            <Button
              type="button"
              variant="outline-secondary"
              onClick={() => {
                setFilter(null);
                close();
              }}
            >
              &times;
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Col>
    );
  }
);

export default TextFilter;
