import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import Pregunta from "./Pregunta";
import { textInputChange } from "./utils";
import Loader from "./Loader";
import EntradaTexto from "./EntradaTexto";
import {
  HAS_SUBMITTED_FEEDBACK,
  SUBMIT_FEEDBACK,
} from "../graphql-client/queries/account";
import FeedbackMessage from "./FeedbackMessage";
import client from "../graphql-client/connection";

const { cache } = client;

const oneToTen: Array<number> = Array.from(Array(10).keys());

type Props = {
  close: () => void;
};

const StyledModal = styled(Modal)`
  text-align: center;
`;

const UserFeedback = ({ close }: Props) => {
  const [submitFeedback] = useMutation(SUBMIT_FEEDBACK);
  const [rating, setRating] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [notNow, setNotNow] = React.useState(false);

  const onClick = () => {
    if (!rating) return;
    setLoading(true);
    submitFeedback({
      variables: {
        input: {
          rating: parseInt(rating, 10),
          comment,
        },
      },
    }).then((res) => {
      const { asUser } = client.readQuery({ query: HAS_SUBMITTED_FEEDBACK });
      client.writeQuery({
        query: HAS_SUBMITTED_FEEDBACK,
        data: { asUser: { ...asUser, hasSubmittedFeedback: true } },
      });
      setLoading(false);
      setSuccess(true);
    });
  };

  if (success || notNow) {
    return (
      <StyledModal show onHide={close}>
        <Modal.Header style={{ textAlign: "center" }} closeButton>
          {success && <Modal.Title>Gracias por tu reseña</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
          {success ? (
            <h5>¡Queremos seguir escuchándote!</h5>
          ) : (
            <h5>Queremos escucharte</h5>
          )}
          <FeedbackMessage />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={close}> Cerrar </Button>
        </Modal.Footer>
      </StyledModal>
    );
  }

  return (
    <StyledModal show backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>¿Te está gustando Coordinate?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Regálanos unos segundos de tu tiempo para calificar nuestro servicio.
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          onChange={textInputChange(setRating)}
        >
          {oneToTen.map((num: number) => (
            <div
              key={num}
              style={{
                margin: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <input type="radio" value={(num + 1).toString()} /> {num + 1}
            </div>
          ))}
        </div>
        <Pregunta
          pregunta={<span> Comentarios (Opcional)</span>}
          descripcion="En Coordinate valoramos al máximo la opinión de nuestros usuarios. Cualquier comentario es bienvenido y apreciado."
        >
          <EntradaTexto
            ejemplo="Me gustó..., pero cambiaría..."
            maxLetras={500}
            saveText={(text: string) => setComment(text)}
            textoInicial=""
            rows={3}
            readOnly={loading}
          />
        </Pregunta>
      </Modal.Body>
      {loading ? (
        <Loader size={60} message="Enviando..." />
      ) : (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setNotNow(true)}>
            Ahora no
          </Button>
          <Button variant="info" onClick={onClick} disabled={rating === null}>
            Enviar
          </Button>
        </Modal.Footer>
      )}
    </StyledModal>
  );
};

export default UserFeedback;
