import React from "react";
import { Button, Form } from "react-bootstrap";
import Autosuggest from "react-autosuggest";
import styled from "styled-components";
import { FaUser, FaEnvelope, FaHourglassStart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import {
  EDIT_MEMBER,
  SEARCH_USERS,
  INVITE_MEMBER,
} from "../../../graphql-client/queries/project";
import type {
  User,
  Member,
  MemberInput,
} from "../../../graphql-client/codegen-types";
import { Access } from "../../../graphql-client/codegen-types";
import { useProjectMutation } from "../../components/project-context";
import Loader from "../../../components/Loader";
import client from "../../../graphql-client/connection";
import { functionify } from "../../../components/utils";
import { formatStartDate } from "../../components/gantt/utils";

const { cache } = client;

type Props = {
  initialMember: Member;
  projectId: string;
  readOnly: boolean;
  setMemberErrorMessage: (value: string) => void;
  setMemberErrorDetail: (value: string) => void;
};

const StyledAutosuggestWrapper = styled.div`
  size: 40%;
  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    width: 100%;
    height: 30px;
    padding: 10px 20px;
    font-size: 16px;
    color: #053d56;
    border: 1px solid #aaa;
    border-radius: 4px;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-size: 16px;
    color: #053d56;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

  .container {
    display: flex;
  }
`;

const MemberEditor = ({
  projectId,
  initialMember,
  readOnly,
  setMemberErrorMessage,
  setMemberErrorDetail,
}: Props) => {
  const [member, setMember] = React.useState(initialMember);
  const [loadingInvite, setLoadingInvite] = React.useState(false);
  const getDisplayedText = React.useCallback(() => {
    if (member.user) {
      if (!member.user.handle) throw Error("a user's handle can't be null");
      return `@${member.user.handle}`;
    }
    return member.name || "";
  }, [member]);
  const [suggestions, setSuggestions] = React.useState<Array<User>>([]);
  const editMember = useProjectMutation(EDIT_MEMBER);
  const inviteMember = useProjectMutation(INVITE_MEMBER);

  const fetchQuery = () =>
    client
      .query({
        query: SEARCH_USERS,
        variables: { query: getDisplayedText().replace("@", "") },
      })
      .then((res) => setSuggestions(res?.data?.asUser?.searchUsers || []));
  const save = (newMember: Member) => {
    const getMemberInput = (): MemberInput => {
      if (newMember.user)
        return {
          id: newMember.id,
          userId: newMember.user.id,
          access: newMember.access || Access.Read,
        };
      return { id: newMember.id, name: newMember.name };
    };
    const updateCache = () => {
      const { id, user, name, ...memberAttributes } = newMember;
      if (user) {
        cache.modify({
          id: cache.identify(initialMember),
          fields: {
            ...functionify(memberAttributes),
            user: () => ({ __ref: cache.identify(user) }),
          },
        });
      } else {
        cache.modify({
          id: cache.identify(initialMember),
          fields: {
            ...functionify(memberAttributes),
            name: () => name,
            user: () => null,
          },
        });
      }
    };

    const input = getMemberInput();
    editMember({ variables: { projectId, input }, update: updateCache }).catch(
      (e) => {
        const memberError =
          e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail
            ?.projectError?.memberError;
        if (memberError) {
          const { atLeastOneAdminMember, noDuplicateMembers } = memberError;
          if (atLeastOneAdminMember) {
            setMemberErrorMessage("Debe haber al menos un dueño del proyecto");
            setMemberErrorDetail(
              "De otro modo, nadie podría editar los miembros o eliminar el proyecto."
            );
            setTimeout(() => {
              setMemberErrorMessage("");
              setMemberErrorDetail("");
            }, 7000);
            setMember({ ...member, access: Access.Admin });
          } else if (noDuplicateMembers) {
            setMemberErrorMessage(
              `El usuario ${getDisplayedText()} ya es miembro del equipo`
            );
            setTimeout(() => {
              setMemberErrorMessage("");
            }, 7000);
            setMember({
              ...member,
              user: null,
              name: getDisplayedText().substr(1), // Quitar el '@', para dar la sensacion de que ya no se esta taggeando al usuario
            });
          } else {
            console.log("Unexpected error", e);
          }
        }
      }
    );
  };

  const textReadOnly = readOnly || member.invitedOn;
  const inputProps = {
    placeholder: "Nombre del miembro",
    value: getDisplayedText(),
    onChange: (event: unknown, { newValue }: { newValue: string }) => {
      setMember({
        id: member.id,
        name: newValue.replace("@", ""),
        access: member.access,
        user: null,
      });
    },
    onBlur: textReadOnly ? () => {} : () => save(member),
    readOnly: textReadOnly,
  };

  const getSidePane = () => {
    if (!initialMember?.name?.length && !initialMember.user)
      return <i className="text-muted"> Escribe el nombre del miembro </i>;
    const onChange = (e: React.BaseSyntheticEvent) => {
      const access = e.target.value as Access;
      const newMember = { ...member, access };
      setMember(newMember);
      save(newMember);
    };
    if (member.confirmedOn) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Form.Control
            style={{
              margin: "0 10px 0 10px",
              fontSize: "14px",
              height: "32px",
            }}
            as="select"
            onChange={onChange}
            value={member.access || Access.Read}
            disabled={readOnly}
          >
            <option value={Access.Read}>Puede ver</option>
            <option value={Access.Write}>Puede editar</option>
            <option value={Access.Admin}>Es dueño(a) de</option>
          </Form.Control>{" "}
          este proyecto
        </div>
      );
    }
    if (member.invitedOn) {
      return (
        <span style={{ marginLeft: "10px" }}>
          <FaHourglassStart /> Esperando aceptación
        </span>
      );
    }
    if (member.user) {
      const submit = () => {
        setLoadingInvite(true);
        inviteMember({ variables: { projectId, memberId: member.id } }).then(
          () => {
            setMember({ ...member, invitedOn: new Date() });
            setLoadingInvite(false);
            cache.modify({
              id: cache.identify(member),
              fields: {
                invitedOn: () => formatStartDate(new Date()),
              },
            });
          }
        );
      };
      if (loadingInvite) return <Loader size={50} message="Invitando..." />;
      return (
        <Button
          variant="light"
          style={{ border: "1px solid black" }}
          disabled={readOnly}
          onClick={submit}
        >
          <FaEnvelope /> Invitar al proyecto
        </Button>
      );
    }
    return (
      <span className="text-muted" style={{ marginLeft: "10px" }}>
        Miembro sin cuenta de Coordinate
      </span>
    );
  };
  const getPictureOrIcon = () => {
    const memberIcon = <FaUser style={{ width: "30px", height: "30px" }} />;
    if (!member?.user) return memberIcon;
    const { id, handle, profilePictureLink } = member.user;
    const userIcon = profilePictureLink ? (
      <img
        alt="Foto perfil"
        src={profilePictureLink}
        style={{ maxWidth: "35px", maxHeight: "35px" }}
      />
    ) : (
      memberIcon
    );
    return (
      <Tooltip title={`Ir al perfil de ${member?.user?.handle}`} arrow>
        <Link to={`/profile/${member?.user?.id}`}>{userIcon}</Link>
      </Tooltip>
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ marginRight: "5px" }}>{getPictureOrIcon()}</div>
      <StyledAutosuggestWrapper>
        <Autosuggest
          suggestions={textReadOnly ? [] : suggestions}
          onSuggestionsFetchRequested={() => fetchQuery()}
          onSuggestionsClearRequested={() => setSuggestions([])}
          getSuggestionValue={(suggestion) => `@${suggestion.handle}`}
          renderSuggestion={(suggestion) => <div> @{suggestion.handle} </div>}
          inputProps={inputProps}
          onSuggestionSelected={(event: unknown, { suggestion }) => {
            const newMember = {
              id: member.id,
              user: suggestion,
              access: member.access,
            };
            setMember(newMember);
            save(newMember);
          }}
        />
      </StyledAutosuggestWrapper>
      <div style={{ width: "50%" }}>{getSidePane()}</div>
    </div>
  );
};

export default MemberEditor;
