import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { Button, Dropdown } from "react-bootstrap";
import { Packer } from "docx";
import {
  useProjectMutation,
  useProjectQuery,
} from "../components/project-context";
import {
  DELETE_LINK,
  ADD_LINK,
  GET_DATES_CUANDO,
  EDIT_WBS_NODE,
} from "../../graphql-client/queries/project";
import CuandoGanttChart from "./components/CuandoGanttChart";
import { todayWithoutTime, ActLabel, DelivLabel } from "../../components/utils";
import Pregunta from "../../components/Pregunta";
import type {
  Project,
  WbsNode,
  Link as WbsNodeLink,
} from "../../graphql-client/codegen-types";
import { Step, Access } from "../../graphql-client/codegen-types";
import { parseStartDate } from "../components/gantt/utils";
import Loader from "../../components/Loader";
import Assumptions from "../components/Assumptions";
import Restrictions from "../components/Restrictions";
import CuandoGanttPNGExporter from "./components/CuandoGanttPNGExporter";
import PDFReport from "./components/PDFReport";
import { Fullscreen, useFullscreen } from "../components/fullscreen";
import WorkDaysModal from "../components/WorkDaysModal";
import client from "../../graphql-client/connection";
import getDocxReportPromise from "./components/docx-report";
import Risks from "./components/Risks";
import { useProVerifier } from "../../plan";

const { cache } = client;

type Props = {
  projectId: string;
};

const Cuando = ({ projectId }: Props) => {
  const { loading, error, data, refetch } = useProjectQuery(GET_DATES_CUANDO, {
    variables: { id: projectId, step: Step.Cuando },
  });
  const proVerifier = useProVerifier();
  const editWbsNode = useProjectMutation(EDIT_WBS_NODE);
  const addLink = useProjectMutation(ADD_LINK);
  const deleteLink = useProjectMutation(DELETE_LINK);
  const [activeFullscreen, , fullscreenHandler] = useFullscreen();
  const [loadingPDF, setLoadingPDF] = React.useState(false);
  const [isWorkDaysModalOpen, setIsWorkDaysModalOpen] = React.useState(false);
  const updateTaskInCache = (wbsNode: WbsNode) => {
    const { id, plannedStart, plannedEnd } = wbsNode;
    cache.modify({
      id: cache.identify({ id, __typename: "WbsNode" }),
      fields: {
        plannedStart: () => plannedStart,
        plannedEnd: () => plannedEnd,
      },
    });
  };
  const onTaskUpd = (wbsNode: WbsNode) => {
    const { id, plannedStart, plannedEnd } = wbsNode;
    return editWbsNode({
      variables: {
        projectId,
        input: {
          id,
          plannedStart,
          plannedEnd,
        },
      },
    }).then(() => {});
  };

  if (loading) return <Loader size={60} message="Cargando el proyecto" />;
  if (error) return <p> Error al cargar el Gantt </p>;
  const project = data.asUser.getProject;
  const readOnly = project.access === Access.Read;
  const onLinkAdd = (link: WbsNodeLink) =>
    addLink({
      variables: { projectId, link },
      update() {
        cache.modify({
          id: cache.identify(project),
          fields: {
            links: (oldLinks) => oldLinks.concat(link),
          },
        });
      },
    });

  const onLinkDelete = (id: string | number) =>
    deleteLink({
      variables: { projectId, id },
      update() {
        cache.modify({
          id: cache.identify(project),
          fields: {
            links: (oldLinks) =>
              oldLinks.filter((link: WbsNodeLink) => id !== link.id),
          },
        });
      },
    });

  const { links, members } = project;
  const ganttContainerRef = React.createRef<HTMLDivElement>();
  const isIncomplete = (deliv: WbsNode) => (deliv.children || []).length === 0;
  const entregables = data.asUser.getProject.wbsRoot.children || [];
  if (entregables.length === 0 || entregables.some(isIncomplete)) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h4>
          {" "}
          <FaExclamationTriangle /> Falta agregar <ActLabel plural />
        </h4>
        <br />
        <span>
          {" "}
          Uno de tus <DelivLabel plural /> no tiene actividades{" "}
        </span>
        <br />
        <span>
          {" "}
          Regresa al paso{" "}
          <Link to={`/projects/${projectId}/como`}>
            <i> 2. ¿Cómo? </i>{" "}
          </Link>
          y agrégalas para continuar
        </span>
      </div>
    );
  }
  const { startEstimate } = project;
  const defaultDate = startEstimate
    ? parseStartDate(startEstimate)
    : todayWithoutTime();

  const onGanttExport = (ganttScreenshot: any, updatedProject: Project) =>
    pdf(
      <PDFReport
        project={updatedProject}
        ganttPNGScreenshot={ganttScreenshot}
      />
    )
      .toBlob()
      .then((blob) => {
        saveAs(blob, "Cuando.pdf");
      });

  const workDaysHandler = (
    <Dropdown.Item
      variant="light"
      title="Click para definir los días laborales"
      onClick={() => setIsWorkDaysModalOpen(true)}
      disabled={readOnly}
    >
      Definir días laborales
    </Dropdown.Item>
  );
  const getProjectPromise = () =>
    refetch().then((res) => res?.data?.asUser?.getProject);
  const ganttChart = (
    <Fullscreen active={activeFullscreen}>
      <CuandoGanttChart
        ganttContainerRef={ganttContainerRef}
        wbsRoot={project.wbsRoot}
        links={links}
        onTaskUpdate={onTaskUpd}
        proVerifier={proVerifier}
        updateTaskInCache={updateTaskInCache}
        onLinkAdd={onLinkAdd}
        onLinkDelete={onLinkDelete}
        defaultDate={defaultDate}
        readOnly={readOnly}
        fullscreenHandler={fullscreenHandler}
        members={members}
        workDays={project.workDays || []}
        workDaysHandler={workDaysHandler}
      />
    </Fullscreen>
  );

  if (activeFullscreen) return ganttChart;
  return (
    <div>
      <div>
        Ahora, usa el siguiente{" "}
        <a
          href="https://es.wikipedia.org/wiki/Diagrama_de_Gantt"
          target="_blank"
          rel="noopener noreferrer"
        >
          diagrama de Gantt{" "}
        </a>
        para asignar fechas a las Actividades de tu proyecto.
      </div>
      <hr />
      <Pregunta
        pregunta={
          <span>
            1. Haz click sobre cada <ActLabel /> en el diagrama de Gantt
          </span>
        }
        descripcion="Así, podrás definir su duración, fecha de inicio, y fecha de terminación"
      >
        {ganttChart}
      </Pregunta>
      <br />
      <Pregunta
        pregunta={
          <span>
            2. ¿Cuáles son los riesgos que pueden afectar el proyecto?
          </span>
        }
        descripcion={`Escribe las preocupaciones/amenazas más relevantes para el proyecto y más probables de suceder. Usa frases específicas como: "Que la encuesta no tenga respuestas suficientes", evita frases generales como "Falta de dinero"`}
      >
        <Risks project={project} readOnly={readOnly} />
      </Pregunta>
      <br />
      <Pregunta
        pregunta={
          <span>
            3. ¿Han cambiado las suposiciones del proyecto? En ese caso,
            actualízalas aquí
          </span>
        }
        descripcion=""
      >
        <Assumptions project={project} readOnly={readOnly} />
      </Pregunta>
      <br />
      <Pregunta
        pregunta={
          <span>
            4. ¿Han cambiado las restricciones del proyecto? En ese caso,
            actualízalas aquí
          </span>
        }
        descripcion=""
      >
        <Restrictions project={project} readOnly={readOnly} />
      </Pregunta>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        <CuandoGanttPNGExporter
          text="Imprimir reporte en PDF"
          onGanttExport={onGanttExport}
          project={project}
          defaultDate={defaultDate}
        />
        <Button
          variant="info"
          onClick={() => {
            getDocxReportPromise(project)
              .then((docxReport) => Packer.toBlob(docxReport))
              .then((blob) => {
                saveAs(blob, "Cuando.docx");
              });
          }}
        >
          Exportar a Word (.docx)
        </Button>
      </div>
      <WorkDaysModal
        project={project}
        isOpen={isWorkDaysModalOpen}
        close={() => setIsWorkDaysModalOpen(false)}
      />
    </div>
  );
};

export default Cuando;
