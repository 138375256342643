import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};




export type AccountError = {
  __typename?: 'AccountError';
  userDoesNotExist?: Maybe<Scalars['Boolean']>;
  oldVersionAccount?: Maybe<Scalars['Boolean']>;
  wrongPassword?: Maybe<Scalars['Boolean']>;
  userAlreadyExists?: Maybe<Scalars['Boolean']>;
  invalidToken?: Maybe<Scalars['Boolean']>;
  expiredToken?: Maybe<Scalars['Boolean']>;
  unauthorized?: Maybe<Scalars['Boolean']>;
  banned?: Maybe<Scalars['Boolean']>;
  handleAlreadyExists?: Maybe<Scalars['Boolean']>;
  invalidCaptcha?: Maybe<Scalars['Boolean']>;
  signOutOnError?: Maybe<Scalars['Boolean']>;
};

export type FileError = {
  __typename?: 'FileError';
  sizeLimitExceeded?: Maybe<Scalars['Int']>;
  invalidFileType?: Maybe<Scalars['Boolean']>;
};

export type ProjectMemberError = {
  __typename?: 'ProjectMemberError';
  atLeastOneAdminMember?: Maybe<Scalars['Boolean']>;
  noDuplicateMembers?: Maybe<Scalars['Boolean']>;
  cantEditInvitedUsers?: Maybe<Scalars['Boolean']>;
  notInvited?: Maybe<Scalars['Boolean']>;
  inviteAlreadySent?: Maybe<Scalars['Boolean']>;
  inviteAlreadyConfirmed?: Maybe<Scalars['Boolean']>;
  memberLimitExceeded?: Maybe<Scalars['Int']>;
};

export type ProjectError = {
  __typename?: 'ProjectError';
  wrongWbsParent?: Maybe<Scalars['Boolean']>;
  wbsChildLimitExceeded?: Maybe<Scalars['Int']>;
  insufficientPermissions?: Maybe<Scalars['Boolean']>;
  memberError?: Maybe<ProjectMemberError>;
  benefitsLimitExceeded?: Maybe<Scalars['Int']>;
  assumptionsLimitExceeded?: Maybe<Scalars['Int']>;
  restrictionsLimitExceeded?: Maybe<Scalars['Int']>;
  lessonsLimitExceeded?: Maybe<Scalars['Int']>;
  risksLimitExceeded?: Maybe<Scalars['Int']>;
};

export type LicenseError = {
  __typename?: 'LicenseError';
  projectLimitExceeded?: Maybe<Scalars['Int']>;
};

export type ApplicationErrorDetail = {
  __typename?: 'ApplicationErrorDetail';
  fileError?: Maybe<FileError>;
  projectError?: Maybe<ProjectError>;
  licenseError?: Maybe<LicenseError>;
  accountError?: Maybe<AccountError>;
  tagLimitExceeded?: Maybe<Scalars['Int']>;
};

export enum ProjectTrafficType {
  Read = 'READ',
  Write = 'WRITE'
}

export type UserFeedback = {
  __typename?: 'UserFeedback';
  user: User;
  rating: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};

export type UserFeedbackInput = {
  rating: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};

export type UserMetadata = {
  institutionName?: Maybe<Scalars['String']>;
  agreedToMarketing?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type ProjectFeedback = {
  __typename?: 'ProjectFeedback';
  user?: Maybe<User>;
  project?: Maybe<Project>;
  reusable?: Maybe<Scalars['Int']>;
  recommendable?: Maybe<Scalars['Int']>;
  learnable?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
};

export type ProjectTrafficEntry = {
  __typename?: 'ProjectTrafficEntry';
  user?: Maybe<User>;
  project?: Maybe<Project>;
  step?: Maybe<Step>;
  type?: Maybe<ProjectTrafficType>;
  periodsSince: Scalars['Int'];
  frequency: Scalars['Int'];
};

export type ProjectTrafficInput = {
  userId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  step?: Maybe<Step>;
  type?: Maybe<ProjectTrafficType>;
};

export type Risk = {
  __typename?: 'Risk';
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
  impact?: Maybe<Scalars['Int']>;
  probability?: Maybe<Scalars['Int']>;
  actions?: Maybe<Scalars['String']>;
  responsibleId?: Maybe<Scalars['ID']>;
};

export type RiskInput = {
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
  impact?: Maybe<Scalars['Float']>;
  probability?: Maybe<Scalars['Float']>;
  actions?: Maybe<Scalars['String']>;
  responsibleId?: Maybe<Scalars['ID']>;
};

export type Restriction = {
  __typename?: 'Restriction';
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

export type RestrictionInput = {
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

export type Lesson = {
  __typename?: 'Lesson';
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

export type LessonInput = {
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

export type Assumption = {
  __typename?: 'Assumption';
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

export type AssumptionInput = {
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

export type Benefit = {
  __typename?: 'Benefit';
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

export type BenefitInput = {
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

export type Link = {
  __typename?: 'Link';
  id: Scalars['ID'];
  source: Scalars['ID'];
  target: Scalars['ID'];
  type: Scalars['String'];
};

export type LinkInput = {
  id: Scalars['ID'];
  source: Scalars['ID'];
  target: Scalars['ID'];
  type: Scalars['String'];
};

export type MutationResponse = {
  message: Scalars['String'];
};

export type SimpleMutationResponse = MutationResponse & {
  __typename?: 'SimpleMutationResponse';
  message: Scalars['String'];
};

export type ConfirmMemberResponse = MutationResponse & {
  __typename?: 'ConfirmMemberResponse';
  message: Scalars['String'];
  newProject: Project;
};

export type LoginResponse = MutationResponse & {
  __typename?: 'LoginResponse';
  message: Scalars['String'];
  sessionToken: Scalars['String'];
};

export type ConfirmAccountResponse = MutationResponse & {
  __typename?: 'ConfirmAccountResponse';
  message: Scalars['String'];
  sessionToken: Scalars['String'];
};

export type CreatePaymentSessionResponse = MutationResponse & {
  __typename?: 'CreatePaymentSessionResponse';
  message: Scalars['String'];
  portalSessionUrl: Scalars['String'];
};

export type CreateTagResponse = MutationResponse & {
  __typename?: 'CreateTagResponse';
  message: Scalars['String'];
  newTag: Tag;
};

export type EditProfileResponse = MutationResponse & {
  __typename?: 'EditProfileResponse';
  message: Scalars['String'];
  updatedUser: User;
};

export type AddBenefitResponse = MutationResponse & {
  __typename?: 'AddBenefitResponse';
  message: Scalars['String'];
  newBenefit: Benefit;
};

export type AddLessonResponse = MutationResponse & {
  __typename?: 'AddLessonResponse';
  message: Scalars['String'];
  newLesson: Lesson;
};

export type AddAssumptionResponse = MutationResponse & {
  __typename?: 'AddAssumptionResponse';
  message: Scalars['String'];
  newAssumption: Assumption;
};

export type AddRiskResponse = MutationResponse & {
  __typename?: 'AddRiskResponse';
  message: Scalars['String'];
  newRisk: Risk;
};

export type AddRestrictionResponse = MutationResponse & {
  __typename?: 'AddRestrictionResponse';
  message: Scalars['String'];
  newRestriction: Restriction;
};

export type CreateWbsNodeResponse = MutationResponse & {
  __typename?: 'CreateWbsNodeResponse';
  message: Scalars['String'];
  newWbsNode: WbsNode;
};

export type CreateProjectResponse = MutationResponse & {
  __typename?: 'CreateProjectResponse';
  message: Scalars['String'];
  newProject: Project;
};

export type AddMemberResponse = MutationResponse & {
  __typename?: 'AddMemberResponse';
  message: Scalars['String'];
  newMember: Member;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type TagInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export enum Step {
  Que = 'QUE',
  Como = 'COMO',
  Quien = 'QUIEN',
  Cuando = 'CUANDO',
  Comova = 'COMOVA',
  Comotermino = 'COMOTERMINO'
}

export enum WbsNodeStatus {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Done = 'DONE'
}

export enum Job {
  Coordinate = 'COORDINATE',
  Execute = 'EXECUTE',
  Inform = 'INFORM',
  Report = 'REPORT'
}

export type Duty = {
  __typename?: 'Duty';
  memberId: Scalars['ID'];
  job: Job;
};

export type WbsNode = {
  __typename?: 'WbsNode';
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  plannedStart?: Maybe<Scalars['DateTime']>;
  plannedEnd?: Maybe<Scalars['DateTime']>;
  children?: Maybe<Array<WbsNode>>;
  duties?: Maybe<Array<Duty>>;
  status?: Maybe<WbsNodeStatus>;
  projectId?: Maybe<Scalars['ID']>;
};

export type WbsNodeInput = {
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  plannedStart?: Maybe<Scalars['DateTime']>;
  plannedEnd?: Maybe<Scalars['DateTime']>;
  status?: Maybe<WbsNodeStatus>;
};

export type Institution = {
  __typename?: 'Institution';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export type Basic = {
  __typename?: 'Basic';
  _?: Maybe<Scalars['Boolean']>;
};

export type Individual = {
  __typename?: 'Individual';
  activeUntil?: Maybe<Scalars['DateTime']>;
};

export type Enterprise = {
  __typename?: 'Enterprise';
  institution: Institution;
  activeUntil?: Maybe<Scalars['DateTime']>;
};

export type Plan = Basic | Individual | Enterprise;

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  handle?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  lastNames?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  profilePictureLink?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  licenseExpiresOn?: Maybe<Scalars['DateTime']>;
};

export type AuthenticatedUserQuery = {
  __typename?: 'AuthenticatedUserQuery';
  id: Scalars['ID'];
  plan?: Maybe<Plan>;
  getUser?: Maybe<User>;
  getProjects?: Maybe<Array<Maybe<Project>>>;
  getProject?: Maybe<Project>;
  getTags?: Maybe<Array<Tag>>;
  searchUsers?: Maybe<Array<User>>;
  hasSubmittedFeedback?: Maybe<Scalars['Boolean']>;
  getDutiesByDate?: Maybe<Array<WbsNode>>;
};


export type AuthenticatedUserQueryGetUserArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type AuthenticatedUserQueryGetProjectsArgs = {
  filters: ProjectFilter;
};


export type AuthenticatedUserQueryGetProjectArgs = {
  id: Scalars['ID'];
  step: Step;
};


export type AuthenticatedUserQuerySearchUsersArgs = {
  query: Scalars['String'];
};


export type AuthenticatedUserQueryGetDutiesByDateArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type HumanVerifiedMutation = {
  __typename?: 'HumanVerifiedMutation';
  login?: Maybe<LoginResponse>;
  getChangePasswordToken?: Maybe<SimpleMutationResponse>;
  getSignUpToken?: Maybe<SimpleMutationResponse>;
  getRevalidationEmail?: Maybe<SimpleMutationResponse>;
};


export type HumanVerifiedMutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type HumanVerifiedMutationGetChangePasswordTokenArgs = {
  email: Scalars['String'];
};


export type HumanVerifiedMutationGetSignUpTokenArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['ID'];
  wbsRoot?: Maybe<WbsNode>;
  name?: Maybe<Scalars['String']>;
  problems?: Maybe<Scalars['String']>;
  objective?: Maybe<Scalars['String']>;
  benefits?: Maybe<Array<Benefit>>;
  assumptions?: Maybe<Array<Assumption>>;
  restrictions?: Maybe<Array<Restriction>>;
  lessons?: Maybe<Array<Lesson>>;
  risks?: Maybe<Array<Risk>>;
  client?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  startEstimate?: Maybe<Scalars['DateTime']>;
  endEstimate?: Maybe<Scalars['DateTime']>;
  members?: Maybe<Array<Member>>;
  access?: Maybe<Access>;
  links?: Maybe<Array<Link>>;
  hasSubmittedFeedback?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Tag>>;
  workDays?: Maybe<Array<Scalars['Boolean']>>;
  statusComments?: Maybe<Scalars['String']>;
};

export enum StringFilterOption {
  Contains = 'CONTAINS'
}

export enum ProjectActionType {
  Read = 'READ',
  Write = 'WRITE'
}

export enum ProjectField {
  Name = 'name',
  Problems = 'problems',
  Objective = 'objective',
  Benefits = 'benefits',
  Client = 'client'
}

export type StringFilter = {
  field: ProjectField;
  option: StringFilterOption;
  text: Scalars['String'];
};

export type ProjectFilter = {
  textFilters: Array<StringFilter>;
};

export type Member = {
  __typename?: 'Member';
  id: Scalars['ID'];
  user?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  access?: Maybe<Access>;
  invitedOn?: Maybe<Scalars['DateTime']>;
  confirmedOn?: Maybe<Scalars['DateTime']>;
};

export type ProjectFeedbackFilters = {
  userId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
};

export type ProjectFeedbackInput = {
  reusable: Scalars['Int'];
  recommendable: Scalars['Int'];
  learnable: Scalars['Int'];
  comment: Scalars['String'];
};

export type SiteAdminQuery = {
  __typename?: 'SiteAdminQuery';
  getProjectTraffic?: Maybe<Array<ProjectTrafficEntry>>;
  getProjectFeedback?: Maybe<Array<ProjectFeedback>>;
};


export type SiteAdminQueryGetProjectTrafficArgs = {
  periodLength: Scalars['Int'];
  input: ProjectTrafficInput;
};


export type SiteAdminQueryGetProjectFeedbackArgs = {
  input: ProjectFeedbackFilters;
};

export enum Access {
  Read = 'READ',
  Write = 'WRITE',
  Admin = 'ADMIN'
}

export type MemberInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  access?: Maybe<Access>;
};

export type UserInput = {
  id: Scalars['ID'];
  handle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  lastNames?: Maybe<Scalars['String']>;
};

export type ProjectInput = {
  name?: Maybe<Scalars['String']>;
  problems?: Maybe<Scalars['String']>;
  objective?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  startEstimate?: Maybe<Scalars['DateTime']>;
  endEstimate?: Maybe<Scalars['DateTime']>;
  workDays?: Maybe<Array<Scalars['Boolean']>>;
  statusComments?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  asUser?: Maybe<AuthenticatedUserQuery>;
  asAdmin?: Maybe<SiteAdminQuery>;
};

export type ProjectAdminMutation = {
  __typename?: 'ProjectAdminMutation';
  projectId: Scalars['ID'];
  addMember?: Maybe<AddMemberResponse>;
  deleteMember?: Maybe<SimpleMutationResponse>;
  editMember?: Maybe<SimpleMutationResponse>;
  inviteMember?: Maybe<SimpleMutationResponse>;
  deleteProject?: Maybe<SimpleMutationResponse>;
};


export type ProjectAdminMutationDeleteMemberArgs = {
  id: Scalars['ID'];
};


export type ProjectAdminMutationEditMemberArgs = {
  input: MemberInput;
};


export type ProjectAdminMutationInviteMemberArgs = {
  memberId: Scalars['ID'];
};

export type AuthenticatedUserMutation = {
  __typename?: 'AuthenticatedUserMutation';
  revalidateEmail?: Maybe<SimpleMutationResponse>;
  createProject?: Maybe<CreateProjectResponse>;
  editProject?: Maybe<ProjectMutation>;
  editProjectAsAdmin?: Maybe<ProjectAdminMutation>;
  editProfile?: Maybe<EditProfileResponse>;
  confirmMemberInvitation?: Maybe<ConfirmMemberResponse>;
  abandonProject?: Maybe<SimpleMutationResponse>;
  createTag?: Maybe<CreateTagResponse>;
  deleteTag?: Maybe<SimpleMutationResponse>;
  editTag?: Maybe<SimpleMutationResponse>;
  submitFeedback?: Maybe<SimpleMutationResponse>;
  createPaymentSession?: Maybe<CreatePaymentSessionResponse>;
};


export type AuthenticatedUserMutationRevalidateEmailArgs = {
  token: Scalars['String'];
};


export type AuthenticatedUserMutationCreateProjectArgs = {
  name: Scalars['String'];
};


export type AuthenticatedUserMutationEditProjectArgs = {
  projectId: Scalars['ID'];
  step: Step;
};


export type AuthenticatedUserMutationEditProjectAsAdminArgs = {
  projectId: Scalars['ID'];
  step: Step;
};


export type AuthenticatedUserMutationEditProfileArgs = {
  input: UserInput;
  profilePicture?: Maybe<Scalars['Upload']>;
};


export type AuthenticatedUserMutationConfirmMemberInvitationArgs = {
  memberId: Scalars['ID'];
};


export type AuthenticatedUserMutationAbandonProjectArgs = {
  projectId: Scalars['ID'];
};


export type AuthenticatedUserMutationCreateTagArgs = {
  name: Scalars['String'];
};


export type AuthenticatedUserMutationDeleteTagArgs = {
  id: Scalars['ID'];
};


export type AuthenticatedUserMutationEditTagArgs = {
  input: TagInput;
};


export type AuthenticatedUserMutationSubmitFeedbackArgs = {
  input: UserFeedbackInput;
};

export type ProjectMutation = {
  __typename?: 'ProjectMutation';
  projectId: Scalars['ID'];
  setAttributes?: Maybe<SimpleMutationResponse>;
  createWbsNode?: Maybe<CreateWbsNodeResponse>;
  deleteWbsNode?: Maybe<SimpleMutationResponse>;
  editWbsNode?: Maybe<SimpleMutationResponse>;
  reorderWbsNode?: Maybe<SimpleMutationResponse>;
  setDuty?: Maybe<SimpleMutationResponse>;
  addLink?: Maybe<SimpleMutationResponse>;
  deleteLink?: Maybe<SimpleMutationResponse>;
  submitFeedback?: Maybe<SimpleMutationResponse>;
  addBenefit?: Maybe<AddBenefitResponse>;
  deleteBenefit?: Maybe<SimpleMutationResponse>;
  editBenefit?: Maybe<SimpleMutationResponse>;
  reorderBenefits?: Maybe<SimpleMutationResponse>;
  addAssumption?: Maybe<AddAssumptionResponse>;
  deleteAssumption?: Maybe<SimpleMutationResponse>;
  editAssumption?: Maybe<SimpleMutationResponse>;
  reorderAssumptions?: Maybe<SimpleMutationResponse>;
  addRestriction?: Maybe<AddRestrictionResponse>;
  deleteRestriction?: Maybe<SimpleMutationResponse>;
  editRestriction?: Maybe<SimpleMutationResponse>;
  reorderRestrictions?: Maybe<SimpleMutationResponse>;
  addLesson?: Maybe<AddLessonResponse>;
  deleteLesson?: Maybe<SimpleMutationResponse>;
  editLesson?: Maybe<SimpleMutationResponse>;
  reorderLessons?: Maybe<SimpleMutationResponse>;
  assignTag?: Maybe<SimpleMutationResponse>;
  unassignTag?: Maybe<SimpleMutationResponse>;
  addRisk?: Maybe<AddRiskResponse>;
  editRisk?: Maybe<SimpleMutationResponse>;
  deleteRisk?: Maybe<SimpleMutationResponse>;
};


export type ProjectMutationSetAttributesArgs = {
  input: ProjectInput;
};


export type ProjectMutationCreateWbsNodeArgs = {
  parentId: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};


export type ProjectMutationDeleteWbsNodeArgs = {
  id: Scalars['ID'];
};


export type ProjectMutationEditWbsNodeArgs = {
  input: WbsNodeInput;
};


export type ProjectMutationReorderWbsNodeArgs = {
  parentId: Scalars['ID'];
  idList: Array<Scalars['ID']>;
};


export type ProjectMutationSetDutyArgs = {
  memberId: Scalars['ID'];
  wbsNodeId: Scalars['ID'];
  job?: Maybe<Job>;
};


export type ProjectMutationAddLinkArgs = {
  link: LinkInput;
};


export type ProjectMutationDeleteLinkArgs = {
  id: Scalars['ID'];
};


export type ProjectMutationSubmitFeedbackArgs = {
  input: ProjectFeedbackInput;
};


export type ProjectMutationDeleteBenefitArgs = {
  id: Scalars['ID'];
};


export type ProjectMutationEditBenefitArgs = {
  input: BenefitInput;
};


export type ProjectMutationReorderBenefitsArgs = {
  idList: Array<Scalars['ID']>;
};


export type ProjectMutationDeleteAssumptionArgs = {
  id: Scalars['ID'];
};


export type ProjectMutationEditAssumptionArgs = {
  input: AssumptionInput;
};


export type ProjectMutationReorderAssumptionsArgs = {
  idList: Array<Scalars['ID']>;
};


export type ProjectMutationDeleteRestrictionArgs = {
  id: Scalars['ID'];
};


export type ProjectMutationEditRestrictionArgs = {
  input: RestrictionInput;
};


export type ProjectMutationReorderRestrictionsArgs = {
  idList: Array<Scalars['ID']>;
};


export type ProjectMutationDeleteLessonArgs = {
  id: Scalars['ID'];
};


export type ProjectMutationEditLessonArgs = {
  input: LessonInput;
};


export type ProjectMutationReorderLessonsArgs = {
  idList: Array<Scalars['ID']>;
};


export type ProjectMutationAssignTagArgs = {
  tagId: Scalars['ID'];
};


export type ProjectMutationUnassignTagArgs = {
  tagId: Scalars['ID'];
};


export type ProjectMutationEditRiskArgs = {
  input: RiskInput;
};


export type ProjectMutationDeleteRiskArgs = {
  id: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  asHuman?: Maybe<HumanVerifiedMutation>;
  changePassword?: Maybe<SimpleMutationResponse>;
  confirmAccount?: Maybe<ConfirmAccountResponse>;
  asUser?: Maybe<AuthenticatedUserMutation>;
};


export type MutationAsHumanArgs = {
  captchaKey: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  token: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationConfirmAccountArgs = {
  signUpToken: Scalars['String'];
  handle: Scalars['String'];
  name: Scalars['String'];
  lastNames: Scalars['String'];
  profilePicture?: Maybe<Scalars['Upload']>;
  userMetadata?: Maybe<UserMetadata>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserQuery' }
    & Pick<AuthenticatedUserQuery, 'id'>
    & { getUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'handle' | 'name' | 'lastNames' | 'created' | 'profilePictureLink'>
    )> }
  )> }
);

export type GetMyUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyUserInfoQuery = (
  { __typename?: 'Query' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserQuery' }
    & Pick<AuthenticatedUserQuery, 'id'>
    & { getUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'handle' | 'name' | 'lastNames' | 'created' | 'profilePictureLink' | 'isAdmin'>
    )> }
  )> }
);

export type EditProfileMutationVariables = Exact<{
  input: UserInput;
  profilePicture?: Maybe<Scalars['Upload']>;
}>;


export type EditProfileMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProfile?: Maybe<(
      { __typename?: 'EditProfileResponse' }
      & Pick<EditProfileResponse, 'message'>
      & { updatedUser: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'handle' | 'email' | 'isAdmin' | 'name' | 'lastNames' | 'profilePictureLink'>
      ) }
    )> }
  )> }
);

export type LoginMutationVariables = Exact<{
  captchaKey: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { asHuman?: Maybe<(
    { __typename?: 'HumanVerifiedMutation' }
    & { login?: Maybe<(
      { __typename?: 'LoginResponse' }
      & Pick<LoginResponse, 'message' | 'sessionToken'>
    )> }
  )> }
);

export type GetSignUpTokenMutationVariables = Exact<{
  captchaKey: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type GetSignUpTokenMutation = (
  { __typename?: 'Mutation' }
  & { asHuman?: Maybe<(
    { __typename?: 'HumanVerifiedMutation' }
    & { getSignUpToken?: Maybe<(
      { __typename?: 'SimpleMutationResponse' }
      & Pick<SimpleMutationResponse, 'message'>
    )> }
  )> }
);

export type ConfirmAccountMutationVariables = Exact<{
  token: Scalars['String'];
  handle: Scalars['String'];
  name: Scalars['String'];
  lastNames: Scalars['String'];
  profilePicture?: Maybe<Scalars['Upload']>;
}>;


export type ConfirmAccountMutation = (
  { __typename?: 'Mutation' }
  & { confirmAccount?: Maybe<(
    { __typename?: 'ConfirmAccountResponse' }
    & Pick<ConfirmAccountResponse, 'message' | 'sessionToken'>
  )> }
);

export type GetChangePasswordTokenMutationVariables = Exact<{
  captchaKey: Scalars['String'];
  email: Scalars['String'];
}>;


export type GetChangePasswordTokenMutation = (
  { __typename?: 'Mutation' }
  & { asHuman?: Maybe<(
    { __typename?: 'HumanVerifiedMutation' }
    & { getChangePasswordToken?: Maybe<(
      { __typename?: 'SimpleMutationResponse' }
      & Pick<SimpleMutationResponse, 'message'>
    )> }
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  token: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword?: Maybe<(
    { __typename?: 'SimpleMutationResponse' }
    & Pick<SimpleMutationResponse, 'message'>
  )> }
);

export type HasSubmittedFeedbackQueryVariables = Exact<{ [key: string]: never; }>;


export type HasSubmittedFeedbackQuery = (
  { __typename?: 'Query' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserQuery' }
    & Pick<AuthenticatedUserQuery, 'id' | 'hasSubmittedFeedback'>
  )> }
);

export type SubmitUserFeedbackMutationVariables = Exact<{
  input: UserFeedbackInput;
}>;


export type SubmitUserFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { submitFeedback?: Maybe<(
      { __typename?: 'SimpleMutationResponse' }
      & Pick<SimpleMutationResponse, 'message'>
    )> }
  )> }
);

export type GetPlanQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlanQuery = (
  { __typename?: 'Query' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserQuery' }
    & Pick<AuthenticatedUserQuery, 'id'>
    & { plan?: Maybe<(
      { __typename: 'Basic' }
      & Pick<Basic, '_'>
    ) | (
      { __typename: 'Individual' }
      & Pick<Individual, 'activeUntil'>
    ) | (
      { __typename: 'Enterprise' }
      & Pick<Enterprise, 'activeUntil'>
      & { institution: (
        { __typename?: 'Institution' }
        & Pick<Institution, 'id' | 'name' | 'logoUrl'>
      ) }
    )> }
  )> }
);

export type GetRevalidationEmailMutationVariables = Exact<{
  captchaKey: Scalars['String'];
}>;


export type GetRevalidationEmailMutation = (
  { __typename?: 'Mutation' }
  & { asHuman?: Maybe<(
    { __typename?: 'HumanVerifiedMutation' }
    & { getRevalidationEmail?: Maybe<(
      { __typename?: 'SimpleMutationResponse' }
      & Pick<SimpleMutationResponse, 'message'>
    )> }
  )> }
);

export type CreatePaymentSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type CreatePaymentSessionMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { createPaymentSession?: Maybe<(
      { __typename?: 'CreatePaymentSessionResponse' }
      & Pick<CreatePaymentSessionResponse, 'message' | 'portalSessionUrl'>
    )> }
  )> }
);

export type RevalidateEmailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type RevalidateEmailMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { revalidateEmail?: Maybe<(
      { __typename?: 'SimpleMutationResponse' }
      & Pick<SimpleMutationResponse, 'message'>
    )> }
  )> }
);

export type GetTrafficQueryVariables = Exact<{
  periodLength: Scalars['Int'];
  input: ProjectTrafficInput;
}>;


export type GetTrafficQuery = (
  { __typename?: 'Query' }
  & { asAdmin?: Maybe<(
    { __typename?: 'SiteAdminQuery' }
    & { getProjectTraffic?: Maybe<Array<(
      { __typename?: 'ProjectTrafficEntry' }
      & Pick<ProjectTrafficEntry, 'step' | 'type' | 'periodsSince' | 'frequency'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'handle' | 'name' | 'lastNames' | 'email'>
      )>, project?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type GetProjectFeedbackQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectFeedbackQuery = (
  { __typename?: 'Query' }
  & { asAdmin?: Maybe<(
    { __typename?: 'SiteAdminQuery' }
    & { getProjectFeedback?: Maybe<Array<(
      { __typename?: 'ProjectFeedback' }
      & Pick<ProjectFeedback, 'comment' | 'recommendable' | 'reusable' | 'learnable'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'handle' | 'name' | 'lastNames' | 'email'>
      )>, project?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type CoreMemberFieldsFragment = (
  { __typename?: 'Member' }
  & Pick<Member, 'id' | 'name' | 'access' | 'invitedOn' | 'confirmedOn'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'handle' | 'name' | 'lastNames' | 'profilePictureLink'>
  )> }
);

export type GetProjectsQueryVariables = Exact<{
  textFilters: Array<StringFilter> | StringFilter;
}>;


export type GetProjectsQuery = (
  { __typename?: 'Query' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserQuery' }
    & Pick<AuthenticatedUserQuery, 'id'>
    & { getTags?: Maybe<Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )>>, getProjects?: Maybe<Array<Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'startEstimate' | 'endEstimate' | 'access'>
      & { wbsRoot?: Maybe<(
        { __typename?: 'WbsNode' }
        & Pick<WbsNode, 'id' | 'plannedStart' | 'plannedEnd' | 'startDate' | 'endDate'>
        & { children?: Maybe<Array<(
          { __typename?: 'WbsNode' }
          & Pick<WbsNode, 'id'>
          & { children?: Maybe<Array<(
            { __typename?: 'WbsNode' }
            & Pick<WbsNode, 'id' | 'plannedStart' | 'plannedEnd' | 'startDate' | 'endDate' | 'status'>
          )>> }
        )>> }
      )>, tags?: Maybe<Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name'>
      )>> }
    )>>> }
  )> }
);

export type DeleteProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
}>;


export type DeleteProjectMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProjectAsAdmin?: Maybe<(
      { __typename?: 'ProjectAdminMutation' }
      & { deleteProject?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type CreateProjectMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { createProject?: Maybe<(
      { __typename?: 'CreateProjectResponse' }
      & Pick<CreateProjectResponse, 'message'>
      & { newProject: (
        { __typename?: 'Project' }
        & Pick<Project, 'id'>
      ) }
    )> }
  )> }
);

export type GetProjectQueryVariables = Exact<{
  id: Scalars['ID'];
  step: Step;
}>;


export type GetProjectQuery = (
  { __typename?: 'Query' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserQuery' }
    & Pick<AuthenticatedUserQuery, 'id'>
    & { getTags?: Maybe<Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )>>, getProject?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'access' | 'problems' | 'objective' | 'workDays' | 'client' | 'startEstimate' | 'endEstimate'>
      & { benefits?: Maybe<Array<(
        { __typename?: 'Benefit' }
        & Pick<Benefit, 'id' | 'text'>
      )>>, assumptions?: Maybe<Array<(
        { __typename?: 'Assumption' }
        & Pick<Assumption, 'id' | 'text'>
      )>>, restrictions?: Maybe<Array<(
        { __typename?: 'Restriction' }
        & Pick<Restriction, 'id' | 'text'>
      )>>, wbsRoot?: Maybe<(
        { __typename?: 'WbsNode' }
        & Pick<WbsNode, 'id'>
        & { children?: Maybe<Array<(
          { __typename?: 'WbsNode' }
          & Pick<WbsNode, 'id' | 'text'>
        )>> }
      )>, members?: Maybe<Array<(
        { __typename?: 'Member' }
        & CoreMemberFieldsFragment
      )>>, tags?: Maybe<Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name'>
      )>> }
    )> }
  )> }
);

export type GetProjectNameQueryVariables = Exact<{
  id: Scalars['ID'];
  step: Step;
}>;


export type GetProjectNameQuery = (
  { __typename?: 'Query' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserQuery' }
    & Pick<AuthenticatedUserQuery, 'id'>
    & { getProject?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name'>
    )> }
  )> }
);

export type GetProjectAccessQueryVariables = Exact<{
  id: Scalars['ID'];
  step: Step;
}>;


export type GetProjectAccessQuery = (
  { __typename?: 'Query' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserQuery' }
    & Pick<AuthenticatedUserQuery, 'id'>
    & { getProject?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'access'>
    )> }
  )> }
);

export type EditProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
  input: ProjectInput;
}>;


export type EditProjectMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { setAttributes?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type DitWbsNodeMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
  input: WbsNodeInput;
}>;


export type DitWbsNodeMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { editWbsNode?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type ReorderWbsNodeMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
  parentId: Scalars['ID'];
  idList: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ReorderWbsNodeMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { reorderWbsNode?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type CreateWbsNodeMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
  parentId: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
}>;


export type CreateWbsNodeMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { createWbsNode?: Maybe<(
        { __typename?: 'CreateWbsNodeResponse' }
        & Pick<CreateWbsNodeResponse, 'message'>
        & { newWbsNode: (
          { __typename?: 'WbsNode' }
          & Pick<WbsNode, 'id' | 'text'>
        ) }
      )> }
    )> }
  )> }
);

export type DeleteWbsNodeMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
  id: Scalars['ID'];
}>;


export type DeleteWbsNodeMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { deleteWbsNode?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type GetWbsQueryVariables = Exact<{
  id: Scalars['ID'];
  step: Step;
}>;


export type GetWbsQuery = (
  { __typename?: 'Query' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserQuery' }
    & Pick<AuthenticatedUserQuery, 'id'>
    & { getProject?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'access'>
      & { wbsRoot?: Maybe<(
        { __typename?: 'WbsNode' }
        & Pick<WbsNode, 'id' | 'text'>
        & { children?: Maybe<Array<(
          { __typename?: 'WbsNode' }
          & Pick<WbsNode, 'id' | 'text'>
          & { children?: Maybe<Array<(
            { __typename?: 'WbsNode' }
            & Pick<WbsNode, 'id' | 'text'>
          )>> }
        )>> }
      )> }
    )> }
  )> }
);

export type GetMembersQueryVariables = Exact<{
  id: Scalars['ID'];
  step: Step;
}>;


export type GetMembersQuery = (
  { __typename?: 'Query' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserQuery' }
    & Pick<AuthenticatedUserQuery, 'id'>
    & { getProject?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'access'>
      & { members?: Maybe<Array<(
        { __typename?: 'Member' }
        & Pick<Member, 'id' | 'name' | 'access'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'handle'>
        )> }
      )>>, wbsRoot?: Maybe<(
        { __typename?: 'WbsNode' }
        & Pick<WbsNode, 'id' | 'text'>
        & { duties?: Maybe<Array<(
          { __typename?: 'Duty' }
          & Pick<Duty, 'memberId'>
        )>>, children?: Maybe<Array<(
          { __typename?: 'WbsNode' }
          & Pick<WbsNode, 'id' | 'text'>
          & { duties?: Maybe<Array<(
            { __typename?: 'Duty' }
            & Pick<Duty, 'memberId'>
          )>>, children?: Maybe<Array<(
            { __typename?: 'WbsNode' }
            & Pick<WbsNode, 'id' | 'text'>
            & { duties?: Maybe<Array<(
              { __typename?: 'Duty' }
              & Pick<Duty, 'memberId'>
            )>> }
          )>> }
        )>> }
      )> }
    )> }
  )> }
);

export type SetDutyMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
  memberId: Scalars['ID'];
  wbsNodeId: Scalars['ID'];
  job?: Maybe<Job>;
}>;


export type SetDutyMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { setDuty?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type GetDatesCuandoQueryVariables = Exact<{
  id: Scalars['ID'];
  step: Step;
}>;


export type GetDatesCuandoQuery = (
  { __typename?: 'Query' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserQuery' }
    & Pick<AuthenticatedUserQuery, 'id'>
    & { getProject?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'access' | 'startEstimate' | 'workDays'>
      & { members?: Maybe<Array<(
        { __typename?: 'Member' }
        & Pick<Member, 'id' | 'name'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'handle'>
        )> }
      )>>, assumptions?: Maybe<Array<(
        { __typename?: 'Assumption' }
        & Pick<Assumption, 'id' | 'text'>
      )>>, restrictions?: Maybe<Array<(
        { __typename?: 'Restriction' }
        & Pick<Restriction, 'id' | 'text'>
      )>>, wbsRoot?: Maybe<(
        { __typename?: 'WbsNode' }
        & Pick<WbsNode, 'id' | 'text' | 'plannedStart' | 'plannedEnd'>
        & { duties?: Maybe<Array<(
          { __typename?: 'Duty' }
          & Pick<Duty, 'memberId'>
        )>>, children?: Maybe<Array<(
          { __typename?: 'WbsNode' }
          & Pick<WbsNode, 'id' | 'text' | 'plannedStart' | 'plannedEnd'>
          & { duties?: Maybe<Array<(
            { __typename?: 'Duty' }
            & Pick<Duty, 'memberId'>
          )>>, children?: Maybe<Array<(
            { __typename?: 'WbsNode' }
            & Pick<WbsNode, 'id' | 'text' | 'plannedStart' | 'plannedEnd'>
            & { duties?: Maybe<Array<(
              { __typename?: 'Duty' }
              & Pick<Duty, 'memberId'>
            )>> }
          )>> }
        )>> }
      )>, links?: Maybe<Array<(
        { __typename?: 'Link' }
        & Pick<Link, 'id' | 'source' | 'target' | 'type'>
      )>>, risks?: Maybe<Array<(
        { __typename?: 'Risk' }
        & Pick<Risk, 'id' | 'text' | 'impact' | 'probability' | 'actions' | 'responsibleId'>
      )>> }
    )> }
  )> }
);

export type GetDatesComoterminoQueryVariables = Exact<{
  id: Scalars['ID'];
  step: Step;
}>;


export type GetDatesComoterminoQuery = (
  { __typename?: 'Query' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserQuery' }
    & Pick<AuthenticatedUserQuery, 'id'>
    & { getProject?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'access' | 'workDays' | 'hasSubmittedFeedback'>
      & { lessons?: Maybe<Array<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'id' | 'text'>
      )>>, benefits?: Maybe<Array<(
        { __typename?: 'Benefit' }
        & Pick<Benefit, 'id' | 'text'>
      )>>, wbsRoot?: Maybe<(
        { __typename?: 'WbsNode' }
        & Pick<WbsNode, 'id' | 'text' | 'startDate' | 'endDate' | 'plannedStart' | 'plannedEnd' | 'status'>
        & { children?: Maybe<Array<(
          { __typename?: 'WbsNode' }
          & Pick<WbsNode, 'id' | 'text' | 'startDate' | 'endDate' | 'plannedStart' | 'plannedEnd'>
        )>> }
      )> }
    )> }
  )> }
);

export type GetDatesComovaQueryVariables = Exact<{
  id: Scalars['ID'];
  step: Step;
}>;


export type GetDatesComovaQuery = (
  { __typename?: 'Query' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserQuery' }
    & Pick<AuthenticatedUserQuery, 'id'>
    & { getProject?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'access' | 'workDays' | 'statusComments'>
      & { assumptions?: Maybe<Array<(
        { __typename?: 'Assumption' }
        & Pick<Assumption, 'id' | 'text'>
      )>>, restrictions?: Maybe<Array<(
        { __typename?: 'Restriction' }
        & Pick<Restriction, 'id' | 'text'>
      )>>, members?: Maybe<Array<(
        { __typename?: 'Member' }
        & Pick<Member, 'id' | 'name'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'handle'>
        )> }
      )>>, wbsRoot?: Maybe<(
        { __typename?: 'WbsNode' }
        & Pick<WbsNode, 'id' | 'text' | 'startDate' | 'endDate' | 'plannedStart' | 'plannedEnd' | 'status'>
        & { duties?: Maybe<Array<(
          { __typename?: 'Duty' }
          & Pick<Duty, 'memberId'>
        )>>, children?: Maybe<Array<(
          { __typename?: 'WbsNode' }
          & Pick<WbsNode, 'id' | 'text' | 'startDate' | 'endDate' | 'plannedStart' | 'plannedEnd' | 'status'>
          & { duties?: Maybe<Array<(
            { __typename?: 'Duty' }
            & Pick<Duty, 'memberId'>
          )>>, children?: Maybe<Array<(
            { __typename?: 'WbsNode' }
            & Pick<WbsNode, 'id' | 'text' | 'startDate' | 'endDate' | 'plannedStart' | 'plannedEnd' | 'status'>
            & { duties?: Maybe<Array<(
              { __typename?: 'Duty' }
              & Pick<Duty, 'memberId'>
            )>> }
          )>> }
        )>> }
      )>, links?: Maybe<Array<(
        { __typename?: 'Link' }
        & Pick<Link, 'id' | 'source' | 'target' | 'type'>
      )>> }
    )> }
  )> }
);

export type SearchUsersQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type SearchUsersQuery = (
  { __typename?: 'Query' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserQuery' }
    & Pick<AuthenticatedUserQuery, 'id'>
    & { searchUsers?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'handle' | 'name' | 'lastNames' | 'profilePictureLink'>
    )>> }
  )> }
);

export type AddMemberMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
}>;


export type AddMemberMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProjectAsAdmin?: Maybe<(
      { __typename?: 'ProjectAdminMutation' }
      & { addMember?: Maybe<(
        { __typename?: 'AddMemberResponse' }
        & Pick<AddMemberResponse, 'message'>
        & { newMember: (
          { __typename?: 'Member' }
          & CoreMemberFieldsFragment
        ) }
      )> }
    )> }
  )> }
);

export type DeleteMemberMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
  id: Scalars['ID'];
}>;


export type DeleteMemberMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProjectAsAdmin?: Maybe<(
      { __typename?: 'ProjectAdminMutation' }
      & { deleteMember?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type EditMemberMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
  input: MemberInput;
}>;


export type EditMemberMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProjectAsAdmin?: Maybe<(
      { __typename?: 'ProjectAdminMutation' }
      & { editMember?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type AddLinkMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
  link: LinkInput;
}>;


export type AddLinkMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { addLink?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type DeleteLinkMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
  id: Scalars['ID'];
}>;


export type DeleteLinkMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { deleteLink?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type SubmitFeedbackMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
  input: ProjectFeedbackInput;
}>;


export type SubmitFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { submitFeedback?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type ConfirmMemberInvitationMutationVariables = Exact<{
  memberId: Scalars['ID'];
}>;


export type ConfirmMemberInvitationMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { confirmMemberInvitation?: Maybe<(
      { __typename?: 'ConfirmMemberResponse' }
      & Pick<ConfirmMemberResponse, 'message'>
      & { newProject: (
        { __typename?: 'Project' }
        & Pick<Project, 'id'>
      ) }
    )> }
  )> }
);

export type InviteMemberMutationVariables = Exact<{
  projectId: Scalars['ID'];
  memberId: Scalars['ID'];
  step: Step;
}>;


export type InviteMemberMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProjectAsAdmin?: Maybe<(
      { __typename?: 'ProjectAdminMutation' }
      & { inviteMember?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type AbandonProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type AbandonProjectMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { abandonProject?: Maybe<(
      { __typename?: 'SimpleMutationResponse' }
      & Pick<SimpleMutationResponse, 'message'>
    )> }
  )> }
);

export type AddBenefitMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
}>;


export type AddBenefitMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { addBenefit?: Maybe<(
        { __typename?: 'AddBenefitResponse' }
        & { newBenefit: (
          { __typename?: 'Benefit' }
          & Pick<Benefit, 'id' | 'text'>
        ) }
      )> }
    )> }
  )> }
);

export type DeleteBenefitMutationVariables = Exact<{
  projectId: Scalars['ID'];
  id: Scalars['ID'];
  step: Step;
}>;


export type DeleteBenefitMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { deleteBenefit?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type EditBenefitMutationVariables = Exact<{
  projectId: Scalars['ID'];
  input: BenefitInput;
  step: Step;
}>;


export type EditBenefitMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { editBenefit?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type ReorderBenefitsMutationVariables = Exact<{
  projectId: Scalars['ID'];
  idList: Array<Scalars['ID']> | Scalars['ID'];
  step: Step;
}>;


export type ReorderBenefitsMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { reorderBenefits?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type AddAssumptionMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
}>;


export type AddAssumptionMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { addAssumption?: Maybe<(
        { __typename?: 'AddAssumptionResponse' }
        & { newAssumption: (
          { __typename?: 'Assumption' }
          & Pick<Assumption, 'id' | 'text'>
        ) }
      )> }
    )> }
  )> }
);

export type DeleteAssumptionMutationVariables = Exact<{
  projectId: Scalars['ID'];
  id: Scalars['ID'];
  step: Step;
}>;


export type DeleteAssumptionMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { deleteAssumption?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type EditAssumptionMutationVariables = Exact<{
  projectId: Scalars['ID'];
  input: AssumptionInput;
  step: Step;
}>;


export type EditAssumptionMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { editAssumption?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type ReorderAssumptionsMutationVariables = Exact<{
  projectId: Scalars['ID'];
  idList: Array<Scalars['ID']> | Scalars['ID'];
  step: Step;
}>;


export type ReorderAssumptionsMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { reorderAssumptions?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type AddRestrictionMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
}>;


export type AddRestrictionMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { addRestriction?: Maybe<(
        { __typename?: 'AddRestrictionResponse' }
        & { newRestriction: (
          { __typename?: 'Restriction' }
          & Pick<Restriction, 'id' | 'text'>
        ) }
      )> }
    )> }
  )> }
);

export type DeleteRestrictionMutationVariables = Exact<{
  projectId: Scalars['ID'];
  id: Scalars['ID'];
  step: Step;
}>;


export type DeleteRestrictionMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { deleteRestriction?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type EditRestrictionMutationVariables = Exact<{
  projectId: Scalars['ID'];
  input: RestrictionInput;
  step: Step;
}>;


export type EditRestrictionMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { editRestriction?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type ReorderRestrictionsMutationVariables = Exact<{
  projectId: Scalars['ID'];
  idList: Array<Scalars['ID']> | Scalars['ID'];
  step: Step;
}>;


export type ReorderRestrictionsMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { reorderRestrictions?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type AddLessonMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
}>;


export type AddLessonMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { addLesson?: Maybe<(
        { __typename?: 'AddLessonResponse' }
        & { newLesson: (
          { __typename?: 'Lesson' }
          & Pick<Lesson, 'id' | 'text'>
        ) }
      )> }
    )> }
  )> }
);

export type DeleteLessonMutationVariables = Exact<{
  projectId: Scalars['ID'];
  id: Scalars['ID'];
  step: Step;
}>;


export type DeleteLessonMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { deleteLesson?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type EditLessonMutationVariables = Exact<{
  projectId: Scalars['ID'];
  input: LessonInput;
  step: Step;
}>;


export type EditLessonMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { editLesson?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type ReorderLessonsMutationVariables = Exact<{
  projectId: Scalars['ID'];
  idList: Array<Scalars['ID']> | Scalars['ID'];
  step: Step;
}>;


export type ReorderLessonsMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { reorderLessons?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type AssignTagMutationVariables = Exact<{
  projectId: Scalars['ID'];
  tagId: Scalars['ID'];
  step: Step;
}>;


export type AssignTagMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { assignTag?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type UnassignTagMutationVariables = Exact<{
  projectId: Scalars['ID'];
  tagId: Scalars['ID'];
  step: Step;
}>;


export type UnassignTagMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { unassignTag?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type GetDutiesByDateQueryVariables = Exact<{
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
}>;


export type GetDutiesByDateQuery = (
  { __typename?: 'Query' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserQuery' }
    & Pick<AuthenticatedUserQuery, 'id'>
    & { getDutiesByDate?: Maybe<Array<(
      { __typename?: 'WbsNode' }
      & Pick<WbsNode, 'id' | 'text' | 'status' | 'plannedEnd' | 'endDate' | 'projectId'>
    )>> }
  )> }
);

export type AddRiskMutationVariables = Exact<{
  projectId: Scalars['ID'];
  step: Step;
}>;


export type AddRiskMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { addRisk?: Maybe<(
        { __typename?: 'AddRiskResponse' }
        & { newRisk: (
          { __typename?: 'Risk' }
          & Pick<Risk, 'id' | 'text' | 'impact' | 'probability' | 'actions' | 'responsibleId'>
        ) }
      )> }
    )> }
  )> }
);

export type DeleteRiskMutationVariables = Exact<{
  projectId: Scalars['ID'];
  id: Scalars['ID'];
  step: Step;
}>;


export type DeleteRiskMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { deleteRisk?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type EditRiskMutationVariables = Exact<{
  projectId: Scalars['ID'];
  input: RiskInput;
  step: Step;
}>;


export type EditRiskMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editProject?: Maybe<(
      { __typename?: 'ProjectMutation' }
      & { editRisk?: Maybe<(
        { __typename?: 'SimpleMutationResponse' }
        & Pick<SimpleMutationResponse, 'message'>
      )> }
    )> }
  )> }
);

export type GetTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTagsQuery = (
  { __typename?: 'Query' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserQuery' }
    & Pick<AuthenticatedUserQuery, 'id'>
    & { getTags?: Maybe<Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )>> }
  )> }
);

export type CreateTagMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateTagMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { createTag?: Maybe<(
      { __typename?: 'CreateTagResponse' }
      & Pick<CreateTagResponse, 'message'>
      & { newTag: (
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type DeleteTagMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTagMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { deleteTag?: Maybe<(
      { __typename?: 'SimpleMutationResponse' }
      & Pick<SimpleMutationResponse, 'message'>
    )> }
  )> }
);

export type EditTagMutationVariables = Exact<{
  input: TagInput;
}>;


export type EditTagMutation = (
  { __typename?: 'Mutation' }
  & { asUser?: Maybe<(
    { __typename?: 'AuthenticatedUserMutation' }
    & { editTag?: Maybe<(
      { __typename?: 'SimpleMutationResponse' }
      & Pick<SimpleMutationResponse, 'message'>
    )> }
  )> }
);

export const CoreMemberFieldsFragmentDoc = gql`
    fragment CoreMemberFields on Member {
  id
  name
  user {
    id
    handle
    name
    lastNames
    profilePictureLink
  }
  access
  invitedOn
  confirmedOn
}
    `;
export const GetUserDocument = gql`
    query getUser($id: ID!) {
  asUser {
    id
    getUser(id: $id) {
      id
      handle
      name
      lastNames
      created
      profilePictureLink
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetMyUserInfoDocument = gql`
    query getMyUserInfo {
  asUser {
    id
    getUser {
      id
      email
      handle
      name
      lastNames
      created
      profilePictureLink
      isAdmin
    }
  }
}
    `;

/**
 * __useGetMyUserInfoQuery__
 *
 * To run a query within a React component, call `useGetMyUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetMyUserInfoQuery, GetMyUserInfoQueryVariables>) {
        return Apollo.useQuery<GetMyUserInfoQuery, GetMyUserInfoQueryVariables>(GetMyUserInfoDocument, baseOptions);
      }
export function useGetMyUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyUserInfoQuery, GetMyUserInfoQueryVariables>) {
          return Apollo.useLazyQuery<GetMyUserInfoQuery, GetMyUserInfoQueryVariables>(GetMyUserInfoDocument, baseOptions);
        }
export type GetMyUserInfoQueryHookResult = ReturnType<typeof useGetMyUserInfoQuery>;
export type GetMyUserInfoLazyQueryHookResult = ReturnType<typeof useGetMyUserInfoLazyQuery>;
export type GetMyUserInfoQueryResult = Apollo.QueryResult<GetMyUserInfoQuery, GetMyUserInfoQueryVariables>;
export const EditProfileDocument = gql`
    mutation editProfile($input: UserInput!, $profilePicture: Upload) {
  asUser {
    editProfile(input: $input, profilePicture: $profilePicture) {
      message
      updatedUser {
        id
        handle
        email
        isAdmin
        name
        lastNames
        profilePictureLink
      }
    }
  }
}
    `;
export type EditProfileMutationFn = Apollo.MutationFunction<EditProfileMutation, EditProfileMutationVariables>;

/**
 * __useEditProfileMutation__
 *
 * To run a mutation, you first call `useEditProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProfileMutation, { data, loading, error }] = useEditProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *      profilePicture: // value for 'profilePicture'
 *   },
 * });
 */
export function useEditProfileMutation(baseOptions?: Apollo.MutationHookOptions<EditProfileMutation, EditProfileMutationVariables>) {
        return Apollo.useMutation<EditProfileMutation, EditProfileMutationVariables>(EditProfileDocument, baseOptions);
      }
export type EditProfileMutationHookResult = ReturnType<typeof useEditProfileMutation>;
export type EditProfileMutationResult = Apollo.MutationResult<EditProfileMutation>;
export type EditProfileMutationOptions = Apollo.BaseMutationOptions<EditProfileMutation, EditProfileMutationVariables>;
export const LoginDocument = gql`
    mutation login($captchaKey: String!, $email: String!, $password: String!) {
  asHuman(captchaKey: $captchaKey) {
    login(email: $email, password: $password) {
      message
      sessionToken
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      captchaKey: // value for 'captchaKey'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const GetSignUpTokenDocument = gql`
    mutation getSignUpToken($captchaKey: String!, $email: String!, $password: String!) {
  asHuman(captchaKey: $captchaKey) {
    getSignUpToken(email: $email, password: $password) {
      message
    }
  }
}
    `;
export type GetSignUpTokenMutationFn = Apollo.MutationFunction<GetSignUpTokenMutation, GetSignUpTokenMutationVariables>;

/**
 * __useGetSignUpTokenMutation__
 *
 * To run a mutation, you first call `useGetSignUpTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSignUpTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSignUpTokenMutation, { data, loading, error }] = useGetSignUpTokenMutation({
 *   variables: {
 *      captchaKey: // value for 'captchaKey'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetSignUpTokenMutation(baseOptions?: Apollo.MutationHookOptions<GetSignUpTokenMutation, GetSignUpTokenMutationVariables>) {
        return Apollo.useMutation<GetSignUpTokenMutation, GetSignUpTokenMutationVariables>(GetSignUpTokenDocument, baseOptions);
      }
export type GetSignUpTokenMutationHookResult = ReturnType<typeof useGetSignUpTokenMutation>;
export type GetSignUpTokenMutationResult = Apollo.MutationResult<GetSignUpTokenMutation>;
export type GetSignUpTokenMutationOptions = Apollo.BaseMutationOptions<GetSignUpTokenMutation, GetSignUpTokenMutationVariables>;
export const ConfirmAccountDocument = gql`
    mutation confirmAccount($token: String!, $handle: String!, $name: String!, $lastNames: String!, $profilePicture: Upload) {
  confirmAccount(
    signUpToken: $token
    handle: $handle
    name: $name
    lastNames: $lastNames
    profilePicture: $profilePicture
  ) {
    message
    sessionToken
  }
}
    `;
export type ConfirmAccountMutationFn = Apollo.MutationFunction<ConfirmAccountMutation, ConfirmAccountMutationVariables>;

/**
 * __useConfirmAccountMutation__
 *
 * To run a mutation, you first call `useConfirmAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmAccountMutation, { data, loading, error }] = useConfirmAccountMutation({
 *   variables: {
 *      token: // value for 'token'
 *      handle: // value for 'handle'
 *      name: // value for 'name'
 *      lastNames: // value for 'lastNames'
 *      profilePicture: // value for 'profilePicture'
 *   },
 * });
 */
export function useConfirmAccountMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmAccountMutation, ConfirmAccountMutationVariables>) {
        return Apollo.useMutation<ConfirmAccountMutation, ConfirmAccountMutationVariables>(ConfirmAccountDocument, baseOptions);
      }
export type ConfirmAccountMutationHookResult = ReturnType<typeof useConfirmAccountMutation>;
export type ConfirmAccountMutationResult = Apollo.MutationResult<ConfirmAccountMutation>;
export type ConfirmAccountMutationOptions = Apollo.BaseMutationOptions<ConfirmAccountMutation, ConfirmAccountMutationVariables>;
export const GetChangePasswordTokenDocument = gql`
    mutation getChangePasswordToken($captchaKey: String!, $email: String!) {
  asHuman(captchaKey: $captchaKey) {
    getChangePasswordToken(email: $email) {
      message
    }
  }
}
    `;
export type GetChangePasswordTokenMutationFn = Apollo.MutationFunction<GetChangePasswordTokenMutation, GetChangePasswordTokenMutationVariables>;

/**
 * __useGetChangePasswordTokenMutation__
 *
 * To run a mutation, you first call `useGetChangePasswordTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetChangePasswordTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getChangePasswordTokenMutation, { data, loading, error }] = useGetChangePasswordTokenMutation({
 *   variables: {
 *      captchaKey: // value for 'captchaKey'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetChangePasswordTokenMutation(baseOptions?: Apollo.MutationHookOptions<GetChangePasswordTokenMutation, GetChangePasswordTokenMutationVariables>) {
        return Apollo.useMutation<GetChangePasswordTokenMutation, GetChangePasswordTokenMutationVariables>(GetChangePasswordTokenDocument, baseOptions);
      }
export type GetChangePasswordTokenMutationHookResult = ReturnType<typeof useGetChangePasswordTokenMutation>;
export type GetChangePasswordTokenMutationResult = Apollo.MutationResult<GetChangePasswordTokenMutation>;
export type GetChangePasswordTokenMutationOptions = Apollo.BaseMutationOptions<GetChangePasswordTokenMutation, GetChangePasswordTokenMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($token: String!, $newPassword: String!) {
  changePassword(token: $token, newPassword: $newPassword) {
    message
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const HasSubmittedFeedbackDocument = gql`
    query hasSubmittedFeedback {
  asUser {
    id
    hasSubmittedFeedback
  }
}
    `;

/**
 * __useHasSubmittedFeedbackQuery__
 *
 * To run a query within a React component, call `useHasSubmittedFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasSubmittedFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasSubmittedFeedbackQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasSubmittedFeedbackQuery(baseOptions?: Apollo.QueryHookOptions<HasSubmittedFeedbackQuery, HasSubmittedFeedbackQueryVariables>) {
        return Apollo.useQuery<HasSubmittedFeedbackQuery, HasSubmittedFeedbackQueryVariables>(HasSubmittedFeedbackDocument, baseOptions);
      }
export function useHasSubmittedFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasSubmittedFeedbackQuery, HasSubmittedFeedbackQueryVariables>) {
          return Apollo.useLazyQuery<HasSubmittedFeedbackQuery, HasSubmittedFeedbackQueryVariables>(HasSubmittedFeedbackDocument, baseOptions);
        }
export type HasSubmittedFeedbackQueryHookResult = ReturnType<typeof useHasSubmittedFeedbackQuery>;
export type HasSubmittedFeedbackLazyQueryHookResult = ReturnType<typeof useHasSubmittedFeedbackLazyQuery>;
export type HasSubmittedFeedbackQueryResult = Apollo.QueryResult<HasSubmittedFeedbackQuery, HasSubmittedFeedbackQueryVariables>;
export const SubmitUserFeedbackDocument = gql`
    mutation submitUserFeedback($input: UserFeedbackInput!) {
  asUser {
    submitFeedback(input: $input) {
      message
    }
  }
}
    `;
export type SubmitUserFeedbackMutationFn = Apollo.MutationFunction<SubmitUserFeedbackMutation, SubmitUserFeedbackMutationVariables>;

/**
 * __useSubmitUserFeedbackMutation__
 *
 * To run a mutation, you first call `useSubmitUserFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitUserFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitUserFeedbackMutation, { data, loading, error }] = useSubmitUserFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitUserFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<SubmitUserFeedbackMutation, SubmitUserFeedbackMutationVariables>) {
        return Apollo.useMutation<SubmitUserFeedbackMutation, SubmitUserFeedbackMutationVariables>(SubmitUserFeedbackDocument, baseOptions);
      }
export type SubmitUserFeedbackMutationHookResult = ReturnType<typeof useSubmitUserFeedbackMutation>;
export type SubmitUserFeedbackMutationResult = Apollo.MutationResult<SubmitUserFeedbackMutation>;
export type SubmitUserFeedbackMutationOptions = Apollo.BaseMutationOptions<SubmitUserFeedbackMutation, SubmitUserFeedbackMutationVariables>;
export const GetPlanDocument = gql`
    query getPlan {
  asUser {
    id
    plan {
      __typename
      ... on Basic {
        _
      }
      ... on Individual {
        activeUntil
      }
      ... on Enterprise {
        institution {
          id
          name
          logoUrl
        }
        activeUntil
      }
    }
  }
}
    `;

/**
 * __useGetPlanQuery__
 *
 * To run a query within a React component, call `useGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlanQuery(baseOptions?: Apollo.QueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
        return Apollo.useQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, baseOptions);
      }
export function useGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
          return Apollo.useLazyQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, baseOptions);
        }
export type GetPlanQueryHookResult = ReturnType<typeof useGetPlanQuery>;
export type GetPlanLazyQueryHookResult = ReturnType<typeof useGetPlanLazyQuery>;
export type GetPlanQueryResult = Apollo.QueryResult<GetPlanQuery, GetPlanQueryVariables>;
export const GetRevalidationEmailDocument = gql`
    mutation getRevalidationEmail($captchaKey: String!) {
  asHuman(captchaKey: $captchaKey) {
    getRevalidationEmail {
      message
    }
  }
}
    `;
export type GetRevalidationEmailMutationFn = Apollo.MutationFunction<GetRevalidationEmailMutation, GetRevalidationEmailMutationVariables>;

/**
 * __useGetRevalidationEmailMutation__
 *
 * To run a mutation, you first call `useGetRevalidationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetRevalidationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getRevalidationEmailMutation, { data, loading, error }] = useGetRevalidationEmailMutation({
 *   variables: {
 *      captchaKey: // value for 'captchaKey'
 *   },
 * });
 */
export function useGetRevalidationEmailMutation(baseOptions?: Apollo.MutationHookOptions<GetRevalidationEmailMutation, GetRevalidationEmailMutationVariables>) {
        return Apollo.useMutation<GetRevalidationEmailMutation, GetRevalidationEmailMutationVariables>(GetRevalidationEmailDocument, baseOptions);
      }
export type GetRevalidationEmailMutationHookResult = ReturnType<typeof useGetRevalidationEmailMutation>;
export type GetRevalidationEmailMutationResult = Apollo.MutationResult<GetRevalidationEmailMutation>;
export type GetRevalidationEmailMutationOptions = Apollo.BaseMutationOptions<GetRevalidationEmailMutation, GetRevalidationEmailMutationVariables>;
export const CreatePaymentSessionDocument = gql`
    mutation createPaymentSession {
  asUser {
    createPaymentSession {
      message
      portalSessionUrl
    }
  }
}
    `;
export type CreatePaymentSessionMutationFn = Apollo.MutationFunction<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>;

/**
 * __useCreatePaymentSessionMutation__
 *
 * To run a mutation, you first call `useCreatePaymentSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentSessionMutation, { data, loading, error }] = useCreatePaymentSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreatePaymentSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>) {
        return Apollo.useMutation<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>(CreatePaymentSessionDocument, baseOptions);
      }
export type CreatePaymentSessionMutationHookResult = ReturnType<typeof useCreatePaymentSessionMutation>;
export type CreatePaymentSessionMutationResult = Apollo.MutationResult<CreatePaymentSessionMutation>;
export type CreatePaymentSessionMutationOptions = Apollo.BaseMutationOptions<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>;
export const RevalidateEmailDocument = gql`
    mutation revalidateEmail($token: String!) {
  asUser {
    revalidateEmail(token: $token) {
      message
    }
  }
}
    `;
export type RevalidateEmailMutationFn = Apollo.MutationFunction<RevalidateEmailMutation, RevalidateEmailMutationVariables>;

/**
 * __useRevalidateEmailMutation__
 *
 * To run a mutation, you first call `useRevalidateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevalidateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revalidateEmailMutation, { data, loading, error }] = useRevalidateEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRevalidateEmailMutation(baseOptions?: Apollo.MutationHookOptions<RevalidateEmailMutation, RevalidateEmailMutationVariables>) {
        return Apollo.useMutation<RevalidateEmailMutation, RevalidateEmailMutationVariables>(RevalidateEmailDocument, baseOptions);
      }
export type RevalidateEmailMutationHookResult = ReturnType<typeof useRevalidateEmailMutation>;
export type RevalidateEmailMutationResult = Apollo.MutationResult<RevalidateEmailMutation>;
export type RevalidateEmailMutationOptions = Apollo.BaseMutationOptions<RevalidateEmailMutation, RevalidateEmailMutationVariables>;
export const GetTrafficDocument = gql`
    query getTraffic($periodLength: Int!, $input: ProjectTrafficInput!) {
  asAdmin {
    getProjectTraffic(periodLength: $periodLength, input: $input) {
      user {
        id
        handle
        name
        lastNames
        email
      }
      project {
        id
        name
      }
      step
      type
      periodsSince
      frequency
    }
  }
}
    `;

/**
 * __useGetTrafficQuery__
 *
 * To run a query within a React component, call `useGetTrafficQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrafficQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrafficQuery({
 *   variables: {
 *      periodLength: // value for 'periodLength'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTrafficQuery(baseOptions: Apollo.QueryHookOptions<GetTrafficQuery, GetTrafficQueryVariables>) {
        return Apollo.useQuery<GetTrafficQuery, GetTrafficQueryVariables>(GetTrafficDocument, baseOptions);
      }
export function useGetTrafficLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrafficQuery, GetTrafficQueryVariables>) {
          return Apollo.useLazyQuery<GetTrafficQuery, GetTrafficQueryVariables>(GetTrafficDocument, baseOptions);
        }
export type GetTrafficQueryHookResult = ReturnType<typeof useGetTrafficQuery>;
export type GetTrafficLazyQueryHookResult = ReturnType<typeof useGetTrafficLazyQuery>;
export type GetTrafficQueryResult = Apollo.QueryResult<GetTrafficQuery, GetTrafficQueryVariables>;
export const GetProjectFeedbackDocument = gql`
    query getProjectFeedback {
  asAdmin {
    getProjectFeedback(input: {}) {
      comment
      recommendable
      reusable
      learnable
      user {
        id
        handle
        name
        lastNames
        email
      }
      project {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetProjectFeedbackQuery__
 *
 * To run a query within a React component, call `useGetProjectFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectFeedbackQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectFeedbackQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectFeedbackQuery, GetProjectFeedbackQueryVariables>) {
        return Apollo.useQuery<GetProjectFeedbackQuery, GetProjectFeedbackQueryVariables>(GetProjectFeedbackDocument, baseOptions);
      }
export function useGetProjectFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectFeedbackQuery, GetProjectFeedbackQueryVariables>) {
          return Apollo.useLazyQuery<GetProjectFeedbackQuery, GetProjectFeedbackQueryVariables>(GetProjectFeedbackDocument, baseOptions);
        }
export type GetProjectFeedbackQueryHookResult = ReturnType<typeof useGetProjectFeedbackQuery>;
export type GetProjectFeedbackLazyQueryHookResult = ReturnType<typeof useGetProjectFeedbackLazyQuery>;
export type GetProjectFeedbackQueryResult = Apollo.QueryResult<GetProjectFeedbackQuery, GetProjectFeedbackQueryVariables>;
export const GetProjectsDocument = gql`
    query getProjects($textFilters: [StringFilter!]!) {
  asUser {
    id
    getTags {
      id
      name
    }
    getProjects(filters: {textFilters: $textFilters}) {
      id
      name
      startEstimate
      endEstimate
      access
      wbsRoot {
        id
        plannedStart
        plannedEnd
        startDate
        endDate
        children {
          id
          children {
            id
            plannedStart
            plannedEnd
            startDate
            endDate
            status
          }
        }
      }
      tags {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      textFilters: // value for 'textFilters'
 *   },
 * });
 */
export function useGetProjectsQuery(baseOptions: Apollo.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
        return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, baseOptions);
      }
export function useGetProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
          return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, baseOptions);
        }
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const DeleteProjectDocument = gql`
    mutation deleteProject($projectId: ID!, $step: Step!) {
  asUser {
    editProjectAsAdmin(projectId: $projectId, step: $step) {
      deleteProject {
        message
      }
    }
  }
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, baseOptions);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const CreateProjectDocument = gql`
    mutation createProject($name: String!) {
  asUser {
    createProject(name: $name) {
      message
      newProject {
        id
      }
    }
  }
}
    `;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, baseOptions);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const GetProjectDocument = gql`
    query getProject($id: ID!, $step: Step!) {
  asUser {
    id
    getTags {
      id
      name
    }
    getProject(id: $id, step: $step) {
      id
      name
      access
      problems
      objective
      workDays
      benefits {
        id
        text
      }
      assumptions {
        id
        text
      }
      restrictions {
        id
        text
      }
      client
      startEstimate
      endEstimate
      wbsRoot {
        id
        children {
          id
          text
        }
      }
      members {
        ...CoreMemberFields
      }
      tags {
        id
        name
      }
    }
  }
}
    ${CoreMemberFieldsFragmentDoc}`;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useGetProjectQuery(baseOptions: Apollo.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
        return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
      }
export function useGetProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
          return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
        }
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = Apollo.QueryResult<GetProjectQuery, GetProjectQueryVariables>;
export const GetProjectNameDocument = gql`
    query getProjectName($id: ID!, $step: Step!) {
  asUser {
    id
    getProject(id: $id, step: $step) {
      id
      name
    }
  }
}
    `;

/**
 * __useGetProjectNameQuery__
 *
 * To run a query within a React component, call `useGetProjectNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useGetProjectNameQuery(baseOptions: Apollo.QueryHookOptions<GetProjectNameQuery, GetProjectNameQueryVariables>) {
        return Apollo.useQuery<GetProjectNameQuery, GetProjectNameQueryVariables>(GetProjectNameDocument, baseOptions);
      }
export function useGetProjectNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectNameQuery, GetProjectNameQueryVariables>) {
          return Apollo.useLazyQuery<GetProjectNameQuery, GetProjectNameQueryVariables>(GetProjectNameDocument, baseOptions);
        }
export type GetProjectNameQueryHookResult = ReturnType<typeof useGetProjectNameQuery>;
export type GetProjectNameLazyQueryHookResult = ReturnType<typeof useGetProjectNameLazyQuery>;
export type GetProjectNameQueryResult = Apollo.QueryResult<GetProjectNameQuery, GetProjectNameQueryVariables>;
export const GetProjectAccessDocument = gql`
    query getProjectAccess($id: ID!, $step: Step!) {
  asUser {
    id
    getProject(id: $id, step: $step) {
      id
      access
    }
  }
}
    `;

/**
 * __useGetProjectAccessQuery__
 *
 * To run a query within a React component, call `useGetProjectAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectAccessQuery({
 *   variables: {
 *      id: // value for 'id'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useGetProjectAccessQuery(baseOptions: Apollo.QueryHookOptions<GetProjectAccessQuery, GetProjectAccessQueryVariables>) {
        return Apollo.useQuery<GetProjectAccessQuery, GetProjectAccessQueryVariables>(GetProjectAccessDocument, baseOptions);
      }
export function useGetProjectAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectAccessQuery, GetProjectAccessQueryVariables>) {
          return Apollo.useLazyQuery<GetProjectAccessQuery, GetProjectAccessQueryVariables>(GetProjectAccessDocument, baseOptions);
        }
export type GetProjectAccessQueryHookResult = ReturnType<typeof useGetProjectAccessQuery>;
export type GetProjectAccessLazyQueryHookResult = ReturnType<typeof useGetProjectAccessLazyQuery>;
export type GetProjectAccessQueryResult = Apollo.QueryResult<GetProjectAccessQuery, GetProjectAccessQueryVariables>;
export const EditProjectDocument = gql`
    mutation editProject($projectId: ID!, $step: Step!, $input: ProjectInput!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      setAttributes(input: $input) {
        message
      }
    }
  }
}
    `;
export type EditProjectMutationFn = Apollo.MutationFunction<EditProjectMutation, EditProjectMutationVariables>;

/**
 * __useEditProjectMutation__
 *
 * To run a mutation, you first call `useEditProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectMutation, { data, loading, error }] = useEditProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditProjectMutation(baseOptions?: Apollo.MutationHookOptions<EditProjectMutation, EditProjectMutationVariables>) {
        return Apollo.useMutation<EditProjectMutation, EditProjectMutationVariables>(EditProjectDocument, baseOptions);
      }
export type EditProjectMutationHookResult = ReturnType<typeof useEditProjectMutation>;
export type EditProjectMutationResult = Apollo.MutationResult<EditProjectMutation>;
export type EditProjectMutationOptions = Apollo.BaseMutationOptions<EditProjectMutation, EditProjectMutationVariables>;
export const DitWbsNodeDocument = gql`
    mutation ditWbsNode($projectId: ID!, $step: Step!, $input: WbsNodeInput!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      editWbsNode(input: $input) {
        message
      }
    }
  }
}
    `;
export type DitWbsNodeMutationFn = Apollo.MutationFunction<DitWbsNodeMutation, DitWbsNodeMutationVariables>;

/**
 * __useDitWbsNodeMutation__
 *
 * To run a mutation, you first call `useDitWbsNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDitWbsNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ditWbsNodeMutation, { data, loading, error }] = useDitWbsNodeMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDitWbsNodeMutation(baseOptions?: Apollo.MutationHookOptions<DitWbsNodeMutation, DitWbsNodeMutationVariables>) {
        return Apollo.useMutation<DitWbsNodeMutation, DitWbsNodeMutationVariables>(DitWbsNodeDocument, baseOptions);
      }
export type DitWbsNodeMutationHookResult = ReturnType<typeof useDitWbsNodeMutation>;
export type DitWbsNodeMutationResult = Apollo.MutationResult<DitWbsNodeMutation>;
export type DitWbsNodeMutationOptions = Apollo.BaseMutationOptions<DitWbsNodeMutation, DitWbsNodeMutationVariables>;
export const ReorderWbsNodeDocument = gql`
    mutation reorderWbsNode($projectId: ID!, $step: Step!, $parentId: ID!, $idList: [ID!]!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      reorderWbsNode(parentId: $parentId, idList: $idList) {
        message
      }
    }
  }
}
    `;
export type ReorderWbsNodeMutationFn = Apollo.MutationFunction<ReorderWbsNodeMutation, ReorderWbsNodeMutationVariables>;

/**
 * __useReorderWbsNodeMutation__
 *
 * To run a mutation, you first call `useReorderWbsNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderWbsNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderWbsNodeMutation, { data, loading, error }] = useReorderWbsNodeMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *      parentId: // value for 'parentId'
 *      idList: // value for 'idList'
 *   },
 * });
 */
export function useReorderWbsNodeMutation(baseOptions?: Apollo.MutationHookOptions<ReorderWbsNodeMutation, ReorderWbsNodeMutationVariables>) {
        return Apollo.useMutation<ReorderWbsNodeMutation, ReorderWbsNodeMutationVariables>(ReorderWbsNodeDocument, baseOptions);
      }
export type ReorderWbsNodeMutationHookResult = ReturnType<typeof useReorderWbsNodeMutation>;
export type ReorderWbsNodeMutationResult = Apollo.MutationResult<ReorderWbsNodeMutation>;
export type ReorderWbsNodeMutationOptions = Apollo.BaseMutationOptions<ReorderWbsNodeMutation, ReorderWbsNodeMutationVariables>;
export const CreateWbsNodeDocument = gql`
    mutation createWbsNode($projectId: ID!, $step: Step!, $parentId: ID!, $text: String) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      createWbsNode(parentId: $parentId, text: $text) {
        message
        newWbsNode {
          id
          text
        }
      }
    }
  }
}
    `;
export type CreateWbsNodeMutationFn = Apollo.MutationFunction<CreateWbsNodeMutation, CreateWbsNodeMutationVariables>;

/**
 * __useCreateWbsNodeMutation__
 *
 * To run a mutation, you first call `useCreateWbsNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWbsNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWbsNodeMutation, { data, loading, error }] = useCreateWbsNodeMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *      parentId: // value for 'parentId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateWbsNodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateWbsNodeMutation, CreateWbsNodeMutationVariables>) {
        return Apollo.useMutation<CreateWbsNodeMutation, CreateWbsNodeMutationVariables>(CreateWbsNodeDocument, baseOptions);
      }
export type CreateWbsNodeMutationHookResult = ReturnType<typeof useCreateWbsNodeMutation>;
export type CreateWbsNodeMutationResult = Apollo.MutationResult<CreateWbsNodeMutation>;
export type CreateWbsNodeMutationOptions = Apollo.BaseMutationOptions<CreateWbsNodeMutation, CreateWbsNodeMutationVariables>;
export const DeleteWbsNodeDocument = gql`
    mutation deleteWbsNode($projectId: ID!, $step: Step!, $id: ID!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      deleteWbsNode(id: $id) {
        message
      }
    }
  }
}
    `;
export type DeleteWbsNodeMutationFn = Apollo.MutationFunction<DeleteWbsNodeMutation, DeleteWbsNodeMutationVariables>;

/**
 * __useDeleteWbsNodeMutation__
 *
 * To run a mutation, you first call `useDeleteWbsNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWbsNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWbsNodeMutation, { data, loading, error }] = useDeleteWbsNodeMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWbsNodeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWbsNodeMutation, DeleteWbsNodeMutationVariables>) {
        return Apollo.useMutation<DeleteWbsNodeMutation, DeleteWbsNodeMutationVariables>(DeleteWbsNodeDocument, baseOptions);
      }
export type DeleteWbsNodeMutationHookResult = ReturnType<typeof useDeleteWbsNodeMutation>;
export type DeleteWbsNodeMutationResult = Apollo.MutationResult<DeleteWbsNodeMutation>;
export type DeleteWbsNodeMutationOptions = Apollo.BaseMutationOptions<DeleteWbsNodeMutation, DeleteWbsNodeMutationVariables>;
export const GetWbsDocument = gql`
    query getWbs($id: ID!, $step: Step!) {
  asUser {
    id
    getProject(id: $id, step: $step) {
      id
      name
      access
      wbsRoot {
        id
        text
        children {
          id
          text
          children {
            id
            text
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetWbsQuery__
 *
 * To run a query within a React component, call `useGetWbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useGetWbsQuery(baseOptions: Apollo.QueryHookOptions<GetWbsQuery, GetWbsQueryVariables>) {
        return Apollo.useQuery<GetWbsQuery, GetWbsQueryVariables>(GetWbsDocument, baseOptions);
      }
export function useGetWbsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWbsQuery, GetWbsQueryVariables>) {
          return Apollo.useLazyQuery<GetWbsQuery, GetWbsQueryVariables>(GetWbsDocument, baseOptions);
        }
export type GetWbsQueryHookResult = ReturnType<typeof useGetWbsQuery>;
export type GetWbsLazyQueryHookResult = ReturnType<typeof useGetWbsLazyQuery>;
export type GetWbsQueryResult = Apollo.QueryResult<GetWbsQuery, GetWbsQueryVariables>;
export const GetMembersDocument = gql`
    query getMembers($id: ID!, $step: Step!) {
  asUser {
    id
    getProject(id: $id, step: $step) {
      id
      name
      access
      members {
        id
        name
        user {
          id
          handle
        }
        access
      }
      wbsRoot {
        id
        text
        duties {
          memberId
        }
        children {
          id
          text
          duties {
            memberId
          }
          children {
            id
            text
            duties {
              memberId
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetMembersQuery__
 *
 * To run a query within a React component, call `useGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useGetMembersQuery(baseOptions: Apollo.QueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
        return Apollo.useQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, baseOptions);
      }
export function useGetMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
          return Apollo.useLazyQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, baseOptions);
        }
export type GetMembersQueryHookResult = ReturnType<typeof useGetMembersQuery>;
export type GetMembersLazyQueryHookResult = ReturnType<typeof useGetMembersLazyQuery>;
export type GetMembersQueryResult = Apollo.QueryResult<GetMembersQuery, GetMembersQueryVariables>;
export const SetDutyDocument = gql`
    mutation setDuty($projectId: ID!, $step: Step!, $memberId: ID!, $wbsNodeId: ID!, $job: Job) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      setDuty(memberId: $memberId, wbsNodeId: $wbsNodeId, job: $job) {
        message
      }
    }
  }
}
    `;
export type SetDutyMutationFn = Apollo.MutationFunction<SetDutyMutation, SetDutyMutationVariables>;

/**
 * __useSetDutyMutation__
 *
 * To run a mutation, you first call `useSetDutyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDutyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDutyMutation, { data, loading, error }] = useSetDutyMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *      memberId: // value for 'memberId'
 *      wbsNodeId: // value for 'wbsNodeId'
 *      job: // value for 'job'
 *   },
 * });
 */
export function useSetDutyMutation(baseOptions?: Apollo.MutationHookOptions<SetDutyMutation, SetDutyMutationVariables>) {
        return Apollo.useMutation<SetDutyMutation, SetDutyMutationVariables>(SetDutyDocument, baseOptions);
      }
export type SetDutyMutationHookResult = ReturnType<typeof useSetDutyMutation>;
export type SetDutyMutationResult = Apollo.MutationResult<SetDutyMutation>;
export type SetDutyMutationOptions = Apollo.BaseMutationOptions<SetDutyMutation, SetDutyMutationVariables>;
export const GetDatesCuandoDocument = gql`
    query getDatesCuando($id: ID!, $step: Step!) {
  asUser {
    id
    getProject(id: $id, step: $step) {
      id
      name
      access
      startEstimate
      workDays
      members {
        id
        name
        user {
          id
          handle
        }
      }
      assumptions {
        id
        text
      }
      restrictions {
        id
        text
      }
      wbsRoot {
        id
        text
        plannedStart
        plannedEnd
        duties {
          memberId
        }
        children {
          id
          text
          plannedStart
          plannedEnd
          duties {
            memberId
          }
          children {
            id
            text
            plannedStart
            plannedEnd
            duties {
              memberId
            }
          }
        }
      }
      links {
        id
        source
        target
        type
      }
      risks {
        id
        text
        impact
        probability
        actions
        responsibleId
      }
    }
  }
}
    `;

/**
 * __useGetDatesCuandoQuery__
 *
 * To run a query within a React component, call `useGetDatesCuandoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatesCuandoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatesCuandoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useGetDatesCuandoQuery(baseOptions: Apollo.QueryHookOptions<GetDatesCuandoQuery, GetDatesCuandoQueryVariables>) {
        return Apollo.useQuery<GetDatesCuandoQuery, GetDatesCuandoQueryVariables>(GetDatesCuandoDocument, baseOptions);
      }
export function useGetDatesCuandoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatesCuandoQuery, GetDatesCuandoQueryVariables>) {
          return Apollo.useLazyQuery<GetDatesCuandoQuery, GetDatesCuandoQueryVariables>(GetDatesCuandoDocument, baseOptions);
        }
export type GetDatesCuandoQueryHookResult = ReturnType<typeof useGetDatesCuandoQuery>;
export type GetDatesCuandoLazyQueryHookResult = ReturnType<typeof useGetDatesCuandoLazyQuery>;
export type GetDatesCuandoQueryResult = Apollo.QueryResult<GetDatesCuandoQuery, GetDatesCuandoQueryVariables>;
export const GetDatesComoterminoDocument = gql`
    query getDatesComotermino($id: ID!, $step: Step!) {
  asUser {
    id
    getProject(id: $id, step: $step) {
      id
      name
      access
      workDays
      lessons {
        id
        text
      }
      benefits {
        id
        text
      }
      hasSubmittedFeedback
      wbsRoot {
        id
        text
        startDate
        endDate
        plannedStart
        plannedEnd
        status
        children {
          id
          text
          startDate
          endDate
          plannedStart
          plannedEnd
        }
      }
    }
  }
}
    `;

/**
 * __useGetDatesComoterminoQuery__
 *
 * To run a query within a React component, call `useGetDatesComoterminoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatesComoterminoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatesComoterminoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useGetDatesComoterminoQuery(baseOptions: Apollo.QueryHookOptions<GetDatesComoterminoQuery, GetDatesComoterminoQueryVariables>) {
        return Apollo.useQuery<GetDatesComoterminoQuery, GetDatesComoterminoQueryVariables>(GetDatesComoterminoDocument, baseOptions);
      }
export function useGetDatesComoterminoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatesComoterminoQuery, GetDatesComoterminoQueryVariables>) {
          return Apollo.useLazyQuery<GetDatesComoterminoQuery, GetDatesComoterminoQueryVariables>(GetDatesComoterminoDocument, baseOptions);
        }
export type GetDatesComoterminoQueryHookResult = ReturnType<typeof useGetDatesComoterminoQuery>;
export type GetDatesComoterminoLazyQueryHookResult = ReturnType<typeof useGetDatesComoterminoLazyQuery>;
export type GetDatesComoterminoQueryResult = Apollo.QueryResult<GetDatesComoterminoQuery, GetDatesComoterminoQueryVariables>;
export const GetDatesComovaDocument = gql`
    query getDatesComova($id: ID!, $step: Step!) {
  asUser {
    id
    getProject(id: $id, step: $step) {
      id
      name
      access
      workDays
      statusComments
      assumptions {
        id
        text
      }
      restrictions {
        id
        text
      }
      members {
        id
        name
        user {
          id
          handle
        }
      }
      wbsRoot {
        id
        text
        startDate
        endDate
        plannedStart
        plannedEnd
        status
        duties {
          memberId
        }
        children {
          id
          text
          startDate
          endDate
          plannedStart
          plannedEnd
          status
          duties {
            memberId
          }
          children {
            id
            text
            startDate
            endDate
            plannedStart
            plannedEnd
            status
            duties {
              memberId
            }
          }
        }
      }
      links {
        id
        source
        target
        type
      }
    }
  }
}
    `;

/**
 * __useGetDatesComovaQuery__
 *
 * To run a query within a React component, call `useGetDatesComovaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatesComovaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatesComovaQuery({
 *   variables: {
 *      id: // value for 'id'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useGetDatesComovaQuery(baseOptions: Apollo.QueryHookOptions<GetDatesComovaQuery, GetDatesComovaQueryVariables>) {
        return Apollo.useQuery<GetDatesComovaQuery, GetDatesComovaQueryVariables>(GetDatesComovaDocument, baseOptions);
      }
export function useGetDatesComovaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatesComovaQuery, GetDatesComovaQueryVariables>) {
          return Apollo.useLazyQuery<GetDatesComovaQuery, GetDatesComovaQueryVariables>(GetDatesComovaDocument, baseOptions);
        }
export type GetDatesComovaQueryHookResult = ReturnType<typeof useGetDatesComovaQuery>;
export type GetDatesComovaLazyQueryHookResult = ReturnType<typeof useGetDatesComovaLazyQuery>;
export type GetDatesComovaQueryResult = Apollo.QueryResult<GetDatesComovaQuery, GetDatesComovaQueryVariables>;
export const SearchUsersDocument = gql`
    query searchUsers($query: String!) {
  asUser {
    id
    searchUsers(query: $query) {
      id
      handle
      name
      lastNames
      profilePictureLink
    }
  }
}
    `;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const AddMemberDocument = gql`
    mutation addMember($projectId: ID!, $step: Step!) {
  asUser {
    editProjectAsAdmin(projectId: $projectId, step: $step) {
      addMember {
        message
        newMember {
          ...CoreMemberFields
        }
      }
    }
  }
}
    ${CoreMemberFieldsFragmentDoc}`;
export type AddMemberMutationFn = Apollo.MutationFunction<AddMemberMutation, AddMemberMutationVariables>;

/**
 * __useAddMemberMutation__
 *
 * To run a mutation, you first call `useAddMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberMutation, { data, loading, error }] = useAddMemberMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useAddMemberMutation(baseOptions?: Apollo.MutationHookOptions<AddMemberMutation, AddMemberMutationVariables>) {
        return Apollo.useMutation<AddMemberMutation, AddMemberMutationVariables>(AddMemberDocument, baseOptions);
      }
export type AddMemberMutationHookResult = ReturnType<typeof useAddMemberMutation>;
export type AddMemberMutationResult = Apollo.MutationResult<AddMemberMutation>;
export type AddMemberMutationOptions = Apollo.BaseMutationOptions<AddMemberMutation, AddMemberMutationVariables>;
export const DeleteMemberDocument = gql`
    mutation deleteMember($projectId: ID!, $step: Step!, $id: ID!) {
  asUser {
    editProjectAsAdmin(projectId: $projectId, step: $step) {
      deleteMember(id: $id) {
        message
      }
    }
  }
}
    `;
export type DeleteMemberMutationFn = Apollo.MutationFunction<DeleteMemberMutation, DeleteMemberMutationVariables>;

/**
 * __useDeleteMemberMutation__
 *
 * To run a mutation, you first call `useDeleteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberMutation, { data, loading, error }] = useDeleteMemberMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMemberMutation, DeleteMemberMutationVariables>) {
        return Apollo.useMutation<DeleteMemberMutation, DeleteMemberMutationVariables>(DeleteMemberDocument, baseOptions);
      }
export type DeleteMemberMutationHookResult = ReturnType<typeof useDeleteMemberMutation>;
export type DeleteMemberMutationResult = Apollo.MutationResult<DeleteMemberMutation>;
export type DeleteMemberMutationOptions = Apollo.BaseMutationOptions<DeleteMemberMutation, DeleteMemberMutationVariables>;
export const EditMemberDocument = gql`
    mutation editMember($projectId: ID!, $step: Step!, $input: MemberInput!) {
  asUser {
    editProjectAsAdmin(projectId: $projectId, step: $step) {
      editMember(input: $input) {
        message
      }
    }
  }
}
    `;
export type EditMemberMutationFn = Apollo.MutationFunction<EditMemberMutation, EditMemberMutationVariables>;

/**
 * __useEditMemberMutation__
 *
 * To run a mutation, you first call `useEditMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMemberMutation, { data, loading, error }] = useEditMemberMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMemberMutation(baseOptions?: Apollo.MutationHookOptions<EditMemberMutation, EditMemberMutationVariables>) {
        return Apollo.useMutation<EditMemberMutation, EditMemberMutationVariables>(EditMemberDocument, baseOptions);
      }
export type EditMemberMutationHookResult = ReturnType<typeof useEditMemberMutation>;
export type EditMemberMutationResult = Apollo.MutationResult<EditMemberMutation>;
export type EditMemberMutationOptions = Apollo.BaseMutationOptions<EditMemberMutation, EditMemberMutationVariables>;
export const AddLinkDocument = gql`
    mutation addLink($projectId: ID!, $step: Step!, $link: LinkInput!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      addLink(link: $link) {
        message
      }
    }
  }
}
    `;
export type AddLinkMutationFn = Apollo.MutationFunction<AddLinkMutation, AddLinkMutationVariables>;

/**
 * __useAddLinkMutation__
 *
 * To run a mutation, you first call `useAddLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLinkMutation, { data, loading, error }] = useAddLinkMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useAddLinkMutation(baseOptions?: Apollo.MutationHookOptions<AddLinkMutation, AddLinkMutationVariables>) {
        return Apollo.useMutation<AddLinkMutation, AddLinkMutationVariables>(AddLinkDocument, baseOptions);
      }
export type AddLinkMutationHookResult = ReturnType<typeof useAddLinkMutation>;
export type AddLinkMutationResult = Apollo.MutationResult<AddLinkMutation>;
export type AddLinkMutationOptions = Apollo.BaseMutationOptions<AddLinkMutation, AddLinkMutationVariables>;
export const DeleteLinkDocument = gql`
    mutation deleteLink($projectId: ID!, $step: Step!, $id: ID!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      deleteLink(id: $id) {
        message
      }
    }
  }
}
    `;
export type DeleteLinkMutationFn = Apollo.MutationFunction<DeleteLinkMutation, DeleteLinkMutationVariables>;

/**
 * __useDeleteLinkMutation__
 *
 * To run a mutation, you first call `useDeleteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLinkMutation, { data, loading, error }] = useDeleteLinkMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLinkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLinkMutation, DeleteLinkMutationVariables>) {
        return Apollo.useMutation<DeleteLinkMutation, DeleteLinkMutationVariables>(DeleteLinkDocument, baseOptions);
      }
export type DeleteLinkMutationHookResult = ReturnType<typeof useDeleteLinkMutation>;
export type DeleteLinkMutationResult = Apollo.MutationResult<DeleteLinkMutation>;
export type DeleteLinkMutationOptions = Apollo.BaseMutationOptions<DeleteLinkMutation, DeleteLinkMutationVariables>;
export const SubmitFeedbackDocument = gql`
    mutation submitFeedback($projectId: ID!, $step: Step!, $input: ProjectFeedbackInput!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      submitFeedback(input: $input) {
        message
      }
    }
  }
}
    `;
export type SubmitFeedbackMutationFn = Apollo.MutationFunction<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>;

/**
 * __useSubmitFeedbackMutation__
 *
 * To run a mutation, you first call `useSubmitFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFeedbackMutation, { data, loading, error }] = useSubmitFeedbackMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>) {
        return Apollo.useMutation<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>(SubmitFeedbackDocument, baseOptions);
      }
export type SubmitFeedbackMutationHookResult = ReturnType<typeof useSubmitFeedbackMutation>;
export type SubmitFeedbackMutationResult = Apollo.MutationResult<SubmitFeedbackMutation>;
export type SubmitFeedbackMutationOptions = Apollo.BaseMutationOptions<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>;
export const ConfirmMemberInvitationDocument = gql`
    mutation confirmMemberInvitation($memberId: ID!) {
  asUser {
    confirmMemberInvitation(memberId: $memberId) {
      message
      newProject {
        id
      }
    }
  }
}
    `;
export type ConfirmMemberInvitationMutationFn = Apollo.MutationFunction<ConfirmMemberInvitationMutation, ConfirmMemberInvitationMutationVariables>;

/**
 * __useConfirmMemberInvitationMutation__
 *
 * To run a mutation, you first call `useConfirmMemberInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMemberInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMemberInvitationMutation, { data, loading, error }] = useConfirmMemberInvitationMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useConfirmMemberInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmMemberInvitationMutation, ConfirmMemberInvitationMutationVariables>) {
        return Apollo.useMutation<ConfirmMemberInvitationMutation, ConfirmMemberInvitationMutationVariables>(ConfirmMemberInvitationDocument, baseOptions);
      }
export type ConfirmMemberInvitationMutationHookResult = ReturnType<typeof useConfirmMemberInvitationMutation>;
export type ConfirmMemberInvitationMutationResult = Apollo.MutationResult<ConfirmMemberInvitationMutation>;
export type ConfirmMemberInvitationMutationOptions = Apollo.BaseMutationOptions<ConfirmMemberInvitationMutation, ConfirmMemberInvitationMutationVariables>;
export const InviteMemberDocument = gql`
    mutation inviteMember($projectId: ID!, $memberId: ID!, $step: Step!) {
  asUser {
    editProjectAsAdmin(projectId: $projectId, step: $step) {
      inviteMember(memberId: $memberId) {
        message
      }
    }
  }
}
    `;
export type InviteMemberMutationFn = Apollo.MutationFunction<InviteMemberMutation, InviteMemberMutationVariables>;

/**
 * __useInviteMemberMutation__
 *
 * To run a mutation, you first call `useInviteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMemberMutation, { data, loading, error }] = useInviteMemberMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      memberId: // value for 'memberId'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useInviteMemberMutation(baseOptions?: Apollo.MutationHookOptions<InviteMemberMutation, InviteMemberMutationVariables>) {
        return Apollo.useMutation<InviteMemberMutation, InviteMemberMutationVariables>(InviteMemberDocument, baseOptions);
      }
export type InviteMemberMutationHookResult = ReturnType<typeof useInviteMemberMutation>;
export type InviteMemberMutationResult = Apollo.MutationResult<InviteMemberMutation>;
export type InviteMemberMutationOptions = Apollo.BaseMutationOptions<InviteMemberMutation, InviteMemberMutationVariables>;
export const AbandonProjectDocument = gql`
    mutation abandonProject($projectId: ID!) {
  asUser {
    abandonProject(projectId: $projectId) {
      message
    }
  }
}
    `;
export type AbandonProjectMutationFn = Apollo.MutationFunction<AbandonProjectMutation, AbandonProjectMutationVariables>;

/**
 * __useAbandonProjectMutation__
 *
 * To run a mutation, you first call `useAbandonProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAbandonProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [abandonProjectMutation, { data, loading, error }] = useAbandonProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useAbandonProjectMutation(baseOptions?: Apollo.MutationHookOptions<AbandonProjectMutation, AbandonProjectMutationVariables>) {
        return Apollo.useMutation<AbandonProjectMutation, AbandonProjectMutationVariables>(AbandonProjectDocument, baseOptions);
      }
export type AbandonProjectMutationHookResult = ReturnType<typeof useAbandonProjectMutation>;
export type AbandonProjectMutationResult = Apollo.MutationResult<AbandonProjectMutation>;
export type AbandonProjectMutationOptions = Apollo.BaseMutationOptions<AbandonProjectMutation, AbandonProjectMutationVariables>;
export const AddBenefitDocument = gql`
    mutation addBenefit($projectId: ID!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      addBenefit {
        newBenefit {
          id
          text
        }
      }
    }
  }
}
    `;
export type AddBenefitMutationFn = Apollo.MutationFunction<AddBenefitMutation, AddBenefitMutationVariables>;

/**
 * __useAddBenefitMutation__
 *
 * To run a mutation, you first call `useAddBenefitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBenefitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBenefitMutation, { data, loading, error }] = useAddBenefitMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useAddBenefitMutation(baseOptions?: Apollo.MutationHookOptions<AddBenefitMutation, AddBenefitMutationVariables>) {
        return Apollo.useMutation<AddBenefitMutation, AddBenefitMutationVariables>(AddBenefitDocument, baseOptions);
      }
export type AddBenefitMutationHookResult = ReturnType<typeof useAddBenefitMutation>;
export type AddBenefitMutationResult = Apollo.MutationResult<AddBenefitMutation>;
export type AddBenefitMutationOptions = Apollo.BaseMutationOptions<AddBenefitMutation, AddBenefitMutationVariables>;
export const DeleteBenefitDocument = gql`
    mutation deleteBenefit($projectId: ID!, $id: ID!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      deleteBenefit(id: $id) {
        message
      }
    }
  }
}
    `;
export type DeleteBenefitMutationFn = Apollo.MutationFunction<DeleteBenefitMutation, DeleteBenefitMutationVariables>;

/**
 * __useDeleteBenefitMutation__
 *
 * To run a mutation, you first call `useDeleteBenefitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBenefitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBenefitMutation, { data, loading, error }] = useDeleteBenefitMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useDeleteBenefitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBenefitMutation, DeleteBenefitMutationVariables>) {
        return Apollo.useMutation<DeleteBenefitMutation, DeleteBenefitMutationVariables>(DeleteBenefitDocument, baseOptions);
      }
export type DeleteBenefitMutationHookResult = ReturnType<typeof useDeleteBenefitMutation>;
export type DeleteBenefitMutationResult = Apollo.MutationResult<DeleteBenefitMutation>;
export type DeleteBenefitMutationOptions = Apollo.BaseMutationOptions<DeleteBenefitMutation, DeleteBenefitMutationVariables>;
export const EditBenefitDocument = gql`
    mutation editBenefit($projectId: ID!, $input: BenefitInput!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      editBenefit(input: $input) {
        message
      }
    }
  }
}
    `;
export type EditBenefitMutationFn = Apollo.MutationFunction<EditBenefitMutation, EditBenefitMutationVariables>;

/**
 * __useEditBenefitMutation__
 *
 * To run a mutation, you first call `useEditBenefitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBenefitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBenefitMutation, { data, loading, error }] = useEditBenefitMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      input: // value for 'input'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useEditBenefitMutation(baseOptions?: Apollo.MutationHookOptions<EditBenefitMutation, EditBenefitMutationVariables>) {
        return Apollo.useMutation<EditBenefitMutation, EditBenefitMutationVariables>(EditBenefitDocument, baseOptions);
      }
export type EditBenefitMutationHookResult = ReturnType<typeof useEditBenefitMutation>;
export type EditBenefitMutationResult = Apollo.MutationResult<EditBenefitMutation>;
export type EditBenefitMutationOptions = Apollo.BaseMutationOptions<EditBenefitMutation, EditBenefitMutationVariables>;
export const ReorderBenefitsDocument = gql`
    mutation reorderBenefits($projectId: ID!, $idList: [ID!]!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      reorderBenefits(idList: $idList) {
        message
      }
    }
  }
}
    `;
export type ReorderBenefitsMutationFn = Apollo.MutationFunction<ReorderBenefitsMutation, ReorderBenefitsMutationVariables>;

/**
 * __useReorderBenefitsMutation__
 *
 * To run a mutation, you first call `useReorderBenefitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderBenefitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderBenefitsMutation, { data, loading, error }] = useReorderBenefitsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      idList: // value for 'idList'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useReorderBenefitsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderBenefitsMutation, ReorderBenefitsMutationVariables>) {
        return Apollo.useMutation<ReorderBenefitsMutation, ReorderBenefitsMutationVariables>(ReorderBenefitsDocument, baseOptions);
      }
export type ReorderBenefitsMutationHookResult = ReturnType<typeof useReorderBenefitsMutation>;
export type ReorderBenefitsMutationResult = Apollo.MutationResult<ReorderBenefitsMutation>;
export type ReorderBenefitsMutationOptions = Apollo.BaseMutationOptions<ReorderBenefitsMutation, ReorderBenefitsMutationVariables>;
export const AddAssumptionDocument = gql`
    mutation addAssumption($projectId: ID!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      addAssumption {
        newAssumption {
          id
          text
        }
      }
    }
  }
}
    `;
export type AddAssumptionMutationFn = Apollo.MutationFunction<AddAssumptionMutation, AddAssumptionMutationVariables>;

/**
 * __useAddAssumptionMutation__
 *
 * To run a mutation, you first call `useAddAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssumptionMutation, { data, loading, error }] = useAddAssumptionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useAddAssumptionMutation(baseOptions?: Apollo.MutationHookOptions<AddAssumptionMutation, AddAssumptionMutationVariables>) {
        return Apollo.useMutation<AddAssumptionMutation, AddAssumptionMutationVariables>(AddAssumptionDocument, baseOptions);
      }
export type AddAssumptionMutationHookResult = ReturnType<typeof useAddAssumptionMutation>;
export type AddAssumptionMutationResult = Apollo.MutationResult<AddAssumptionMutation>;
export type AddAssumptionMutationOptions = Apollo.BaseMutationOptions<AddAssumptionMutation, AddAssumptionMutationVariables>;
export const DeleteAssumptionDocument = gql`
    mutation deleteAssumption($projectId: ID!, $id: ID!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      deleteAssumption(id: $id) {
        message
      }
    }
  }
}
    `;
export type DeleteAssumptionMutationFn = Apollo.MutationFunction<DeleteAssumptionMutation, DeleteAssumptionMutationVariables>;

/**
 * __useDeleteAssumptionMutation__
 *
 * To run a mutation, you first call `useDeleteAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssumptionMutation, { data, loading, error }] = useDeleteAssumptionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useDeleteAssumptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAssumptionMutation, DeleteAssumptionMutationVariables>) {
        return Apollo.useMutation<DeleteAssumptionMutation, DeleteAssumptionMutationVariables>(DeleteAssumptionDocument, baseOptions);
      }
export type DeleteAssumptionMutationHookResult = ReturnType<typeof useDeleteAssumptionMutation>;
export type DeleteAssumptionMutationResult = Apollo.MutationResult<DeleteAssumptionMutation>;
export type DeleteAssumptionMutationOptions = Apollo.BaseMutationOptions<DeleteAssumptionMutation, DeleteAssumptionMutationVariables>;
export const EditAssumptionDocument = gql`
    mutation editAssumption($projectId: ID!, $input: AssumptionInput!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      editAssumption(input: $input) {
        message
      }
    }
  }
}
    `;
export type EditAssumptionMutationFn = Apollo.MutationFunction<EditAssumptionMutation, EditAssumptionMutationVariables>;

/**
 * __useEditAssumptionMutation__
 *
 * To run a mutation, you first call `useEditAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAssumptionMutation, { data, loading, error }] = useEditAssumptionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      input: // value for 'input'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useEditAssumptionMutation(baseOptions?: Apollo.MutationHookOptions<EditAssumptionMutation, EditAssumptionMutationVariables>) {
        return Apollo.useMutation<EditAssumptionMutation, EditAssumptionMutationVariables>(EditAssumptionDocument, baseOptions);
      }
export type EditAssumptionMutationHookResult = ReturnType<typeof useEditAssumptionMutation>;
export type EditAssumptionMutationResult = Apollo.MutationResult<EditAssumptionMutation>;
export type EditAssumptionMutationOptions = Apollo.BaseMutationOptions<EditAssumptionMutation, EditAssumptionMutationVariables>;
export const ReorderAssumptionsDocument = gql`
    mutation reorderAssumptions($projectId: ID!, $idList: [ID!]!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      reorderAssumptions(idList: $idList) {
        message
      }
    }
  }
}
    `;
export type ReorderAssumptionsMutationFn = Apollo.MutationFunction<ReorderAssumptionsMutation, ReorderAssumptionsMutationVariables>;

/**
 * __useReorderAssumptionsMutation__
 *
 * To run a mutation, you first call `useReorderAssumptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderAssumptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderAssumptionsMutation, { data, loading, error }] = useReorderAssumptionsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      idList: // value for 'idList'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useReorderAssumptionsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderAssumptionsMutation, ReorderAssumptionsMutationVariables>) {
        return Apollo.useMutation<ReorderAssumptionsMutation, ReorderAssumptionsMutationVariables>(ReorderAssumptionsDocument, baseOptions);
      }
export type ReorderAssumptionsMutationHookResult = ReturnType<typeof useReorderAssumptionsMutation>;
export type ReorderAssumptionsMutationResult = Apollo.MutationResult<ReorderAssumptionsMutation>;
export type ReorderAssumptionsMutationOptions = Apollo.BaseMutationOptions<ReorderAssumptionsMutation, ReorderAssumptionsMutationVariables>;
export const AddRestrictionDocument = gql`
    mutation addRestriction($projectId: ID!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      addRestriction {
        newRestriction {
          id
          text
        }
      }
    }
  }
}
    `;
export type AddRestrictionMutationFn = Apollo.MutationFunction<AddRestrictionMutation, AddRestrictionMutationVariables>;

/**
 * __useAddRestrictionMutation__
 *
 * To run a mutation, you first call `useAddRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRestrictionMutation, { data, loading, error }] = useAddRestrictionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useAddRestrictionMutation(baseOptions?: Apollo.MutationHookOptions<AddRestrictionMutation, AddRestrictionMutationVariables>) {
        return Apollo.useMutation<AddRestrictionMutation, AddRestrictionMutationVariables>(AddRestrictionDocument, baseOptions);
      }
export type AddRestrictionMutationHookResult = ReturnType<typeof useAddRestrictionMutation>;
export type AddRestrictionMutationResult = Apollo.MutationResult<AddRestrictionMutation>;
export type AddRestrictionMutationOptions = Apollo.BaseMutationOptions<AddRestrictionMutation, AddRestrictionMutationVariables>;
export const DeleteRestrictionDocument = gql`
    mutation deleteRestriction($projectId: ID!, $id: ID!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      deleteRestriction(id: $id) {
        message
      }
    }
  }
}
    `;
export type DeleteRestrictionMutationFn = Apollo.MutationFunction<DeleteRestrictionMutation, DeleteRestrictionMutationVariables>;

/**
 * __useDeleteRestrictionMutation__
 *
 * To run a mutation, you first call `useDeleteRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRestrictionMutation, { data, loading, error }] = useDeleteRestrictionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useDeleteRestrictionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRestrictionMutation, DeleteRestrictionMutationVariables>) {
        return Apollo.useMutation<DeleteRestrictionMutation, DeleteRestrictionMutationVariables>(DeleteRestrictionDocument, baseOptions);
      }
export type DeleteRestrictionMutationHookResult = ReturnType<typeof useDeleteRestrictionMutation>;
export type DeleteRestrictionMutationResult = Apollo.MutationResult<DeleteRestrictionMutation>;
export type DeleteRestrictionMutationOptions = Apollo.BaseMutationOptions<DeleteRestrictionMutation, DeleteRestrictionMutationVariables>;
export const EditRestrictionDocument = gql`
    mutation editRestriction($projectId: ID!, $input: RestrictionInput!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      editRestriction(input: $input) {
        message
      }
    }
  }
}
    `;
export type EditRestrictionMutationFn = Apollo.MutationFunction<EditRestrictionMutation, EditRestrictionMutationVariables>;

/**
 * __useEditRestrictionMutation__
 *
 * To run a mutation, you first call `useEditRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRestrictionMutation, { data, loading, error }] = useEditRestrictionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      input: // value for 'input'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useEditRestrictionMutation(baseOptions?: Apollo.MutationHookOptions<EditRestrictionMutation, EditRestrictionMutationVariables>) {
        return Apollo.useMutation<EditRestrictionMutation, EditRestrictionMutationVariables>(EditRestrictionDocument, baseOptions);
      }
export type EditRestrictionMutationHookResult = ReturnType<typeof useEditRestrictionMutation>;
export type EditRestrictionMutationResult = Apollo.MutationResult<EditRestrictionMutation>;
export type EditRestrictionMutationOptions = Apollo.BaseMutationOptions<EditRestrictionMutation, EditRestrictionMutationVariables>;
export const ReorderRestrictionsDocument = gql`
    mutation reorderRestrictions($projectId: ID!, $idList: [ID!]!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      reorderRestrictions(idList: $idList) {
        message
      }
    }
  }
}
    `;
export type ReorderRestrictionsMutationFn = Apollo.MutationFunction<ReorderRestrictionsMutation, ReorderRestrictionsMutationVariables>;

/**
 * __useReorderRestrictionsMutation__
 *
 * To run a mutation, you first call `useReorderRestrictionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderRestrictionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderRestrictionsMutation, { data, loading, error }] = useReorderRestrictionsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      idList: // value for 'idList'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useReorderRestrictionsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderRestrictionsMutation, ReorderRestrictionsMutationVariables>) {
        return Apollo.useMutation<ReorderRestrictionsMutation, ReorderRestrictionsMutationVariables>(ReorderRestrictionsDocument, baseOptions);
      }
export type ReorderRestrictionsMutationHookResult = ReturnType<typeof useReorderRestrictionsMutation>;
export type ReorderRestrictionsMutationResult = Apollo.MutationResult<ReorderRestrictionsMutation>;
export type ReorderRestrictionsMutationOptions = Apollo.BaseMutationOptions<ReorderRestrictionsMutation, ReorderRestrictionsMutationVariables>;
export const AddLessonDocument = gql`
    mutation addLesson($projectId: ID!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      addLesson {
        newLesson {
          id
          text
        }
      }
    }
  }
}
    `;
export type AddLessonMutationFn = Apollo.MutationFunction<AddLessonMutation, AddLessonMutationVariables>;

/**
 * __useAddLessonMutation__
 *
 * To run a mutation, you first call `useAddLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLessonMutation, { data, loading, error }] = useAddLessonMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useAddLessonMutation(baseOptions?: Apollo.MutationHookOptions<AddLessonMutation, AddLessonMutationVariables>) {
        return Apollo.useMutation<AddLessonMutation, AddLessonMutationVariables>(AddLessonDocument, baseOptions);
      }
export type AddLessonMutationHookResult = ReturnType<typeof useAddLessonMutation>;
export type AddLessonMutationResult = Apollo.MutationResult<AddLessonMutation>;
export type AddLessonMutationOptions = Apollo.BaseMutationOptions<AddLessonMutation, AddLessonMutationVariables>;
export const DeleteLessonDocument = gql`
    mutation deleteLesson($projectId: ID!, $id: ID!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      deleteLesson(id: $id) {
        message
      }
    }
  }
}
    `;
export type DeleteLessonMutationFn = Apollo.MutationFunction<DeleteLessonMutation, DeleteLessonMutationVariables>;

/**
 * __useDeleteLessonMutation__
 *
 * To run a mutation, you first call `useDeleteLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLessonMutation, { data, loading, error }] = useDeleteLessonMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useDeleteLessonMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLessonMutation, DeleteLessonMutationVariables>) {
        return Apollo.useMutation<DeleteLessonMutation, DeleteLessonMutationVariables>(DeleteLessonDocument, baseOptions);
      }
export type DeleteLessonMutationHookResult = ReturnType<typeof useDeleteLessonMutation>;
export type DeleteLessonMutationResult = Apollo.MutationResult<DeleteLessonMutation>;
export type DeleteLessonMutationOptions = Apollo.BaseMutationOptions<DeleteLessonMutation, DeleteLessonMutationVariables>;
export const EditLessonDocument = gql`
    mutation editLesson($projectId: ID!, $input: LessonInput!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      editLesson(input: $input) {
        message
      }
    }
  }
}
    `;
export type EditLessonMutationFn = Apollo.MutationFunction<EditLessonMutation, EditLessonMutationVariables>;

/**
 * __useEditLessonMutation__
 *
 * To run a mutation, you first call `useEditLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLessonMutation, { data, loading, error }] = useEditLessonMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      input: // value for 'input'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useEditLessonMutation(baseOptions?: Apollo.MutationHookOptions<EditLessonMutation, EditLessonMutationVariables>) {
        return Apollo.useMutation<EditLessonMutation, EditLessonMutationVariables>(EditLessonDocument, baseOptions);
      }
export type EditLessonMutationHookResult = ReturnType<typeof useEditLessonMutation>;
export type EditLessonMutationResult = Apollo.MutationResult<EditLessonMutation>;
export type EditLessonMutationOptions = Apollo.BaseMutationOptions<EditLessonMutation, EditLessonMutationVariables>;
export const ReorderLessonsDocument = gql`
    mutation reorderLessons($projectId: ID!, $idList: [ID!]!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      reorderLessons(idList: $idList) {
        message
      }
    }
  }
}
    `;
export type ReorderLessonsMutationFn = Apollo.MutationFunction<ReorderLessonsMutation, ReorderLessonsMutationVariables>;

/**
 * __useReorderLessonsMutation__
 *
 * To run a mutation, you first call `useReorderLessonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderLessonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderLessonsMutation, { data, loading, error }] = useReorderLessonsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      idList: // value for 'idList'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useReorderLessonsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderLessonsMutation, ReorderLessonsMutationVariables>) {
        return Apollo.useMutation<ReorderLessonsMutation, ReorderLessonsMutationVariables>(ReorderLessonsDocument, baseOptions);
      }
export type ReorderLessonsMutationHookResult = ReturnType<typeof useReorderLessonsMutation>;
export type ReorderLessonsMutationResult = Apollo.MutationResult<ReorderLessonsMutation>;
export type ReorderLessonsMutationOptions = Apollo.BaseMutationOptions<ReorderLessonsMutation, ReorderLessonsMutationVariables>;
export const AssignTagDocument = gql`
    mutation assignTag($projectId: ID!, $tagId: ID!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      assignTag(tagId: $tagId) {
        message
      }
    }
  }
}
    `;
export type AssignTagMutationFn = Apollo.MutationFunction<AssignTagMutation, AssignTagMutationVariables>;

/**
 * __useAssignTagMutation__
 *
 * To run a mutation, you first call `useAssignTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTagMutation, { data, loading, error }] = useAssignTagMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      tagId: // value for 'tagId'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useAssignTagMutation(baseOptions?: Apollo.MutationHookOptions<AssignTagMutation, AssignTagMutationVariables>) {
        return Apollo.useMutation<AssignTagMutation, AssignTagMutationVariables>(AssignTagDocument, baseOptions);
      }
export type AssignTagMutationHookResult = ReturnType<typeof useAssignTagMutation>;
export type AssignTagMutationResult = Apollo.MutationResult<AssignTagMutation>;
export type AssignTagMutationOptions = Apollo.BaseMutationOptions<AssignTagMutation, AssignTagMutationVariables>;
export const UnassignTagDocument = gql`
    mutation unassignTag($projectId: ID!, $tagId: ID!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      unassignTag(tagId: $tagId) {
        message
      }
    }
  }
}
    `;
export type UnassignTagMutationFn = Apollo.MutationFunction<UnassignTagMutation, UnassignTagMutationVariables>;

/**
 * __useUnassignTagMutation__
 *
 * To run a mutation, you first call `useUnassignTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignTagMutation, { data, loading, error }] = useUnassignTagMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      tagId: // value for 'tagId'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useUnassignTagMutation(baseOptions?: Apollo.MutationHookOptions<UnassignTagMutation, UnassignTagMutationVariables>) {
        return Apollo.useMutation<UnassignTagMutation, UnassignTagMutationVariables>(UnassignTagDocument, baseOptions);
      }
export type UnassignTagMutationHookResult = ReturnType<typeof useUnassignTagMutation>;
export type UnassignTagMutationResult = Apollo.MutationResult<UnassignTagMutation>;
export type UnassignTagMutationOptions = Apollo.BaseMutationOptions<UnassignTagMutation, UnassignTagMutationVariables>;
export const GetDutiesByDateDocument = gql`
    query getDutiesByDate($from: DateTime!, $to: DateTime!) {
  asUser {
    id
    getDutiesByDate(from: $from, to: $to) {
      id
      text
      status
      plannedEnd
      endDate
      projectId
    }
  }
}
    `;

/**
 * __useGetDutiesByDateQuery__
 *
 * To run a query within a React component, call `useGetDutiesByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDutiesByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDutiesByDateQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetDutiesByDateQuery(baseOptions: Apollo.QueryHookOptions<GetDutiesByDateQuery, GetDutiesByDateQueryVariables>) {
        return Apollo.useQuery<GetDutiesByDateQuery, GetDutiesByDateQueryVariables>(GetDutiesByDateDocument, baseOptions);
      }
export function useGetDutiesByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDutiesByDateQuery, GetDutiesByDateQueryVariables>) {
          return Apollo.useLazyQuery<GetDutiesByDateQuery, GetDutiesByDateQueryVariables>(GetDutiesByDateDocument, baseOptions);
        }
export type GetDutiesByDateQueryHookResult = ReturnType<typeof useGetDutiesByDateQuery>;
export type GetDutiesByDateLazyQueryHookResult = ReturnType<typeof useGetDutiesByDateLazyQuery>;
export type GetDutiesByDateQueryResult = Apollo.QueryResult<GetDutiesByDateQuery, GetDutiesByDateQueryVariables>;
export const AddRiskDocument = gql`
    mutation addRisk($projectId: ID!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      addRisk {
        newRisk {
          id
          text
          impact
          probability
          actions
          responsibleId
        }
      }
    }
  }
}
    `;
export type AddRiskMutationFn = Apollo.MutationFunction<AddRiskMutation, AddRiskMutationVariables>;

/**
 * __useAddRiskMutation__
 *
 * To run a mutation, you first call `useAddRiskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRiskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRiskMutation, { data, loading, error }] = useAddRiskMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useAddRiskMutation(baseOptions?: Apollo.MutationHookOptions<AddRiskMutation, AddRiskMutationVariables>) {
        return Apollo.useMutation<AddRiskMutation, AddRiskMutationVariables>(AddRiskDocument, baseOptions);
      }
export type AddRiskMutationHookResult = ReturnType<typeof useAddRiskMutation>;
export type AddRiskMutationResult = Apollo.MutationResult<AddRiskMutation>;
export type AddRiskMutationOptions = Apollo.BaseMutationOptions<AddRiskMutation, AddRiskMutationVariables>;
export const DeleteRiskDocument = gql`
    mutation deleteRisk($projectId: ID!, $id: ID!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      deleteRisk(id: $id) {
        message
      }
    }
  }
}
    `;
export type DeleteRiskMutationFn = Apollo.MutationFunction<DeleteRiskMutation, DeleteRiskMutationVariables>;

/**
 * __useDeleteRiskMutation__
 *
 * To run a mutation, you first call `useDeleteRiskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRiskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRiskMutation, { data, loading, error }] = useDeleteRiskMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useDeleteRiskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRiskMutation, DeleteRiskMutationVariables>) {
        return Apollo.useMutation<DeleteRiskMutation, DeleteRiskMutationVariables>(DeleteRiskDocument, baseOptions);
      }
export type DeleteRiskMutationHookResult = ReturnType<typeof useDeleteRiskMutation>;
export type DeleteRiskMutationResult = Apollo.MutationResult<DeleteRiskMutation>;
export type DeleteRiskMutationOptions = Apollo.BaseMutationOptions<DeleteRiskMutation, DeleteRiskMutationVariables>;
export const EditRiskDocument = gql`
    mutation editRisk($projectId: ID!, $input: RiskInput!, $step: Step!) {
  asUser {
    editProject(projectId: $projectId, step: $step) {
      editRisk(input: $input) {
        message
      }
    }
  }
}
    `;
export type EditRiskMutationFn = Apollo.MutationFunction<EditRiskMutation, EditRiskMutationVariables>;

/**
 * __useEditRiskMutation__
 *
 * To run a mutation, you first call `useEditRiskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRiskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRiskMutation, { data, loading, error }] = useEditRiskMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      input: // value for 'input'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useEditRiskMutation(baseOptions?: Apollo.MutationHookOptions<EditRiskMutation, EditRiskMutationVariables>) {
        return Apollo.useMutation<EditRiskMutation, EditRiskMutationVariables>(EditRiskDocument, baseOptions);
      }
export type EditRiskMutationHookResult = ReturnType<typeof useEditRiskMutation>;
export type EditRiskMutationResult = Apollo.MutationResult<EditRiskMutation>;
export type EditRiskMutationOptions = Apollo.BaseMutationOptions<EditRiskMutation, EditRiskMutationVariables>;
export const GetTagsDocument = gql`
    query getTags {
  asUser {
    id
    getTags {
      id
      name
    }
  }
}
    `;

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
        return Apollo.useQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, baseOptions);
      }
export function useGetTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
          return Apollo.useLazyQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, baseOptions);
        }
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>;
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>;
export type GetTagsQueryResult = Apollo.QueryResult<GetTagsQuery, GetTagsQueryVariables>;
export const CreateTagDocument = gql`
    mutation createTag($name: String!) {
  asUser {
    createTag(name: $name) {
      message
      newTag {
        id
        name
      }
    }
  }
}
    `;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
        return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, baseOptions);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const DeleteTagDocument = gql`
    mutation deleteTag($id: ID!) {
  asUser {
    deleteTag(id: $id) {
      message
    }
  }
}
    `;
export type DeleteTagMutationFn = Apollo.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>) {
        return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, baseOptions);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>;
export const EditTagDocument = gql`
    mutation editTag($input: TagInput!) {
  asUser {
    editTag(input: $input) {
      message
    }
  }
}
    `;
export type EditTagMutationFn = Apollo.MutationFunction<EditTagMutation, EditTagMutationVariables>;

/**
 * __useEditTagMutation__
 *
 * To run a mutation, you first call `useEditTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTagMutation, { data, loading, error }] = useEditTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTagMutation(baseOptions?: Apollo.MutationHookOptions<EditTagMutation, EditTagMutationVariables>) {
        return Apollo.useMutation<EditTagMutation, EditTagMutationVariables>(EditTagDocument, baseOptions);
      }
export type EditTagMutationHookResult = ReturnType<typeof useEditTagMutation>;
export type EditTagMutationResult = Apollo.MutationResult<EditTagMutation>;
export type EditTagMutationOptions = Apollo.BaseMutationOptions<EditTagMutation, EditTagMutationVariables>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "MutationResponse",
        "possibleTypes": [
          {
            "name": "SimpleMutationResponse"
          },
          {
            "name": "ConfirmMemberResponse"
          },
          {
            "name": "LoginResponse"
          },
          {
            "name": "ConfirmAccountResponse"
          },
          {
            "name": "CreatePaymentSessionResponse"
          },
          {
            "name": "CreateTagResponse"
          },
          {
            "name": "EditProfileResponse"
          },
          {
            "name": "AddBenefitResponse"
          },
          {
            "name": "AddLessonResponse"
          },
          {
            "name": "AddAssumptionResponse"
          },
          {
            "name": "AddRiskResponse"
          },
          {
            "name": "AddRestrictionResponse"
          },
          {
            "name": "CreateWbsNodeResponse"
          },
          {
            "name": "CreateProjectResponse"
          },
          {
            "name": "AddMemberResponse"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "Plan",
        "possibleTypes": [
          {
            "name": "Basic"
          },
          {
            "name": "Individual"
          },
          {
            "name": "Enterprise"
          }
        ]
      }
    ]
  }
};
      export default result;
    