import React from "react";
import { Text, Image, Page, View, Document } from "@react-pdf/renderer";
import type { Project } from "../../../graphql-client/codegen-types";
import PDFQuestion from "../../components/pdf/PDFQuestion";
import PDFHeader from "../../components/pdf/PDFHeader";
import PDFTitle from "../../components/pdf/PDFTitle";
import {
  differenceInWorkDays,
  parseStartDate,
} from "../../components/gantt/utils";
import { readableFormat } from "../../../components/utils";

type Props = {
  project: Project;
  ganttPNGScreenshot: string;
};

const daysLabel = (days: number) =>
  days === 1 ? "día laboral" : "días laborales";

const EndDateAnalogy = ({ delay }: { delay: number }) => {
  if (delay === 0) return <Text>El proyecto va justo a tiempo</Text>;
  if (delay < 0)
    return (
      <Text>
        ¡El proyecto va adelantado {-delay} {daysLabel(-delay)}!
      </Text>
    );
  return (
    <Text>
      El proyecto va atrasado {delay} {daysLabel(delay)}
    </Text>
  );
};

const Table = ({ project }: { project: Project }) => {
  if (
    !project?.wbsRoot?.plannedStart ||
    !project?.wbsRoot?.plannedEnd ||
    !project?.wbsRoot?.startDate ||
    !project?.wbsRoot?.endDate
  ) {
    return <Text> Fechas aún no definidas. </Text>;
  }
  const plannedStart = parseStartDate(project.wbsRoot.plannedStart);
  const plannedEnd = parseStartDate(project.wbsRoot.plannedEnd);
  const startDate = parseStartDate(project.wbsRoot.startDate);
  const endDate = parseStartDate(project.wbsRoot.endDate);
  const delay = differenceInWorkDays(
    project.workDays || [],
    plannedEnd,
    endDate
  );

  return (
    <View
      style={{
        fontSize: "12px",
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          fontFamily: "Helvetica-Bold",
        }}
      >
        <View
          style={{ border: "1px solid black", width: "33%", padding: "3px" }}
        />
        <View
          style={{ border: "1px solid black", width: "33%", padding: "3px" }}
        >
          <Text>Planeado</Text>
        </View>
        <View
          style={{ border: "1px solid black", width: "33%", padding: "3px" }}
        >
          <Text>Real</Text>
        </View>
      </View>
      <View style={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <View
          style={{
            border: "1px solid black",
            width: "33%",
            padding: "3px",
            fontFamily: "Helvetica-Bold",
          }}
        >
          <Text>Inicio</Text>
        </View>
        <View
          style={{ border: "1px solid black", width: "33%", padding: "3px" }}
        >
          <Text>{readableFormat(plannedStart)}</Text>
        </View>
        <View
          style={{ border: "1px solid black", width: "33%", padding: "3px" }}
        >
          <Text>{readableFormat(startDate)}</Text>
        </View>
      </View>
      <View style={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <View
          style={{
            border: "1px solid black",
            width: "33%",
            padding: "3px",
            fontFamily: "Helvetica-Bold",
          }}
        >
          <Text>Terminación</Text>
        </View>
        <View
          style={{ border: "1px solid black", width: "33%", padding: "3px" }}
        >
          <Text>{readableFormat(plannedEnd)}</Text>
        </View>
        <View
          style={{ border: "1px solid black", width: "33%", padding: "3px" }}
        >
          <Text>{readableFormat(endDate)}</Text>
        </View>
      </View>
      <Text
        style={{
          border: "1px solid black",
          width: "99%",
          padding: "5px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {" "}
        <EndDateAnalogy delay={delay} />{" "}
      </Text>
    </View>
  );
};

const PDFReport = ({ project, ganttPNGScreenshot }: Props) => {
  const { name, members, assumptions, restrictions, statusComments } = project;
  return (
    <Document>
      <Page size="A4" style={{ padding: "40px", color: "#053d56" }}>
        <PDFHeader name={name || "[Proyecto sin nombre]"} />
        <PDFTitle title="¿Cómo va?" />
        <Table project={project} />
        <View style={{ fontSize: "17px" }}>
          <PDFQuestion question="Diagrama de Gantt: Monitoreo del Proyecto">
            <Image
              src={ganttPNGScreenshot}
              style={{
                marginTop: "10px",
                maxHeight: "750px",
                width: "auto",
                objectFit: "contain",
              }}
            />
          </PDFQuestion>
        </View>
        <View style={{ fontSize: "13px" }}>
          <PDFQuestion question="Comentarios/notas sobre el estatus del proyecto">
            <Text> {statusComments} </Text>
          </PDFQuestion>
        </View>
        <View style={{ fontSize: "13px" }}>
          <PDFQuestion question="¿Qué suposiciones hay para que el proyecto avance?">
            {assumptions?.map(({ id, text }) => (
              <Text style={{ marginBottom: "2px" }} key={id}>
                - {text}
              </Text>
            ))}
          </PDFQuestion>
          <PDFQuestion question="¿Qué restricciones existen que pueden limitar el avance del proyecto?">
            {restrictions?.map(({ id, text }) => (
              <Text style={{ marginBottom: "2px" }} key={id}>
                - {text}
              </Text>
            ))}
          </PDFQuestion>
        </View>
      </Page>
    </Document>
  );
};

export default PDFReport;
