import {
  WidthType,
  HeadingLevel,
  TextRun,
  AlignmentType,
  TableCell,
  TableRow,
  Table,
  Paragraph,
} from "docx";
import {
  WbsNodeStatus,
  WbsNode,
  Project,
} from "../../../graphql-client/codegen-types";
import getDocxTemplatePromise from "../../components/docx/docx-template";
import { readableFormat } from "../../../components/utils";
import {
  differenceInWorkDays,
  parseStartDate,
} from "../../components/gantt/utils";

const daysLabel = (days: number) => (days === 1 ? "día" : "días");

const getStatusText = (status?: WbsNodeStatus | null) => {
  if (!status) return "";
  if (status === WbsNodeStatus.Pending) return "No iniciado";
  if (status === WbsNodeStatus.InProgress) return "En progreso";
  return "Terminado";
};

const getTableCell = (text: string, alignCenter = false, bold = false) =>
  new TableCell({
    children: [
      new Paragraph({
        children: [new TextRun({ text, bold })],
        alignment: alignCenter ? AlignmentType.CENTER : AlignmentType.LEFT,
        heading: HeadingLevel.HEADING_3,
        spacing: { before: 50, after: 50 },
      }),
    ],
  });

const displayWbs = (
  {
    text,
    children,
    startDate,
    endDate,
    plannedStart,
    plannedEnd,
    status,
  }: WbsNode,
  wbsCode = ""
): Array<TableRow> => {
  const displayedChildren = (children || [])
    .map((child, idx) => displayWbs(child, `${wbsCode}${idx + 1}.`))
    .flat();
  const formattedStartDate =
    startDate || plannedStart
      ? readableFormat(parseStartDate(startDate || plannedStart))
      : "";
  const formattedEndDate =
    endDate || plannedEnd
      ? readableFormat(parseStartDate(endDate || plannedEnd))
      : "";
  const nodeRow = new TableRow({
    children: [
      getTableCell(`${wbsCode} ${text}`),
      getTableCell(getStatusText(status), true),
      getTableCell(formattedStartDate, true),
      getTableCell(formattedEndDate, true),
    ],
  });
  return [nodeRow, ...displayedChildren];
};

const getMainTable = (node: WbsNode) =>
  new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    rows: [
      new TableRow({
        children: [
          getTableCell(""),
          getTableCell("Estatus", true, true),
          getTableCell("Inicio real", true, true),
          getTableCell("Terminación real", true, true),
        ],
        tableHeader: true,
      }),
      ...displayWbs(node),
    ],
  });

const getProjectInfo = (
  { startDate, endDate, plannedStart, plannedEnd }: WbsNode,
  workDays: Array<boolean>
) => {
  const formattedPlannedStart = plannedStart
    ? readableFormat(parseStartDate(plannedStart))
    : "";
  const formattedPlannedEnd = plannedEnd
    ? readableFormat(parseStartDate(plannedEnd))
    : "";
  const formattedStartDate =
    startDate || plannedStart
      ? readableFormat(parseStartDate(startDate || plannedStart))
      : "";
  const formattedEndDate =
    endDate || plannedEnd
      ? readableFormat(parseStartDate(endDate || plannedEnd))
      : "";
  const getEndDateAnalogy = () => {
    const delay = differenceInWorkDays(
      workDays,
      parseStartDate(plannedEnd),
      parseStartDate(endDate)
    );
    if (delay === 0) return "El Proyecto va Justo a tiempo";
    if (delay <= 0)
      return `El Proyecto va Adelantado ${-delay} ${daysLabel(-delay)} lab.`;
    return `El Proyecto va Atrasado ${delay} ${daysLabel(delay)} lab.`;
  };
  return [
    new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        new TableRow({
          children: [
            getTableCell(""),
            getTableCell("Planeado", true, true),
            getTableCell("Real", true, true),
          ],
          tableHeader: true,
        }),
        new TableRow({
          children: [
            getTableCell("Inicio", true, true),
            getTableCell(formattedPlannedStart, true),
            getTableCell(formattedStartDate, true),
          ],
        }),
        new TableRow({
          children: [
            getTableCell("Terminación", true, true),
            getTableCell(formattedPlannedEnd, true),
            getTableCell(formattedEndDate, true),
          ],
        }),
      ],
    }),
    new Paragraph({
      children: [new TextRun({ text: getEndDateAnalogy(), bold: true })],
      alignment: AlignmentType.CENTER,
      heading: HeadingLevel.HEADING_3,
      spacing: { before: 100, after: 400 },
    }),
  ];
};

const getAssumptions = (project: Project) => [
  new Paragraph({
    children: [
      new TextRun({
        text: "¿Qué suposiciones hay para que el proyecto avance?",
        break: 1,
      }),
    ],
    heading: HeadingLevel.HEADING_2,
  }),
  ...(project.assumptions || []).map(
    ({ text }) =>
      new Paragraph({
        text: text || "",
        bullet: { level: 0 },
        heading: HeadingLevel.HEADING_3,
      })
  ),
];

const getRestrictions = (project: Project) => [
  new Paragraph({
    children: [
      new TextRun({
        text:
          "¿Qué restricciones existen que pueden limitar el avance del proyecto?",
        break: 1,
      }),
    ],
    heading: HeadingLevel.HEADING_2,
  }),
  ...(project.restrictions || []).map(
    ({ text }) =>
      new Paragraph({
        text: text || "",
        bullet: { level: 0 },
        heading: HeadingLevel.HEADING_3,
      })
  ),
];

const getComments = (project: Project) => {
  const { statusComments } = project;
  if (!statusComments?.length) return [];
  return [
    new Paragraph({
      children: [
        new TextRun({
          text: "Comentarios/notas sobre el estatus del proyecto",
          break: 1,
        }),
      ],
      heading: HeadingLevel.HEADING_2,
    }),
    new Paragraph({
      text: statusComments || "",
      heading: HeadingLevel.HEADING_3,
    }),
  ];
};

const getDocxReportPromise = (project: Project) => {
  const { wbsRoot } = project;
  if (!wbsRoot) throw Error("Wbs is undefined");
  return getDocxTemplatePromise(project, [
    ...getProjectInfo(wbsRoot, project.workDays || []),
    getMainTable(wbsRoot),
    ...getComments(project),
    ...getAssumptions(project),
    ...getRestrictions(project),
  ]);
};

export default getDocxReportPromise;
