import React from "react";
import {
  FaCalendarTimes,
  FaCalendarMinus,
  FaCalendarCheck,
  FaCalendarPlus,
  FaCircle,
} from "react-icons/fa";
import styled from "styled-components";

type Props = {
  projectDelay: number;
  projectDuration: number;
};

const StyledCircle = styled(FaCircle)<{ color: string }>`
  margin: 0 5px 0 5px;
  color: ${(props) => props.color};
`;

const StyledSpan = styled.span<{ background: string }>`
  border-radius: 4px;
  background: ${(props) => props.background};
  padding: 3px;
`;

const InnerStopLight = ({ percentage }: { percentage: number }) => {
  const iconStyle = { height: "17px", width: "17px" };
  if (percentage < 0)
    return (
      <span style={{ color: "green" }}>
        {" "}
        <FaCalendarPlus style={iconStyle} /> Adelantado
      </span>
    );
  if (percentage === 0)
    return (
      <span style={{ color: "green" }}>
        {" "}
        <FaCalendarCheck style={iconStyle} /> A Tiempo{" "}
      </span>
    );
  if (percentage <= 5)
    return (
      <span style={{ color: "orange" }}>
        {" "}
        <FaCalendarMinus style={iconStyle} /> {Math.trunc(percentage)}% Atrasado
      </span>
    );
  return (
    <span style={{ color: "crimson" }}>
      {" "}
      <FaCalendarTimes style={iconStyle} /> {Math.trunc(percentage)}% Atrasado
    </span>
  );
};

const StopLight = ({ projectDelay, projectDuration }: Props) => {
  const positiveDuration = Math.max(projectDuration, 1); // Solo por si acaso, para prevenir errores de division entre cero
  const colors = ["green", "yellow", "red"];
  const percentage = (projectDelay / positiveDuration) * 100;
  return <InnerStopLight percentage={percentage} />;
};

export default StopLight;
