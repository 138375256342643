import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import UsageCarousel from "../components/UsageCarousel";

const Image = ({ src, alt }: { src: string; alt: string }) => (
  <img
    src={src}
    alt={alt}
    style={{
      borderRadius: "15px",
      border: "3px solid lightgrey",
      width: "100%",
      marginBottom: "20px",
    }}
  />
);

const Welcome = () => {
  const history = useHistory();
  return (
    <div style={{ textAlign: "center", width: "100%", color: "#053d56" }}>
      <div style={{ marginBottom: "15px" }}>
        <Button variant="info" onClick={() => history.push("/signin")}>
          {" "}
          Iniciar sesión{" "}
        </Button>
      </div>
      <UsageCarousel />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          margin: "10px 0 10px 0",
          padding: "20px 0 20px 0",
          background: "rgba(240, 248, 255, 0.7)",
        }}
      >
        <div style={{ flexDirection: "column", margin: "0 20px 0 20px" }}>
          <h2>
            Coordinate <b>Pro</b> por sólo <b>$99 pesos</b> al mes
          </h2>
          Precio <b>exclusivo</b> por tiempo limitado
        </div>
        <Button
          variant="info"
          onClick={() => history.push("/register")}
          style={{ marginLeft: "10px", width: "300px" }}
        >
          <h3 style={{ margin: "0" }}>Empezar ahora</h3>
        </Button>
      </div>
    </div>
  );
};

export default Welcome;
