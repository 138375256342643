import React from "react";
import {
  FormCheck,
  Container,
  Button,
  Col,
  Row,
  Dropdown,
} from "react-bootstrap";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { FaBriefcase, FaPlus, FaSearch } from "react-icons/fa";
import { FILTER_PROJECTS } from "./graphql-client/queries/project";
import TarjetaDeProyecto from "./components/TarjetaDeProyecto";
import type { Project } from "./graphql-client/codegen-types";
import Loader from "./components/Loader";
import DateFilter from "./components/DateFilter";
import TextFilter from "./components/TextFilter";
import TagFilter from "./components/TagFilter";
import type { Filter } from "./components/types";
import {
  parseMaybeStartDate,
  parseMaybeEndDate,
} from "./project/components/gantt/utils";
import client from "./graphql-client/connection";
import NewProjectModal from "./components/NewProjectModal";

const { cache } = client;

type ChosenFilterProps = {
  index: number;
  title: string;
  onUpdate: (index: number, searchText: string) => void;
  onDelete: (index: number) => void;
};

const CreateProjectButton = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <div>
      {open && <NewProjectModal close={() => setOpen(false)} />}
      <Button variant="info" onClick={() => setOpen(true)} size="sm">
        <FaPlus /> Crear Proyecto...
      </Button>
    </div>
  );
};

const StyledContainer = styled(Container)`
  background: green;
`;

const Title = styled.h4`
  margin-top: 10px;
  text-align: center;
`;

enum SortKey {
  START_DATE = 1,
  END_DATE = 2,
}

const getSortKeyText = (key: SortKey) => {
  if (key === SortKey.START_DATE) {
    return "Fecha de inicio";
  }
  return "Fecha de terminación";
};

function getProjectSortingKey(key: SortKey, project: Project) {
  if (key === SortKey.START_DATE) {
    return project?.wbsRoot?.startDate;
  }
  return project?.wbsRoot?.endDate;
}

const ProjectList = () => {
  const nameRef = React.useRef<HTMLInputElement>(null);
  const startDateRef = React.useRef<HTMLButtonElement>();
  const tagRef = React.useRef<HTMLSelectElement>();
  const [startDateFilter, setStartDateFilter] = React.useState<Filter>(null);
  const [showStartDateOption, setShowStartDateOption] = React.useState(false);
  const endDateRef = React.useRef<HTMLButtonElement>();
  const [endDateFilter, setEndDateFilter] = React.useState<Filter>(null);
  const [showEndDateOption, setShowEndDateOption] = React.useState(false);
  const [showNameOption, setShowNameOption] = React.useState(false);
  const [nameFilter, setNameFilter] = React.useState<Filter>(null);
  const [showTagOption, setShowTagOption] = React.useState(false);
  const [tagFilter, setTagFilter] = React.useState<Filter>(null);
  const [sortKey, setSortKey] = React.useState<SortKey>(SortKey.END_DATE);
  const [isAscending, setIsAscending] = React.useState(false);
  const { loading, error, data } = useQuery(FILTER_PROJECTS, {
    variables: {
      textFilters: [],
    },
    errorPolicy: "all",
    fetchPolicy: "cache-first",
  });
  const matchesFilters = (project: Project) => {
    if (nameFilter !== null && !nameFilter.matchesFilter(project)) return false;
    if (startDateFilter !== null && !startDateFilter.matchesFilter(project))
      return false;
    if (endDateFilter !== null && !endDateFilter.matchesFilter(project))
      return false;
    if (tagFilter !== null && !tagFilter.matchesFilter(project)) return false;
    return true;
  };

  const getInnerList = () => {
    if (error)
      return (
        <p style={{ margin: "10px", textAlign: "center" }}>
          Hubo un error al cargar los proyectos :( <br /> Revisa tu conexión a
          internet, recarga la página o inténtalo más tarde.
          <br /> Si el problema persiste, repórtalo a contacto@coordinate.si
        </p>
      );
    if (loading)
      return <Loader size={60} message="Cargando tus proyectos..." />;
    const displayedProjects = [
      ...data.asUser.getProjects.filter(matchesFilters),
    ].sort((a: Project, b: Project) => {
      const aKey = getProjectSortingKey(sortKey, a);
      const bKey = getProjectSortingKey(sortKey, b);
      if (!aKey) {
        return 1;
      }
      if (!bKey) {
        return -1;
      }
      if (isAscending) {
        return aKey.localeCompare(bKey);
      }
      return bKey.localeCompare(aKey);
    });
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          {displayedProjects.map((project: Project) => (
            <Row
              key={project.id}
              sm={1}
              lg={2}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <TarjetaDeProyecto project={project} />
            </Row>
          ))}
        </div>
      </div>
    );
  };
  return (
    <div>
      <Title>
        {" "}
        <FaBriefcase /> Portafolio de Proyectos{" "}
      </Title>
      <hr />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col
          sm={12}
          lg={6}
          style={{
            border: "1px solid lightgrey",
            background: "rgb(240, 240, 240)",
            padding: "5px 0 5px 0",
            borderRadius: "5px",
          }}
        >
          {showNameOption && (
            <TextFilter
              label="Nombre"
              setFilter={setNameFilter}
              getProjectText={(project) => project?.name}
              close={() => setShowNameOption(false)}
              ref={nameRef as any}
            />
          )}
          {showStartDateOption && (
            <DateFilter
              label="Fecha de inicio"
              setFilter={setStartDateFilter}
              getProjectDate={(project) =>
                parseMaybeStartDate(project?.wbsRoot?.startDate)
              }
              close={() => setShowStartDateOption(false)}
              ref={startDateRef as any}
            />
          )}
          {showEndDateOption && (
            <DateFilter
              label="Fecha de terminación"
              setFilter={setEndDateFilter}
              getProjectDate={(project) =>
                parseMaybeEndDate(project?.wbsRoot?.endDate)
              }
              close={() => setShowEndDateOption(false)}
              ref={endDateRef as any}
            />
          )}
          {showTagOption && (
            <TagFilter
              setFilter={setTagFilter}
              tags={data?.asUser?.getTags || []}
              close={() => setShowTagOption(false)}
              ref={tagRef as any}
            />
          )}
          <Col>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Dropdown>
                <Dropdown.Toggle split variant="light" id="dropdown-custom-2">
                  <FaSearch /> Buscar...{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu className="super-colors">
                  {!showNameOption && (
                    <Dropdown.Item
                      onClick={() => {
                        setShowNameOption(true);
                        setTimeout(() => nameRef?.current?.focus(), 0);
                      }}
                    >
                      {" "}
                      Por Nombre{" "}
                    </Dropdown.Item>
                  )}
                  {!showStartDateOption && (
                    <Dropdown.Item
                      onClick={() => {
                        setShowStartDateOption(true);
                        setTimeout(() => startDateRef?.current?.click(), 0);
                      }}
                    >
                      {" "}
                      Por Fecha de Inicio{" "}
                    </Dropdown.Item>
                  )}
                  {!showEndDateOption && (
                    <Dropdown.Item
                      onClick={() => {
                        setShowEndDateOption(true);
                        setTimeout(() => endDateRef?.current?.click(), 0);
                      }}
                    >
                      {" "}
                      Por Fecha de Terminación{" "}
                    </Dropdown.Item>
                  )}
                  {!showTagOption && (
                    <Dropdown.Item
                      onClick={() => {
                        setShowTagOption(true);
                        setTimeout(() => tagRef?.current?.focus(), 0);
                      }}
                    >
                      {" "}
                      Por Grupo de Proyecto{" "}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Col>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col
          sm={12}
          lg={6}
          style={{
            padding: "10px 0 5px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div style={{ paddingRight: "5px" }}>
              <Dropdown>
                <Dropdown.Toggle split variant="light" id="dropdown-custom-2">
                  Ordenar por {getSortKeyText(sortKey)}{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu className="super-colors">
                  <Dropdown.Item onClick={() => setSortKey(SortKey.START_DATE)}>
                    Fecha de inicio
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setSortKey(SortKey.END_DATE)}>
                    Fecha de terminación
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div
              style={{
                minWidth: "50px",
                color: "grey",
                padding: "0 5px 0 5px",
              }}
            >
              <FormCheck
                id="asc-switch"
                title="Ordenar por ascendente/descendente"
                type="switch"
                checked={!isAscending}
                onChange={() => setIsAscending(!isAscending)}
              />{" "}
              {isAscending ? "Asc" : "Desc"}
            </div>
            <CreateProjectButton />
          </div>
        </Col>
      </div>
      {getInnerList()}
    </div>
  );
};

export default ProjectList;
