import React from "react";
import { FaTasks } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { statusIcons } from "../project/components/gantt/utils";
import { WbsNodeStatus, WbsNode } from "../graphql-client/codegen-types";

const StyledDiv = styled.div<{ borderColor: string }>`
  margin-bottom: 3px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid ${(props) => props.borderColor};
  padding: 4px;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const Reminder = ({ wbsNode }: { wbsNode: WbsNode }) => {
  const history = useHistory();
  const { id, projectId, status } = wbsNode;
  const getIcon = () => {
    if (status === WbsNodeStatus.Done) return statusIcons.done;
    return <FaTasks color="#999900" />;
  };
  const getBorderColor = () => {
    if (status === WbsNodeStatus.Done) return "limegreen";
    return "#999900";
  };

  const onClick = () => history.push(`/projects/${projectId}/comova/${id}`);
  return (
    <StyledDiv onClick={onClick} borderColor={getBorderColor()}>
      <span>
        {" "}
        {getIcon()} {wbsNode.text}{" "}
      </span>
    </StyledDiv>
  );
};

export default Reminder;
