import React from "react";
import { Button, Table, Collapse } from "react-bootstrap";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { FaExclamationTriangle, FaCaretDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Packer } from "docx";
import { useProjectQuery } from "../components/project-context";
import { GET_DATES_COMOTERMINO } from "../../graphql-client/queries/project";
import ComoterminoGanttChart from "./components/ComoterminoGanttChart";
import ComoterminoGanttPNGExporter from "./components/ComoterminoGanttPNGExporter";
import Pregunta from "../../components/Pregunta";
import {
  WbsNode,
  Access,
  Step,
  WbsNodeStatus,
  Project,
} from "../../graphql-client/codegen-types";
import {
  differenceInWorkDays,
  statusIcons,
  parseStartDate,
} from "../components/gantt/utils";
import { readableFormat, ActLabel, ProjectLabel } from "../../components/utils";
import Loader from "../../components/Loader";
import PDFReport from "./components/PDFReport";
import Feedback from "./components/Feedback";
import Benefits from "../components/Benefits";
import Lessons from "./components/Lessons";
import getDocxReportPromise from "./components/docx-report";
import { useProVerifier } from "../../plan";

const isComplete = (node: WbsNode): boolean => {
  const { plannedStart, plannedEnd, children } = node;
  if (!plannedStart || !plannedEnd) return false;
  return (children || []).every(isComplete);
};

type Props = {
  projectId: string;
};

const Comotermino = ({ projectId }: Props) => {
  const { loading, error, data } = useProjectQuery(GET_DATES_COMOTERMINO, {
    variables: { id: projectId, step: Step.Comotermino },
  });
  const proVerifier = useProVerifier();
  const [projectMarkedFinished, setProjectMarkedFinished] = React.useState(
    false
  );
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);
  const [loadingPDF, setLoadingPDF] = React.useState(false);
  if (loading) return <Loader size={60} message="Cargando el proyecto" />;
  if (error) return <p> Error al cargar el Gantt </p>;
  const project = data.asUser.getProject;
  const { workDays, hasSubmittedFeedback } = project;
  const ganttContainerRef = React.createRef<HTMLDivElement>();
  const readOnly = project.access === Access.Read;
  const getFeedbackArea = () => {
    if (!project.access) return <h4>¡Gracias por usar Coordinate!</h4>;
    if (hasSubmittedFeedback)
      return (
        <div>
          <h4>
            ¡Muchas gracias por darnos tu retro!
            <br />
          </h4>
          <p>
            Todos los días trabajamos mejorando la plataforma, para que tengas
            las mejores herramientas al administrar tus proyectos
          </p>
        </div>
      );
    return (
      <div>
        <h4>¡Gracias por usar Coordinate!</h4>
        <Button variant="light" onClick={() => setFeedbackOpen(!feedbackOpen)}>
          {" "}
          Danos tu retroalimentación <FaCaretDown />{" "}
        </Button>
        <Collapse in={feedbackOpen}>
          <div>
            <br />
            <Feedback project={project} />
          </div>
        </Collapse>
      </div>
    );
  };

  const onGanttExport = (ganttScreenshot: any, updatedProject: Project) =>
    pdf(
      <PDFReport
        project={updatedProject}
        ganttPNGScreenshot={ganttScreenshot}
      />
    )
      .toBlob()
      .then((blob) => {
        saveAs(blob, "Como termino.pdf");
      });

  if (!isComplete(project.wbsRoot)) {
    return (
      <div>
        <h4> No has terminado de planear las fechas del proyecto </h4>
        Hay al menos una <ActLabel /> sin fechas planeadas. Vuelve al paso{" "}
        <Link to={`/projects/${project.id}/cuando`}>
          <i>4. ¿Cuándo?</i>
        </Link>{" "}
        y termina de asignarlas.
      </div>
    );
  }
  if (project.wbsRoot.status !== WbsNodeStatus.Done) {
    if (!projectMarkedFinished) {
      return (
        <div style={{ lineHeight: "2.7" }}>
          <h4>
            {" "}
            <FaExclamationTriangle style={{ color: "#CCCC00" }} /> ¿Seguro(a)
            que ya está terminado el <ProjectLabel />?{" "}
          </h4>
          Algunas <ActLabel plural /> aún tienen el estatus de en progreso{" "}
          {statusIcons.inProgress} o no iniciadas {statusIcons.pending}. <br />
          Todas las <ActLabel plural /> deberían tener estatus de{" "}
          {statusIcons.done} terminadas. <br />
          <Button
            variant="warning"
            onClick={() => setProjectMarkedFinished(true)}
          >
            Sí, el proyecto ya está terminado
          </Button>{" "}
          <Button variant="info">
            <Link to="comova" style={{ color: "white" }}>
              {" "}
              Regresar al paso <i>5. ¿Cómo va?</i>{" "}
            </Link>
          </Button>
        </div>
      );
    }
  }

  const plannedEnd = parseStartDate(project.wbsRoot.plannedEnd);
  const endDate = parseStartDate(project.wbsRoot.endDate);
  const delay = differenceInWorkDays(workDays, endDate, plannedEnd);
  const daysLabel = (days: number) => (days === 1 ? "día lab." : "días lab.");
  const getDelayHeader = () => {
    if (delay < 0) return <th> Se atrasó </th>;
    if (delay > 0) return <th> ¡Se adelantó! </th>;
    return <th> Se entregó </th>;
  };
  const getDelayCell = () => {
    if (delay < 0)
      return (
        <td>
          {" "}
          {Math.abs(delay)} {daysLabel(Math.abs(delay))}{" "}
        </td>
      );
    if (delay === 0) return <td> ¡Justo a tiempo! </td>;
    return (
      <td>
        {" "}
        {delay} {daysLabel(delay)}{" "}
      </td>
    );
  };

  return (
    <div>
      ¡Felicidades! has terminado tu proyecto.
      <hr />
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Planeaba terminarse</th>
            <th>Se terminó</th>
            {getDelayHeader()}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{readableFormat(plannedEnd)}</td>
            <td>{readableFormat(endDate)}</td>
            {getDelayCell()}
          </tr>
        </tbody>
      </Table>
      <Pregunta
        pregunta={
          <span>
            1. Compara cuáles entregables se entregaron a tiempo y cuáles se
            atrasaron
          </span>
        }
        descripcion="Es común que los proyectos se atrasen. Lo importante reflexionar cómo podemos mejorar los estimados en el futuro."
      />
      <ComoterminoGanttChart
        ganttContainerRef={ganttContainerRef}
        wbsRoot={project.wbsRoot}
        links={[]}
        onTaskUpdate={() => Promise.resolve()}
        proVerifier={proVerifier}
        onLinkAdd={() => Promise.resolve()}
        onLinkDelete={() => Promise.resolve()}
        criticalPathDisabled
      />
      <br />
      <Pregunta
        pregunta={
          <span>
            2. Analiza los beneficios definidos inicialmente ¿Algunos cambiaron?
            Actualízalos aquí{" "}
          </span>
        }
        descripcion="Es importante analizar los beneficios iniciales para saber qué cosas salieron bien y cuáles se pueden mejorar"
      >
        <Benefits project={project} readOnly={readOnly} />
      </Pregunta>
      <br />
      <Pregunta
        pregunta={
          <span>
            3. ¿Qué aprendizajes te dejó este proyecto? Lista las lecciones que
            consideras más importantes
          </span>
        }
        descripcion="Esta reflexión te puede ayudar a aplicar los aprendizajes en proyectos futuros"
      >
        <Lessons project={project} readOnly={readOnly} />
      </Pregunta>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        <ComoterminoGanttPNGExporter
          text="Imprimir reporte en PDF"
          onGanttExport={onGanttExport}
          project={project}
        />
        <Button
          variant="info"
          onClick={() => {
            getDocxReportPromise(project)
              .then((docxReport) => Packer.toBlob(docxReport))
              .then((blob) => {
                saveAs(blob, "Como terminó.docx");
              });
          }}
        >
          Exportar a Word (.docx)
        </Button>
      </div>
      <hr />
      {getFeedbackArea()}
      <hr />
      <Button variant="info">
        <Link to="/home" style={{ color: "white" }}>
          {" "}
          Volver a la página de inicio{" "}
        </Link>
      </Button>
    </div>
  );
};

export default Comotermino;
