import React from "react";
import { Dropdown } from "react-bootstrap";
import type { GanttStatic } from "dhtmlx-gantt";
import { FaExclamationTriangle } from "react-icons/fa";
import styled from "styled-components";
import type { GanttNode } from "../../components/gantt/types";
import type { ComovaNodeExtraAttributes } from "./types";
import { ActLabel } from "../../../components/utils";
import { WbsNodeStatus } from "../../../graphql-client/codegen-types";
import { statusIcons, getSupposedStatus } from "../../components/gantt/utils";

const StyledButton = styled.button`
  background: none;
  border: none;
  color: #0096ff;
  text-decoration: underline;
`;

type Props = {
  node: GanttNode & ComovaNodeExtraAttributes;
  gantt: GanttStatic;
  statusDate: Date;
  switchToDates: () => void;
};

const getStatusMessage = (status: WbsNodeStatus) => {
  if (status === WbsNodeStatus.Pending)
    return <span> {statusIcons.pending} No se ha iniciado </span>;
  if (status === WbsNodeStatus.InProgress)
    return <span> {statusIcons.inProgress} Está en progreso </span>;
  return <span> {statusIcons.done} Ya se terminó </span>;
};

const getStatusSupposedMessage = (status: WbsNodeStatus) => {
  if (status === WbsNodeStatus.Pending)
    return <b> Aún no debería estar iniciada. </b>;
  if (status === WbsNodeStatus.InProgress)
    return <b> Debería estar en progreso. </b>;
  return <b> Ya debería estar terminada. </b>;
};

const StatusEditor = ({ node, gantt, statusDate, switchToDates }: Props) => {
  const { id, status } = node;

  const onSelect = (eventKey: string | null) => {
    gantt.getTask(id).status = eventKey;
    gantt.updateTask(id);
  };

  const getMaybeWarning = () => {
    const supposedStatus = getSupposedStatus(node, statusDate);
    if (status === supposedStatus) return null;
    return (
      <div style={{ marginTop: "10px", background: "lemonchiffon" }}>
        {" "}
        <FaExclamationTriangle /> {getStatusSupposedMessage(
          supposedStatus
        )}{" "}
        <StyledButton onClick={switchToDates}>
          Hubo cambio de planes{" "}
        </StyledButton>
      </div>
    );
  };

  return (
    <div style={{ margin: "20px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span style={{ margin: "0 6px 0 6px" }}>
          La <ActLabel />
        </span>
        <Dropdown onSelect={onSelect}>
          <Dropdown.Toggle
            variant="light"
            id="dropdown-basic"
            style={{ border: "1px solid lightgrey" }}
          >
            {getStatusMessage(status)}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item eventKey={WbsNodeStatus.Pending}>
              {getStatusMessage(WbsNodeStatus.Pending)}{" "}
            </Dropdown.Item>
            <Dropdown.Item eventKey={WbsNodeStatus.InProgress}>
              {getStatusMessage(WbsNodeStatus.InProgress)}{" "}
            </Dropdown.Item>
            <Dropdown.Item eventKey={WbsNodeStatus.Done}>
              {getStatusMessage(WbsNodeStatus.Done)}{" "}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {getMaybeWarning()}
    </div>
  );
};

export default StatusEditor;
