import { HeadingLevel, Paragraph } from "docx";
import type { WbsNode, Project } from "../../../graphql-client/codegen-types";
import getDocxTemplatePromise from "../../components/docx/docx-template";

const displayWbs = (
  { text, children }: WbsNode,
  numberingLevel = -1
): Array<Paragraph> => {
  const displayedChildren = (children || [])
    .map((child, idx) => displayWbs(child, numberingLevel + 1))
    .flat();
  const numberingProps =
    numberingLevel !== -1
      ? { numbering: { reference: "default", level: numberingLevel } }
      : {};
  const nodeParagraph = new Paragraph({
    text: text || "",
    ...numberingProps,
    heading: HeadingLevel.HEADING_3,
  });
  return [nodeParagraph, ...displayedChildren];
};

const getDocxReportPromise = (project: Project) => {
  const { wbsRoot } = project;
  if (!wbsRoot) throw Error("Wbs is undefined");
  return getDocxTemplatePromise(project, displayWbs(wbsRoot));
};

export default getDocxReportPromise;
