// Se usa la herramienta de un tercero para el diagrama de gantt, se llama dhtmlx
// https://dhtmlx.com/docs/products/dhtmlxGantt/
// Documentación: https://docs.dhtmlx.com/gantt/index.html
//
// La versión gratis de dhtmlx se puede instalar fácilmente con npm. Sin embargo, aquí
// se usa la versión premium, la cual no puede ser instalada directamente con npm.
//
// Para incluirla, la herramienta está incluida en el folder dhtmlx_gantt, en el directorio
// raíz del proyecto. Se ha configurado para que se genere un enlace hacia la herramienta
// en node_modules cada vez que se ejecuta el proyecto.
//
// Para entender cómo funciona el gantt de dhtmlx, hay una larga lista de ejemplos. Éstos
// se pueden encontrar en https://docs.dhtmlx.com/gantt/samples/. El código de cada uno de ellos
// está incluido en el directorio de la herramienta (/dhtmlx_gantt/samples/).
//
// Este tutorial
// https://dhtmlx.com/blog/create-react-gantt-chart-component-dhtmlxgantt/
//
// cubre el material básico necesario para correr el gantt de dhtmlx en una app de React.
// Muchas líneas de este archivo son basadas en la documentación de dhtmlx. Si estás editando
// este componente, se recomienda revisar dicha documentación cuando haya dudas de qué hace
// cada cosa.

import React from "react";
import type { GanttStatic } from "dhtmlx-gantt";
import { Gantt } from "dhtmlx-gantt";
import ReactDOMServer from "react-dom/server";
import { FaTasks, FaGlobeAmericas } from "react-icons/fa";
import styled from "styled-components";
import type { WbsNode, Link } from "../../../graphql-client/codegen-types";
import type { GanttState, GanttNode, GanttBasicProps } from "./types";
import { Rhombus } from "../../../components/utils";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";

// Transforma JSX (React Components) en strings de html.
// Esto es útil porque dhtmlx usa html crudo para renderear.
// Documentación: https://reactjs.org/docs/react-dom-server.html
const jsxToStr = ReactDOMServer.renderToString;

const GanttStyleWrapper = styled.div`
  .gantt_task_content {
    cursor: inherit;
    color: inherit;
  }
  .act-bar {
    background: #999900;
    border: 1px solid grey;
    cursor: pointer;
    color: white;
    :hover {
      opacity: 0.8;
    }
  }
  .deliv-bar {
    background: coral;
    border: 1px solid grey;
    cursor: default;
    color: lightgray;
  }
  .project-bar {
    background: #0086ba;
    border: 1px solid grey;
    cursor: default;
    color: lightgray;
  }
  .critical-bar {
    border: 1px solid red;
    background: red;
    color: white;
  }
  .project-row,
  .project-row:hover,
  .project-row.odd:hover {
    background: #e6e8fa;
  }
  .deliv-row,
  .deliv-row:hover,
  .deliv-row.odd:hover {
    background: #fff1d8;
  }
  .act-row {
    background: white;
  }
  .gantt_grid_data .act-row:hover {
    background: aliceblue;
  }
  .gantt_grid_data .act-row {
    cursor: pointer;
  }
  .col-header {
    font-size: 14px;
    color: gray;
  }
  .weekend {
    background: #f4f7f4 !important;
  }
`;

class GanttChart<
  AdditionalGanttProps,
  AdditionalNodeAttributes,
  State extends GanttState
> extends React.Component<GanttBasicProps & AdditionalGanttProps, State> {
  // Objeto gantt de dhtmlx
  myGantt: GanttStatic;

  constructor(props: GanttBasicProps & AdditionalGanttProps) {
    super(props);
    this.myGantt = Gantt.getGanttInstance();
    this.styleGantt();
    this.setUpColumns();
    this.configureGantt();
  }

  componentDidMount() {
    const { ganttContainerRef, wbsRoot, links } = this.props;
    if (!ganttContainerRef.current) throw Error("ganttContainerRef is null.");
    this.myGantt.init(ganttContainerRef.current);
    const ganttNodes = this.toGanttNodeArray(wbsRoot);
    this.myGantt.parse({ data: ganttNodes, links });
  }

  componentWillUnmount() {
    this.myGantt.destructor();
  }

  setUpColumns() {
    const textColumn = {
      name: "text",
      label: " ",
      width: "160",
      tree: true,
      template: (task: GanttNode & AdditionalNodeAttributes) =>
        jsxToStr(
          <div
            style={{
              fontSize: "16px",
              color: task.readonly ? "grey" : "inherit",
            }}
          >
            {task.text}
          </div>
        ),
    };
    this.myGantt.config.columns = [textColumn];
  }

  toGanttNodeArray(
    node: WbsNode,
    parent = "0",
    level = 0
  ): Array<GanttNode & AdditionalNodeAttributes> {
    const ganttNode = this.toGanttNode(node, parent, level);
    const { id, children } = node;
    const ganttDescendants = (children || []).reduce((descendants, child) => {
      const childGanttNodeArray = this.toGanttNodeArray(child, id, level + 1);
      return descendants.concat(childGanttNodeArray);
    }, [] as Array<GanttNode & AdditionalNodeAttributes>);
    return [ganttNode, ...ganttDescendants];
  }

  toGanttNode(
    node: WbsNode,
    parent: string,
    level: number
  ): GanttNode & AdditionalNodeAttributes {
    throw Error(
      "Attempted to run an abstract method. Inherit this class and implement it."
    );
  }

  toWbsNode(node: GanttNode & AdditionalNodeAttributes): WbsNode {
    throw Error(
      "Attempted to run an abstract method. Inherit this class and implement it."
    );
  }

  configureGantt() {
    const {
      onTaskUpdate,
      updateTaskInCache,
      onLinkAdd,
      onLinkDelete,
      readOnly,
      workDays,
    } = this.props;
    this.myGantt.config.project_start = new Date(2021, 6, 24);
    this.myGantt.attachEvent(
      "onAfterTaskUpdate",
      (id: number, task: GanttNode & AdditionalNodeAttributes) => {
        if (task.level === 2) {
          // Solo las actividades se actualizan
          onTaskUpdate(this.toWbsNode(task));
          updateTaskInCache?.(this.toWbsNode(task));
        }
      },
      {}
    );
    this.myGantt.attachEvent(
      "onBeforeLinkAdd",
      (_, link: Link) => {
        const source = this.myGantt.getTask(link.source);
        if (source.type !== "task" || source.type !== "task") {
          return false; // Solo permitir enlaces entre actividades
        }
        if (readOnly) {
          return false;
        }
        onLinkAdd(link);
        return true;
      },
      {}
    );
    this.myGantt.attachEvent("onBeforeTaskDrag", () => !readOnly, {});
    this.myGantt.attachEvent("onAfterLinkDelete", onLinkDelete, {});
    this.myGantt.attachEvent("onBeforeLinkDelete", () => !readOnly, {});

    // Deshabilitando el lightbox default de dhtmlx
    this.myGantt.attachEvent("onBeforeLightbox", () => false, {});

    this.myGantt.plugins({
      critical_path: true,
      marker: true,
    });

    const config = {
      drag_progress: false,
      autosize: "y",
      readonly: readOnly,
      duration_unit: "day",
      static_background: true,
    };
    this.myGantt.config = Object.assign(this.myGantt.config, config);
  }

  // Este método se basa en la documentación de estilos de dhtmlx
  // The next few lines are based on the styling documentation of dhtmlx
  // Fuentes relevantes:
  // - https://docs.dhtmlx.com/gantt/desktop__styling.html
  // - https://docs.dhtmlx.com/gantt/api__refs__gantt_templates.html
  // - https://docs.dhtmlx.com/gantt/desktop__specifying_columns.html
  styleGantt() {
    const { workDays } = this.props;
    const isWeekend = (date: Date) => !this.myGantt.isWorkTime(date);
    const getClass = (task: GanttNode) =>
      ["project-row", "deliv-row", "act-row"][task.level];
    const scale_cell_class = (date: Date) => {
      const { zoom } = this.state;
      // Pintar deshabilitadas solo si representan dias no habiles
      const isDayCell = zoom <= 1;
      if (!this.myGantt.isWorkTime(date) && isDayCell) return "weekend";
      return "";
    };
    const timeline_cell_class = (task: GanttNode, date: Date) => {
      const { zoom } = this.state;
      const isDayCell = zoom <= 1;
      // console.log(date, isWeekend(date));
      return isWeekend(date) && isDayCell ? "weekend" : getClass(task);
    };
    const wrapIcon = (content: React.ReactNode) =>
      jsxToStr(<div style={{ margin: "0px 3px 0px 0px" }}>{content}</div>);
    const getIcon = ({ level }: GanttNode) =>
      [
        <FaGlobeAmericas style={{ color: "#0086ba" }} />,
        <Rhombus />,
        <FaTasks style={{ color: "#999900" }} />,
      ][level];
    const getBarClass = ({ level }: GanttNode) =>
      ["project-bar", "deliv-bar", "act-bar"][level];
    const maybeAddCritical = (node: GanttNode, currentClass: string) => {
      const { showCriticalPath } = this.state;
      if (showCriticalPath && this.myGantt.isCriticalTask(node))
        return `critical-bar ${currentClass}`;
      return currentClass;
    };
    const templates = {
      timeline_cell_class,
      scale_cell_class,
      grid_row_class: (start: Date, end: Date, task: GanttNode) =>
        getClass(task),
      scale_row_class: () => "col-header",
      task_class: (start: Date, end: Date, node: GanttNode) =>
        maybeAddCritical(node, getBarClass(node)),
      grid_folder: (task: GanttNode) => wrapIcon(getIcon(task)),
      grid_file: (task: GanttNode) => wrapIcon(getIcon(task)),
    };
    this.myGantt.templates = Object.assign(this.myGantt.templates, templates);
  }

  render() {
    const { ganttContainerRef } = this.props;
    return (
      <GanttStyleWrapper>
        <div
          ref={ganttContainerRef}
          style={{ width: "100%", height: "100%" }}
        />
      </GanttStyleWrapper>
    );
  }
}
export default GanttChart;
