import { Footer, Paragraph, TextRun, PageNumber, HeadingLevel } from "docx";

const docxFooter = new Footer({
  children: [
    new Paragraph({
      children: [new TextRun({ children: [PageNumber.CURRENT] })],
      heading: HeadingLevel.HEADING_3,
    }),
  ],
});

export default docxFooter;
