import React from "react";
import ReactDOMServer from "react-dom/server";
import { addDays, differenceInDays } from "date-fns";
import { FaTasks, FaGlobeAmericas } from "react-icons/fa";
import { Button } from "react-bootstrap";
import ReactDOM from "react-dom";
import CuandoGanttChart from "./CuandoGanttChart";
import type { CuandoNode } from "./types";
import type { Project } from "../../../graphql-client/codegen-types";
import { briefFormat, Rhombus } from "../../../components/utils";
import { getGanttPNGScreenshot } from "../../components/gantt/utils";
import Loader from "../../../components/Loader";

// Transforma JSX (React Components) en strings de html.
// Esto es útil porque dhtmlx usa html crudo para renderear.
// Documentación: https://reactjs.org/docs/react-dom-server.html
const jsxToStr = ReactDOMServer.renderToString;

class PrintableGanttChart extends CuandoGanttChart {
  setUpColumns() {
    super.setUpColumns();
    const getIcon = ({ level }: CuandoNode) =>
      [
        <FaGlobeAmericas style={{ color: "#0086ba" }} />,
        <Rhombus />,
        <FaTasks style={{ color: "#999900" }} />,
      ][level];
    const textColumn = {
      name: "text",
      label: " ",
      width: "160",
      template: (task: CuandoNode) => {
        const charLimit = 20;
        const multilineAttributes =
          task.text.length > charLimit ? { lineHeight: "18px" } : {};
        const getMultilineText = () => {
          if (task.text.length <= charLimit) return task.text;
          return (
            <span>
              {" "}
              {task.text.substring(0, charLimit)} <br />{" "}
              {task.text.substring(charLimit)}{" "}
            </span>
          );
        };
        return jsxToStr(
          <div
            style={{
              fontSize: "14px",
              textAlign: "left",
              color: task.readonly ? "grey" : "inherit",
              ...multilineAttributes,
            }}
          >
            {getIcon(task)} {getMultilineText()}
          </div>
        );
      },
    };
    const startDateColumn = {
      name: "start",
      label: jsxToStr(
        <div style={{ margin: "5px", lineHeight: "19px" }}>
          Inicio <br />
          Planeado
        </div>
      ),
      width: "90",
      template: (task: CuandoNode) =>
        jsxToStr(
          <div
            style={{
              fontSize: "14px",
              color: task.readonly ? "grey" : "inherit",
            }}
          >
            {briefFormat(task.start_date)}
          </div>
        ),
    };
    const endDateColumn = {
      name: "end",
      label: jsxToStr(
        <div style={{ margin: "5px", lineHeight: "19px" }}>
          Terminación <br />
          Planeada
        </div>
      ),
      width: "90",
      template: (task: CuandoNode) =>
        jsxToStr(
          <div
            style={{
              fontSize: "14px",
              color: task.readonly ? "grey" : "inherit",
            }}
          >
            {briefFormat(addDays(task.end_date, -1))}
          </div>
        ),
    };
    const durationColumn = {
      name: "duration",
      label: jsxToStr(
        <div style={{ margin: "5px", lineHeight: "19px" }}>
          Duración
          <br />
          (días lab)
        </div>
      ),
      width: "60",
      template: (task: CuandoNode) =>
        jsxToStr(
          <div
            style={{
              fontSize: "14px",
              color: task.readonly ? "grey" : "inherit",
            }}
          >
            {differenceInDays(task.end_date, task.start_date)} d
          </div>
        ),
    };

    this.myGantt.config.columns = [
      textColumn,
      startDateColumn,
      endDateColumn,
      durationColumn,
    ];
  }

  styleGantt() {
    super.styleGantt();
    const getBarClass = (
      start: Date,
      end: Date,
      { level, unset }: CuandoNode
    ) => {
      if (level === 0) return "project-bar";
      if (level === 1) return "deliv-bar";
      return "act-bar";
    };
    this.myGantt.templates.task_class = getBarClass;
    const getTaskText = (
      start: Date,
      end: Date,
      { text, level, unset }: CuandoNode
    ) => text;
    this.myGantt.templates.task_text = getTaskText;
  }

  configureGantt() {
    super.configureGantt();
    this.myGantt.config.row_height = 40;
  }
}

type Props = {
  text: string;
  project: Project;
  defaultDate: Date;
  onGanttExport: (png: any, updatedProject: Project) => Promise<void>;
};

const CuandoGanttPNGExporter = ({
  text,
  project,
  defaultDate,
  onGanttExport,
}: Props) => {
  const ganttContainerRef = React.createRef<HTMLDivElement>();
  const hiddenElementRef = React.createRef<HTMLDivElement>();
  const [loading, setLoading] = React.useState(false);

  const onClick = () => {
    setLoading(true);
    if (!hiddenElementRef.current) throw Error("hidden element is undefined");
    const hiddenElement = hiddenElementRef.current;
    const onAfterRender = () => {
      setTimeout(() => {
        if (!ganttContainerRef.current) throw Error("gantt is undefined");
        getGanttPNGScreenshot(ganttContainerRef.current)
          .then((ganttPNG) => onGanttExport(ganttPNG, project))
          .then(() => {
            ReactDOM.unmountComponentAtNode(hiddenElement);
            setLoading(false);
          });
      }, 0);
    };
    const { wbsRoot, links } = project;
    if (!wbsRoot || !links) throw Error("wbs root or links is null");
    ReactDOM.render(
      <PrintableGanttChart
        ganttContainerRef={ganttContainerRef}
        wbsRoot={wbsRoot}
        links={links}
        onTaskUpdate={() => Promise.resolve()}
        proVerifier={[() => false, false]}
        onLinkAdd={() => Promise.resolve()}
        onLinkDelete={() => Promise.resolve()}
        defaultDate={defaultDate}
        readOnly
      />,
      hiddenElement,
      onAfterRender
    );
  };

  return (
    <div>
      {loading ? (
        <Loader size={60} message="Imprimiendo..." />
      ) : (
        <Button onClick={onClick} variant="info">
          {" "}
          {text}{" "}
        </Button>
      )}
      <div
        ref={hiddenElementRef}
        style={{
          position: "absolute",
          top: "0",
          left: "-10000px",
          width: "800px",
        }}
      />
    </div>
  );
};

export default CuandoGanttPNGExporter;
