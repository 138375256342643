import React from "react";
import { isBefore, isSameDay, addDays } from "date-fns";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaExclamationTriangle } from "react-icons/fa";
import { GET_DUTIES_BY_DATE } from "../graphql-client/queries/project";
import { WbsNodeStatus, WbsNode } from "../graphql-client/codegen-types";
import {
  parseStartDate,
  formatStartDate,
} from "../project/components/gantt/utils";
import Loader from "./Loader";
import Reminder from "./Reminder";

const TaskContainer = styled.div<{ delayed?: boolean }>`
  border-radius: 5px;
  border: 1px solid grey;
  width: 40%;
  min-width: 200px;
  .container-title {
    font-size: 18px;
    background: ${(props) => (props.delayed ? "#EED202" : "#d4ebf2")};
    border-radius: 5px;
    padding: 4px;
  }
  hr {
    margin: 0;
    border: 1px solid grey;
  }
  .container-content {
    padding: 8px;
  }
`;

const TaskDashboard = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const yesterday = addDays(today, -1);
  const tomorrow = addDays(today, 1);
  const twoWeeksAgo = addDays(today, -(7 * 2));
  const afterTomorrow = addDays(today, 2);
  const from = formatStartDate(twoWeeksAgo);
  const to = formatStartDate(afterTomorrow);
  const { data, loading, error } = useQuery(GET_DUTIES_BY_DATE, {
    variables: { from, to },
  });
  if (loading && !data)
    return <Loader size={60} message="Cargando pendientes..." />;
  if (error) console.log("Unexpected error", error);
  const wbsNodes = data.asUser.getDutiesByDate;
  const getDelayedReminders = () => {
    const delayedWbsNodes = wbsNodes.filter((node: WbsNode) => {
      const endDate = node.endDate || node.plannedEnd;
      return (
        node.status !== WbsNodeStatus.Done &&
        isBefore(parseStartDate(endDate), today)
      );
    });
    if (delayedWbsNodes.length === 0) return null;
    return (
      <TaskContainer delayed>
        <div className="container-title">
          <FaExclamationTriangle /> Atrasadas
        </div>
        <div
          className="container-content"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
            maxWidth: "500px",
          }}
        >
          {delayedWbsNodes.map((wbsNode: WbsNode) => (
            <Reminder key={wbsNode.id} wbsNode={wbsNode} />
          ))}
        </div>
      </TaskContainer>
    );
  };
  const getTodayReminders = () => {
    const todayWbsNodes = wbsNodes.filter((node: WbsNode) => {
      const endDate = node.endDate || node.plannedEnd;
      return isSameDay(parseStartDate(endDate), today);
    });
    if (todayWbsNodes.length === 0)
      return <span className="text-muted"> ¡No hay entregas para hoy!</span>;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
          maxWidth: "500px",
        }}
      >
        {todayWbsNodes.map((wbsNode: WbsNode) => (
          <Reminder key={wbsNode.id} wbsNode={wbsNode} />
        ))}
      </div>
    );
  };
  const getTomorrowReminders = () => {
    const tomorrowWbsNodes = wbsNodes.filter((node: WbsNode) => {
      const endDate = node.endDate || node.plannedEnd;
      return isSameDay(parseStartDate(endDate), tomorrow);
    });
    if (tomorrowWbsNodes.length === 0)
      return <span className="text-muted"> ¡No hay entregas para mañana!</span>;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
          maxWidth: "500px",
        }}
      >
        {tomorrowWbsNodes.map((wbsNode: WbsNode) => (
          <Reminder key={wbsNode.id} wbsNode={wbsNode} />
        ))}
      </div>
    );
  };

  return (
    <div>
      <div style={{ margin: "10px 0 10px 0" }}>
        <b>Entregas cercanas</b>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <TaskContainer>
          <div className="container-title"> Hoy </div>
          <hr />
          <div className="container-content">{getTodayReminders()}</div>
        </TaskContainer>
        <TaskContainer>
          <div className="container-title"> Mañana </div>
          <hr />
          <div className="container-content">{getTomorrowReminders()}</div>
        </TaskContainer>
        {getDelayedReminders()}
      </div>
      <div style={{ marginTop: "15px" }}>
        {" "}
        <Link to="/calendar"> Ver calendario completo </Link>{" "}
      </div>
    </div>
  );
};

export default TaskDashboard;
