import React from "react";
import { FaWhatsappSquare } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";

const StyledDiv = styled.div`
  background: white;
  border-radius: 5px;
  z-index: 100000;
  position: fixed;
  right: 5px;
  bottom: 7px;
`;

const StyledLink = styled.a`
  color: green;
  :hover {
    color: green;
    opacity: 0.8;
  }
`;

const StyledIcon = styled(FaWhatsappSquare)`
  width: 60px;
  height: 60px;
`;

const WhatsappButton = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 600px)" });
  return (
    <Tooltip
      arrow
      placement="left"
      title="¿Dudas? ¿Comentarios? ¿Quejas? ¡Mándanos un Whatsapp!"
    >
      <StyledDiv id="whatsapp-global-button">
        <StyledLink
          href="https://wa.me/5218118014013"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledIcon />
        </StyledLink>
      </StyledDiv>
    </Tooltip>
  );
};

export default WhatsappButton;
