import {
  Document,
  Paragraph,
  Table,
  AlignmentType,
  convertInchesToTwip,
  LevelFormat,
} from "docx";
import docxHeader from "./docx-header";
import docxFooter from "./docx-footer";
import docxStyles from "./docx-styles";
import type { Project } from "../../../graphql-client/codegen-types";

const getDocxTemplatePromise = (
  project: Project,
  content: Array<Table | Paragraph>
) => {
  const logoBlobPromise = fetch("/coordinate-logo.png").then((response) =>
    response.blob()
  );
  return logoBlobPromise.then(
    (logoBlob) =>
      new Document({
        numbering: {
          config: [
            {
              reference: "default",
              levels: [
                {
                  level: 0,
                  text: "%1",
                  alignment: AlignmentType.START,
                  format: LevelFormat.DECIMAL,
                  style: {
                    paragraph: {
                      indent: {
                        left: convertInchesToTwip(0.2),
                        hanging: convertInchesToTwip(0.18),
                      },
                    },
                  },
                },
                {
                  level: 1,
                  format: LevelFormat.DECIMAL,
                  text: "%1.%2",
                  alignment: AlignmentType.START,
                  style: {
                    paragraph: {
                      indent: {
                        left: convertInchesToTwip(0.4),
                        hanging: convertInchesToTwip(0.28),
                      },
                    },
                  },
                },
              ],
            },
          ],
        },
        styles: docxStyles,
        sections: [
          {
            properties: {},
            headers: {
              default: docxHeader(project, logoBlob),
            },
            footers: {
              default: docxFooter,
            },
            children: content,
          },
        ],
      })
  );
};

export default getDocxTemplatePromise;
