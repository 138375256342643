import React from "react";

const TermsAndConditions = () => (
  <div
    className="container texto"
    style={{
      background: "white",
      margin: "20px",
      opacity: 0.75,
      borderRadius: "8px",
    }}
  >
    <br />
    <h2>Políticas de privacidad</h2>
    <br />
    <p>
      En cumplimiento a la Ley Federal de Protección de Datos Personales en
      Posesión de los Particulares (LFPDPPP), Coordinación efectiva de proyectos
      SAS de CV (CEPSASCV) como responsable del tratamiento de sus datos
      personales mediante su departamento/encargado de protección de datos, con
      domicilio en Av. Eugenio Garza Sada 427 Local 37. Monterrey, N.L., México
      C.P. 64849, es responsable de recabar sus datos personales, del uso que se
      le dé a los mismos y de su protección.
    </p>
    <br />
    <h4>Datos personales y forma de recabarse</h4>
    CEPSASCV recabará de usted los datos personales que sean necesarios para
    mantener un canal de comunicación adecuado y en su caso la adecuada
    prestación de nuestros servicios. Dichos datos personales serán solicitados
    en la hoja de registro del servicio de Coordinate y pueden comprender, entre
    otros, los siguientes:
    <ol style={{ margin: "10px 0px 10px 30px" }}>
      <li>
        Su nombre, fecha de nacimiento, ciudad y país donde radica, cuenta de
        correo electrónico, compañía, puesto, Registro Federal de
        Contribuyentes, género, edad, profesión u oficio y estado civil
      </li>
    </ol>
    Se hace de su conocimiento que ciertos datos personales que serán recabados
    pueden ser considerados como Datos Personales Sensibles conforme a la Ley de
    Protección de Datos Personales en Posesión de Particulares.
    <br />
    <br />
    <h3>Finalidades de los datos personales</h3>
    <br />
    El Tratamiento de datos personales del titular, incluyendo los datos
    personales sensibles que en su caso lleguemos a recabar, y salvo que el
    titular disponga lo contrario mediante el medio descrito en el presente
    Aviso de Privacidad, tiene como finalidad, el desarrollo de nuevos productos
    y servicios, comercialización, contratación y colocación de todo tipo de
    servicios y productos en Materia de desarrollo de soluciones globales para
    las nuevas tecnologías contratados para usted o la empresa que representa y
    otras obligaciones derivadas de cualquier relación jurídica entre el titular
    de datos personales y &quot;CEPSASCV&quot;. Sus datos personales, también
    podrán tener, entre otras, las siguientes finalidades:
    <ol style={{ margin: "10px 0px 10px 30px" }}>
      <li>Atender requerimientos legales de autoridades competentes.</li>

      <li>
        {" "}
        Hacer de su conocimiento, la gama de nuevos productos y servicios en
        materia de tecnología, así como notificaciones sobre cambios en las
        condiciones de un contrato y en general toda la información derivada de
        los servicios que ofrece CEPSASCV a través de su página en internet.
      </li>

      <li>
        {" "}
        Para el cumplimiento de nuestros términos y condiciones en la prestación
        de nuestros servicios. Los datos sensibles que se pudieran recabar,
        podrán ser utilizados para la identificación de riesgo en la
        contratación y diseño de productos.
      </li>

      <li>
        {" "}
        Incorporación y actualización de nuestras Bases de Datos, de sus datos
        personales como cliente previo, entre los que se incluirá su historial
        de compras, con fines meramente estadísticos y de mejoramiento en el
        servicio.
      </li>

      <li> Evaluación como cliente potencial.</li>

      <li>
        {" "}
        En su caso la elaboración y celebración de contratos de compraventa,
        consignación, distribución, suministro o maquila, servicio, entre otros,
        según sea el caso de lo que requiera el cliente.
      </li>

      <li>
        {" "}
        El cumplimiento de obligaciones que deriven de la relación jurídica que
        se establezca con el cliente.
      </li>

      <li>
        {" "}
        Proveer los servicios y productos requeridos por el cliente de forma
        oportuna y correcta.
      </li>

      <li>
        {" "}
        Informar sobre cambios o nuevos productos o servicios que estén
        relacionados con el contratado o adquirido por el cliente.
      </li>

      <li> Evaluar la calidad de los productos y servicios.</li>

      <li>
        {" "}
        Realizar estudios internos sobre hábitos de consumo y comportamientos
        crediticios.
      </li>
    </ol>
    Al proporcionar sus datos personales, consiente su tratamiento tanto dentro
    como fuera de los Estados Unidos Mexicanos y entiende que podrán ser
    tratados directa o indirectamente por CEPSASCV o en su caso autoridades
    competentes.
    <br />
    <br />
    <h4>Transferencia de los datos personales</h4>
    <br />
    <p>
      Mediante el presente aviso de privacidad Usted acepta expresamente que
      “CEPSASCV” podrá transmitir sus datos personales para los fines previstos
      en el presente Aviso de Privacidad o para los fines que en su caso le sean
      comunicados.
    </p>
    <p>
      CEPSASCV no transmitirá sus datos personales sensibles a ningún tercero
      salvo para los efectos de lo dispuesto en el inciso (i) del segundo
      párrafo de la presente sección y únicamente según sea necesario para
      dichos fines.{" "}
    </p>
    <p>
      SOLICITUD DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN DE DATOS
      PERSONALES Y REVOCACIÓN DEL CONSENTIMIENTO. DERECHOS (A.R.C.O.).{" "}
    </p>
    <p>
      Conforme a la Ley Federal de Protección de Datos Personales en Posesión de
      los Particulares (LFPDPPP) y siempre y cuando que no exista disposición
      legal que indique lo contrario, usted tiene en todo momento el derecho de
      acceder, rectificar, cancelar u oponerse al tratamiento que le damos a sus
      datos personales así como revocar el consentimiento otorgado para el
      tratamiento de los mismos; lo que puede hacer mediante una solicitud por
      correo electrónico a la cuenta del contacto localizada en
      www.coordinate.si.
    </p>
    <p>
      El plazo máximo de respuesta sobre la procedencia o improcedencia de dicha
      petición será de 20 días hábiles a partir de la recepción de la solicitud.{" "}
    </p>
    <p>
      CEPSASCV se reserva el derecho de enmendar o modificar el presente Aviso
      de Privacidad como estime conveniente; por ejemplo, para cumplir con
      cambios a la legislación sobre protección de datos y/o cumplir con
      políticas internas de la empresa. La compañía le informará y pondrá a su
      disposición el Aviso de Privacidad actualizado cuando se le hagan cambios
      significativos al mismo.
    </p>
    <p>
      De conformidad con lo previsto en los artículos 8, 9, 13 y 36 de la Ley
      para la Protección de Datos Personales en Posesión de Particulares por
      medio de la presente:
    </p>
    <ol style={{ margin: "10px 0px 10px 30px" }}>
      <li>
        {" "}
        Manifiesto que he leído y entiendo los alcances del Aviso de Privacidad
        de CEPSASCV.
      </li>

      <li>
        {" "}
        Autorizo de forma expresa a CEPSASCV a recabar y tratar mis datos
        personales para la finalidad descrita en el presente Aviso de Privacidad
        y las modificaciones al mismo, y las disposiciones legales aplicables.
      </li>

      <li>
        {" "}
        Autorizo expresamente a CEPSASCV para transmitir mis datos personales de
        conformidad con y a las personas previstas en el presente Aviso de
        Privacidad y las modificaciones al mismo, y las disposiciones legales
        aplicables.
      </li>
    </ol>
    <br />
    <h2>Términos y condiciones</h2>
    <br />
    <h4>
      Al visitar este Sitio, acepta cumplir los términos y condiciones
      comprendidos a continuación, así como cualquier otra ley o reglamento
      aplicable al Sitio ó a Internet:
    </h4>
    <ol style={{ margin: "10px 0px 10px 30px" }}>
      <li>
        {" "}
        Se ha diseñado este Sitio para su información, educación y comunicación.
        Podrá examinarlo libremente. Podrá también transferir a su computadora
        los archivos con vínculos de descarga para uso personal y no comercial,
        siempre respetando los derechos de propiedad intelectual que López
        Alatorre y Asociados S.A. de C.V. (de ahora en adelante (CEPSASCV) tiene
        sobre dichos archivos y cubriendo las cuotas que pudieran cargarse
        dependiendo del nivel de servicio que hubiese contratado).
      </li>

      <li>
        Queda prohibido distribuir, modificar, transmitir, reenviar o utilizar
        el contenido del Sitio con fines comerciales o públicos, incluyendo el
        texto, las imágenes, audio y/o video allí contenidos, sin la previa y
        expresa autorización de CEPSASCV.
      </li>

      <li>
        El acceso y uso del Sitio están sujetos al cumplimiento de los términos
        y condiciones aquí señalados y a cualquier otra legislación aplicable.
        Al acceder y examinar el Sitio, estará aceptando, sin limitación y/o
        excepción alguna, estos términos y condiciones y reconociendo que
        cualquier otro acuerdo anterior que para el uso del Sitio pudiera tener
        con CEPSASCV queda anulado y sin fuerza legal alguna.
      </li>

      <li>
        {" "}
        El presente sistema consta de una aplicación diseñada para ser utilizada
        via internet, desarrollada en lenguaje de programación php y java script
        con la capacidad de funcionar en cualquier navegador disponible en
        Internet al mes de julio del año 2012, más allá de que por la naturaleza
        de éstos, la aplicación pueda presentar ciertas modificaciones gráficas.
      </li>

      <li>
        {" "}
        La función del sistema es la de permitir al usuario programar, controlar
        y cerrar sus proyectos personales, profesionales o de otra índole. Los
        reportes que se generan no deben ser considerados de ninguna manera
        oficiales ante ninguna autoridad, sino que resultan de una naturaleza
        administrativa e informativa sin carácter oficial y/o regulatorio.
      </li>

      <li>
        {" "}
        El precios está sujeto a cambios por parte de CEPSASCV sin previo aviso.
        Los cobros se realizarán mediante cargo electrónico a la tarjeta de
        crédito que registre para tal efecto. Dichos cargos se harán al momento
        de contratar o renovar la licencia PREMIUM por medio del sistema PayPal.
        Una vez realizado el pago, usted no podrá cancelar el servicio. Los
        datos registrados en la base de datos serán almacenados permanentemente
        en caso de que quiera volver a usar el servicio.
      </li>

      <li>
        {" "}
        CEPSASCV se responsabiliza en guardar la información del usuario con
        licencia PREMIUM por un período de hasta 1 año. A partir de ese momento
        la información será borrada de la base de datos. Es responsabilidad de
        Usted como usuario del sistema descargar la información y almacenarla.
        CEPSASCV no se responsabiliza en guardar la información de usuarios con
        licencia libre.
      </li>

      <li>
        {" "}
        CEPSASCV se reserva el derecho a negar el servicio a cualquiera que sea
        sorprendido haciendo mal uso del sistema o violando los términos y
        condiciones aquí establecidos.
      </li>

      <li>
        {" "}
        Queda prohibido descargar contenido del código de programación del
        sistema.
      </li>

      <li>
        {" "}
        Cada elemento que vea o lea en el Sitio está protegida como derecho de
        propiedad intelectual -salvo que esté expresamente establecido lo
        contrario- por lo que no podrá utilizarla en forma diferente a lo
        señalado en estos términos y condiciones o en el resto del Sitio, sin el
        previo y expreso permiso de CEPSASCV . CEPSASCV no garantiza ni promete
        de ningún modo que el uso de la información exhibida en el Sitio no
        violará derechos de terceras partes que no sean controladas o
        controlantes de CEPSASCV.
      </li>

      <li>
        {" "}
        Aunque CEPSASCV ha efectuado y efectuará esfuerzos razonables para
        incluir información precisa y actualizada en el Sitio, de ningún modo
        garantiza o promete que dicha información es absolutamente veraz.
        CEPSASCV no será responsable por ningún error u omisión contenidos en el
        Sitio.
      </li>

      <li>
        {" "}
        El uso o examen del Sitio que pudiera realizar es a su exclusivo riesgo.
        Ni CEPSASCV, ni sus trabajadores o socios, ni cualquier otra parte
        involucrada en la creación, producción o desarrollo del Sitio será
        responsable por ninguna clase de daño y/o perjuicio -directo o indirecto
        o de cualquier otra clase- que hubieren sido ocasionados como
        consecuencia del acceso, uso o examen del Sitio. Sin perjuicio de lo
        expresado anteriormente, todo lo incluido en el Sitio, está disponible
        &quot;en la condición en la que se encuentra&quot; sin garantías de
        ninguna especie expresas o implícitas. Lo estipulado en el presente
        Contrato constituye la distribución de los riesgos derivados de este
        Contrato entre CEPSASCV y Usted. La fijación de precios de CEPSASCV
        refleja dicha distribución de riesgo y la limitación de responsabilidad
        especificada.
      </li>

      <li>
        {" "}
        CEPSASCV tampoco será responsable por ningún daño causado a su
        computadora o cualquier otro equipamiento o por cualquier virus que
        pudiera infectarlos como consecuencia del acceso, uso o examen del Sitio
        o como consecuencia de la transferencia que pudiera realizar a su
        computadora de cualquier archivo, dato, texto, imagen, video y/o audio
        contenidos en el Sitio.
      </li>

      <li>
        {" "}
        Cualquier comunicación o documento que transmita al Sitio por correo
        electrónico o por cualquier otro medio, incluyendo datos, preguntas,
        comentarios, sugerencias o similares, serán considerados de carácter
        público y no confidencial. Cualquier cosa que transmita o envíe se
        convertirá en propiedad de CEPSASCV y podrá ser utilizada con cualquier
        finalidad, incluyendo entre otros, su reproducción, difusión,
        transmisión, publicación y/o envío. Más aún, CEPSASCV podrá utilizar
        cualquier idea, concepto, conocimiento o técnica contenida en cualquier
        comunicación que envíe al Sitio con cualquier finalidad incluyendo,
        entre otros, el desarrollo, producción y marketing de productos.
      </li>

      <li>
        {" "}
        Cada vez que utilice el Sitio, podrá estar dando y/o CEPSASCV estar
        obteniendo información acerca de su persona y/o del uso que ha dado al
        Sitio. CEPSASCV podrá utilizar esta información para cualquier propósito
        que estime apropiado incluyendo, entre otros, páginas &quot;hechas de
        medida&quot; o cualquier otra actividad de marketing.
      </li>

      <li>
        {" "}
        Las imágenes de gente y/o lugares exhibidas en el Sitio, son propiedad
        de CEPSASCV o utilizadas por ésta con la autorización de sus dueños. El
        uso de dichas imágenes está prohibido, excepto que el mismo hubiera sido
        expresamente permitido en estos términos y condiciones o en alguna otra
        parte del Sitio. Cualquier uso no autorizado de esas imágenes puede
        resultar violatorio de las leyes de propiedad intelectual, marcas y/o
        comunicaciones.
      </li>

      <li>
        {" "}
        Nada de lo establecido y/o contenido en el Sitio podrá ser interpretado
        como equivalente al otorgamiento, expreso o implícito, de una licencia o
        cualquier otro derecho de uso sobre alguna de las Marcas exhibidas en el
        Sitio sin el expreso consentimiento de CEPSASCV. El uso de las Marcas
        exhibidas en el Sitio, está estrictamente prohibido excepto que dicho
        uso se efectúe conforme lo establecido en estos términos y condiciones.
        CEPSASCV hará respetar sus derechos de propiedad intelectual con el
        máximo rigor permitido por la ley, incluyendo el inicio de acciones
        penales.
      </li>

      <li>
        {" "}
        CEPSASCV no ha revisado ninguno de los sitios vinculados al Sitio por lo
        que no será responsable por el contenido de &quot;páginas&quot;
        contenidas fuera del Sitio o por cualquier otra liga vinculada al Sitio.
        Su vinculación al Sitio, páginas fuera del Sitio o cualquier otro sitio
        es a su exclusivo riesgo y sin la intervención de CEPSASCV.
      </li>

      <li>
        {" "}
        Aunque CEPSASCV podrá monitorear o revisar las discusiones, charlas
        (chats), envíos, transmisiones, boletines y similares que se efectúen en
        el Sitio, queda claramente entendido que no asume ninguna obligación de
        hacerlo y, por lo tanto, no será responsable por el contenido de dichos
        mensajes ni por ningún error, difamación, libelo, calumnia, omisión,
        falsedad, obscenidad, pornografía, blasfemia, irreverencia, peligro o
        inexactitud, allí contenidos.
      </li>

      <li>
        {" "}
        Está prohibido el envío o transmisión de material ilegal, amenazador,
        difamatorio, obsceno, escandaloso, provocativo, pornográfico, profano
        y/o de cualquier otra clase de material que pueda constituir o instigar
        la comisión de conductas que pudieran ser consideradas delito, civil o
        penal, o que pudieran violar cualquier ley. CEPSASCV cooperará con
        cualquier autoridad que le solicite su colaboración para identificar a
        cualquiera que hubiere enviado información o material de dichas
        características.
      </li>

      <li>
        {" "}
        CEPSASCV podrá en cualquier momento revisar estos términos y condiciones
        actualizando este Sitio. El usuario está obligado a cumplir con
        cualquiera de esas modificaciones y, por lo tanto, deberá visitar
        periódicamente el Sitio para revisar los términos y condiciones
        actualizados que deberá cumplir.
      </li>

      <li>
        {" "}
        CEPSASCV respeta la privacidad de toda persona que visita el sitio en la
        Web. Esta Política de Privacidad indica la información que CEPSASCV
        puede recopilar y el uso que puede dar a esa información.
      </li>

      <li>
        {" "}
        En algunos casos, se puede recopilar información técnica en forma
        automática (es decir, sin que medie el registro voluntario). Esta
        información incluye, por ejemplo, el tipo de explorador para Internet
        que usted utiliza, el sistema operativo de su computadora y el nombre de
        dominio del sitio de la Web desde el cual se vinculó con el Sitio.
      </li>

      <li>
        {" "}
        Cuando usted ingresa en nuestro Sitio de la Web, se puede almacenar
        alguna información en su computadora, bajo la forma de una
        &quot;Cookie&quot; o archivo similar. Con la mayoría de los exploradores
        para Internet, usted puede borrar las Cookies del disco duro de su
        computadora, bloquear todas las Cookies o recibir un mensaje de alerta
        antes de que se almacene una Cookie. Remitirse a las instrucciones de su
        explorador o a la pantalla de ayuda para conocer más sobre estas
        funciones.
      </li>

      <li>
        {" "}
        Sujeto a los términos y condiciones contenidas en este Contrato,
        CEPSASCV le otorga una licencia personal, limitada, no transferible, no
        exclusiva (sin derecho a sublicenciar) para usar el las porciones del
        Sistema por las cuales hubiese pagado los cargos correspondientes, para
        el cual se han emitido códigos válidos de control por parte de CEPSASCV.
        Cualquier actualización, parche, paquete de solución, paquete de
        servicio, paquete de valor añadido, reparación, solución, módulo
        pre-empacado y/o versión nueva, versión, o mejora emitida por CEPSASCV
        relacionada con el Sistema (cada una “Actualización”) reemplaza parte o
        todo el Sistema o Actualización previamente autorizado a Usted y dicho
        Sistema o Actualización previamente autorizada cesará hasta el punto que
        sea reemplazado por la Actualización. Cada Actualización estará sujeta a
        los términos y condiciones del acuerdo de licencia aquí contenidos.
      </li>

      <li>
        {" "}
        En virtud de este Contrato, Usted adquiere solamente el derecho no
        exclusivo para usar el Sistema o parte de él, dependiendo de las cuotas
        que hubiese pagado y no adquiere derecho alguno de propiedad de (i) el
        Sistema, (ii) cualquier Documentación proporcionada con el mismo, o
        (iii) los medios, en su caso, en los cuales el Sistema y la
        Documentación son presentados. CEPSASCV y/o sus licenciantes retendrán
        en todo momento todos los derechos, títulos e intereses en el Sistema.
        Excepto por los derechos de licencia expresamente otorgados en el
        presente, este Contrato no otorga licencia adicional, derechos o
        intereses expresos o implícitos en el Sistema o en cualquier derecho de
        autor, patente, secreto profesional, marca registrada, u otros derechos
        de propiedad intelectual o de invención de CEPSASCV. CEPSASCV se reserva
        todos los derechos no otorgados expresamente a Usted por virtud de este
        Contrato.
      </li>

      <li>
        {" "}
        Usted se obliga a no copiar, desensamblar, realizar ingeniería inversa,
        descompilar, modificar o crear trabajos derivados del Sistema.
      </li>

      <li>
        {" "}
        Usted no tendrá derecho a usar el Sistema para proporcionar servicios de
        contratación de terceros (outsourcing), actuar como anfitrión (hosting),
        tiempos compartidos (timesharing) o suministro de servicios de
        aplicación (application service provider (ASP))
      </li>

      <li>
        {" "}
        Usted será el único responsable de identificar y cumplir con todas las
        leyes de cualquier jurisdicción dentro y fuera de los Estados Unidos
        Mexicanos con relación al uso del Sistema y cualquier dato técnico
        proporcionado por CEPSASCV. Usted acuerda obtener todas las licencias,
        permisos o aprobaciones requeridas por cualquier gobierno a su costo y
        gasto exclusivo.
      </li>

      <li>
        {" "}
        Este Contrato es personal para Usted y no puede transferir, asignar o de
        lo contrario, transmitir o ceder este Contrato o el Sistema, en todo o
        en parte, por operación legal, fusión o cualquier otro medio, a
        cualquier tercero, incluyendo cualquier casa matriz, subsidiaria o
        entidad afiliada.
      </li>

      <li>
        {" "}
        CEPSASCV no garantiza que las funciones del Sistema cumplirán con los
        requerimientos de Usted o que la operación del Sistema será
        ininterrumpida o libre de errores. Usted asume responsabilidad por
        seleccionar el Sistema para alcanzar los resultados pretendidos y por el
        uso y los resultados obtenidos del Sistema.
      </li>

      <li>
        {" "}
        CEPSASCV puede terminar este Contrato en cualquier momento si Usted
        incumple con cualquier provisión de este Contrato y falla en corregir
        dicho incumplimiento a satisfacción de CEPSASCV. Este remedio no será
        exclusivo y deberá ser en adición a cualquier otro remedio que CEPSASCV
        pueda tener bajo este Contrato o fuera de éste.
      </li>

      <li>
        {" "}
        El presente Contrato se regirá e interpretará por las leyes aplicables
        en Monterrey, Nuevo León, de los Estados Unidos Mexicanos
        exclusivamente.
      </li>

      <li>
        {" "}
        En el caso de existir alguna controversia sobre la interpretación,
        ejecución, o cumplimiento del presente Contrato las partes acuerdan
        someterse a la jurisdicción y competencia de los tribunales de la Ciudad
        de Monterrey, Nuevo León, México, renunciando a cualquier otra
        jurisdicción que por su domicilio presente o futuro pudiera
        corresponderles.
      </li>

      <li>
        {" "}
        En el supuesto que cualquier cláusula del presente Contrato fuera
        considerada inválida o no exigible, las cláusulas restantes del mismo
        continuarán en vigor y produciendo todos sus efectos.
      </li>

      <li> Este Contrato entrará en vigencia el 1 de ENERO del año 2013.</li>
    </ol>
    <br />
  </div>
);
export default TermsAndConditions;
