import React from "react";
import { gantt } from "dhtmlx-gantt";
import { max, addDays, isBefore } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { FaRegCircle, FaRegCheckCircle } from "react-icons/fa";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import type { GanttStatic } from "dhtmlx-gantt";
import html2canvas from "html2canvas";
import type { Link, WbsNode } from "../../../graphql-client/codegen-types";
import { WbsNodeStatus } from "../../../graphql-client/codegen-types";

export const getEarliestStart = (nodeId: string, myGantt: GanttStatic) => {
  const isStartConstraint = ({ target, type }: Link) =>
    target === nodeId &&
    (type === gantt.config.links.finish_to_start ||
      type === gantt.config.links.start_to_start);
  const startConstrainingLinks = myGantt.getLinks().filter(isStartConstraint);
  if (startConstrainingLinks.length === 0) return null;
  const startConstrainingTasks = startConstrainingLinks.map(({ source }) =>
    myGantt.getTask(source)
  );
  return max(startConstrainingTasks.map(({ end_date }) => end_date));
};

export const differenceInWorkDays = (
  workDays: Array<boolean>,
  start?: Date,
  end?: Date
) => {
  if (!start || !end) return 0;
  const days = workDays?.map((active) => (active ? 1 : 0));
  gantt.config.work_time = true;
  gantt.config.duration_unit = "day";
  workDays.forEach((active: boolean, idx: number) =>
    gantt.setWorkTime({ day: (idx + 1) % 7, hours: active })
  );
  if (isBefore(end, start))
    return -gantt.calculateDuration({ start_date: end, end_date: start });
  return gantt.calculateDuration({ start_date: start, end_date: end });
};

export const addWorkDays = (
  workDays: Array<boolean>,
  start: Date,
  duration: number
) => {
  const days = workDays?.map((active) => (active ? 1 : 0));
  gantt.config.work_time = true;
  gantt.config.duration_unit = "day";
  workDays.forEach((active: boolean, idx: number) =>
    gantt.setWorkTime({ day: (idx + 1) % 7, hours: active })
  );
  return gantt.calculateEndDate({ start_date: start, duration });
};

export const statusIcons = {
  pending: <FaRegCircle style={{ width: "20px", height: "20px" }} />,
  inProgress: (
    <HiOutlineDotsCircleHorizontal
      style={{ width: "25px", height: "25px", color: "orange" }}
    />
  ),
  done: (
    <FaRegCheckCircle
      style={{ width: "20px", height: "20px", color: "limegreen" }}
    />
  ),
};

export const getSupposedStatus = (
  { start_date, end_date }: { start_date: Date; end_date: Date },
  statusDate: Date
) => {
  if (isBefore(statusDate, start_date)) return WbsNodeStatus.Pending;
  const supposedEndDate = addDays(statusDate, 1);
  if (isBefore(supposedEndDate, end_date)) return WbsNodeStatus.InProgress;
  return WbsNodeStatus.Done;
};

export const parseStartDate = (dateStr: string) => {
  const date = utcToZonedTime(dateStr, "UTC-0");
  date.setHours(0, 0, 0);
  return date;
};
export const parseEndDate = (dateStr: string) => {
  const date = addDays(utcToZonedTime(dateStr, "UTC-0"), 1);
  date.setHours(0, 0, 0);
  return date;
};

export const parseMaybeStartDate = (dateStr: string | null) =>
  dateStr ? parseStartDate(dateStr) : null;
export const parseMaybeEndDate = (dateStr: string | null) =>
  dateStr ? parseEndDate(dateStr) : null;

export const formatStartDate = (date: Date) => date.toISOString();
export const formatEndDate = (date: Date) => addDays(date, -1).toISOString();

export const getBasicGanttNodeAttributes = (
  { id, text }: WbsNode,
  parent: string,
  level: number
) => {
  const nodeType =
    level === 2 ? gantt.config.types.task : gantt.config.types.project;
  return {
    id,
    text: text ?? "",
    parent,
    open: true,
    type: nodeType,
    readonly: level < 2,
    editable: level === 2,
    level,
  };
};

export const getGanttPNGScreenshot = (ganttElement: HTMLDivElement) => {
  const area = ganttElement.getBoundingClientRect();
  return html2canvas(ganttElement, {
    scrollX: 0,
    scrollY: -window.scrollY,
    height: area.height,
    width: area.width,
  }).then((canvas) => canvas.toDataURL("image/png"));
};
