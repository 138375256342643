import React from "react";
import { isSameDay, addDays } from "date-fns";
import ReactDOMServer from "react-dom/server";
import { FaTasks } from "react-icons/fa";
import { Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import type { WbsNode, Link } from "../../../graphql-client/codegen-types";
import type { CuandoNodeExtraAttributes, CuandoNode } from "./types";
import {
  parseStartDate,
  parseEndDate,
  formatStartDate,
  formatEndDate,
  getBasicGanttNodeAttributes,
  getEarliestStart,
} from "../../components/gantt/utils";
import InteractiveGanttChart from "../../components/gantt/InteractiveGanttChart";
import LinkEditor from "./LinkEditor";
import { ActLabel } from "../../../components/utils";
import Pregunta from "../../../components/Pregunta";
import GanttDateEditor from "../../components/gantt/GanttDateEditor";
import GanttNodeDescription from "../../components/gantt/GanttNodeDescription";

type CuandoGanttExtraProps = {
  defaultDate: Date;
};

const CuandoGanttStyleWrapper = styled.div`
  .unset-bar {
    background: white;
    border: 1px solid grey;
    cursor: pointer;
    color: black;
    :hover {
      opacity: 0.8;
    }
  }
`;

// Transforma JSX (React Components) en strings de html.
// Esto es útil porque dhtmlx usa html crudo para renderear.
// Documentación: https://reactjs.org/docs/react-dom-server.html
const jsxToStr = ReactDOMServer.renderToString;

class CuandoGanttChart extends InteractiveGanttChart<
  CuandoGanttExtraProps,
  CuandoNodeExtraAttributes
> {
  getProjectDates() {
    const { wbsRoot } = this.props;
    const ganttTasks = this.myGantt.getTaskByTime();
    // Si el gantt no esta inicializado aun (ganttTasks.length === 0), utiliza la lista de
    // tasks inicial. De otro modo, usa las del gantt inicializado.
    const tasks =
      ganttTasks.length === 0 ? this.toGanttNodeArray(wbsRoot) : ganttTasks;
    const projectNode = tasks.find(({ level }: CuandoNode) => level === 0);
    if (!projectNode) throw Error("Project node not found");
    const { start_date, end_date } = projectNode;
    return [start_date, end_date];
  }

  // El tooltip que se se enseña cuando una task en el gantt chart es hovereada.
  // Fuente relevante: https://docs.dhtmlx.com/gantt/desktop__tooltips.html
  getStyledTooltip(start: Date, end: Date, task: CuandoNode): string {
    if (task.unset) {
      if (task.level < 2) return "";
      return jsxToStr(
        <div style={{ textAlign: "center" }}>
          <b>
            ¿Cuándo se realizará <br />
            esta <ActLabel />? <br />
          </b>
          Click para definir
        </div>
      );
    }
    return super.getStyledTooltip(start, end, task);
  }

  toWbsNode({ id, text, start_date, end_date }: CuandoNode) {
    return {
      id,
      text,
      plannedStart: formatStartDate(start_date),
      plannedEnd: formatEndDate(end_date),
    };
  }

  toGanttNode(node: WbsNode, parent: string, level: number) {
    const { defaultDate } = this.props;
    const { plannedStart, plannedEnd, duties } = node;
    return {
      ...getBasicGanttNodeAttributes(node, parent, level),
      start_date: plannedStart ? parseStartDate(plannedStart) : defaultDate,
      end_date: plannedEnd ? parseEndDate(plannedEnd) : addDays(defaultDate, 1),
      unset: plannedStart === null || plannedEnd === null,
      duties,
    };
  }

  configureGantt() {
    super.configureGantt();
    this.myGantt.attachEvent(
      "onBeforeTaskUpdate",
      (id: number, task: CuandoNode) => {
        if (task.unset) task.unset = false;
      },
      {}
    );
    this.myGantt.attachEvent(
      "onAfterTaskUpdate",
      (id: number, task: CuandoNode) => {
        if (task.parent !== "0") {
          const parentNode = this.myGantt.getTask(task.parent);
          this.myGantt.updateTask(task.parent);
          const { updateTaskInCache } = this.props;
          updateTaskInCache?.(this.toWbsNode(parentNode));
        }
      },
      {}
    );

    this.myGantt.attachEvent(
      "onAfterLinkAdd",
      (_, link: Link) => {
        const source = this.myGantt.getTask(link.source);
        const target = this.myGantt.getTask(link.target);
        if (source.unset) this.myGantt.updateTask(source.id);
        if (target.unset) this.myGantt.updateTask(target.id);
      },
      {}
    );
  }

  styleGantt() {
    super.styleGantt();
    const getBarClass = ({ level, unset }: CuandoNode) => {
      if (level === 0) return "project-bar";
      if (level === 1) return "deliv-bar";
      if (unset) return "unset-bar";
      return "act-bar";
    };
    const maybeAddCritical = (node: CuandoNode, currentClass: string) => {
      const { showCriticalPath } = this.state;
      if (showCriticalPath && this.myGantt.isCriticalTask(node))
        return `critical-bar ${currentClass}`;
      return currentClass;
    };
    this.myGantt.templates.task_class = (
      start: Date,
      end: Date,
      node: CuandoNode
    ) => maybeAddCritical(node, getBarClass(node));
    const getTaskText = (
      start: Date,
      end: Date,
      { text, level, unset }: CuandoNode
    ) => {
      if (level === 2 && unset)
        return jsxToStr(
          <b>
            <FaTasks style={{ color: "#999900" }} /> ¿Cuándo?
          </b>
        );
      return text;
    };
    this.myGantt.templates.task_text = getTaskText;
  }

  render() {
    return <CuandoGanttStyleWrapper>{super.render()}</CuandoGanttStyleWrapper>;
  }

  renderActEditor(task: CuandoNode) {
    const { id, unset, start_date, end_date } = task;
    const { readOnly, workDays } = this.props;
    if (readOnly) {
      return <GanttNodeDescription node={task} />;
    }
    const startDate = unset ? null : start_date;
    const endDate = unset ? null : addDays(end_date, -1);
    const save = (start: Date, end: Date) => {
      if (
        unset ||
        !isSameDay(start, task.start_date) ||
        !isSameDay(addDays(end, 1), task.end_date)
      ) {
        task.start_date = start;
        task.end_date = addDays(end, 1);
        this.myGantt.autoSchedule(id);
        this.myGantt.updateTask(id);
      }
    };
    const earliestStartDate = getEarliestStart(id, this.myGantt);
    const pregunta = (
      <span>
        ¿Cuándo planeas iniciar esta <ActLabel />? <br />
        ¿Cuándo planeas terminarla?
      </span>
    );
    const descripcion = "Si no sabes con certeza, da un estimado.";
    return (
      <Tabs defaultActiveKey="dependencias" id="uncontrolled-tab-example">
        <Tab eventKey="dependencias" title="1. Dependencias">
          <div>
            <LinkEditor node={task} gantt={this.myGantt} />
          </div>
        </Tab>
        <Tab eventKey="fechas" title="2. Fechas">
          <div style={{ margin: "20px" }}>
            <Pregunta pregunta={pregunta} descripcion={descripcion}>
              <GanttDateEditor
                startDate={startDate}
                endDate={endDate}
                earliestStartDate={earliestStartDate}
                save={save}
                workDays={workDays}
              />
            </Pregunta>
          </div>
        </Tab>
      </Tabs>
    );
  }
}

export default CuandoGanttChart;
