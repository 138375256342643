import React from "react";
import { FaHandPaper } from "react-icons/fa";
import { Alert } from "react-bootstrap";
import { Reference, FetchResult } from "@apollo/client";
import {
  ADD_RESTRICTION,
  EDIT_RESTRICTION,
  DELETE_RESTRICTION,
  REORDER_RESTRICTIONS,
} from "../../graphql-client/queries/project";
import type {
  Scalars,
  Restriction,
  AddRestrictionMutation,
  Project,
} from "../../graphql-client/codegen-types";
import { useProjectMutation } from "./project-context";
import DynamicList from "./DynamicList";
import Explanation from "../../components/Explanation";
import EntradaTexto from "../../components/EntradaTexto";
import client from "../../graphql-client/connection";

type ID = Scalars["ID"];

type Props = {
  project: Project;
  readOnly: boolean;
};

function addRestrictionCallback({
  data,
}: FetchResult<AddRestrictionMutation>): Restriction {
  const ret = data?.asUser?.editProject?.addRestriction?.newRestriction;
  if (!ret) throw new Error("Error creating restriction");
  return ret;
}

const Restrictions = ({ project, readOnly }: Props) => {
  const { cache } = client;
  const addRestriction = useProjectMutation<
    AddRestrictionMutation,
    { projectId: ID }
  >(ADD_RESTRICTION);
  const editRestriction = useProjectMutation(EDIT_RESTRICTION);
  const deleteRestriction = useProjectMutation(DELETE_RESTRICTION);
  const reorderRestrictions = useProjectMutation(REORDER_RESTRICTIONS);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorDetail, setErrorDetail] = React.useState("");
  return (
    <div>
      <DynamicList
        title={
          <span>
            {" "}
            <FaHandPaper /> Restricciones{" "}
          </span>
        }
        deleteInfo={
          readOnly
            ? undefined
            : {
                onDelete: ({ id }: Restriction) => {
                  cache.modify({
                    id: cache.identify(project),
                    fields: {
                      restrictions: (oldRestrictions, { readField }) =>
                        oldRestrictions.filter(
                          (restriction: Reference) =>
                            id !== readField("id", restriction)
                        ),
                    },
                  });
                  return deleteRestriction({
                    variables: { projectId: project.id, id },
                  }).then(() => true);
                },
                deleteWarnTitle: "¿Eliminar beneficio?",
                deleteWarnMsg: "No se podrá recuperar.",
              }
        }
        renderItem={(restriction: Restriction, isNew: boolean) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              background: "whitesmoke",
              borderRadius: "5px",
              padding: "5px",
            }}
          >
            <FaHandPaper style={{ margin: "15px 5px 5px 5px" }} />
            <div style={{ width: "100%" }}>
              <EntradaTexto
                ejemplo="Aún no hemos contratado al diseñador."
                maxLetras={280}
                autoFocus={isNew}
                saveText={
                  readOnly
                    ? () => {}
                    : (text: string) =>
                        editRestriction({
                          variables: {
                            projectId: project.id,
                            input: { id: restriction.id, text },
                          },
                          update() {
                            cache.modify({
                              id: cache.identify(restriction),
                              fields: {
                                text: () => text,
                              },
                            });
                          },
                        })
                }
                textoInicial={restriction.text ?? ""}
                rows={2}
                readOnly={readOnly}
              />
            </div>
          </div>
        )}
        getItemId={({ id }: Restriction) => id}
        initial={project.restrictions || []}
        newItem={
          readOnly
            ? undefined
            : () =>
                addRestriction({
                  variables: { projectId: project.id },
                })
                  .then(addRestrictionCallback)
                  .then((newRestriction: Restriction) => {
                    cache.modify({
                      id: cache.identify(project),
                      fields: {
                        restrictions: (oldRestrictions) =>
                          oldRestrictions.concat({
                            __ref: cache.identify(newRestriction),
                          }),
                      },
                    });
                    return newRestriction;
                  })
                  .catch((e) => {
                    const applicationErrorDetail =
                      e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail;
                    const projectError = applicationErrorDetail?.projectError;
                    if (projectError?.restrictionsLimitExceeded) {
                      setErrorMessage(
                        `No más de ${projectError.restrictionsLimitExceeded} beneficios por proyecto`
                      );
                      setErrorDetail(
                        "Demasiados beneficios son difíciles de monitorear"
                      );
                      setTimeout(() => {
                        setErrorMessage("");
                        setErrorDetail("");
                      }, 7000);
                      return null;
                    }
                    throw e;
                  })
        }
        onReorder={
          readOnly
            ? undefined
            : (restrictions: Array<Restriction>) =>
                reorderRestrictions({
                  variables: {
                    projectId: project.id,
                    idList: restrictions.map(({ id }: Restriction) => id),
                  },
                  update() {
                    cache.modify({
                      id: cache.identify(project),
                      fields: {
                        restrictions: () =>
                          restrictions.map((restriction) => ({
                            __ref: cache.identify(restriction),
                          })),
                      },
                    });
                  },
                })
        }
      />
      {errorMessage !== "" && (
        <Alert variant="warning">
          <Explanation text={errorMessage} detail={errorDetail} />
        </Alert>
      )}
    </div>
  );
};

export default Restrictions;
