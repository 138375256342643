import React from "react";
import ReactDOM from "react-dom";
import { addDays, min, max } from "date-fns";
import styled from "styled-components";
import type { BaselineNodeExtraAttributes, BaselineNode } from "./types";
import InteractiveGanttChart from "./InteractiveGanttChart";
import { briefFormat } from "../../../components/utils";

const BaselineGanttStyleWrapper = styled.div`
  .baseline {
    position: absolute;
    border-radius: 2px;
    opacity: 0.6;
    margin-top: -7px;
    height: 12px;
    background: #ffd180;
    border: 1px solid rgb(255, 153, 0);
  }

  /* move task lines upper */
  .gantt_task_line,
  .gantt_line_wrapper {
    margin-top: -9px;
  }

  .gantt_side_content {
    margin-bottom: 7px;
  }

  .gantt_task_link .gantt_link_arrow {
    margin-top: -12px;
  }
`;

const StyledBaseline = styled.div<{ left: number; width: number; top: number }>`
  position: absolute;
  border-radius: 2px;
  opacity: 0.6;
  margin-top: -7px;
  height: 12px;
  background: #ffd180;
  border: 1px solid rgb(255, 153, 0);
  left: ${(props) => props.left}px;
  width: ${(props) => props.width}px;
  top: ${(props) => props.top}px;
`;

class BaselineGanttChart<
  AdditionalGanttProps,
  AdditionalNodeAttributes
> extends InteractiveGanttChart<
  AdditionalGanttProps,
  BaselineNodeExtraAttributes & AdditionalNodeAttributes
> {
  styleGantt() {
    super.styleGantt();
    // Linea base (amarilla)
    // Ejemplo de dhtmlx: https://docs.dhtmlx.com/gantt/samples/04_customization/15_baselines.html
    this.myGantt.config.task_height = 16;
    this.myGantt.config.row_height = 40;
    const render = (task: BaselineNode & AdditionalNodeAttributes) => {
      const sizes = this.myGantt.getTaskPosition(
        task,
        task.planned_start,
        task.planned_end
      );
      const { left, width, top } = sizes;
      // Esta formula se copió del ejemplo de dhtmlx para líneas base
      // Ejemplo de dhtmlx: https://docs.dhtmlx.com/gantt/samples/04_customization/15_baselines.html
      const actualTop = top + this.myGantt.config.task_height + 13;
      const el = document.createElement("div");
      const title = `Fechas Planeadas
${briefFormat(task.planned_start)} - ${briefFormat(
        addDays(task.planned_end, -1)
      )}`;
      ReactDOM.render(
        <StyledBaseline
          title={title}
          left={left}
          width={width}
          top={actualTop}
        />,
        el
      );
      return el;
    };
    const getRectangle = (task: BaselineNode & AdditionalNodeAttributes) =>
      this.myGantt.getTaskPosition(task, task.planned_start, task.planned_end);
    this.myGantt.addTaskLayer({
      topmost: true,
      renderer: {
        render,
        getRectangle,
      },
    });
  }

  getProjectDates(): Array<Date> {
    const { wbsRoot } = this.props;
    const ganttTasks = this.myGantt.getTaskByTime();
    // Si el gantt no esta inicializado aun (ganttTasks.length === 0), utiliza la lista de
    // tasks inicial. De otro modo, usa las del gantt inicializado.
    const tasks =
      ganttTasks.length === 0 ? this.toGanttNodeArray(wbsRoot) : ganttTasks;
    const projectNode = tasks.find(
      ({ level }: BaselineNode & AdditionalNodeAttributes) => level === 0
    );
    if (!projectNode) throw Error("Project node not found");

    const { start_date, end_date, planned_start, planned_end } = projectNode;
    const startDates = [start_date, planned_start];
    const endDates = [end_date, planned_end];
    return [min(startDates), max(endDates)];
  }

  // El tooltip que se se enseña cuando una task en el gantt chart es hovereada.
  // Fuente relevante: https://docs.dhtmlx.com/gantt/desktop__tooltips.html
  getStyledTooltip(
    start: Date,
    end: Date,
    task: BaselineNode & AdditionalNodeAttributes
  ): string {
    return super.getStyledTooltip(start, end, task);
  }

  render() {
    return (
      <BaselineGanttStyleWrapper>{super.render()}</BaselineGanttStyleWrapper>
    );
  }
}
export default BaselineGanttChart;
