import React from "react";
import { isBefore } from "date-fns";
import { Alert } from "react-bootstrap";
import { DateRangePicker } from "../../../components/DatePicker";
import { briefFormat } from "../../../components/utils";
import Explanation from "../../../components/Explanation";

type Props = {
  startDate: Date | null;
  endDate: Date | null;
  earliestStartDate: Date | null;
  save: (start: Date, end: Date) => void;
  workDays?: Array<boolean>;
};

const GanttDateEditor = ({
  startDate,
  endDate,
  earliestStartDate,
  save,
  workDays = Array(7).fill(true),
}: Props) => {
  const [error, setError] = React.useState(false);
  return (
    <div>
      {error && earliestStartDate !== null && (
        <Alert
          variant="warning"
          onClose={() => setError(false)}
          dismissible
          style={{ maxWidth: "400px" }}
        >
          {" "}
          <Explanation
            text={`Esta actividad no puede iniciar antes del ${briefFormat(
              earliestStartDate
            )}`}
            detail={`Las actividades de las que depende se completan hasta el ${briefFormat(
              earliestStartDate
            )}. Elimina las dependencias, o actualízalas para que terminen antes.`}
          />{" "}
        </Alert>
      )}
      {!error && (
        <DateRangePicker
          onRangeChange={(start, end) => {
            if (earliestStartDate && isBefore(start, earliestStartDate)) {
              setError(true);
            } else {
              save(start, end);
            }
          }}
          initialStart={startDate}
          initialEnd={endDate}
          propsMayChange
          workDays={workDays}
        />
      )}
    </div>
  );
};

export default GanttDateEditor;
