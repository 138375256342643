import React from "react";
import { Text } from "@react-pdf/renderer";

type Props = {
  title: string;
};

const PDFTitle = ({ title }: Props) => (
  <Text
    style={{
      backgroundColor: "whitesmoke",
      padding: "10px 0 10px 0",
      marginTop: "5px",
      marginBottom: "5px",
      textAlign: "center",
      fontSize: "20px",
    }}
  >
    {title}
  </Text>
);

export default PDFTitle;
