import React from "react";
import { Alert, Button } from "react-bootstrap";
import { Reference, FetchResult } from "@apollo/client";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { FaUsers } from "react-icons/fa";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import {
  useProjectMutation,
  useProjectQuery,
} from "../components/project-context";
import Pregunta from "../../components/Pregunta";
import EntradaTexto from "../../components/EntradaTexto";
import {
  PROJECT_INFO,
  UPDATE_PROJECT,
  EDIT_WBS_NODE,
  REORDER_WBS_NODE,
  CREATE_WBS_NODE,
  DELETE_WBS_NODE,
  ADD_MEMBER,
  DELETE_MEMBER,
} from "../../graphql-client/queries/project";
import {
  Step,
  Access,
  Member,
  CreateWbsNodeMutation,
  WbsNode,
  ProjectInput,
  AddMemberMutation,
  Tag,
  Project,
} from "../../graphql-client/codegen-types";
import { DateRangePicker } from "../../components/DatePicker";
import DynamicList from "../components/DynamicList";
import { functionify, Rhombus, DelivLabel } from "../../components/utils";
import Loader from "../../components/Loader";
import MemberEditor from "./components/MemberEditor";
import PDFReport from "./components/PDFReport";
import Explanation from "../../components/Explanation";
import Benefits from "../components/Benefits";
import Assumptions from "../components/Assumptions";
import Restrictions from "../components/Restrictions";
import ProjectTags from "./components/ProjectTags";
import WorkDaysModal from "../components/WorkDaysModal";
import client from "../../graphql-client/connection";
import { parseMaybeStartDate } from "../components/gantt/utils";
import getDocxReportPromise from "./components/docx-report";

type Props = {
  projectId: string;
  articleLink?: string;
};

function createNodeCallback({
  data,
}: FetchResult<CreateWbsNodeMutation>): WbsNode {
  const ret = data?.asUser?.editProject?.createWbsNode?.newWbsNode;
  if (!ret) throw new Error("Error creating Wbs Node");
  return ret;
}

const InnerQue = ({
  project,
  tags,
  articleLink,
}: {
  project: Project;
  tags: Array<Tag>;
  articleLink?: string;
}) => {
  const { cache } = client;
  const editProject = useProjectMutation(UPDATE_PROJECT);
  const editWbsNode = useProjectMutation(EDIT_WBS_NODE);
  const reorderWbsNode = useProjectMutation(REORDER_WBS_NODE);
  const createWbsNode = useProjectMutation<
    CreateWbsNodeMutation,
    { projectId: string | number; parentId: string | number }
  >(CREATE_WBS_NODE);
  const deleteWbsNode = useProjectMutation(DELETE_WBS_NODE);
  const addMember = useProjectMutation<
    AddMemberMutation,
    { projectId: string }
  >(ADD_MEMBER);
  const deleteMember = useProjectMutation(DELETE_MEMBER);
  const updateAttribute = (input: ProjectInput) => {
    editProject({
      variables: { projectId: project.id, input },
      update() {
        cache.modify({
          id: cache.identify(project),
          fields: functionify(input),
        });
      },
    }).catch((e: Error) => {
      console.log(e.message);
    });
  };
  const [memberErrorMessage, setMemberErrorMessage] = React.useState("");
  const [memberErrorDetail, setMemberErrorDetail] = React.useState("");
  const [wbsErrorMessage, setWbsErrorMessage] = React.useState("");
  const [wbsErrorDetail, setWbsErrorDetail] = React.useState("");
  const [isWorkDaysModalOpen, setIsWorkDaysModalOpen] = React.useState(false);
  const readOnly = project.access === Access.Read;
  const isAdmin = project.access === Access.Admin;
  const startEstimate = parseMaybeStartDate(project.startEstimate);
  const endEstimate = parseMaybeStartDate(project.endEstimate);

  const getSectionLink = (sectionId: string) =>
    articleLink ? `${articleLink}#${sectionId}` : undefined;
  return (
    <div>
      Primero lo primero, hay que describir claramente el proyecto y definir sus
      características más importantes.
      <hr />
      <div style={{ marginBottom: "30px" }}>
        <Pregunta
          pregunta="1. ¿Cuál será el nombre del proyecto?"
          descripcion="Elige un título corto, representativo y muy atractivo"
          detailLink={getSectionLink("name")}
        >
          <EntradaTexto
            ejemplo="Ventas Campaña 2020"
            maxLetras={30}
            saveText={
              readOnly
                ? () => {}
                : (text: string) => {
                    updateAttribute({ name: text });
                  }
            }
            textoInicial={project.name || ""}
            rows={1}
            readOnly={readOnly}
          />
        </Pregunta>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <Pregunta
          pregunta="2. ¿Qué problemas, necesidades o requerimientos dan origen al proyecto?"
          descripcion="Describe qué falta, qué funciona mal, qué carencia o exceso existe"
          detailLink={getSectionLink("problems")}
        >
          <EntradaTexto
            ejemplo="No se tiene, no existe, hay demasiados, es limitado, es reducido..."
            maxLetras={350}
            saveText={
              readOnly
                ? () => {}
                : (text: string) => updateAttribute({ problems: text })
            }
            textoInicial={project.problems || ""}
            readOnly={readOnly}
          />
        </Pregunta>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <Pregunta
          pregunta="3. ¿Cuál es el objetivo del proyecto que resuelve el problema o la necesidad?"
          descripcion="Escribe el objetivo con un verbo de acción + un sujeto + un complemento"
          detailLink={getSectionLink("objective")}
        >
          <EntradaTexto
            ejemplo="Desarrollar un plan de capacitación para personal de mantenimiento"
            maxLetras={270}
            saveText={
              readOnly
                ? () => {}
                : (text: string) => updateAttribute({ objective: text })
            }
            textoInicial={project.objective || ""}
            readOnly={readOnly}
          />
        </Pregunta>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <Pregunta
          pregunta="4. ¿Qué vas a entregar o presentar para lograr el objetivo, durante y al final del proyecto?"
          descripcion="Diagnóstico, Prueba validada, Presentación expuesta a inversionistas, etc"
          detailLink={getSectionLink("deliverables")}
        >
          <DynamicList
            title={<DelivLabel plural />}
            deleteInfo={
              readOnly
                ? undefined
                : {
                    onDelete: ({ id }: WbsNode) => {
                      cache.modify({
                        id: cache.identify(project.wbsRoot!),
                        fields: {
                          children: (oldChildren, { readField }) =>
                            oldChildren.filter(
                              (childRef: Reference) =>
                                id !== readField("id", childRef)
                            ),
                        },
                      });
                      return deleteWbsNode({
                        variables: { projectId: project.id, id },
                      }).then(() => true);
                    },
                    deleteWarnTitle: "¿Eliminar entregable?",
                    deleteWarnMsg:
                      "Se eliminarán también todas las actividades asociadas a él. Esta acción es irreversible.",
                  }
            }
            renderItem={(node: WbsNode, isNew: boolean) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  background: "#fff1d8",
                  borderRadius: "5px",
                }}
              >
                <Rhombus style={{ margin: "15px 0 5px 5px" }} />
                <div style={{ margin: "5px", width: "100%" }}>
                  <EntradaTexto
                    ejemplo="Reporte de análisis, Plan del Proyecto, Sitio Web Autorizado"
                    maxLetras={100}
                    autoFocus={isNew}
                    saveText={
                      readOnly
                        ? () => {}
                        : (text: string) =>
                            editWbsNode({
                              variables: {
                                projectId: project.id,
                                input: { id: node.id, text },
                              },
                              update() {
                                cache.modify({
                                  id: cache.identify(node),
                                  fields: functionify({ text }),
                                });
                              },
                            })
                    }
                    textoInicial={node.text ?? ""}
                    rows={2}
                    readOnly={readOnly}
                  />
                </div>
              </div>
            )}
            getItemId={({ id }: WbsNode) => id}
            initial={project.wbsRoot?.children || []}
            newItem={
              readOnly
                ? undefined
                : () =>
                    createWbsNode({
                      variables: {
                        projectId: project.id,
                        parentId: project.wbsRoot!.id,
                      },
                    })
                      .then(createNodeCallback)
                      .then((newNode: WbsNode) => {
                        cache.modify({
                          id: cache.identify(project.wbsRoot!),
                          fields: {
                            children: (oldChildren) =>
                              oldChildren.concat({
                                __ref: cache.identify(newNode),
                              }),
                          },
                        });
                        return newNode;
                      })
                      .catch((e) => {
                        const errorDetail =
                          e?.graphQLErrors?.[0]?.extensions
                            ?.applicationErrorDetail;
                        const projectError = errorDetail?.projectError;
                        if (projectError?.wbsChildLimitExceeded) {
                          setWbsErrorMessage(
                            `No más de ${projectError.wbsChildLimitExceeded} entregables por proyecto`
                          );
                          setWbsErrorDetail(
                            "Poner demasiados entregables afecta la organización del proyecto"
                          );
                          setTimeout(() => {
                            setWbsErrorMessage("");
                            setWbsErrorDetail("");
                          }, 7000);
                          return null;
                        }
                        throw e;
                      })
            }
            onReorder={
              readOnly
                ? undefined
                : (nodes: Array<WbsNode>) =>
                    reorderWbsNode({
                      variables: {
                        projectId: project.id,
                        parentId: project.wbsRoot!.id,
                        idList: nodes.map(({ id }: WbsNode) => id),
                      },
                      update() {
                        const refArray = nodes.map((node) => ({
                          __ref: cache.identify(node),
                        }));
                        cache.modify({
                          id: cache.identify(project.wbsRoot!),
                          fields: {
                            children: () => refArray,
                          },
                        });
                      },
                    })
            }
          />
          {wbsErrorMessage !== "" && (
            <Alert variant="warning">
              <Explanation text={wbsErrorMessage} detail={wbsErrorDetail} />
            </Alert>
          )}
        </Pregunta>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <Pregunta
          pregunta="5. ¿Cuáles son los beneficios esperados? Lístalos y ordénalos por importancia."
          descripcion="Lista los beneficios que se obtendrán después de terminar el proyecto, como resultado de utilizar o aprovechar los entregables."
          detailLink={getSectionLink("benefits")}
        >
          <Benefits project={project} readOnly={readOnly} />
        </Pregunta>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <Pregunta
          pregunta="6. ¿Quién es el cliente del proyecto y los usuarios del producto o de los entregables?"
          descripcion="Cliente es quien solicita el proyecto, lo revisa y acepta los resultados. Usuarios son los que van a usar los entregables"
          detailLink={getSectionLink("client")}
        >
          <EntradaTexto
            ejemplo="El cliente principal es el Ing. Arturo Fuentes director de Mercadotecnia. Los usuarios son los que van a comprar las nuevas máquinas."
            maxLetras={350}
            saveText={
              readOnly
                ? () => {}
                : (text: string) => updateAttribute({ client: text })
            }
            textoInicial={project.client || ""}
            readOnly={readOnly}
          />
        </Pregunta>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <Pregunta
          pregunta="7. ¿Cuándo estimas empezar el proyecto? ¿Cuándo estimas terminarlo?"
          descripcion="21 de Mayo al 6 de Julio"
          detailLink={getSectionLink("date-estimates")}
        >
          <DateRangePicker
            onRangeChange={(start: Date, end: Date) => {
              updateAttribute({
                startEstimate: start,
                endEstimate: end,
              });
              cache.modify({
                id: cache.identify(project),
                fields: {
                  startEstimate: () => start,
                  endEstimate: () => end,
                },
              });
            }}
            initialStart={startEstimate}
            initialEnd={endEstimate}
            readOnly={readOnly}
            workDays={project.workDays || []}
          />
        </Pregunta>
        <br />
        <Button
          variant="light"
          style={{ border: "1px solid lightgrey" }}
          onClick={() => setIsWorkDaysModalOpen(true)}
          disabled={readOnly}
        >
          {" "}
          Definir días laborales{" "}
        </Button>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <Pregunta
          pregunta="8. ¿Quiénes van a trabajar en el proyecto? Lista a los miembros de tu equipo."
          descripcion="Escribe los nombres de los que van a trabajar en el proyecto contigo"
          detailLink={getSectionLink("members")}
        >
          <div>
            <DynamicList
              title={
                <span>
                  {" "}
                  <FaUsers /> Equipo de trabajo{" "}
                </span>
              }
              deleteInfo={
                !isAdmin
                  ? undefined
                  : {
                      onDelete: ({ id }: Member) =>
                        deleteMember({
                          variables: { projectId: project.id, id },
                        })
                          .then(() => {
                            cache.modify({
                              id: cache.identify(project),
                              fields: {
                                members: (oldMembers, { readField }) =>
                                  oldMembers.filter(
                                    (memberRef: Reference) =>
                                      id !== readField("id", memberRef)
                                  ),
                              },
                            });
                            return true;
                          })
                          .catch((e) => {
                            const errorDetail =
                              e?.graphQLErrors?.[0]?.extensions
                                ?.applicationErrorDetail;
                            const projectError = errorDetail?.projectError;
                            if (
                              projectError?.memberError?.atLeastOneAdminMember
                            ) {
                              setMemberErrorMessage(
                                "Debe haber al menos un dueño del proyecto"
                              );
                              setMemberErrorDetail(
                                "De otro modo, nadie podría editar los miembros o eliminar el proyecto."
                              );
                              setTimeout(() => {
                                setMemberErrorMessage("");
                                setMemberErrorDetail("");
                              }, 7000);
                              return false;
                            }
                            throw e;
                          }),
                      deleteWarnTitle: "¿Eliminar miembro?",
                      deleteWarnMsg:
                        "Se eliminarán también todas las tareas asignadas a él/ella.",
                    }
              }
              renderItem={(member: Member) => (
                <MemberEditor
                  initialMember={member}
                  projectId={project.id}
                  readOnly={!isAdmin}
                  setMemberErrorMessage={setMemberErrorMessage}
                  setMemberErrorDetail={setMemberErrorDetail}
                />
              )}
              getItemId={({ id }: Member) => id}
              initial={project.members || []}
              newItem={
                !isAdmin
                  ? undefined
                  : () =>
                      addMember({
                        variables: { projectId: project.id },
                      })
                        .then((resp: FetchResult<AddMemberMutation>) => {
                          const newMember =
                            resp.data?.asUser?.editProjectAsAdmin?.addMember
                              ?.newMember;
                          if (!newMember)
                            throw new Error("Error creating Member");
                          cache.modify({
                            id: cache.identify(project),
                            fields: {
                              members: (oldMembers) =>
                                oldMembers.concat({
                                  __ref: cache.identify(newMember),
                                }),
                            },
                          });
                          return newMember;
                        })
                        .catch((e) => {
                          const errorDetail =
                            e?.graphQLErrors?.[0]?.extensions
                              ?.applicationErrorDetail;
                          const projectError = errorDetail?.projectError;
                          if (projectError?.memberError?.memberLimitExceeded) {
                            setMemberErrorMessage(
                              `No más de ${projectError.memberError.memberLimitExceeded} miembros por proyecto`
                            );
                            setMemberErrorDetail(
                              "Demasiados miembros afectan la organización en el proyecto"
                            );
                            setTimeout(() => {
                              setMemberErrorMessage("");
                              setMemberErrorDetail("");
                            }, 7000);
                            return null;
                          }
                          throw e;
                        })
              }
            />
            {memberErrorMessage !== "" && (
              <Alert variant="warning">
                <Explanation
                  text={memberErrorMessage}
                  detail={memberErrorDetail}
                />
              </Alert>
            )}
            {!isAdmin && (
              <div style={{ margin: "20px 0 20px 0" }}>
                {" "}
                <b>Nota:</b>
                <span className="text-muted">
                  {" "}
                  Sólo el dueño del proyecto tiene permiso de editar a los
                  miembros{" "}
                </span>
              </div>
            )}
          </div>
        </Pregunta>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <Pregunta
          pregunta="9. ¿Qué suposiciones hay para que el proyecto avance?"
          descripcion="Suposiciones son eventos que se espera que ocurran para que avance el proyecto"
          detailLink={getSectionLink("assumptions")}
        >
          <Assumptions project={project} readOnly={readOnly} />
        </Pregunta>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <Pregunta
          pregunta="10. ¿Qué restricciones existen que pueden limitar el avance del proyecto?"
          descripcion="Restricciones son las limitaciones que, si no se superan, perjudica los objetivos del proyecto"
          detailLink={getSectionLink("restrictions")}
        >
          <Restrictions project={project} readOnly={readOnly} />
        </Pregunta>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <Pregunta
          pregunta="11. (Opcional) ¿Qué grupo(s) quieres asignarle al proyecto?"
          descripcion="Te ayudará a segmentar y agrupar los proyectos de tu portafolio, y encontrarlos más fácilmente"
          detailLink={getSectionLink("groups")}
        >
          <br />
          <ProjectTags project={project} tags={tags} />
          <br />
          <span className="text-muted">
            {" "}
            <b> Nota </b> Los grupos de proyecto que asignes son privados. Sólo
            tú puedes verlos.
          </span>
        </Pregunta>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        <PDFDownloadLink
          document={<PDFReport project={project} />}
          fileName="Que.pdf"
        >
          <Button variant="info"> Imprimir reporte en PDF </Button>
        </PDFDownloadLink>
        <Button
          variant="info"
          onClick={() => {
            getDocxReportPromise(project)
              .then((docxReport) => Packer.toBlob(docxReport))
              .then((blob) => {
                saveAs(blob, "Que.docx");
              });
          }}
        >
          Exportar a Word (.docx)
        </Button>
      </div>
      <WorkDaysModal
        project={project}
        isOpen={isWorkDaysModalOpen}
        close={() => setIsWorkDaysModalOpen(false)}
      />
    </div>
  );
};

const Que = ({ projectId, articleLink }: Props) => {
  const { loading, error, data } = useProjectQuery(PROJECT_INFO, {
    variables: { id: projectId, step: Step.Que },
    errorPolicy: "all",
  });
  const project = data?.asUser?.getProject;
  const tags = data?.asUser?.getTags || [];
  if (loading) return <Loader size={60} message="Cargando el proyecto" />;
  if (error || !project) {
    return <p style={{ textAlign: "center" }}>Error! {error?.message}</p>;
  }
  return <InnerQue project={project} tags={tags} articleLink={articleLink} />;
};

export default Que;
