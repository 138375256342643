import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import styled from "styled-components";
import steps from "./constants";

type MaybeArrowProps = {
  idx: number;
  redirRoute: string;
};

const StyledDiv = styled.div`
  background: aliceblue;
  padding: 4px;
  border-radius: 7px;
  margin: 5px;
  border: 1px solid #cccc00;
`;

const MaybeLeftArrow = ({ idx, redirRoute }: MaybeArrowProps) =>
  idx > 0 ? (
    <Col md="auto">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <Row
          style={{
            marginRight: "5px",
            color: "grey",
            fontSize: "14px",
            justifyContent: "center",
          }}
        >
          Paso anterior
        </Row>
        <Row>
          <Link to={`${redirRoute}/${steps[idx - 1].path}`}>
            <StyledDiv>
              <FaChevronCircleLeft
                style={{
                  height: "25px",
                  width: "35px",
                  color: "coral",
                }}
              />
              <b style={{ color: "#0484bc", fontSize: "19px" }}>
                {idx}. {steps[idx - 1].title}
              </b>
            </StyledDiv>
          </Link>
        </Row>
      </div>
    </Col>
  ) : (
    <div />
  );

const MaybeRightArrow = ({ idx, redirRoute }: MaybeArrowProps) =>
  idx + 1 < steps.length ? (
    <Col md="auto">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Row
          style={{
            marginLeft: "5px",
            color: "grey",
            fontSize: "14px",
            justifyContent: "center",
          }}
        >
          Siguiente paso
        </Row>
        <Row style={{ alignItems: "center" }}>
          <Link to={`${redirRoute}/${steps[idx + 1].path}`}>
            <StyledDiv>
              <b style={{ color: "#0484bc", fontSize: "19px" }}>
                {idx + 2}. {steps[idx + 1].title}
              </b>
              <FaChevronCircleRight
                style={{
                  height: "25px",
                  width: "35px",
                  color: "coral",
                }}
              />
            </StyledDiv>
          </Link>
        </Row>
      </div>
    </Col>
  ) : (
    <div />
  );

const ProjectFooter = () => {
  const { projectId, stepPath } = useParams<{
    projectId: string;
    stepPath: string;
  }>();

  const redirRoute = `/projects/${projectId}`;
  const idx = steps.findIndex(({ path }) => path === stepPath);
  const step = steps[idx];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <MaybeLeftArrow idx={idx} redirRoute={redirRoute} />
        <MaybeRightArrow idx={idx} redirRoute={redirRoute} />
      </div>
    </div>
  );
};

export default ProjectFooter;
