import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import SignIn from "./SignIn";
import ChangePassword from "./ChangePassword";
import ChangePasswordRequest from "./ChangePasswordRequest";
import ConfirmAccount from "./ConfirmAccount";
import Register from "./Register";
import Welcome from "./Welcome";
import Logo from "../components/Logo";
import TermsAndConditions from "./TermsAndConditions";

const StyledContentWrapper = styled.div`
  flex: 1 0 auto;
`;

const GlobalBodyStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    font-family: sans-serif;
    background-image: url("/fondo-azul.png");
    background-repeat: repeat;
    background-size: cover;
  }

  body {
    margin: 0;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
`;

const RouterDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
`;

const Vr = styled.hr`
  border: none;
  border-left: 1px solid steelblue;
  height: 100%;
  width: 1px;
  margin: 0 8px 0 0;
`;

type Props = {
  message?: string;
};

const LoginPage = ({ message }: Props) => (
  <StyledContentWrapper>
    <GlobalBodyStyle />
    <StyledDiv>
      <RouterDiv>
        <Logo />
        <Router>
          <Switch>
            <Route path="/signin" render={() => <SignIn message={message} />} />
            <Route path="/register" component={Register} />
            <Route path="/confirm/:token" component={ConfirmAccount} />
            <Route
              path="/change-password-request"
              component={ChangePasswordRequest}
            />
            <Route path="/change-password/:token" component={ChangePassword} />
            <Route path="/welcome" component={Welcome} />
            <Route path="/terms" component={TermsAndConditions} />
            <Redirect from="/" to="/welcome" />
          </Switch>
        </Router>
        <a
          href="https://coordinate.si/login.php"
          style={{ marginTop: "10px", color: "whitesmoke" }}
        >
          {" "}
          Ir a la versión anterior de Coordinate
        </a>
      </RouterDiv>
    </StyledDiv>
  </StyledContentWrapper>
);

export default LoginPage;
