import React from "react";
import { Carousel } from "react-bootstrap";

const Image = ({ src, alt }: { src: string; alt: string }) => (
  <img
    src={src}
    alt={alt}
    style={{
      borderRadius: "15px",
      border: "3px solid lightgrey",
      width: "100%",
      maxWidth: "910px",
      marginBottom: "20px",
    }}
  />
);

const UsageCarousel = () => {
  return (
    <Carousel
      interval={4000}
      style={{
        padding: "30px",
        background: "rgba(240, 248, 255, 0.7)",
        width: "100%",
      }}
    >
      <Carousel.Item>
        <h3 style={{ marginBottom: "5px" }}>
          {" "}
          <b>
            Convierte tus <span style={{ color: "coral" }}>ideas</span> en{" "}
            <span style={{ color: "steelblue" }}>Proyectos Realizables</span>{" "}
          </b>
        </h3>
        <hr />
        <Image src="/queyporque.gif" alt="Iniciando el proyecto" />
      </Carousel.Item>
      <Carousel.Item>
        <h3 style={{ marginBottom: "5px" }}>
          {" "}
          <b>
            Ordena tus <span style={{ color: "coral" }}>Actividades</span> y
            aumenta tu{" "}
            <span style={{ color: "steelblue" }}> Productividad </span>{" "}
          </b>
        </h3>
        <hr />
        <Image src="/como.gif" alt="Creando actividades" />
      </Carousel.Item>
      <Carousel.Item>
        <h3 style={{ marginBottom: "5px" }}>
          {" "}
          <b>
            Organízate con tu <span style={{ color: "coral" }}>Equipo</span> y
            distribuyan el <span style={{ color: "steelblue" }}>Trabajo</span>{" "}
          </b>
        </h3>
        <hr />
        <Image src="/quien.gif" alt="Asignando roles" />
      </Carousel.Item>
      <Carousel.Item>
        <h3 style={{ marginBottom: "5px" }}>
          {" "}
          <b>
            Planea <span style={{ color: "coral" }}>Fechas</span> de forma{" "}
            <span style={{ color: "steelblue" }}>simple y concisa</span>{" "}
          </b>
        </h3>
        <hr />
        <Image src="/cuando.gif" alt="Planeando fechas" />
      </Carousel.Item>
      <Carousel.Item>
        <h3 style={{ marginBottom: "5px" }}>
          {" "}
          <b>
            Monitorea los <span style={{ color: "coral" }}>Avances</span> y
            entrega el proyecto{" "}
            <span style={{ color: "steelblue" }}>Completo</span> y{" "}
            <span style={{ color: "steelblue" }}>A Tiempo</span>{" "}
          </b>
        </h3>
        <hr />
        <Image src="/comova.gif" alt="Monitoreando avances" />
      </Carousel.Item>
      <Carousel.Item>
        <h3 style={{ marginBottom: "5px" }}>
          {" "}
          <b>
            Administra <span style={{ color: "coral" }}>Todos</span> tus
            proyectos como lo hacen los{" "}
            <span style={{ color: "steelblue" }}>Expertos</span>
          </b>
        </h3>
        <hr />
        <Image
          src="/portafolioproyectos.gif"
          alt="Administrando lista de proyectos"
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default UsageCarousel;
