import { gql } from "@apollo/client";

const CORE_MEMBER_FIELDS = gql`
  fragment CoreMemberFields on Member {
    id
    name
    user {
      id
      handle
      name
      lastNames
      profilePictureLink
    }
    access
    invitedOn
    confirmedOn
  }
`;

export const FILTER_PROJECTS = gql`
  query getProjects($textFilters: [StringFilter!]!) {
    asUser {
      id
      getTags {
        id
        name
      }
      getProjects(filters: { textFilters: $textFilters }) {
        id
        name
        startEstimate
        endEstimate
        access
        wbsRoot {
          id
          plannedStart
          plannedEnd
          startDate
          endDate
          children {
            id
            children {
              id
              plannedStart
              plannedEnd
              startDate
              endDate
              status
            }
          }
        }
        tags {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation deleteProject($projectId: ID!, $step: Step!) {
    asUser {
      editProjectAsAdmin(projectId: $projectId, step: $step) {
        deleteProject {
          message
        }
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation createProject($name: String!) {
    asUser {
      createProject(name: $name) {
        message
        newProject {
          id
        }
      }
    }
  }
`;

export const PROJECT_INFO = gql`
  ${CORE_MEMBER_FIELDS}
  query getProject($id: ID!, $step: Step!) {
    asUser {
      id
      getTags {
        id
        name
      }
      getProject(id: $id, step: $step) {
        id
        name
        access
        problems
        objective
        workDays
        benefits {
          id
          text
        }
        assumptions {
          id
          text
        }
        restrictions {
          id
          text
        }
        client
        startEstimate
        endEstimate
        wbsRoot {
          id
          children {
            id
            text
          }
        }
        members {
          ...CoreMemberFields
        }
        tags {
          id
          name
        }
      }
    }
  }
`;

export const PROJECT_NAME = gql`
  query getProjectName($id: ID!, $step: Step!) {
    asUser {
      id
      getProject(id: $id, step: $step) {
        id
        name
      }
    }
  }
`;

export const PROJECT_ACCESS = gql`
  query getProjectAccess($id: ID!, $step: Step!) {
    asUser {
      id
      getProject(id: $id, step: $step) {
        id
        access
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation editProject($projectId: ID!, $step: Step!, $input: ProjectInput!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        setAttributes(input: $input) {
          message
        }
      }
    }
  }
`;

export const EDIT_WBS_NODE = gql`
  mutation ditWbsNode($projectId: ID!, $step: Step!, $input: WbsNodeInput!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        editWbsNode(input: $input) {
          message
        }
      }
    }
  }
`;

export const REORDER_WBS_NODE = gql`
  mutation reorderWbsNode(
    $projectId: ID!
    $step: Step!
    $parentId: ID!
    $idList: [ID!]!
  ) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        reorderWbsNode(parentId: $parentId, idList: $idList) {
          message
        }
      }
    }
  }
`;

export const CREATE_WBS_NODE = gql`
  mutation createWbsNode(
    $projectId: ID!
    $step: Step!
    $parentId: ID!
    $text: String
  ) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        createWbsNode(parentId: $parentId, text: $text) {
          message
          newWbsNode {
            id
            text
          }
        }
      }
    }
  }
`;

export const DELETE_WBS_NODE = gql`
  mutation deleteWbsNode($projectId: ID!, $step: Step!, $id: ID!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        deleteWbsNode(id: $id) {
          message
        }
      }
    }
  }
`;

export const GET_WBS = gql`
  query getWbs($id: ID!, $step: Step!) {
    asUser {
      id
      getProject(id: $id, step: $step) {
        id
        name
        access
        wbsRoot {
          id
          text
          children {
            id
            text
            children {
              id
              text
            }
          }
        }
      }
    }
  }
`;

// Same query as the other one except that we query all the memberIds at each
// level too
export const GET_MEMBERS_WBS = gql`
  query getMembers($id: ID!, $step: Step!) {
    asUser {
      id
      getProject(id: $id, step: $step) {
        id
        name
        access
        members {
          id
          name
          user {
            id
            handle
          }
          access
        }
        wbsRoot {
          id
          text
          duties {
            memberId
          }
          children {
            id
            text
            duties {
              memberId
            }
            children {
              id
              text
              duties {
                memberId
              }
            }
          }
        }
      }
    }
  }
`;

export const SET_DUTY = gql`
  mutation setDuty(
    $projectId: ID!
    $step: Step!
    $memberId: ID!
    $wbsNodeId: ID!
    $job: Job
  ) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        setDuty(memberId: $memberId, wbsNodeId: $wbsNodeId, job: $job) {
          message
        }
      }
    }
  }
`;

export const GET_DATES_CUANDO = gql`
  query getDatesCuando($id: ID!, $step: Step!) {
    asUser {
      id
      getProject(id: $id, step: $step) {
        id
        name
        access
        startEstimate
        workDays
        members {
          id
          name
          user {
            id
            handle
          }
        }
        assumptions {
          id
          text
        }
        restrictions {
          id
          text
        }
        wbsRoot {
          id
          text
          plannedStart
          plannedEnd
          duties {
            memberId
          }
          children {
            id
            text
            plannedStart
            plannedEnd
            duties {
              memberId
            }
            children {
              id
              text
              plannedStart
              plannedEnd
              duties {
                memberId
              }
            }
          }
        }
        links {
          id
          source
          target
          type
        }
        risks {
          id
          text
          impact
          probability
          actions
          responsibleId
        }
      }
    }
  }
`;

export const GET_DATES_COMOTERMINO = gql`
  query getDatesComotermino($id: ID!, $step: Step!) {
    asUser {
      id
      getProject(id: $id, step: $step) {
        id
        name
        access
        workDays
        lessons {
          id
          text
        }
        benefits {
          id
          text
        }
        hasSubmittedFeedback
        wbsRoot {
          id
          text
          startDate
          endDate
          plannedStart
          plannedEnd
          status
          children {
            id
            text
            startDate
            endDate
            plannedStart
            plannedEnd
          }
        }
      }
    }
  }
`;

export const GET_DATES_COMOVA = gql`
  query getDatesComova($id: ID!, $step: Step!) {
    asUser {
      id
      getProject(id: $id, step: $step) {
        id
        name
        access
        workDays
        statusComments
        assumptions {
          id
          text
        }
        restrictions {
          id
          text
        }
        members {
          id
          name
          user {
            id
            handle
          }
        }
        wbsRoot {
          id
          text
          startDate
          endDate
          plannedStart
          plannedEnd
          status
          duties {
            memberId
          }
          children {
            id
            text
            startDate
            endDate
            plannedStart
            plannedEnd
            status
            duties {
              memberId
            }
            children {
              id
              text
              startDate
              endDate
              plannedStart
              plannedEnd
              status
              duties {
                memberId
              }
            }
          }
        }
        links {
          id
          source
          target
          type
        }
      }
    }
  }
`;

export const SEARCH_USERS = gql`
  query searchUsers($query: String!) {
    asUser {
      id
      searchUsers(query: $query) {
        id
        handle
        name
        lastNames
        profilePictureLink
      }
    }
  }
`;

export const ADD_MEMBER = gql`
  ${CORE_MEMBER_FIELDS}
  mutation addMember($projectId: ID!, $step: Step!) {
    asUser {
      editProjectAsAdmin(projectId: $projectId, step: $step) {
        addMember {
          message
          newMember {
            ...CoreMemberFields
          }
        }
      }
    }
  }
`;

export const DELETE_MEMBER = gql`
  mutation deleteMember($projectId: ID!, $step: Step!, $id: ID!) {
    asUser {
      editProjectAsAdmin(projectId: $projectId, step: $step) {
        deleteMember(id: $id) {
          message
        }
      }
    }
  }
`;

export const EDIT_MEMBER = gql`
  mutation editMember($projectId: ID!, $step: Step!, $input: MemberInput!) {
    asUser {
      editProjectAsAdmin(projectId: $projectId, step: $step) {
        editMember(input: $input) {
          message
        }
      }
    }
  }
`;

export const ADD_LINK = gql`
  mutation addLink($projectId: ID!, $step: Step!, $link: LinkInput!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        addLink(link: $link) {
          message
        }
      }
    }
  }
`;

export const DELETE_LINK = gql`
  mutation deleteLink($projectId: ID!, $step: Step!, $id: ID!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        deleteLink(id: $id) {
          message
        }
      }
    }
  }
`;

export const SUBMIT_FEEDBACK = gql`
  mutation submitFeedback(
    $projectId: ID!
    $step: Step!
    $input: ProjectFeedbackInput!
  ) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        submitFeedback(input: $input) {
          message
        }
      }
    }
  }
`;

export const CONFIRM_MEMBER_INVITATION = gql`
  mutation confirmMemberInvitation($memberId: ID!) {
    asUser {
      confirmMemberInvitation(memberId: $memberId) {
        message
        newProject {
          id
        }
      }
    }
  }
`;

export const INVITE_MEMBER = gql`
  mutation inviteMember($projectId: ID!, $memberId: ID!, $step: Step!) {
    asUser {
      editProjectAsAdmin(projectId: $projectId, step: $step) {
        inviteMember(memberId: $memberId) {
          message
        }
      }
    }
  }
`;

export const ABANDON_PROJECT = gql`
  mutation abandonProject($projectId: ID!) {
    asUser {
      abandonProject(projectId: $projectId) {
        message
      }
    }
  }
`;

export const ADD_BENEFIT = gql`
  mutation addBenefit($projectId: ID!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        addBenefit {
          newBenefit {
            id
            text
          }
        }
      }
    }
  }
`;

export const DELETE_BENEFIT = gql`
  mutation deleteBenefit($projectId: ID!, $id: ID!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        deleteBenefit(id: $id) {
          message
        }
      }
    }
  }
`;

export const EDIT_BENEFIT = gql`
  mutation editBenefit($projectId: ID!, $input: BenefitInput!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        editBenefit(input: $input) {
          message
        }
      }
    }
  }
`;

export const REORDER_BENEFITS = gql`
  mutation reorderBenefits($projectId: ID!, $idList: [ID!]!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        reorderBenefits(idList: $idList) {
          message
        }
      }
    }
  }
`;

export const ADD_ASSUMPTION = gql`
  mutation addAssumption($projectId: ID!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        addAssumption {
          newAssumption {
            id
            text
          }
        }
      }
    }
  }
`;

export const DELETE_ASSUMPTION = gql`
  mutation deleteAssumption($projectId: ID!, $id: ID!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        deleteAssumption(id: $id) {
          message
        }
      }
    }
  }
`;

export const EDIT_ASSUMPTION = gql`
  mutation editAssumption(
    $projectId: ID!
    $input: AssumptionInput!
    $step: Step!
  ) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        editAssumption(input: $input) {
          message
        }
      }
    }
  }
`;

export const REORDER_ASSUMPTIONS = gql`
  mutation reorderAssumptions($projectId: ID!, $idList: [ID!]!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        reorderAssumptions(idList: $idList) {
          message
        }
      }
    }
  }
`;

export const ADD_RESTRICTION = gql`
  mutation addRestriction($projectId: ID!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        addRestriction {
          newRestriction {
            id
            text
          }
        }
      }
    }
  }
`;

export const DELETE_RESTRICTION = gql`
  mutation deleteRestriction($projectId: ID!, $id: ID!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        deleteRestriction(id: $id) {
          message
        }
      }
    }
  }
`;

export const EDIT_RESTRICTION = gql`
  mutation editRestriction(
    $projectId: ID!
    $input: RestrictionInput!
    $step: Step!
  ) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        editRestriction(input: $input) {
          message
        }
      }
    }
  }
`;

export const REORDER_RESTRICTIONS = gql`
  mutation reorderRestrictions($projectId: ID!, $idList: [ID!]!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        reorderRestrictions(idList: $idList) {
          message
        }
      }
    }
  }
`;

export const ADD_LESSON = gql`
  mutation addLesson($projectId: ID!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        addLesson {
          newLesson {
            id
            text
          }
        }
      }
    }
  }
`;

export const DELETE_LESSON = gql`
  mutation deleteLesson($projectId: ID!, $id: ID!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        deleteLesson(id: $id) {
          message
        }
      }
    }
  }
`;

export const EDIT_LESSON = gql`
  mutation editLesson($projectId: ID!, $input: LessonInput!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        editLesson(input: $input) {
          message
        }
      }
    }
  }
`;

export const REORDER_LESSONS = gql`
  mutation reorderLessons($projectId: ID!, $idList: [ID!]!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        reorderLessons(idList: $idList) {
          message
        }
      }
    }
  }
`;

export const ASSIGN_TAG = gql`
  mutation assignTag($projectId: ID!, $tagId: ID!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        assignTag(tagId: $tagId) {
          message
        }
      }
    }
  }
`;

export const UNASSIGN_TAG = gql`
  mutation unassignTag($projectId: ID!, $tagId: ID!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        unassignTag(tagId: $tagId) {
          message
        }
      }
    }
  }
`;

export const GET_DUTIES_BY_DATE = gql`
  query getDutiesByDate($from: DateTime!, $to: DateTime!) {
    asUser {
      id
      getDutiesByDate(from: $from, to: $to) {
        id
        text
        status
        plannedEnd
        endDate
        projectId
      }
    }
  }
`;

export const ADD_RISK = gql`
  mutation addRisk($projectId: ID!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        addRisk {
          newRisk {
            id
            text
            impact
            probability
            actions
            responsibleId
          }
        }
      }
    }
  }
`;

export const DELETE_RISK = gql`
  mutation deleteRisk($projectId: ID!, $id: ID!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        deleteRisk(id: $id) {
          message
        }
      }
    }
  }
`;

export const EDIT_RISK = gql`
  mutation editRisk($projectId: ID!, $input: RiskInput!, $step: Step!) {
    asUser {
      editProject(projectId: $projectId, step: $step) {
        editRisk(input: $input) {
          message
        }
      }
    }
  }
`;
