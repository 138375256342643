import React from "react";
import { useHistory } from "react-router-dom";
import { FetchResult, useQuery, useMutation } from "@apollo/client";
import { FaTags, FaAngleLeft } from "react-icons/fa";
import { Alert, Button, Col } from "react-bootstrap";
import type { Reference } from "@apollo/client";
import {
  GET_TAGS,
  CREATE_TAG,
  DELETE_TAG,
  EDIT_TAG,
} from "../graphql-client/queries/tags";
import type { Tag, CreateTagMutation } from "../graphql-client/codegen-types";
import DynamicList from "../project/components/DynamicList";
import EntradaTexto from "./EntradaTexto";
import Loader from "./Loader";
import Explanation from "./Explanation";
import client from "../graphql-client/connection";

const ManageTags = () => {
  const { cache } = client;
  const { data, loading, error } = useQuery(GET_TAGS, {
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  });
  const [createTag] = useMutation(CREATE_TAG);
  const [deleteTag] = useMutation(DELETE_TAG);
  const [editTag] = useMutation(EDIT_TAG);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorDetail, setErrorDetail] = React.useState("");
  const history = useHistory();

  if (loading)
    return <Loader size={80} message="Cargando grupos de proyecto" />;
  if (error) console.log("Unexpected Error", error);
  const initialTags = data?.asUser?.getTags || [];
  const updateCreateTag = () => {
    return createTag({ variables: { name: "" } })
      .then((resp: FetchResult<CreateTagMutation>) => {
        const newTag = resp.data?.asUser?.createTag?.newTag;
        if (!newTag) throw Error("Undefined id");
        cache.modify({
          id: cache.identify(data.asUser),
          fields: {
            getTags: (oldTags) =>
              oldTags.concat({ __ref: cache.identify(newTag) }),
          },
        });
        return newTag;
      })
      .catch((e) => {
        const tagLimitExceeded =
          e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail
            ?.tagLimitExceeded;
        if (tagLimitExceeded) {
          setErrorMessage(`No más de ${tagLimitExceeded} grupos de proyecto`);
          setErrorDetail("Más que eso es excesivo");
          setTimeout(() => {
            setErrorMessage("");
            setErrorDetail("");
          }, 7000);
        } else {
          console.log("Unexpected error", e);
        }
        return null;
      });
  };

  const updateDeleteTag = (deletedTag: Tag) =>
    deleteTag({
      variables: { id: deletedTag.id },
      update() {
        cache.modify({
          id: cache.identify(data.asUser),
          fields: {
            getTags: (tagRefs, { readField }) =>
              tagRefs.filter(
                (tagRef: Reference) => deletedTag.id !== readField("id", tagRef)
              ),
          },
        });
      },
    }).then(() => true);

  const deleteInfo = {
    onDelete: updateDeleteTag,
    deleteWarnTitle: "¿Eliminar grupo?",
    deleteWarnMsg:
      "Todos los proyectos que estaban asignados a él dejarán de estar asignados a él.",
  };

  const renderItem = (tag: Tag) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        borderRadius: "5px",
      }}
    >
      <FaTags style={{ margin: "15px 0 5px 5px" }} />
      <div style={{ margin: "5px", width: "100%" }}>
        <EntradaTexto
          ejemplo="Trabajo, escuela, servicio social..."
          maxLetras={40}
          saveText={(text) =>
            editTag({
              variables: { input: { id: tag.id, name: text } },
              update() {
                cache.modify({
                  id: cache.identify(tag),
                  fields: {
                    name: () => text,
                  },
                });
              },
            })
          }
          textoInicial={tag.name || ""}
          rows={1}
        />{" "}
      </div>{" "}
    </div>
  );

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Col sm={12} lg={6}>
        <h4 style={{ textAlign: "center" }}>
          <Button
            onClick={() => history.goBack()}
            title="Volver al proyecto"
            variant="light"
          >
            <FaAngleLeft />
          </Button>{" "}
          Editar, Agregar, o Eliminar Grupos de proyecto{" "}
        </h4>
        <hr />
        <DynamicList
          title="Mis grupos de proyecto"
          renderItem={renderItem}
          getItemId={({ id }: Tag) => id}
          initial={initialTags}
          deleteInfo={deleteInfo}
          newItem={updateCreateTag}
        />
        {errorMessage !== "" && (
          <Alert variant="warning">
            {" "}
            <Explanation text={errorMessage} detail={errorDetail} />{" "}
          </Alert>
        )}
        <br />
        <p className="text-muted" style={{ textAlign: "center" }}>
          {" "}
          Si manejas muchos proyectos de diferente tipo, los grupos de proyecto
          son ideales para ti. Esta funcionalidad te permite clasificarlos y
          organizarlos fácilmente.{" "}
        </p>
      </Col>
    </div>
  );
};

export default ManageTags;
