import React from "react";
import styled from "styled-components";
import { Popper } from "@material-ui/core";
import { Card } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

const StyledCard = styled(Card)`
  border-radius: 10px;
  border: 2px solid ${(props) => props.color};
  max-width: 450px;
`;

const StyledTimes = styled(FaTimes)`
  cursor: pointer;
  width: 12px;
  :hover {
    opacity: 0.5;
  }
`;

// Se necesita este css para la flecha del Popper
// Ejemplo relevante: https://codesandbox.io/s/popper-with-arrow-58jhe
const StyledPopper = styled(Popper)`
  z-index: 999;
	&[x-placement*="bottom"] .arrow{

    width: 0; 
    height: 0; 
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-bottom: 1em solid ${(props) => props.color};
    margin-top: -0.9em;
    
    &:before {
			hidden: true;
      border-width: '0 1em 1em 1em';
      border-color: 'transparent transparent white transparent';
    }
  }

  &[x-placement*="top"] .arrow{

    bottom: 0;
    width: 0; 
    height: 0; 
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-top: 1em solid ${(props) => props.color};
    margin-bottom: -0.9em;

    &:before {
      border-width: 1em 1em 0 1em;
      border-color: white transparent transparent transparent;
    }
  }

  &[x-placement*="right"] .arrow{

    left: 0;
    width: 0; 
    height: 0; 
    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;
    border-right: 1em solid ${(props) => props.color};
    margin-left: -0.9em;

    &:before {
      border-width: 1em 1em 1em 0;
      border-color: transparent white transparent transparent;
    }
  }

  &[x-placement*="left"] .arrow{
    
    right: 0;
    width: 0; 
    height: 0; 
    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;
    border-left: 1em solid ${(props) => props.color};
    margin-right: -0.9em;

    &:before {
      border-width: 1em 0 1em 1em;
      border-color: transparent transparent transparent white;
    }
  }

  .arrow {
		color: ${(props) => props.color};
    position: absolute;
    font-size: 7px;
    width: 3em;
    height: 3em;

    &:before {
      content: '""',
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
    }
	}
`;

type Props = {
  title: string;
  close: () => void;
  color: string;
  anchorEl: HTMLElement;
};

export default function CustomPopper({
  title,
  close,
  color,
  anchorEl,
  children,
}: React.PropsWithChildren<Props>) {
  const [arrowElement, setArrowElement] = React.useState<HTMLDivElement | null>(
    null
  );
  return (
    <StyledPopper
      className="editor-popper"
      color={color}
      id="popper"
      open
      anchorEl={anchorEl}
      placement="bottom"
      modifiers={{
        arrow: {
          element: arrowElement,
        },
      }}
    >
      <div
        className="arrow"
        id="popper-arrow"
        ref={(element) => {
          setArrowElement(element);
        }}
      />
      <StyledCard color={color} className="text-center">
        <Card.Body>
          <Card.Title>
            {" "}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ width: "90%" }}>{title}</div>
              <StyledTimes onClick={close} />
            </div>
          </Card.Title>
          {children}
        </Card.Body>
      </StyledCard>
    </StyledPopper>
  );
}
