import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, Form, Alert } from "react-bootstrap";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { FaExclamationTriangle, FaEnvelopeOpenText } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
import type { ReCAPTCHA as ReCAPTCHAType } from "react-google-recaptcha";
import { GET_CHANGE_PASSWORD_TOKEN } from "../graphql-client/queries/account";
import { textInputChange } from "../components/utils";
import Loader from "../components/Loader";

const StyledButtonText = styled.button`
  background: none;
  border: none;
  padding: none;
`;

const CustomEnvelopeIcon = styled(FaEnvelopeOpenText)`
  margin: 0 30px 10px 10px;
  border: 1px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
`;

const ChangePasswordRequest = () => {
  const [email, setEmail] = React.useState("");
  const [captcha, setCaptcha] = React.useState<string | null>(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [getChangePasswordToken] = useMutation(GET_CHANGE_PASSWORD_TOKEN);
  const [loading, setLoading] = React.useState(false);
  const [showSuccessScreen, setShowSuccessScreen] = React.useState(false);
  const captchaRef = React.useRef<ReCAPTCHAType | null>(null);
  const [oldVersionMessage, setOldVersionMessage] = React.useState(false);

  const handleSubmit = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!captcha) {
      setErrorMessage("Por favor, verifica el captcha");
    } else {
      setErrorMessage("");
      setLoading(true);
      getChangePasswordToken({ variables: { captchaKey: captcha, email } })
        .then(() => {
          setLoading(false);
          setShowSuccessScreen(true);
        })
        .catch((e) => {
          setLoading(false);
          captchaRef.current?.reset();
          const accountError =
            e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail
              ?.accountError;
          if (accountError) {
            const {
              userDoesNotExist,
              invalidCaptcha,
              oldVersionAccount,
            } = accountError;
            if (userDoesNotExist)
              setErrorMessage(
                "La cuenta no existe. Regístrate para continuar."
              );
            if (invalidCaptcha)
              setErrorMessage("El captcha expiró, vuélvelo a llenar");
            if (oldVersionAccount) setOldVersionMessage(true);
          } else {
            console.log("Unexpected error");
            console.log(e);
          }
        });
    }
  };

  if (showSuccessScreen) {
    return (
      <Alert variant="info" style={{ width: "80%" }}>
        <Alert.Heading className="text-center">Revisa tu correo</Alert.Heading>
        <hr />
        <div className="d-flex flex-row">
          <CustomEnvelopeIcon size={80} />
          <h5>
            {" "}
            Haz click en el enlace de cambio de contraseña que acabamos de
            enviar a <b>{email}</b>
            <br />
            <b>Nota: </b> A veces, el correo tarda un par de minutos en llegar.
          </h5>
        </div>
      </Alert>
    );
  }
  return (
    <div style={{ width: "80%" }}>
      <Card className="text-center" style={{ background: "aliceblue" }}>
        <Card.Header> Cambiar contraseña </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group controlId="formBasicEmail" className="form-row">
              <Form.Label className="col-sm-3">Correo Electrónico</Form.Label>
              <Form.Control
                className="col-sm-9"
                type="email"
                placeholder="ejemplo@gmail.com"
                value={email}
                onChange={textInputChange(setEmail)}
              />
            </Form.Group>
            <StyledDiv>
              <ReCAPTCHA
                ref={captchaRef}
                onChange={setCaptcha}
                sitekey={
                  // TODO: Handle keys in a better way
                  process.env.NODE_ENV === "production"
                    ? "6LcR6dkZAAAAAJyV5Q-_8O8Ij2lcmPpVx_9iWVpJ"
                    : "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                }
              />
            </StyledDiv>
            {!loading && (
              <Button
                variant="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
              >
                Cambiar contraseña
              </Button>
            )}
            {loading && <Loader size={60} message="Procesando" />}{" "}
            {errorMessage !== "" && (
              <Alert variant="danger" style={{ marginTop: "20px" }}>
                {"  "}
                {errorMessage}
              </Alert>
            )}
            {oldVersionMessage && errorMessage === "" && (
              <Alert variant="warning" style={{ marginTop: "20px" }}>
                {" "}
                <h4>
                  <FaExclamationTriangle /> Tu cuenta está registrada en la{" "}
                  <a href="https://coordinate.si/login.php">
                    versión anterior de Coordinate
                  </a>{" "}
                </h4>
                Te recomendamos{" "}
                <Link to="/register">registrarte en la nueva versión</Link>{" "}
                porque <br />
                en Enero del 2022, se dejará de dar soporte a la anterior.{" "}
              </Alert>
            )}
          </Form>
        </Card.Body>
        <Card.Footer className="text-muted">
          ¿Ya tienes cuenta? Ingresa con ella
          <Link to="/signin">
            <StyledButtonText onClick={() => {}}>aquí</StyledButtonText>
          </Link>
          <br />
          ¿Aún no tienes cuenta? Créala
          <Link to="/register">
            <StyledButtonText onClick={() => {}}>aquí</StyledButtonText>
          </Link>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default ChangePasswordRequest;
