import { gql } from "@apollo/client";

export const GET_TRAFFIC = gql`
  query getTraffic($periodLength: Int!, $input: ProjectTrafficInput!) {
    asAdmin {
      getProjectTraffic(periodLength: $periodLength, input: $input) {
        user {
          id
          handle
          name
          lastNames
          email
        }
        project {
          id
          name
        }
        step
        type
        periodsSince
        frequency
      }
    }
  }
`;

export const GET_FEEDBACK = gql`
  query getProjectFeedback {
    asAdmin {
      getProjectFeedback(input: {}) {
        comment
        recommendable
        reusable
        learnable
        user {
          id
          handle
          name
          lastNames
          email
        }
        project {
          id
          name
        }
      }
    }
  }
`;
