import { useCookies } from "react-cookie";

export const SESSION_NAME = "sessionInfo";

const useSessionToken = () => {
  const [cookies, setCookie, removeCookie] = useCookies([SESSION_NAME]);
  const setSessionToken = (token: string) =>
    setCookie(SESSION_NAME, token, { path: "/" });
  const removeSessionToken = () => removeCookie(SESSION_NAME, { path: "/" });
  return [cookies[SESSION_NAME], setSessionToken, removeSessionToken];
};

export default useSessionToken;
