import React, { useState } from "react";
import { differenceInDays, format } from "date-fns";
import { es } from "date-fns/locale";
import styled from "styled-components";
import { Dropdown, Button, Card, Modal, Row, Container } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Reference, useMutation } from "@apollo/client";
import {
  FaExclamationTriangle,
  FaGlobeAmericas,
  FaTrash,
  FaSignOutAlt,
} from "react-icons/fa";
import { Tooltip } from "@material-ui/core";
import type { Project, WbsNode } from "../graphql-client/codegen-types";
import { Step, Access } from "../graphql-client/codegen-types";
import {
  FILTER_PROJECTS,
  DELETE_PROJECT,
  ABANDON_PROJECT,
} from "../graphql-client/queries/project";
import StopLight from "./StopLight";
import {
  parseStartDate,
  parseEndDate,
  getSupposedStatus,
} from "../project/components/gantt/utils";
import { InlineList, InlineListItem } from "./InlineList";
import client from "../graphql-client/connection";

const { cache } = client;

type Props = {
  project: Project;
};

const StyledToggle = styled(Dropdown.Toggle)`
  background: aliceblue;
  border: 1px solid steelblue;
  padding: 0 5px 0 5px;
  :after {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  background: lemonchiffon;
  border: yellow;
  :hover {
    background: khaki;
  }
`;

const StyledCardWrapper = styled.div`
  line-height: 2;
  .card {
    width: auto;
  }

  .badge {
    margin: 0 0 0 5px;
    vertical-align: top;
  }

  .btn {
    margin: 0 5px 0 5px;
  }
`;

const StyledTrash = styled(FaTrash)`
  color: red;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const StyledSignOut = styled(FaSignOutAlt)`
  color: coral;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  margin-top: 15px;
  width: 20px;
  height: 20px;
`;

const getInconsistentTask = (wbsRoot?: WbsNode): WbsNode | undefined => {
  const activities = (wbsRoot?.children || [])
    .map((deliv: WbsNode) => deliv.children || [])
    .flat();
  const ret = activities.find((act: WbsNode) => {
    const { status } = act;
    const startDate = act.startDate || act.plannedStart;
    const endDate = act.endDate || act.plannedEnd;
    if (!startDate || !endDate || !status) {
      return false;
    }
    const ganttNode = {
      start_date: parseStartDate(startDate),
      end_date: parseStartDate(endDate),
    };
    const supposed = getSupposedStatus(ganttNode, new Date());
    return supposed !== status;
  });
  return ret;
};

const TarjetaDeProyecto = (props: Props) => {
  const [deleteProject] = useMutation(DELETE_PROJECT);
  const [abandonProject] = useMutation(ABANDON_PROJECT);
  const [abandonModalOpen, setAbandonModalOpen] = React.useState(false);
  const [isRemoveModalOpen, setRemoveModal] = useState(false);
  const [onlyAdminError, setOnlyAdminError] = useState(false);
  const history = useHistory();
  const showRemoveModal = () => setRemoveModal(true);
  const hideRemoveModal = () => setRemoveModal(false);
  const { project } = props;
  const { id, name, wbsRoot, startEstimate, endEstimate, access } = project;

  // TODO: Agregar fechas y el status
  const myFormat = (dateStr: string) =>
    dateStr
      ? format(parseStartDate(dateStr), "d/MMM/Y", { locale: es })
      : "Por definir";
  const removeFromProjectList = () => {
    const projectListQuery = client.readQuery({
      query: FILTER_PROJECTS,
      variables: { textFilters: [] },
    });
    if (projectListQuery) {
      const { asUser } = projectListQuery;
      cache.modify({
        id: cache.identify(asUser),
        fields: {
          getProjects: (projectRefs, { readField }) =>
            projectRefs.filter(
              (projectRef: Reference) => id !== readField("id", projectRef)
            ),
        },
      });
    }
  };
  const onProjectDelete = () => {
    deleteProject({
      variables: { projectId: id, step: Step.Que },
      update: removeFromProjectList,
    }).catch((e: Error) => {
      console.log(e.message);
    });
    hideRemoveModal();
  };
  const onProjectAbandon = () => {
    abandonProject({ variables: { projectId: id } })
      .then(removeFromProjectList)
      .catch((e) => {
        const atLeastOneAdminMember =
          e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail
            ?.projectError?.memberError?.atLeastOneAdminMember;
        if (atLeastOneAdminMember) setOnlyAdminError(true);
        else console.log(e.message);
      });
    setAbandonModalOpen(false);
  };

  const onlyAdminModal = (
    <Modal show={onlyAdminError} onHide={() => setOnlyAdminError(false)}>
      <Modal.Header closeButton>
        <Modal.Title>No puedes abandonar el proyecto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Cada proyecto debe tener al menos un dueño. Eres el último miembro del
        proyecto con derechos de dueño. Agrega otro dueño, o elimina el
        proyecto.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setOnlyAdminError(false)}>
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const abandonModal = (
    <Modal show={abandonModalOpen} onHide={() => setAbandonModalOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>¿Abandonar {name}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Ya no tendrás acceso a él, y ya no aparecerá en tu portafolio. Tendrán
        que volver a invitarte para que puedas volver.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setAbandonModalOpen(false)}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={onProjectAbandon}>
          Abandonar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const removeModal = (
    <Modal show={isRemoveModalOpen} onHide={hideRemoveModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          ¿Eliminar
          {name}?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Al eliminarlo, se borrará toda la información que escribió en él, y será
        imposible recuperarla.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideRemoveModal}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={onProjectDelete}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const getTagArea = () => {
    if (!project.tags?.length) return null;
    return (
      <InlineList>
        {project.tags?.map((tag) => (
          <InlineListItem key={tag.id}>
            {" "}
            <span style={{ fontSize: "15px" }}>{tag.name}</span>{" "}
          </InlineListItem>
        ))}
      </InlineList>
    );
  };

  const getMaybeStopLight = () => {
    if (!project.wbsRoot) return null;
    const { plannedStart, plannedEnd, endDate } = project.wbsRoot;
    if (!plannedStart || !plannedEnd || !endDate) return null;
    const parsedPlannedStart = parseStartDate(plannedStart);
    const parsedPlannedEnd = parseEndDate(plannedEnd);
    const parsedEndDate = parseEndDate(endDate);
    const duration = differenceInDays(parsedPlannedEnd, parsedPlannedStart);
    const delay = differenceInDays(parsedEndDate, parsedPlannedEnd);
    return <StopLight projectDuration={duration} projectDelay={delay} />;
  };

  const getMaybeInconsistentTaskArea = () => {
    const inconsistentTask = getInconsistentTask(wbsRoot || undefined);
    if (!inconsistentTask) return null;
    const link = `/projects/${project.id}/comova/${inconsistentTask.id}`;
    const onClick = () => history.push(link);
    return (
      <StyledButton
        size="sm"
        variant="warning"
        onClick={onClick}
        style={{ padding: "2px 10px 2px 10px" }}
      >
        <span style={{ fontSize: "13px" }}>
          <FaExclamationTriangle /> Falta actualizar
        </span>
      </StyledButton>
    );
  };

  const getDateArea = () => {
    if (wbsRoot?.plannedEnd && wbsRoot?.endDate)
      return (
        <span
          className="text-muted"
          title="Más detalle en el paso 5. ¿Cómo va?"
        >
          Terminación planeada <b> {myFormat(wbsRoot?.plannedEnd)}</b>,
          terminación real <b>{myFormat(wbsRoot?.endDate)}</b>
        </span>
      );
    if (startEstimate && endEstimate)
      return (
        <span className="text-muted">
          Fechas estimadas:
          <b> {myFormat(startEstimate)}</b> - <b>{myFormat(endEstimate)}</b>
        </span>
      );
    return <span className="text-muted">Fechas aún no definidas.</span>;
  };
  return (
    <StyledCardWrapper>
      {abandonModal}
      {onlyAdminModal}
      {removeModal}
      <div
        style={{
          margin: "4px 0 4px 0",
          border: "1px solid gainsboro",
          borderRadius: "5px",
          background: "aliceblue",
        }}
      >
        <Card.Body>
          <Container fluid>
            <Row>
              <div style={{ width: "90%" }}>
                <Card.Title
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <Link
                    to={`/projects/${id}/que`}
                    style={{ color: "#0086ba", marginRight: "4px" }}
                  >
                    <FaGlobeAmericas />{" "}
                    {name === "" ? (
                      <span style={{ color: "coral" }}>
                        [Proyecto Sin Nombre]
                      </span>
                    ) : (
                      name
                    )}
                  </Link>
                  {getTagArea()}
                </Card.Title>
                <Card.Subtitle style={{ fontSize: "13px", lineHeight: 2 }}>
                  {getDateArea()}
                  <br />
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Link
                      to={`/projects/${id}/comova`}
                      title="Click para más detalle"
                    >
                      {getMaybeStopLight()}
                    </Link>
                    {getMaybeInconsistentTaskArea()}
                  </div>
                </Card.Subtitle>
              </div>
              <div
                style={{
                  width: "10%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {access === Access.Admin && (
                  <Tooltip title="Eliminar" arrow>
                    <span style={{ textAlign: "center" }}>
                      {" "}
                      <StyledTrash onClick={showRemoveModal} />{" "}
                    </span>
                  </Tooltip>
                )}
                <Tooltip title="Abandonar" arrow>
                  <span style={{ textAlign: "center" }}>
                    <StyledSignOut onClick={() => setAbandonModalOpen(true)} />
                  </span>
                </Tooltip>
              </div>
            </Row>
          </Container>
        </Card.Body>
      </div>
    </StyledCardWrapper>
  );
};

export default TarjetaDeProyecto;
