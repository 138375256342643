import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Card, Form } from "react-bootstrap";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import isValidEmail from "is-valid-email";
import ReCAPTCHA from "react-google-recaptcha";
import type { ReCAPTCHA as ReCAPTCHAType } from "react-google-recaptcha";
import { FaExclamationTriangle } from "react-icons/fa";
import useSessionToken from "../graphql-client/session";
import { SIGN_IN } from "../graphql-client/queries/account";
import Loader from "../components/Loader";

const StyledButtonText = styled.button`
  background: none;
  border: none;
  padding: none;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
`;

type Props = {
  message?: string;
};

const Login = ({ message }: Props) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [login] = useMutation(SIGN_IN);
  const [, setSessionToken] = useSessionToken();
  const [captcha, setCaptcha] = React.useState<string | null>(null);
  const captchaRef = React.useRef<ReCAPTCHAType | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(message || "");
  const [oldVersionMessage, setOldVersionMessage] = React.useState(false);

  const handleLogin = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!isValidEmail(email)) {
      setErrorMessage("Por favor, ingresa una cuenta de correo válida");
    } else if (password === "") {
      setErrorMessage("Por favor, ingresa la contraseña");
    } else if (!captcha) {
      setErrorMessage("Por favor, verifica el captcha");
    } else {
      setErrorMessage("");
      setLoading(true);
      login({ variables: { captchaKey: captcha, email, password } })
        .then(({ data }) => {
          setSessionToken(data.asHuman.login.sessionToken);
        })
        .catch((e) => {
          const accountError =
            e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail
              ?.accountError;
          setLoading(false);
          captchaRef.current?.reset();
          if (accountError) {
            const {
              userDoesNotExist,
              wrongPassword,
              invalidCaptcha,
              oldVersionAccount,
            } = accountError;
            if (userDoesNotExist)
              setErrorMessage(
                "La cuenta no existe. Regístrate para continuar."
              );
            if (wrongPassword) setErrorMessage("La contraseña es incorrecta");
            if (invalidCaptcha)
              setErrorMessage("El captcha expiró, vuélvelo a llenar");
            if (oldVersionAccount) setOldVersionMessage(true);
          } else {
            console.log("Unknown error");
            console.log(e);
          }
        });
    }
  };

  const passwordChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);

  const emailChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);

  return (
    <div style={{ width: "80%" }}>
      <Card className="text-center" style={{ background: "aliceblue" }}>
        <Card.Header> Inicia Sesión </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group controlId="formBasicEmail" className="form-row">
              <Form.Label className="col-sm-3">Correo Electrónico</Form.Label>
              <Form.Control
                className="col-sm-9"
                type="email"
                placeholder="ejemplo@gmail.com"
                value={email}
                onChange={emailChange}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword" className="form-row">
              <Form.Label className="col-sm-3">Contraseña</Form.Label>
              <Form.Control
                className="col-sm-9"
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={passwordChange}
              />
            </Form.Group>
            <StyledDiv>
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={
                  // TODO: Handle keys in a better way
                  process.env.NODE_ENV === "production"
                    ? "6LcR6dkZAAAAAJyV5Q-_8O8Ij2lcmPpVx_9iWVpJ"
                    : "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                }
                onChange={setCaptcha}
              />
            </StyledDiv>
            {!loading && (
              <Button
                variant="primary"
                type="submit"
                onClick={handleLogin}
                disabled={loading}
              >
                Iniciar Sesión
              </Button>
            )}
            {loading && <Loader size={60} message="Iniciando sesión" />}
            {errorMessage !== "" && (
              <Alert variant="danger" style={{ marginTop: "20px" }}>
                {"  "}
                {errorMessage}
              </Alert>
            )}
            {oldVersionMessage && errorMessage === "" && (
              <Alert variant="warning" style={{ marginTop: "20px" }}>
                {" "}
                <h4>
                  <FaExclamationTriangle /> Tu cuenta está registrada en la{" "}
                  <a href="https://coordinate.si/login.php">
                    versión anterior de Coordinate
                  </a>{" "}
                </h4>
                Te recomendamos{" "}
                <Link to="/register">registrarte en la nueva versión</Link>{" "}
                porque <br />
                en Enero del 2022, se dejará de dar soporte a la anterior.{" "}
              </Alert>
            )}
          </Form>
        </Card.Body>
        <Card.Footer className="text-muted">
          ¿No tienes cuenta? Créala
          <Link to="/register">
            <StyledButtonText onClick={() => {}}>aquí</StyledButtonText>
          </Link>
          <br />
          ¿Olvidaste tu contraseña? Recupérala
          <Link to="/change-password-request">
            <StyledButtonText onClick={() => {}}>aquí</StyledButtonText>
          </Link>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default Login;
