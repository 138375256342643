import React from "react";
import { Button, InputGroup, Col, Form } from "react-bootstrap";
import type { Project } from "../graphql-client/codegen-types";
import { textInputChange, blurOnEnter } from "./utils";

type Props = {
  label: string;
  setFilter: (
    filterFunction: { matchesFilter: (project: Project) => boolean } | null
  ) => void;
  getProjectText: (project: Project) => string | null | undefined;
  close: () => void;
};

const TextFilter = React.forwardRef(
  ({ label, setFilter, getProjectText, close }: Props, ref) => {
    const [searchText, setSearchText] = React.useState("");
    const onBlur = () =>
      setFilter({
        matchesFilter: (project: Project) => {
          const text = getProjectText(project);
          if (!text) return false;
          return text.toLowerCase().includes(searchText.toLowerCase());
        },
      });
    return (
      <Col>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="inputGroup-sizing-default">
              {label}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="text"
            placeholder="Frase a buscar"
            value={searchText}
            onChange={textInputChange(setSearchText)}
            onBlur={onBlur}
            onKeyDown={blurOnEnter}
            ref={ref as any}
          />
          <InputGroup.Append>
            <Button
              type="button"
              variant="outline-secondary"
              onClick={() => {
                setFilter(null);
                close();
              }}
            >
              &times;
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Col>
    );
  }
);

export default TextFilter;
