import React from "react";
import { Image, Text, View } from "@react-pdf/renderer";
import { completeFormat } from "../../../components/utils";

type Props = {
  name: string;
};

const PDFHeader = ({ name }: Props) => {
  return (
    <View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        <Image
          src="/coordinate-logo.png"
          style={{ opacity: 0.8, width: "20%" }}
        />
        <Text style={{ width: "65%", textAlign: "center", fontSize: "15px" }}>
          {" "}
          {name}{" "}
        </Text>
        <Text
          hyphenationCallback={(word) => [word]}
          style={{
            fontSize: "10px",
            color: "grey",
            width: "15%",
            textAlign: "right",
          }}
        >
          {completeFormat(new Date())}{" "}
        </Text>
      </View>
      <hr style={{ border: "1px solid #053d56" }} />
    </View>
  );
};

export default PDFHeader;
