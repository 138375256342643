import React from "react";
import { Reference, FetchResult } from "@apollo/client";
import styled from "styled-components";
import { Alert, Button, Collapse, Card } from "react-bootstrap";
import {
  FaExclamationTriangle,
  FaCaretUp,
  FaCaretDown,
  FaTasks,
} from "react-icons/fa";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Link } from "react-router-dom";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import {
  useProjectMutation,
  useProjectQuery,
} from "../components/project-context";
import DynamicList from "../components/DynamicList";
import {
  GET_WBS,
  EDIT_WBS_NODE,
  REORDER_WBS_NODE,
  CREATE_WBS_NODE,
  DELETE_WBS_NODE,
} from "../../graphql-client/queries/project";
import type {
  CreateWbsNodeMutation,
  Project,
  WbsNode,
} from "../../graphql-client/codegen-types";
import { Step, Access } from "../../graphql-client/codegen-types";
import EntradaTexto from "../../components/EntradaTexto";
import { Rhombus, DelivLabel, ActLabel } from "../../components/utils";
import Loader from "../../components/Loader";
import PDFReport from "./components/PDFReport";
import Explanation from "../../components/Explanation";
import client from "../../graphql-client/connection";
import getDocxReportPromise from "./components/docx-report";

type Props = {
  projectId: string;
};

function createNodeCallback({
  data,
}: FetchResult<CreateWbsNodeMutation>): WbsNode {
  const ret = data?.asUser?.editProject?.createWbsNode?.newWbsNode;
  if (!ret) throw new Error("Error creating Wbs Node");
  return ret;
}

const StyledCard = styled(Card)<{ isActive: boolean }>`
  display: flex;
  background: #fff1d8;
  border: none;
`;

const StyledDiv = styled.div`
  background: aliceblue;
  border-radius: 5px;
`;

type ActividadesProps = {
  parent: WbsNode;
  initialList: Array<WbsNode>;
  projectId: string | number;
  access: Access;
};

const Actividades = ({
  initialList,
  parent,
  projectId,
  access,
}: ActividadesProps) => {
  const { cache } = client;
  const createWbsNode = useProjectMutation<CreateWbsNodeMutation, unknown>(
    CREATE_WBS_NODE
  );
  const deleteWbsNode = useProjectMutation(DELETE_WBS_NODE);

  const editWbsNode = useProjectMutation(EDIT_WBS_NODE);
  const reorderWbsNode = useProjectMutation(REORDER_WBS_NODE);
  const [wbsErrorMessage, setWbsErrorMessage] = React.useState("");
  const [wbsErrorDetail, setWbsErrorDetail] = React.useState("");
  const readOnly = access === Access.Read;

  return (
    <StyledDiv>
      <DynamicList
        renderItem={(node: WbsNode, isNew: boolean) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              background: "lemonchiffon",
              borderRadius: "5px",
            }}
          >
            <FaTasks style={{ color: "#999900", margin: "15px 0 5px 5px" }} />
            <div style={{ margin: "5px", width: "100%" }}>
              <EntradaTexto
                ejemplo="Diseñar planos, Desarrollar aplicación móvil, Redactar reporte, etc."
                maxLetras={100}
                autoFocus={isNew}
                saveText={
                  readOnly
                    ? () => {}
                    : (text: string) => {
                        editWbsNode({
                          variables: {
                            projectId,
                            input: { id: node.id, text },
                          },
                          update() {
                            cache.modify({
                              id: cache.identify(node),
                              fields: {
                                text: () => text,
                              },
                            });
                          },
                        });
                      }
                }
                textoInicial={node.text ?? ""}
                rows={2}
                readOnly={readOnly}
              />
            </div>
          </div>
        )}
        getItemId={({ id }: WbsNode) => id}
        initial={initialList}
        newItem={
          readOnly
            ? undefined
            : () =>
                createWbsNode({
                  variables: { projectId, parentId: parent.id },
                })
                  .then(
                    ({ data }: FetchResult<CreateWbsNodeMutation>): WbsNode => {
                      const newWbsNode =
                        data?.asUser?.editProject?.createWbsNode?.newWbsNode;
                      if (!newWbsNode)
                        throw new Error("Error creating Wbs Node");
                      cache.modify({
                        id: cache.identify(parent),
                        fields: {
                          children: (oldChildren) =>
                            oldChildren.concat({
                              __ref: cache.identify(newWbsNode),
                            }),
                        },
                      });
                      return newWbsNode;
                    }
                  )
                  .catch((e) => {
                    const errorDetail =
                      e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail;
                    const projectError = errorDetail?.projectError;
                    if (projectError?.wbsChildLimitExceeded) {
                      setWbsErrorMessage(
                        `No más de ${projectError.wbsChildLimitExceeded} actividades por entregable`
                      );
                      setWbsErrorDetail(
                        "Poner demasiadas actividades afecta la organización del proyecto"
                      );
                      setTimeout(() => {
                        setWbsErrorMessage("");
                        setWbsErrorDetail("");
                      }, 7000);
                      return null;
                    }
                    throw e;
                  })
        }
        deleteInfo={
          readOnly
            ? undefined
            : {
                onDelete: ({ id }: WbsNode) => {
                  cache.modify({
                    id: cache.identify(parent),
                    fields: {
                      children: (childrenRefs, { readField }) =>
                        childrenRefs.filter(
                          (childRef: Reference) =>
                            id !== readField("id", childRef)
                        ),
                    },
                  });
                  return deleteWbsNode({ variables: { projectId, id } }).then(
                    () => true
                  );
                },
                deleteWarnTitle: "¿Eliminar actividad?",
                deleteWarnMsg:
                  "Se eliminará toda la información que escribió en ella. Esta acción es irreversible.",
              }
        }
        onReorder={
          readOnly
            ? undefined
            : (nodes: Array<WbsNode>) => {
                reorderWbsNode({
                  variables: {
                    projectId,
                    parentId: parent.id,
                    idList: nodes.map(({ id }: WbsNode) => id),
                  },
                  update() {
                    cache.modify({
                      id: cache.identify(parent),
                      fields: {
                        children: () =>
                          nodes.map((child) => ({
                            __ref: cache.identify(child),
                          })),
                      },
                    });
                  },
                });
              }
        }
      />
      {wbsErrorMessage !== "" && (
        <Alert variant="warning">
          <Explanation text={wbsErrorMessage} detail={wbsErrorDetail} />
        </Alert>
      )}
    </StyledDiv>
  );
};

const actStatement = (x?: number) => `${x} actividad${!x || x > 1 ? "es" : ""}`;

const EntregableItem = ({
  projectId,
  node,
  access,
}: {
  projectId: number | string;
  node: WbsNode;
  access: Access;
}) => {
  const [open, setOpen] = React.useState(true);
  return (
    <div style={{ marginBottom: "5px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          background: "#fff1d8",
          borderRadius: "5px",
          border: "1px solid coral",
        }}
      >
        <div style={{ margin: "5px", width: "100%" }}>
          <StyledCard>
            <div>
              <Rhombus style={{ margin: "0px 5px 0px 0px" }} />
              <b>{node.text}</b>
            </div>
            <div>
              Tiene <b>{actStatement(node.children?.length || 0)}</b>{" "}
              <Button size="sm" variant="light" onClick={() => setOpen(!open)}>
                {" "}
                {open ? (
                  <div>
                    Ocultar
                    <FaCaretUp />
                  </div>
                ) : (
                  <div>
                    Mostrar
                    <FaCaretDown />
                  </div>
                )}{" "}
              </Button>
            </div>
          </StyledCard>
        </div>
      </div>
      <Collapse in={open}>
        <div style={{ margin: "10px 0 0 50px" }}>
          <Actividades
            parent={node}
            initialList={node.children || []}
            projectId={projectId}
            access={access}
          />
        </div>
      </Collapse>
    </div>
  );
};

const InnerComo = ({ project }: { project: Project }) => {
  if (!project.wbsRoot?.children?.[0]) {
    return (
      <h4>
        Este proyecto no tiene ningún <DelivLabel /> <br />
        <br />
        Se necesita al menos uno para continuar <br />
        <br />
        Vuelve al paso anterior, y agrégalo(s)
      </h4>
    );
  }

  const { wbsRoot } = project;

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ width: "100%" }}>
        {(wbsRoot?.children ?? []).map((node: WbsNode) => (
          <EntregableItem
            key={node.id}
            projectId={project.id}
            node={node}
            access={project.access!}
          />
        ))}
      </div>
    </div>
  );
};

const Como = ({ projectId }: Props) => {
  const { loading, error, data } = useProjectQuery(GET_WBS, {
    variables: { id: projectId, step: Step.Como },
  });

  if (loading) return <Loader size={60} message="Cargando el proyecto" />;
  if (error) return <p> Error al cargar la WBS </p>;
  const project = data.asUser.getProject;

  const entregables = data.asUser.getProject.wbsRoot.children || [];
  const minEntregables = 1;
  if (entregables.length < minEntregables) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h4>
          {" "}
          <FaExclamationTriangle /> Falta agregar <DelivLabel plural />
        </h4>
        <br />
        <h5>
          {" "}
          El proyecto tiene {entregables.length}, pero{" "}
          <b>se necesitan al menos {minEntregables}</b>{" "}
        </h5>
        <br />
        <span>
          {" "}
          Regresa al paso anterior{" "}
          <Link to={`/projects/${projectId}/que`}>
            <i> 1. ¿Qué y por qué? </i>
          </Link>{" "}
          y agrégalos{" "}
        </span>
      </div>
    );
  }
  return (
    <div>
      <div>
        Para cada <DelivLabel />, lista qué <ActLabel plural /> hay que realizar
        para lograrlo.
      </div>
      <hr />
      <InnerComo project={project} />
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        <PDFDownloadLink
          document={<PDFReport project={project} />}
          fileName="Como.pdf"
        >
          <Button variant="info"> Imprimir reporte en PDF </Button>
        </PDFDownloadLink>
        <Button
          variant="info"
          onClick={() => {
            getDocxReportPromise(project)
              .then((docxReport) => Packer.toBlob(docxReport))
              .then((blob) => {
                saveAs(blob, "Como.docx");
              });
          }}
        >
          Exportar a Word (.docx)
        </Button>
      </div>
    </div>
  );
};

export default Como;
