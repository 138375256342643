import { gql } from "@apollo/client";

export const GET_TAGS = gql`
  query getTags {
    asUser {
      id
      getTags {
        id
        name
      }
    }
  }
`;

export const CREATE_TAG = gql`
  mutation createTag($name: String!) {
    asUser {
      createTag(name: $name) {
        message
        newTag {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_TAG = gql`
  mutation deleteTag($id: ID!) {
    asUser {
      deleteTag(id: $id) {
        message
      }
    }
  }
`;

export const EDIT_TAG = gql`
  mutation editTag($input: TagInput!) {
    asUser {
      editTag(input: $input) {
        message
      }
    }
  }
`;
