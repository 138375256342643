import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, Form, Alert } from "react-bootstrap";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { FaEnvelopeOpenText } from "react-icons/fa";
import isValidEmail from "is-valid-email";
import ReCAPTCHA from "react-google-recaptcha";
import type { ReCAPTCHA as ReCAPTCHAType } from "react-google-recaptcha";
import { REGISTER } from "../graphql-client/queries/account";
import { textInputChange } from "../components/utils";
import Loader from "../components/Loader";

const StyledButtonText = styled.button`
  background: none;
  border: none;
  padding: none;
`;

const CustomEnvelopeIcon = styled(FaEnvelopeOpenText)`
  margin: 0 30px 10px 10px;
  border: 1px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
`;

const Register = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConf, setPasswordConf] = React.useState("");
  const [showSuccessScreen, setShowSuccessScreen] = React.useState(false);
  const [captcha, setCaptcha] = React.useState<string | null>(null);
  const [register] = useMutation(REGISTER);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [checkedTerms, setCheckedTerms] = React.useState(false);
  const captchaRef = React.useRef<ReCAPTCHAType | null>(null);

  if (showSuccessScreen) {
    return (
      <Alert variant="info" style={{ width: "80%" }}>
        <Alert.Heading className="text-center">
          {" "}
          Ya casi está lista tu cuenta!{" "}
        </Alert.Heading>
        <hr />
        <div className="d-flex flex-row">
          <CustomEnvelopeIcon size={80} />
          <h5>
            {" "}
            Haz click en el enlace de confirmación que acabamos de enviar a{" "}
            <b>{email}</b>
            <br />
            <b>Nota: </b> A veces, el correo llega a la bandeja de promociones.
          </h5>
        </div>
      </Alert>
    );
  }
  const handleLogin = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!isValidEmail(email)) {
      setErrorMessage("Por favor, ingresa una cuenta de correo válida");
    } else if (password === "" && passwordConf === "") {
      setErrorMessage("Por favor, ingresa una contraseña");
    } else if (password !== passwordConf) {
      setErrorMessage("Las contraseñas no coinciden");
    } else if (!captcha) {
      setErrorMessage("Por favor, verifica el captcha");
    } else {
      setErrorMessage("");
      setLoading(true);
      register({ variables: { captchaKey: captcha, email, password } })
        .then(() => {
          setLoading(false);
          setShowSuccessScreen(true);
        })
        .catch((e) => {
          setLoading(false);
          captchaRef.current?.reset();
          const accountError =
            e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail
              ?.accountError;
          if (accountError) {
            const { userAlreadyExists, invalidCaptcha } = accountError;
            if (userAlreadyExists)
              setErrorMessage(
                "La cuenta de correo ya existe. Ingresa con ella o cambia la contraseña."
              );
            if (invalidCaptcha)
              setErrorMessage("El captcha expiró, vuélvelo a llenar");
          } else {
            console.log("Unknown error");
            console.log(e);
          }
        });
    }
  };

  return (
    <div style={{ width: "80%" }}>
      <Card className="text-center" style={{ background: "aliceblue" }}>
        <Card.Header> Crear Cuenta Nueva </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group controlId="formBasicEmail" className="form-row">
              <Form.Label className="col-sm-3">Correo Electrónico</Form.Label>
              <Form.Control
                className="col-sm-9"
                type="email"
                placeholder="ejemplo@gmail.com"
                value={email}
                onChange={textInputChange(setEmail)}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword" className="form-row">
              <Form.Label className="col-sm-3">Crea una contraseña</Form.Label>
              <Form.Control
                className="col-sm-9"
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={textInputChange(setPassword)}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword2" className="form-row">
              <Form.Label className="col-sm-3">
                Reescribe la contraseña
              </Form.Label>
              <Form.Control
                className="col-sm-9"
                type="password"
                placeholder="Contraseña"
                value={passwordConf}
                onChange={textInputChange(setPasswordConf)}
              />
            </Form.Group>
            <StyledDiv>
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={
                  // TODO: Handle keys in a better way
                  process.env.NODE_ENV === "production"
                    ? "6LcR6dkZAAAAAJyV5Q-_8O8Ij2lcmPpVx_9iWVpJ"
                    : "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                }
                onChange={setCaptcha}
              />
            </StyledDiv>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <Form.Check
                type="checkbox"
                checked={checkedTerms}
                onChange={() => setCheckedTerms(!checkedTerms)}
              />
              <span style={{ marginRight: "4px" }}> Acepto los </span>
              <Link to="/terms" target="_blank">
                términos y condiciones
              </Link>
            </div>
            {!loading && (
              <Button
                variant="primary"
                type="submit"
                onClick={handleLogin}
                disabled={loading || !checkedTerms}
              >
                Registrar
              </Button>
            )}
            {loading && <Loader size={60} message="Creando cuenta" />}
            {errorMessage !== "" && (
              <Alert variant="danger" style={{ marginTop: "20px" }}>
                {"  "}
                {errorMessage}
              </Alert>
            )}
          </Form>
        </Card.Body>
        <Card.Footer className="text-muted">
          ¿Ya tienes cuenta? Ingresa con ella
          <Link to="/signin">
            <StyledButtonText onClick={() => {}}>aquí</StyledButtonText>
          </Link>
          <br />
          ¿Olvidaste tu contraseña? Recupérala
          <Link to="/change-password-request">
            <StyledButtonText onClick={() => {}}>aquí</StyledButtonText>
          </Link>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default Register;
