import React from "react";
import { Card } from "react-bootstrap";
import { FaLock } from "react-icons/fa";
import styled from "styled-components";
import { differenceInDays, addDays } from "date-fns";
import type { GanttNode } from "./types";
import {
  readableFormat,
  ProjectLabel,
  DelivLabel,
  ActLabel,
} from "../../../components/utils";
import Explanation from "../../../components/Explanation";

const StyledExplanation = styled(Explanation)`
  background: #f5f5f5;
`;

const textOptions = [
  <p>
    {" "}
    es el <ProjectLabel /> entonces{" "}
  </p>,
  <p>
    {" "}
    es un <DelivLabel /> entonces{" "}
  </p>,
  <p>
    {" "}
    es una <ActLabel /> entonces{" "}
  </p>,
];

const detailOptions = [
  <div>
    El <ProjectLabel /> se considera iniciado <br />
    cuando inicia su primer <DelivLabel /> <br />
    y se considera terminado cuando <br />
    termina su último <DelivLabel />.
  </div>,
  <div>
    Un <DelivLabel /> se considera iniciado <br />
    cuando inicia su primera <ActLabel /> <br /> y se considera terminado cuando{" "}
    <br /> termina su última <ActLabel />.
  </div>,
  <div>
    Para editar esta <ActLabel />, contacta al <br />
    dueño del proyecto y pide que te dé derechos de <br />
    edición.
  </div>,
];

const GanttNodeDescription = ({ node }: { node: GanttNode }) => {
  const { start_date, end_date, level } = node;
  const startDate = start_date;
  const endDate = addDays(end_date, -1);
  const explanationText = (
    <p>
      <FaLock size={12} /> No es editable
    </p>
  );
  return (
    <div>
      <Card.Subtitle>
        {textOptions[level]}{" "}
        <StyledExplanation
          text={explanationText}
          detail={detailOptions[level]}
        />{" "}
      </Card.Subtitle>
      <p>
        Inicia <b>{startDate ? readableFormat(startDate) : ""}</b> <br />
        Termina <b>{endDate ? readableFormat(endDate) : ""}</b> <br />
        por lo tanto, dura <br />
        <b>
          {startDate && endDate ? differenceInDays(endDate, startDate) + 1 : 0}{" "}
          días laborales
        </b>
      </p>
    </div>
  );
};

export default GanttNodeDescription;
