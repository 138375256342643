import React from "react";
import { Text, View } from "@react-pdf/renderer";

type Props = {
  question: string;
};

function PDFQuestion({ question, children }: React.PropsWithChildren<Props>) {
  return (
    <View
      style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
    >
      <Text style={{ marginRight: "5px", marginBottom: "5px", color: "grey" }}>
        {question}
      </Text>
      {children}
    </View>
  );
}

export default PDFQuestion;
