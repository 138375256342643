// Zoom configuration for the gantt chart
// Relevant resource: https://docs.dhtmlx.com/gantt/desktop__zooming.html
import React from "react";
import { Col, Form } from "react-bootstrap";
import { gantt } from "dhtmlx-gantt";
import { format, addMonths, addDays } from "date-fns";
import { es } from "date-fns/locale";
import { FaSearchMinus, FaSearchPlus } from "react-icons/fa";
import styled from "styled-components";

const getYear = (date: Date) => format(date, "Y", { locale: es });
const getMonth = (date: Date) => format(date, "MMM", { locale: es });
const getMonthDay = (date: Date) => format(date, "d MMM", { locale: es });
const getWeekDay = (date: Date) => format(date, "E d", { locale: es });
const getBriefWeekDay = (date: Date) => format(date, "EEEEE d", { locale: es });
const getMonthAndYear = (date: Date) => format(date, "MMM Y", { locale: es });

// Object with list of zoom levels
// Relevant resource: https://docs.dhtmlx.com/gantt/desktop__zooming.html
export const ganttZoomConfig = {
  levels: [
    {
      name: "Días",
      scale_height: 50,
      min_column_width: 70,
      scales: [
        {
          unit: "day",
          step: 1,
          format: getWeekDay,
        },
        {
          unit: "week",
          format(startDate: Date) {
            const endDate = addDays(startDate, 6);
            return `${getMonthDay(startDate)} - ${getMonthDay(
              endDate
            )} ${getYear(startDate)}`;
          },
        },
      ],
    },
    {
      name: "Semanas",
      scale_height: 50,
      min_column_width: 35,
      scales: [
        {
          unit: "day",
          format: getBriefWeekDay,
        },
        {
          unit: "week",
          format(startDate: Date) {
            const endDate = addDays(startDate, 6);
            return `${getMonthDay(startDate)} - ${getMonthDay(
              endDate
            )} ${getYear(startDate)}`;
          },
        },
      ],
    },
    {
      name: "Meses",
      scale_height: 50,
      min_column_width: 140,
      scales: [
        {
          unit: "week",
          format(startDate: Date) {
            const endDate = addDays(startDate, 6);
            return `${getMonthDay(startDate)} - ${getMonthDay(endDate)}`;
          },
        },
        {
          unit: "month",
          format: getMonthAndYear,
        },
      ],
    },
    {
      name: "Trimestres",
      height: 50,
      min_column_width: 90,
      scales: [
        {
          unit: "month",
          step: 1,
          format: getMonthAndYear,
        },
        {
          unit: "quarter",
          step: 1,
          format(startDate: Date) {
            const endDate = addDays(addMonths(startDate, 3), -1);
            return `${getMonth(startDate)} - ${getMonth(endDate)} ${getYear(
              startDate
            )}`;
          },
        },
      ],
    },
    {
      name: "Años",
      scale_height: 50,
      min_column_width: 30,
      scales: [
        {
          unit: "year",
          step: 1,
          format: getYear,
        },
      ],
    },
  ],
};

// How many weeks (unit) are there between May 21st (from) and June 7th (to)?
const getUnitsBetween = (from: Date, to: Date, unit: string): number => {
  if (from.valueOf() < to.valueOf()) {
    const nextDate = gantt.date.add(from, 1, unit);
    return 1 + getUnitsBetween(nextDate, to, unit);
  }
  return 0;
};

// Long projects take a lot of width in the gantt chart, and short projects don't.
// This function's purpose is to find the zoom level that best fits the specified project.
// We consider the "best level" as the largest level, such that it's completely
// visible within the visible space (ganttAreaWidth). We also add a little slack,
// to make sure we don't choose a level that barely fits the project.
export const getBestZoomLevel = (
  projectStart: Date,
  projectEnd: Date,
  ganttAreaWidth: number
) => {
  const zoomLevels = ganttZoomConfig.levels;

  const bestFit = zoomLevels.findIndex((config) => {
    const columnCount = getUnitsBetween(
      projectStart,
      projectEnd,
      config.scales[0].unit
    );

    const slack = 0.6;
    const contentWidth = (columnCount + slack) * config.min_column_width;
    return contentWidth <= ganttAreaWidth;
  });

  // If bestFit is -1 (no index found), then the project is gigantic and all levels are
  // too small for it. In that case, use the biggest level.
  return bestFit === -1 ? zoomLevels.length - 1 : bestFit;
};

type PaneProps = {
  zoom: number;
  setZoom: (newZoom: number) => void;
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 7px;
  padding: 5px;
`;

const StyledSpan = styled.span<{ isLeft: boolean }>`
  color: steelblue;
  cursor: pointer;
  margin: ${(props) => (props.isLeft ? "0 7px 0 0" : "0")};
  :hover {
    opacity: 0.5;
  }
`;

export const GanttZoomPane = ({ zoom, setZoom }: PaneProps) => (
  <Form style={{ margin: "5px" }}>
    <Form.Row>
      <Col md="auto">
        {" "}
        <StyledDiv>
          <StyledSpan isLeft>
            <FaSearchMinus
              style={{ width: "20px", height: "20px" }}
              onClick={() => {
                if (zoom !== null && zoom + 1 < ganttZoomConfig.levels.length)
                  setZoom(zoom + 1);
              }}
            />
          </StyledSpan>
          <Form.Control
            as="select"
            className="mr-sm-2"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (zoom !== null) setZoom(parseInt(event.target.value, 10));
            }}
            custom
            value={zoom == null ? "0" : zoom.toString()}
            style={{ margin: "0" }}
          >
            {ganttZoomConfig.levels.map(({ name }, idx) => (
              <option key={name} value={idx}>
                {name}
              </option>
            ))}
          </Form.Control>
          <StyledSpan isLeft={false}>
            <FaSearchPlus
              style={{
                width: "20px",
                height: "20px",
              }}
              onClick={() => {
                if (zoom !== null && zoom > 0) setZoom(zoom - 1);
              }}
            />
          </StyledSpan>
        </StyledDiv>
      </Col>
    </Form.Row>
  </Form>
);
