import { Link, useParams } from "react-router-dom";
import React from "react";
import { useMutation } from "@apollo/client";
import { Card, Alert, Form, Button } from "react-bootstrap";
import styled from "styled-components";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { CHANGE_PASSWORD } from "../graphql-client/queries/account";
import Loader from "../components/Loader";
import { textInputChange } from "../components/utils";

const StyledButtonText = styled.button`
  background: none;
  border: none;
  padding: none;
`;

const CustomEnvelopeIcon = styled(FaEnvelopeOpenText)`
  margin: 0 30px 10px 10px;
  border: 1px;
`;

const ChangePassword = () => {
  const [changePassword] = useMutation(CHANGE_PASSWORD);
  const params = useParams<{ token: string }>();
  const { token } = params;
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showSuccessScreen, setShowSuccessScreen] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [passwordConf, setPasswordConf] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const submit = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (password === "" && passwordConf === "") {
      setErrorMessage("Por favor, ingresa una contraseña");
    } else if (password !== passwordConf) {
      setErrorMessage("Las contraseñas no coinciden");
    } else {
      setErrorMessage("");
      setLoading(true);
      changePassword({ variables: { token, newPassword: password } })
        .then(() => {
          setLoading(false);
          setShowSuccessScreen(true);
        })
        .catch((e) => {
          const accountError =
            e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail
              ?.accountError;
          setLoading(false);
          if (accountError) {
            const { expiredToken, invalidToken } = accountError;
            if (expiredToken)
              setErrorMessage(
                "El enlace ha expirado, vuelve a solicitar el correo de confirmación"
              );
            if (invalidToken)
              setErrorMessage(
                "El enlace es inválido o ha expirado. Asegura que lo recibiste de una cuenta autorizada de Coordinate"
              );
          } else {
            console.log("Unexpected error");
            console.log(e);
          }
        });
    }
  };

  if (showSuccessScreen) {
    return (
      <Alert variant="info" style={{ width: "80%" }}>
        <Alert.Heading className="text-center"> ¡Listo!</Alert.Heading>
        <hr />
        <div className="d-flex flex-row">
          <CustomEnvelopeIcon size={80} />
          <h5>
            {" "}
            Se ha cambiado tu contraseña
            <br />
            <Link to="/signin">Ingresar a mi cuenta</Link>
          </h5>
        </div>
      </Alert>
    );
  }

  return (
    <div style={{ width: "80%" }}>
      <Card className="text-center" style={{ background: "aliceblue" }}>
        <Card.Header>Cambio de contraseña</Card.Header>
        <Card.Body>
          <Form>
            <Form.Group controlId="formBasicPassword" className="form-row">
              <Form.Label className="col-sm-3">Crea una contraseña</Form.Label>
              <Form.Control
                className="col-sm-9"
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={textInputChange(setPassword)}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword2" className="form-row">
              <Form.Label className="col-sm-3">
                Reescribe la contraseña
              </Form.Label>
              <Form.Control
                className="col-sm-9"
                type="password"
                placeholder="Contraseña"
                value={passwordConf}
                onChange={textInputChange(setPasswordConf)}
              />
            </Form.Group>
            {!loading && (
              <Button variant="primary" type="submit" onClick={submit}>
                Aceptar
              </Button>
            )}
            {loading && <Loader size={60} message="Procesando tus datos" />}
            {errorMessage !== "" && (
              <Alert variant="danger" style={{ marginTop: "20px" }}>
                {"  "}
                {errorMessage}
              </Alert>
            )}
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ChangePassword;
