import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Switch, useParams, Route } from "react-router-dom";
import styled from "styled-components";
import { ProjectProvider } from "./components/project-context";
import ProjectNavbar from "./components/ProjectNavbar";
import ProjectFooter from "./components/ProjectFooter";
import steps from "./components/constants";

const StyledDiv = styled.div`
  background: aliceblue;
  padding: 4px;
  border-radius: 7px;
  margin: 5px;
  border: 1px solid #cccc00;
`;

const ProjectPage = () => {
  const { projectId, stepPath } = useParams<{
    projectId: string;
    stepPath: string;
  }>();

  const redirRoute = `/projects/${projectId}`;
  const idx = steps.findIndex(({ path }) => path === stepPath);
  const step = steps[idx];

  return (
    <ProjectProvider>
      <ProjectNavbar />
      <div style={{ marginTop: "49px" }}>
        <Switch>
          <Route
            path="/projects/:projectId/:stepPath/:openTaskId?"
            render={() =>
              step ? (
                <Container>
                  <Row>
                    <Col xs={12} md={{ span: 10, offset: 1 }} lg={10}>
                      <Card
                        className="text-center"
                        style={{ background: "aliceblue", width: "100%" }}
                      >
                        <Card.Body>
                          <step.Component
                            projectId={projectId}
                            articleLink={step.articleLink}
                          />
                        </Card.Body>
                        <Card.Footer>
                          <ProjectFooter />
                        </Card.Footer>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              ) : (
                <div />
              )
            }
          />
        </Switch>
      </div>
    </ProjectProvider>
  );
};

export default ProjectPage;
