import React from "react";
import Xarrow from "react-xarrows";

const FeedbackMessage = ({ showArrow = true }: { showArrow?: boolean }) => {
  const ref = React.createRef<HTMLElement>();
  return (
    <div>
      <span ref={ref} style={{ padding: "0 10px 0 10px" }}>
        <b>Cualquier cosa</b>, sólo mándanos un <b>Whatsapp</b>
      </span>
      <hr />
      <ul className="text-muted" style={{ textAlign: "left" }}>
        <li> Para hacernos una pregunta </li>
        <li> Sugerir cómo mejorar el servicio </li>
        <li> Reportar un error </li>
        <li> O simplemente darnos tu opinión </li>
      </ul>
      ¡Te respondemos en <b>menos de una hora</b>!
      {showArrow && (
        <Xarrow
          start={ref || null}
          end="whatsapp-global-button"
          color="black"
          zIndex={100}
        />
      )}
    </div>
  );
};

export default FeedbackMessage;
