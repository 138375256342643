import React from "react";
import { User } from "../graphql-client/codegen-types";

const UserContext = React.createContext<User | undefined>(undefined);

export const UserContextProvider = ({
  user,
  children,
}: React.PropsWithChildren<{ user: User }>) => (
  <UserContext.Provider value={user}>{children}</UserContext.Provider>
);

export const useUserContext = () => React.useContext(UserContext);
