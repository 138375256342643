import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { onError, ErrorResponse } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { RetryLink } from "@apollo/client/link/retry";
import { SESSION_NAME } from "./session";
import cookies from "../cookies";

const uri =
  process.env.NODE_ENV === "production"
    ? "https://4siz52b7we.execute-api.us-east-2.amazonaws.com/dev/graphql"
    : "http://localhost:4000/graphql";

const httpLink = createUploadLink({
  uri,
});

// Default values taken from apollo docs
// https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

// El error de ECONNRESET es comun en sql, pero el backend lo manda como
// error de graphQL, tenemos que convertirlo a error de network para que
// el retryLink lo reintente.
// Fuente: https://github.com/apollographql/apollo-link/issues/541
const retriableGraphQLErrorsLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    if (data?.errors?.[0]?.message?.includes("ECONNRESET")) {
      throw new Error("GraphQL Operational Error");
    }
    return data;
  });
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const authToken = cookies.get(SESSION_NAME);
  if (authToken)
    operation.setContext({
      headers: {
        authorization: authToken,
      },
    });
  return forward(operation);
});

const errorCallback = ({
  graphQLErrors,
  networkError,
  operation,
  forward,
}: ErrorResponse) => {
  const applicationErrorDetail =
    graphQLErrors?.[0]?.extensions?.applicationErrorDetail;
  if (
    applicationErrorDetail?.accountError?.signOutOnError ||
    applicationErrorDetail?.accountError?.banned
  ) {
    cookies.remove(SESSION_NAME, { path: "/" });
  }
};

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network" as const,
    nextFetchPolicy: "cache-first" as const,
    errorPolicy: "all" as const,
  },
  query: {
    errorPolicy: "all" as const,
  },
};

const cache = new InMemoryCache({
  typePolicies: {
    Link: {
      keyFields: false,
    },
    AuthenticatedUserQuery: {
      fields: {
        getProject: {
          keyArgs: ["id"],
        },
      },
    },
  },
});

const client = new ApolloClient({
  link: authMiddleware
    .concat(retryLink)
    .concat(retriableGraphQLErrorsLink)
    .concat(onError(errorCallback))
    .concat(httpLink),
  cache,
  defaultOptions,
  connectToDevTools: true,
});

export default client;
