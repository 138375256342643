import React from "react";
import {
  getYear,
  getMonth,
  getDay,
  isFirstDayOfMonth,
  isSameDay,
  getDate,
  addDays,
} from "date-fns";
import { useQuery } from "@apollo/client";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { GET_DUTIES_BY_DATE } from "../graphql-client/queries/project";
import { WbsNode } from "../graphql-client/codegen-types";
import {
  parseStartDate,
  formatStartDate,
} from "../project/components/gantt/utils";
import Loader from "./Loader";
import Reminder from "./Reminder";

const oneSeventh = `${100 / 7}%`;

const StyledCell = styled.div<{ inMonth: boolean }>`
  display: flex;
  flex-direction: column;
  border: 1px solid lightgrey;
  width: ${oneSeventh};
  min-height: 100%;
  padding: 4px;
  background: ${(props) => (props.inMonth ? "white" : "#E8E9EB")};
`;

const StyledSpan = styled.span<{ today: boolean }>`
  border-radius: ${(props) => (props.today ? "15px" : "0")};
  padding: ${(props) => (props.today ? "2px 5px 2px 5px" : "0")};
  background: ${(props) => (props.today ? "red" : "none")};
  color: ${(props) => (props.today ? "white" : "black")};
  margin: 2px;
`;

const meses = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const diasSemana = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];

const CalendarHeader = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 600px)" });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        border: "2px solid silver",
        padding: "0",
      }}
    >
      {diasSemana.map((label: string, idx: number) => (
        <span
          key={label}
          style={{
            textAlign: "right",
            border: "1px solid silver",
            padding: "4px",
            width: oneSeventh,
          }}
        >
          {" "}
          {isTabletOrMobile ? `${label.substring(0, 3)}.` : label}{" "}
        </span>
      ))}
    </div>
  );
};

const Calendar = ({
  firstDay,
  month,
  rows,
}: {
  firstDay: Date;
  month: number;
  rows: number;
}) => {
  const from = formatStartDate(addDays(firstDay, -1));
  const lastDay = addDays(firstDay, rows * 7);
  const to = formatStartDate(addDays(lastDay, 1));
  const { data, loading, error } = useQuery(GET_DUTIES_BY_DATE, {
    variables: { from, to },
  });

  if (loading && !data)
    return (
      <Loader size={60} message={`Cargando pendientes de ${meses[month]}...`} />
    );
  if (error) console.log("Unexpected error", error);
  const wbsNodes = data.asUser.getDutiesByDate;
  const Cell = ({ date }: { date: Date }) => {
    const dayDuties = wbsNodes.filter((wbsNode: WbsNode) => {
      const endDate = wbsNode.endDate || wbsNode.plannedEnd;
      return endDate && isSameDay(date, parseStartDate(endDate));
    });
    const today = isSameDay(date, new Date());
    return (
      <StyledCell inMonth={getMonth(date) === month}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            color: "#053d56",
            fontWeight: today ? "bold" : "normal",
          }}
        >
          {" "}
          <StyledSpan today={today}> {getDate(date)} </StyledSpan>{" "}
        </div>
        {dayDuties.map((wbsNode: WbsNode) => (
          <Reminder key={wbsNode.id} wbsNode={wbsNode} />
        ))}
      </StyledCell>
    );
  };
  const Row = ({ weekStart }: { weekStart: Date }) => (
    <div style={{ display: "flex", flexDirection: "row", minHeight: "100px" }}>
      {Array.from(Array(7)).map((_, idx: number) => {
        const date = addDays(weekStart, idx);
        return <Cell key={date.getTime()} date={date} />;
      })}
    </div>
  );

  const grid = Array.from(Array(rows)).map((_, idx: number) => (
    <Row key={idx.toString()} weekStart={addDays(firstDay, idx * 7)} />
  ));
  return (
    <div>
      <CalendarHeader />
      {grid}
    </div>
  );
};

const getFirstDayOfMonth = (date: Date): Date => {
  if (isFirstDayOfMonth(date)) return date;
  return getFirstDayOfMonth(addDays(date, -1));
};

const getLastMonday = (date: Date): Date => {
  if (getDay(date) === 1) return date;
  return getLastMonday(addDays(date, -1));
};

const StyledFaAngleLeft = styled(FaAngleLeft)`
  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const StyledFaAngleRight = styled(FaAngleRight)`
  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

type Props = {
  month: number;
  year: number;
};

const TaskCalendar = ({ month, year }: Props) => {
  const firstDayOfMonth = new Date(year, month, 1);
  const history = useHistory();
  const onLeftClick = () => {
    if (month === 0) history.push(`/calendar/11/${year - 1}`);
    else history.push(`/calendar/${month - 1}/${year}`);
  };
  const onRightClick = () => {
    if (month === 11) history.push(`/calendar/0/${year + 1}`);
    else history.push(`/calendar/${month + 1}/${year}`);
  };
  return (
    <div>
      <h3
        style={{
          marginBottom: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
        }}
      >
        {" "}
        <StyledFaAngleLeft onClick={onLeftClick} />{" "}
        {meses[getMonth(firstDayOfMonth)]} del {getYear(firstDayOfMonth)}
        <StyledFaAngleRight onClick={onRightClick} />{" "}
      </h3>
      <Calendar
        firstDay={getLastMonday(firstDayOfMonth)}
        month={getMonth(firstDayOfMonth)}
        rows={5}
      />
    </div>
  );
};

export default TaskCalendar;
