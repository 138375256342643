import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { ApolloProvider, useQuery } from "@apollo/client";
import Header from "./components/Header";
import ProjectList from "./ProjectList";
import LoginPage from "./account/LoginPage";
import client from "./graphql-client/connection";
import useSession from "./graphql-client/session";
import ProjectPage from "./project/ProjectPage";
import Home from "./Home";
import Profile from "./Profile";
import EditProfile from "./EditProfile";
import Admin from "./admin/Admin";
import { UserContextProvider } from "./components/user-context";
import { GET_MY_USER_INFO } from "./graphql-client/queries/account";
import ConfirmMemberInvitation from "./ConfirmMemberInvitation";
import ManageTags from "./components/ManageTags";
import WhatsappButton from "./components/WhatsappButton";
import CalendarPage from "./CalendarPage";
import { ProModalWrapper, RevalidateEmail } from "./plan";

const GlobalBodyStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    font-family: sans-serif;
    color: #053d56;
  }

  body {
    margin: 0;
    padding: 60px 0 0 0;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const StyledContentWrapper = styled.div`
  flex: 1 0 auto;
  padding: 10px;
`;

const InternalRouter = () => {
  const [session, ,] = useSession();
  const { data, loading, error } = useQuery(GET_MY_USER_INFO, {
    fetchPolicy: "cache-first",
  });
  const userInfo = data?.asUser?.getUser;

  if (error) console.log("Error al cargar la informacion de perfil");

  return (
    <Router>
      <UserContextProvider user={userInfo}>
        <ProModalWrapper>
          <Header />
          <StyledContentWrapper>
            <Switch>
              <Route
                path="/projects/:projectId/:stepPath"
                component={ProjectPage}
              />
              <Route path="/admin" component={Admin} />
              <Route path="/home" component={Home} />
              <Route path="/projects" component={ProjectList} />
              <Route path="/tags" component={ManageTags} />
              <Route path="/profile/:userId" component={Profile} />
              <Route path="/edit-profile" render={() => <EditProfile />} />
              <Route path="/revalidate/:token" component={RevalidateEmail} />
              <Route
                path="/confirm-member-invitation/:inviterHandle/:projectId/:projectName/:memberId"
                component={ConfirmMemberInvitation}
              />
              <Route path="/calendar/:month?/:year?" component={CalendarPage} />
              <Redirect from="/" to="/home" />
            </Switch>
          </StyledContentWrapper>
        </ProModalWrapper>
      </UserContextProvider>
      <WhatsappButton />
    </Router>
  );
};

const App = () => {
  const [sessionToken, ,] = useSession();

  if (false) {
    return (
      <Router>
        <GlobalBodyStyle />
        <Header />
        <ApolloProvider client={client}>
          <br />
          <h1 style={{ textAlign: "center" }}>
            {" "}
            <b> ¡Bloqueado! </b>{" "}
          </h1>
          <hr />
          <h3 style={{ textAlign: "center" }}>
            {" "}
            Tu cuenta ha sido <b>bloqueada</b> por violar las políticas de uso
            de <b>Coordinate</b>.{" "}
          </h3>
          <br />
          <img src="/ban-policeman.png" alt="Policía señalando alto" />
        </ApolloProvider>
      </Router>
    );
  }

  if (!sessionToken) {
    return (
      <ApolloProvider client={client}>
        <LoginPage />
        <WhatsappButton />
      </ApolloProvider>
    );
  }

  return (
    <>
      <GlobalBodyStyle />
      <ApolloProvider client={client}>
        <InternalRouter />
      </ApolloProvider>
    </>
  );
};

export default App;
