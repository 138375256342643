import { HeadingLevel, Paragraph, TextRun } from "docx";
import type { Member, Project } from "../../../graphql-client/codegen-types";
import { completeFormat } from "../../../components/utils";
import {
  parseMaybeStartDate,
  differenceInWorkDays,
} from "../../components/gantt/utils";
import getDocxTemplatePromise from "../../components/docx/docx-template";

const getMemberText = (member: Member) => {
  if (member.user) {
    const { name, lastNames, handle } = member.user;
    if (name?.length) return `${name} ${lastNames || ""}`;
    return handle;
  }
  if (member.name?.length) return member.name;
  return "Usuario sin nombre";
};

const getDocxReportPromise = (project: Project) => {
  const parsedStart = parseMaybeStartDate(project.startEstimate);
  const parsedEnd = parseMaybeStartDate(project.endEstimate);
  const durationEstimate =
    project.workDays && parsedStart && parsedEnd
      ? differenceInWorkDays(project.workDays, parsedStart, parsedEnd) + 1
      : 0;
  const durationLabel =
    durationEstimate === 1 ? "día laboral." : "días laborales.";
  return getDocxTemplatePromise(project, [
    new Paragraph({
      children: [
        new TextRun({
          text:
            "¿Qué problemas, necesidades o requerimientos dan origen al proyecto?",
          break: 1,
        }),
      ],
      heading: HeadingLevel.HEADING_2,
    }),
    new Paragraph({
      text: project.problems || "",
      heading: HeadingLevel.HEADING_3,
    }),
    new Paragraph({
      children: [
        new TextRun({
          text:
            "¿Cuál es el objetivo del proyecto que resuelve el problema o la necesidad?",
          break: 1,
        }),
      ],
      heading: HeadingLevel.HEADING_2,
    }),
    new Paragraph({
      text: project.objective || "",
      heading: HeadingLevel.HEADING_3,
    }),
    new Paragraph({
      children: [
        new TextRun({
          text: "¿Cuáles son los beneficios esperados?",
          break: 1,
        }),
      ],
      heading: HeadingLevel.HEADING_2,
    }),
    ...(project.benefits || []).map(
      ({ text }) =>
        new Paragraph({
          text: text || "",
          bullet: { level: 0 },
          heading: HeadingLevel.HEADING_3,
        })
    ),
    new Paragraph({
      children: [
        new TextRun({
          text:
            "¿Quién es el cliente del proyecto y los usuarios del producto o de los entregables?",
          break: 1,
        }),
      ],
      heading: HeadingLevel.HEADING_2,
    }),
    new Paragraph({
      text: project.client || "",
      heading: HeadingLevel.HEADING_3,
    }),
    new Paragraph({
      children: [
        new TextRun({
          text:
            "¿Qué vas a entregar o presentar para lograr el objetivo, durante y al final del proyecto?",
          break: 1,
        }),
      ],
      heading: HeadingLevel.HEADING_2,
    }),
    ...(project.wbsRoot?.children || []).map(
      ({ text }) =>
        new Paragraph({
          text: text || "",
          bullet: { level: 0 },
          heading: HeadingLevel.HEADING_3,
        })
    ),
    new Paragraph({
      children: [
        new TextRun({
          text: "¿Cuándo estimas empezar y terminar el proyecto?",
          break: 1,
        }),
      ],
      heading: HeadingLevel.HEADING_2,
    }),
    new Paragraph({
      children: [
        new TextRun("Empieza: "),
        new TextRun({
          text: parsedStart ? completeFormat(parsedStart) : "",
        }),
        new TextRun({ text: "Termina: ", break: 1 }),
        new TextRun({
          text: parsedEnd ? completeFormat(parsedEnd) : "",
        }),
        new TextRun({ text: "Dura: ", break: 1 }),
        new TextRun({ text: `${durationEstimate} ${durationLabel}` }),
      ],
      heading: HeadingLevel.HEADING_3,
    }),
    new Paragraph({
      children: [
        new TextRun({
          text: "¿Quiénes van a trabajar en el proyecto?",
          break: 1,
        }),
      ],
      heading: HeadingLevel.HEADING_2,
    }),
    ...(project.members || []).map(
      (member) =>
        new Paragraph({
          text: getMemberText(member) || "",
          bullet: { level: 0 },
          heading: HeadingLevel.HEADING_3,
        })
    ),
    new Paragraph({
      children: [
        new TextRun({
          text: "¿Qué suposiciones hay para que el proyecto avance?",
          break: 1,
        }),
      ],
      heading: HeadingLevel.HEADING_2,
    }),
    ...(project.assumptions || []).map(
      ({ text }) =>
        new Paragraph({
          text: text || "",
          bullet: { level: 0 },
          heading: HeadingLevel.HEADING_3,
        })
    ),
    new Paragraph({
      children: [
        new TextRun({
          text:
            "¿Qué restricciones existen que pueden limitar el avance del proyecto?",
          break: 1,
        }),
      ],
      heading: HeadingLevel.HEADING_2,
    }),
    ...(project.restrictions || []).map(
      ({ text }) =>
        new Paragraph({
          text: text || "",
          bullet: { level: 0 },
          heading: HeadingLevel.HEADING_3,
        })
    ),
  ]);
};

export default getDocxReportPromise;
