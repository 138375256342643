import React from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Table, Card } from "react-bootstrap";
import { FaCog, FaRegUser } from "react-icons/fa";
import { useQuery } from "@apollo/client";
import {
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import { RadialChart } from "react-vis";
import { GET_USER } from "./graphql-client/queries/account";
import Loader from "./components/Loader";
import { useUserContext } from "./components/user-context";
import { parseStartDate } from "./project/components/gantt/utils";
import { GET_TRAFFIC } from "./graphql-client/queries/admin";
import { Step } from "./graphql-client/codegen-types";
import UseChart from "./components/UseChart";
import { PlanDescription } from "./plan";

const getDurationDescription = (from: Date, to: Date) => {
  from.setHours(0, 0, 0, 0);
  to.setHours(0, 0, 0, 0);
  const years = differenceInYears(to, from);
  if (years > 0) return `${years} ${years === 1 ? "año" : "años"}`;
  const months = differenceInMonths(to, from);
  if (months > 0) return `${months} ${months === 1 ? "mes" : "meses"}`;
  const days = differenceInDays(to, from);
  return `${days} ${days === 1 ? "día" : "días"}`;
};

const UserAnalytics = ({ userId }: { userId: string }) => {
  const periodLength = 10000000;
  const { data, loading, error } = useQuery(GET_TRAFFIC, {
    variables: { periodLength, input: { step: null, userId } },
    errorPolicy: "all",
  });
  const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);
  const steps = Object.values(Step);
  if (error) console.log("Unexpected error", error);
  if (loading) return <Loader size={60} message="Cargando analiticas" />;
  const info = data.asAdmin.getProjectTraffic;
  const stepFrequencies = steps.map(
    (step) =>
      info?.find((element: { step: Step }) => element.step === step)
        ?.frequency || 0
  );
  const myData = stepFrequencies.map((frequency: number, idx: number) => ({
    angle: frequency,
    label: steps[idx].toLowerCase(),
    index: idx,
  }));
  return (
    <div>
      <Card>
        <Card.Header>
          {" "}
          <b>Información para el administrador</b>{" "}
        </Card.Header>
        <Card.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Card.Subtitle>
              {" "}
              <b>Uso diario </b>{" "}
            </Card.Subtitle>
            <UseChart input={{ userId }} />
            <hr />
            <Card.Subtitle>
              {" "}
              <b>Uso por paso</b>{" "}
            </Card.Subtitle>
            <br />
            <RadialChart
              getLabel={(d) => d.label}
              data={myData}
              showLabels
              onValueMouseOver={({ index }) => setHoveredIndex(index)}
              width={300}
              height={300}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

const Profile = () => {
  const { userId } = useParams<{ userId: string }>();
  const userInfo = useUserContext();
  const { loading, error, data } = useQuery(GET_USER, {
    variables: {
      id: userId,
    },
    fetchPolicy: "cache-first",
  });
  if (loading) {
    return <Loader size={60} message="Cargando perfil" />;
  }
  if (error) {
    return (
      <span> Hubo un error :(. Recarga la página o intenta más tarde.</span>
    );
  }

  const myId = userInfo?.id;
  const email = userInfo?.email;
  const {
    id,
    handle,
    name,
    lastNames,
    created,
    profilePictureLink,
  } = data.asUser.getUser;
  const privateInfo = (
    <div>
      <hr />
      <p> Información privada (solo tú la puedes ver) </p>
      {email !== undefined && (
        <Table responsive striped bordered hover>
          <tbody>
            <tr>
              <td>Correo electrónico</td>
              <td>{email}</td>
            </tr>
          </tbody>
        </Table>
      )}
      <PlanDescription />
      <br />
      <Link to="/edit-profile">
        {" "}
        <FaCog /> Editar perfil{" "}
      </Link>
    </div>
  );

  return (
    <Row
      sm={1}
      md={2}
      style={{
        padding: "0 20px 0 20px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Card>
        <Card.Body>
          {profilePictureLink ? (
            <img
              style={{ maxWidth: "90%" }}
              src={profilePictureLink}
              alt="Foto de perfil"
            />
          ) : (
            <FaRegUser style={{ width: "100px", height: "100px" }} />
          )}
          <hr />
          <Card.Title>
            {" "}
            <h3 style={{}}>
              {name} {lastNames}{" "}
            </h3>
          </Card.Title>
          <Card.Subtitle>
            {" "}
            <h5>@{handle}</h5>{" "}
          </Card.Subtitle>
          <span className="text-muted">
            Usuario de <b>Coordinate</b> desde hace{" "}
            {getDurationDescription(parseStartDate(created), new Date())}
          </span>
          <br />
          {myId === id && privateInfo}
          <hr />
          {!!userInfo?.isAdmin && <UserAnalytics userId={id} />}
        </Card.Body>
      </Card>
    </Row>
  );
};

export default Profile;
