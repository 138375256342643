import React from "react";
import { FaGripVertical, FaPlus, FaTimes } from "react-icons/fa";
import { Modal, Card, Button, ListGroup, Col } from "react-bootstrap";
import styled from "styled-components";
import {
  NotDraggingStyle,
  DraggableProps,
  DropResult,
  DragDropContext,
  Droppable,
  Draggable,
} from "react-beautiful-dnd";
import FlipMove from "react-flip-move";
import Loader from "../../components/Loader";

const StyledIcon = styled.div<{ color: string }>`
  margin-left: 15px;

  .icon {
    cursor: pointer;
    color: ${(props) => props.color};
  }

  .icon:hover {
    opacity: 0.3;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggableProps | NotDraggingStyle
) => ({
  background: isDragging ? "lightblue" : "white",
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "aliceblue" : "lightgrey",
});

type DeleteInfo<T> = {
  onDelete: (item: T) => Promise<boolean>;
  deleteWarnTitle: string;
  deleteWarnMsg: string;
};

type Props<T> = {
  title?: React.ReactNode;
  renderItem: (item: T, isNew: boolean) => React.ReactNode;
  getItemId: (item: T) => string;
  initial: Array<T>;
  deleteInfo?: DeleteInfo<T>;
  newItem?: () => Promise<T | null>;
  onReorder?: (items: Array<T>) => void;
};

type ListElem<T> = {
  item: T;
  isNew: boolean;
};

function reorder<T>(
  list: Array<ListElem<T>>,
  startIndex: number,
  endIndex: number
) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map(({ item }) => ({ item, isNew: false }));
}

function DynamicList<T>({
  title,
  renderItem,
  getItemId,
  initial,
  deleteInfo,
  newItem,
  onReorder,
}: Props<T>) {
  const [elems, setElems] = React.useState<Array<ListElem<T>>>(
    initial.map((item) => ({ item, isNew: false }))
  );
  // Needed due to weird hook behavior. More info: https://stackoverflow.com/questions/54865764/react-usestate-does-not-reload-state-from-props
  React.useEffect(() => {
    setElems(initial.map((item) => ({ item, isNew: false })));
  }, [initial]);
  const [deleting, setDeleting] = React.useState(false);
  const [adding, setAdding] = React.useState(false);
  const [deletingIndex, setDeletingIndex] = React.useState<number | null>(null);
  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.type === "ELEMS") {
      const newElems = reorder(
        elems,
        result.source.index,
        result.destination.index
      );

      if (onReorder) onReorder(newElems.map(({ item }) => item));
      setElems(newElems);
    }
  };

  const removeModal = deleteInfo ? (
    <Modal show={deletingIndex !== null} onHide={() => setDeletingIndex(null)}>
      <Modal.Header closeButton>
        <Modal.Title>{deleteInfo.deleteWarnTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{deleteInfo.deleteWarnMsg}</Modal.Body>
      {deleting ? (
        <div style={{ marginBottom: "20px" }}>
          <Loader size={50} message="Eliminando..." />
        </div>
      ) : (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeletingIndex(null)}>
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              if (deletingIndex === null)
                throw new Error("Deleting index is null");
              setDeleting(true);
              deleteInfo
                .onDelete(elems[deletingIndex].item)
                .then((shouldDelete) => {
                  if (shouldDelete) {
                    setElems(
                      elems.filter((_, idx: number) => idx !== deletingIndex)
                    );
                  }
                  setDeletingIndex(null);
                  setDeleting(false);
                });
            }}
          >
            Eliminar
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  ) : (
    <></>
  );

  const isDraggable = onReorder !== undefined;
  return (
    <Card>
      {deleteInfo && removeModal}
      {title !== undefined && <Card.Header> {title} </Card.Header>}
      <ListGroup>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" type="ELEMS">
            {(droppableProvided, droppableSnapshot) => (
              <div
                ref={droppableProvided.innerRef}
                style={getListStyle(droppableSnapshot.isDraggingOver)}
              >
                <FlipMove disableAllAnimations={!!onReorder} duration={1000}>
                  {elems.map((elem, index) => (
                    <div key={getItemId(elem.item)}>
                      <Draggable
                        key={getItemId(elem.item)}
                        draggableId={getItemId(elem.item)}
                        index={index}
                        isDragDisabled={!isDraggable}
                      >
                        {(draggableProvided, draggableSnapshot) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            style={getItemStyle(
                              draggableSnapshot.isDragging,
                              draggableProvided.draggableProps.style ?? {}
                            )}
                          >
                            <ListGroup.Item>
                              <StyledDiv>
                                {isDraggable && (
                                  <span {...draggableProvided.dragHandleProps}>
                                    <FaGripVertical />
                                  </span>
                                )}
                                <Col> {renderItem(elem.item, elem.isNew)} </Col>
                                {deleteInfo && (
                                  <div>
                                    <StyledIcon color="red">
                                      <FaTimes
                                        className="icon"
                                        onClick={() => setDeletingIndex(index)}
                                      />
                                    </StyledIcon>
                                  </div>
                                )}
                              </StyledDiv>
                            </ListGroup.Item>
                          </div>
                        )}
                      </Draggable>
                    </div>
                  ))}
                  {droppableProvided.placeholder}
                </FlipMove>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ListGroup>
      {newItem && (
        <div>
          {adding && <Loader size={40} message="Agregando..." />}
          {!adding && (
            <Button
              style={{ width: "100%" }}
              onClick={() => {
                setAdding(true);
                newItem().then((createdItem) => {
                  if (createdItem)
                    setElems(
                      elems
                        .map(({ item }) => ({ item, isNew: false }))
                        .concat({ item: createdItem, isNew: true })
                    );
                  setAdding(false);
                });
              }}
              variant="light"
              size="sm"
              className="float-center"
            >
              <b>Agregar</b> <FaPlus />
            </Button>
          )}
        </div>
      )}
    </Card>
  );
}

export default DynamicList;
