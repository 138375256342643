import React from "react";
import { Dropdown, Navbar } from "react-bootstrap";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
import {
  FaCalendar,
  FaList,
  FaHome,
  FaSignOutAlt,
  FaRegUser,
} from "react-icons/fa";
import { Tooltip } from "@material-ui/core";
import useSession from "../graphql-client/session";
import { useUserContext } from "./user-context";

const StyledNavbarWrapper = styled.div`
  .navbar {
    z-index: 20;
    background-image: url("/fondo.png");
  }
`;

const StyledItem = styled(Dropdown.Item)`
  background: whitesmoke;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
`;

const ProfileOptions = () => {
  const userInfo = useUserContext();
  const [, , removeSession] = useSession();
  const history = useHistory();
  const handle = userInfo?.handle;
  const id = userInfo?.id;
  const profilePictureLink = userInfo?.profilePictureLink;
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="light"
        id="dropdown-basic"
        style={{ borderRadius: "25px" }}
      >
        {profilePictureLink ? (
          <img
            style={{ maxWidth: "25px", maxHeight: "25px" }}
            src={profilePictureLink}
            alt="Foto de perfil"
          />
        ) : (
          <span
            style={{
              background: "lightblue",
              borderRadius: "15px",
              alignItems: "center",
              padding: "2px 5px 5px 5px",
            }}
          >
            <FaRegUser />
          </span>
        )}{" "}
        {handle}
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight>
        {id !== null && id !== undefined && (
          <StyledItem onClick={() => history.push(`/profile/${id}`)}>
            <FaRegUser style={{ height: 23, width: 23, color: "steelblue" }} />{" "}
            Mi Perfil
          </StyledItem>
        )}
        <StyledItem onClick={removeSession}>
          <FaSignOutAlt style={{ height: 23, width: 23, color: "coral" }} />{" "}
          Cerrar Sesión{" "}
        </StyledItem>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const Logo = () => (
  <Link to="/">
    <img src="/favicon.ico" alt="Coordinate" height="35" width="35" />
  </Link>
);

const Header = () => (
  <StyledNavbarWrapper>
    <Navbar
      fixed="top"
      style={{
        padding: "0",
        paddingTop: "9px",
        height: "50px",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "33%", paddingLeft: "9px" }}>
          <Logo />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "34%",
            textAlign: "center",
          }}
        >
          <div style={{ margin: "5px" }}>
            <Tooltip title="Inicio">
              <Link to="/home">
                <FaHome style={{ height: 30, width: 30, color: "#0484bc" }} />
              </Link>
            </Tooltip>
          </div>
          <div style={{ margin: "5px" }}>
            <Tooltip title="Portafolio de Proyectos">
              <Link to="/projects">
                <FaList style={{ height: 30, width: 30, color: "#0484bc" }} />
              </Link>
            </Tooltip>
          </div>
          <div style={{ margin: "5px" }}>
            <Tooltip title="Calendario">
              <Link to="/calendar">
                <FaCalendar
                  style={{ height: 30, width: 30, color: "#0484bc" }}
                />
              </Link>
            </Tooltip>
          </div>
        </div>

        <div
          style={{
            width: "33%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            paddingRight: "8px",
          }}
        >
          <ProfileOptions />
        </div>
      </div>
    </Navbar>
  </StyledNavbarWrapper>
);

export default Header;
