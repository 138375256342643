import React from "react";
import { gql, DocumentNode, useQuery, useMutation } from "@apollo/client";
import type {
  MutationFunctionOptions,
  TypedDocumentNode,
} from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  GET_MY_USER_INFO,
  HAS_SUBMITTED_FEEDBACK,
} from "../../graphql-client/queries/account";
import { Member } from "../../graphql-client/codegen-types";
import client from "../../graphql-client/connection";
import UserFeedback from "../../components/UserFeedback";

const { cache } = client;

const USAGE_COOKIE_NAME = "USAGE";

type ProjectContextState = {
  activeProcessCount: number;
  totalSaves: number;
  feedbackPrompt: boolean;
};

type Actions =
  | { type: "startSavingChange" }
  | { type: "finishSavingChange" }
  | { type: "openFeedbackPrompt" }
  | { type: "closeFeedbackPrompt" };

const reducer = (state: ProjectContextState, action: Actions) => {
  switch (action.type) {
    case "startSavingChange": {
      return { ...state, activeProcessCount: state.activeProcessCount + 1 };
    }
    case "finishSavingChange": {
      return {
        ...state,
        activeProcessCount: state.activeProcessCount - 1,
        totalSaves: state.totalSaves + 1,
      };
    }
    case "openFeedbackPrompt": {
      return { ...state, feedbackPrompt: true };
    }
    case "closeFeedbackPrompt": {
      return { ...state, feedbackPrompt: false };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const defaultInitialState = {
  activeProcessCount: 0,
  totalSaves: 0,
  feedbackPrompt: false,
};

const ProjectContext = React.createContext<{
  state: ProjectContextState;
  dispatch: React.Dispatch<Actions>;
}>({ state: defaultInitialState, dispatch: () => undefined });

export function ProjectProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const [state, dispatch] = React.useReducer(reducer, defaultInitialState);
  return (
    <ProjectContext.Provider value={{ state, dispatch }}>
      {children}
      {state.feedbackPrompt && (
        <UserFeedback close={() => dispatch({ type: "closeFeedbackPrompt" })} />
      )}
    </ProjectContext.Provider>
  );
}

export function useProjectContext() {
  const context = React.useContext(ProjectContext);
  if (!context) {
    throw new Error(
      "useProjectContext must be used within a ProjectContext Provider"
    );
  }
  return context;
}

// Esta funcion es para optimizar. Evita peticiones innecesarias al backend. Especificamente, detecta si el
// proyecto solo tiene un miembro con derechos de edicion (el usuario actual), pues estos no necesitan ser actualizados debido
// a que todos los cambios son locales.
const getBestFetchPolicy = (projectId: string) => {
  const userId = client.readQuery({ query: GET_MY_USER_INFO })?.asUser?.id;
  const fragment = gql`
    fragment ProjectMembers on Project {
      members {
        invitedOn
        user {
          id
        }
      }
    }
  `;
  const project = client.readFragment({
    id: cache.identify({ id: projectId, __typename: "Project" }),
    fragment,
  });

  // Si hay al menos un usuario invitado al proyecto que no sea el usuario actual, entonces el proyecto podria
  // ser editado externamente. En ese caso, hay que mantener el proyecto constantemente actualizado (cache-and-network permite eso).
  //
  // Por otro lado, si el unico miembro del proyecto con derechos de editar es el usuario actual, entonces no es necesario cargar la
  // informacion del backend, pues todos los cambios estaran reflejadas localmente, en el cache (cache-only). Notese que los cambios
  // sí se guardaran en el backend normalmente, simplemente se intentará no hacer queries si el usuario cambia de paso.
  //
  // Nota que si los miembros o el userId no se encontraron, regresamos el default (cache-and-network).
  if (
    !userId ||
    !project?.members ||
    project.members.some(
      ({ invitedOn, user }: Member) => invitedOn && user && user.id !== userId
    )
  )
    return "cache-and-network";
  return "cache-first";
};

export const useProjectQuery = (query: DocumentNode, options: any = {}) => {
  const params = useParams<{ projectId: string }>();
  const fetchPolicy = React.useMemo(
    () => getBestFetchPolicy(params.projectId),
    [params]
  );
  return useQuery(query, { fetchPolicy, ...options });
};

const usageLimit = 70;

export const useProjectMutation = <
  T extends unknown,
  TVariables extends unknown
>(
  m: TypedDocumentNode<T, TVariables>
) => {
  const [exec] = useMutation<T, TVariables>(m);
  const { state, dispatch } = useProjectContext();
  const { stepPath } = useParams<{
    stepPath: string;
  }>();
  const newExec = (options: MutationFunctionOptions<T, TVariables>) => {
    dispatch({ type: "startSavingChange" });
    return exec({
      ...options,
      variables: Object.assign(options.variables, {
        step: stepPath.toUpperCase(),
      }),
    })
      .then((res) => {
        dispatch({ type: "finishSavingChange" });
        const currentUsage = state.totalSaves;
        if (currentUsage > 0 && currentUsage % usageLimit === 0) {
          client
            .query({
              query: HAS_SUBMITTED_FEEDBACK,
              fetchPolicy: "cache-first",
            })
            .then(
              (resp?: {
                data?: { asUser?: { hasSubmittedFeedback?: boolean } };
              }) => {
                if (!resp?.data?.asUser?.hasSubmittedFeedback)
                  dispatch({ type: "openFeedbackPrompt" });
              }
            );
        }
        return res;
      })
      .catch((e) => {
        dispatch({ type: "finishSavingChange" });
        throw e;
      });
  };
  return newExec;
};
