import React from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { Card, Button } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import {
  FILTER_PROJECTS,
  CONFIRM_MEMBER_INVITATION,
} from "./graphql-client/queries/project";
import type { Scalars } from "./graphql-client/codegen-types";
import Loader from "./components/Loader";
import client from "./graphql-client/connection";
import { useProVerifier } from "./plan";

const { cache } = client;

type ID = Scalars["ID"];

const ConfirmMemberInvitation = () => {
  const projectListData = useQuery(FILTER_PROJECTS, {
    variables: {
      textFilters: [],
    },
    errorPolicy: "all",
    fetchPolicy: "cache-first",
  }).data;
  const { inviterHandle, projectId, projectName, memberId } = useParams<{
    inviterHandle: string;
    projectId: ID;
    projectName: string;
    memberId: ID;
  }>();
  const [confirmMemberInvitation] = useMutation(CONFIRM_MEMBER_INVITATION);
  const history = useHistory();
  const [inviteAlreadyConfirmed, setInviteAlreadyConfirmed] = React.useState(
    false
  );
  const [otherError, setOtherError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [confirmProAccess] = useProVerifier();
  const submit = () => {
    const projCount = projectListData?.asUser?.getProjects?.length;
    if (projCount !== undefined && projCount !== null && projCount >= 5) {
      if (!confirmProAccess("Para tener más de 5 proyectos ¡Cámbiate a Pro!"))
        return;
    }
    setLoading(true);
    confirmMemberInvitation({ variables: { memberId } })
      .then(({ data }) => {
        const { newProject } = data.asUser.confirmMemberInvitation;
        const projectListQuery = client.readQuery({
          query: FILTER_PROJECTS,
          variables: { textFilters: [] },
        });
        if (projectListQuery) {
          const { asUser } = projectListQuery;
          cache.modify({
            id: cache.identify(asUser),
            fields: {
              getProjects: (oldProjects) => oldProjects.concat(newProject),
            },
          });
        }
        history.push(`/projects/${projectId}/que`);
      })
      .catch((e) => {
        const errorDetail =
          e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail;
        const memberError = errorDetail?.projectError?.memberError;
        if (memberError?.inviteAlreadyConfirmed) {
          setInviteAlreadyConfirmed(true);
        } else {
          console.log(e);
          setOtherError(true);
        }
        setLoading(false);
      });
  };
  if (inviteAlreadyConfirmed)
    return (
      <div>
        <h3 style={{ textAlign: "center" }}>
          {" "}
          Ya eres miembro de este proyecto{" "}
        </h3>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link to={`/projects/${projectId}/que`}>
            {" "}
            <h4>
              {" "}
              Ir al proyecto <b> {projectName} </b>{" "}
            </h4>{" "}
          </Link>
        </div>
      </div>
    );
  if (otherError)
    return (
      <div>
        <h3 style={{ textAlign: "center" }}> Invitación inválida </h3>
        <hr />
        <h4 style={{ textAlign: "center" }}>
          {" "}
          Asegura que obtuviste el enlace de una cuenta autorizada de{" "}
          <b> Coordinate </b>{" "}
        </h4>
      </div>
    );
  return (
    <Card className="text-center">
      <Card.Header>
        <h4>
          {" "}
          Invitación a <b> {projectName}</b>{" "}
        </h4>
      </Card.Header>
      <Card.Body style={{ fontSize: "19px" }}>
        El usuario <b> {inviterHandle} </b> te ha invitado a ser miembro del
        proyecto <b> {projectName} </b>
        <hr />
        {loading ? (
          <Loader message="Aceptando invitación" size={70} />
        ) : (
          <Button variant="info" onClick={submit}>
            Aceptar invitación
          </Button>
        )}
        <hr />
        Si aceptas, te podrán asignar roles del proyecto, y se incluirá en tu
        portafolio.
      </Card.Body>
      <Card.Footer className="text-muted">
        Si no reconoces esta invitación o al usuario que te invitó, no la
        aceptes.
      </Card.Footer>
    </Card>
  );
};

export default ConfirmMemberInvitation;
