import React from "react";
import DatePicker from "react-datepicker";
import { Button, Dropdown, InputGroup, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { addDays, isBefore } from "date-fns";
import type { Project } from "../graphql-client/codegen-types";
import type { Filter } from "./types";

type Props = {
  label: string;
  setFilter: (filterFunction: Filter) => void;
  getProjectDate: (project: Project) => Date | null;
  close: () => void;
};

enum Option {
  BEFORE,
  AFTER,
  BETWEEN,
}

const StyledDatePicker = styled(DatePicker)`
  min-height: 38px;
  text-align: center;
  border: 1px solid gainsboro;
  max-width: 100px;
`;

const DateFilter = React.forwardRef(
  ({ label, setFilter, getProjectDate, close }: Props, ref) => {
    const dateRef = React.useRef<any>();
    const startDateRef = React.useRef<any>();
    const endDateRef = React.useRef<any>();
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
    const [
      selectedStartDate,
      setSelectedStartDate,
    ] = React.useState<Date | null>(null);
    const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
      null
    );
    const [option, setOption] = React.useState<Option | null>(null);
    const closeButton = (
      <InputGroup.Append>
        <Button
          type="button"
          variant="outline-secondary"
          onClick={() => {
            setFilter(null);
            close();
          }}
        >
          &times;
        </Button>
      </InputGroup.Append>
    );
    const setBefore = () => {
      setOption(Option.BEFORE);
      setTimeout(() => dateRef?.current?.setOpen(true), 0);
    };
    const setAfter = () => {
      setOption(Option.AFTER);
      setTimeout(() => dateRef?.current?.setOpen(true), 0);
    };
    const setBetween = () => {
      setOption(Option.BETWEEN);
      setTimeout(() => startDateRef?.current?.setOpen(true), 0);
    };
    if (option === null) {
      return (
        <Col>
          <InputGroup className="mb-2">
            <Dropdown>
              <Dropdown.Toggle
                ref={ref as any}
                variant="light"
                id="dropdown-basic"
              >
                {label}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={setBefore}>Antes de</Dropdown.Item>
                <Dropdown.Item onClick={setAfter}>Después de</Dropdown.Item>
                <Dropdown.Item onClick={setBetween}>
                  Dentro de un periodo
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {closeButton}
          </InputGroup>
        </Col>
      );
    }
    if (option === Option.BEFORE) {
      const onBeforeChange = (date: Date) => {
        setSelectedDate(date);
        setFilter({
          matchesFilter: (project: Project) => {
            if (!date) return true;
            const projectDate = getProjectDate(project);
            if (!projectDate) return false;
            return isBefore(projectDate, addDays(date, 1));
          },
        });
      };
      return (
        <Col>
          <Row className="align-items-center">
            <Col xs="auto">
              <InputGroup className="mb-2">
                <InputGroup.Text>
                  <span>
                    {" "}
                    <b>{label}</b>{" "}
                    <span className="text-muted"> antes del</span>{" "}
                  </span>
                </InputGroup.Text>
                <StyledDatePicker
                  placeholderText="-"
                  locale="es"
                  dateFormat="d/LLL/yyyy"
                  selected={selectedDate}
                  onChange={onBeforeChange}
                  ref={dateRef}
                />
                {closeButton}
              </InputGroup>
            </Col>
          </Row>
        </Col>
      );
    }
    if (option === Option.AFTER) {
      const onAfterChange = (date: Date) => {
        setSelectedDate(date);
        setFilter({
          matchesFilter: (project: Project) => {
            if (!date) return true;
            const projectDate = getProjectDate(project);
            if (!projectDate) return false;
            return isBefore(date, projectDate);
          },
        });
      };
      return (
        <Col>
          <Row className="align-items-center">
            <Col xs="auto">
              <InputGroup className="mb-2">
                <InputGroup.Text>
                  <span>
                    {" "}
                    <b>{label}</b>{" "}
                    <span className="text-muted"> después del</span>{" "}
                  </span>
                </InputGroup.Text>
                <StyledDatePicker
                  placeholderText="-"
                  locale="es"
                  dateFormat="d/LLL/yyyy"
                  selected={selectedDate}
                  onChange={onAfterChange}
                  ref={dateRef}
                />
                {closeButton}
              </InputGroup>
            </Col>
          </Row>
        </Col>
      );
    }

    const onBetweenChange = (startDate: Date | null, endDate: Date | null) => {
      setFilter({
        matchesFilter: (project: Project) => {
          if (!startDate || !endDate) return true;
          const date = getProjectDate(project);
          if (!date) return false;
          if (isBefore(date, startDate)) return false;
          if (isBefore(addDays(endDate, 1), date)) return false;
          return true;
        },
      });
    };
    const onStartDateChange = (date: Date) => {
      setSelectedStartDate(date);
      onBetweenChange(date, selectedEndDate);
      endDateRef.current?.setOpen(true);
    };
    const onEndDateChange = (date: Date) => {
      setSelectedEndDate(date);
      onBetweenChange(selectedStartDate, date);
    };
    return (
      <Col>
        <Row className="align-items-center">
          <Col xs="auto">
            <InputGroup className="mb-2">
              <InputGroup.Text>
                <span>
                  {" "}
                  <b>{label}</b> <span className="text-muted"> entre el </span>{" "}
                </span>
              </InputGroup.Text>
              <StyledDatePicker
                placeholderText="-"
                locale="es"
                dateFormat="d/LLL/yyyy"
                selected={selectedStartDate}
                onChange={onStartDateChange}
                ref={startDateRef}
              />
              <InputGroup.Text>
                <span>
                  <span className="text-muted"> y el </span>{" "}
                </span>
              </InputGroup.Text>
              <StyledDatePicker
                placeholderText="-"
                locale="es"
                dateFormat="d/LLL/yyyy"
                selected={selectedEndDate}
                onChange={onEndDateChange}
                ref={endDateRef}
              />
              {closeButton}
            </InputGroup>
          </Col>
        </Row>
      </Col>
    );
  }
);

export default DateFilter;
