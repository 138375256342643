import { gql } from "@apollo/client";

export const GET_USER = gql`
  query getUser($id: ID!) {
    asUser {
      id
      getUser(id: $id) {
        id
        handle
        name
        lastNames
        created
        profilePictureLink
      }
    }
  }
`;

export const GET_MY_USER_INFO = gql`
  query getMyUserInfo {
    asUser {
      id
      getUser {
        id
        email
        handle
        name
        lastNames
        created
        profilePictureLink
        isAdmin
      }
    }
  }
`;

export const EDIT_PROFILE = gql`
  mutation editProfile($input: UserInput!, $profilePicture: Upload) {
    asUser {
      editProfile(input: $input, profilePicture: $profilePicture) {
        message
        updatedUser {
          id
          handle
          email
          isAdmin
          name
          lastNames
          profilePictureLink
        }
      }
    }
  }
`;

export const SIGN_IN = gql`
  mutation login($captchaKey: String!, $email: String!, $password: String!) {
    asHuman(captchaKey: $captchaKey) {
      login(email: $email, password: $password) {
        message
        sessionToken
      }
    }
  }
`;

export const REGISTER = gql`
  mutation getSignUpToken(
    $captchaKey: String!
    $email: String!
    $password: String!
  ) {
    asHuman(captchaKey: $captchaKey) {
      getSignUpToken(email: $email, password: $password) {
        message
      }
    }
  }
`;

export const CONFIRM = gql`
  mutation confirmAccount(
    $token: String!
    $handle: String!
    $name: String!
    $lastNames: String!
    $profilePicture: Upload
    $userMetadata: UserMetadata
  ) {
    confirmAccount(
      signUpToken: $token
      handle: $handle
      name: $name
      lastNames: $lastNames
      profilePicture: $profilePicture
      userMetadata: $userMetadata
    ) {
      message
      sessionToken
    }
  }
`;

export const GET_CHANGE_PASSWORD_TOKEN = gql`
  mutation getChangePasswordToken($captchaKey: String!, $email: String!) {
    asHuman(captchaKey: $captchaKey) {
      getChangePasswordToken(email: $email) {
        message
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($token: String!, $newPassword: String!) {
    changePassword(token: $token, newPassword: $newPassword) {
      message
    }
  }
`;

export const HAS_SUBMITTED_FEEDBACK = gql`
  query hasSubmittedFeedback {
    asUser {
      id
      hasSubmittedFeedback
    }
  }
`;

export const SUBMIT_FEEDBACK = gql`
  mutation submitUserFeedback($input: UserFeedbackInput!) {
    asUser {
      submitFeedback(input: $input) {
        message
      }
    }
  }
`;

export const GET_PLAN = gql`
  query getPlan {
    asUser {
      id
      plan {
        __typename
        ... on Basic {
          _
        }
        ... on Individual {
          activeUntil
        }
        ... on Enterprise {
          institution {
            id
            name
            logoUrl
          }
          activeUntil
        }
      }
    }
  }
`;

export const GET_REVALIDATION_EMAIL = gql`
  mutation getRevalidationEmail($captchaKey: String!) {
    asHuman(captchaKey: $captchaKey) {
      getRevalidationEmail {
        message
      }
    }
  }
`;

export const CREATE_PAYMENT_SESSION = gql`
  mutation createPaymentSession {
    asUser {
      createPaymentSession {
        message
        portalSessionUrl
      }
    }
  }
`;

export const REVALIDATE_EMAIL = gql`
  mutation revalidateEmail($token: String!) {
    asUser {
      revalidateEmail(token: $token) {
        message
      }
    }
  }
`;
