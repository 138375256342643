import React from "react";
import { differenceInDays, addDays } from "date-fns";
import ReactDOMServer from "react-dom/server";
import { Dropdown, Button, Form } from "react-bootstrap";
import type { GanttStatic } from "dhtmlx-gantt";
import { FaCog, FaUndo, FaRedo } from "react-icons/fa";
import GanttNodeDescription from "./GanttNodeDescription";
import type { GanttBasicProps, GanttState, GanttNode } from "./types";
import { ganttZoomConfig, getBestZoomLevel, GanttZoomPane } from "./zoom";
import CustomPopper from "../CustomPopper";
import GanttChart from "./GanttChart";
import { briefFormat } from "../../../components/utils";
import type { Duty, Member } from "../../../graphql-client/codegen-types";
import "dhtmlx-gantt/codebase/api";

// Transforma JSX (React Components) en strings de html.
// Esto es útil porque dhtmlx usa html crudo para renderear.
// Documentación: https://reactjs.org/docs/react-dom-server.html
const jsxToStr = ReactDOMServer.renderToString;

const scrollToMidScreen = (element: HTMLElement) => {
  const elementRect = element.getBoundingClientRect();
  const absoluteElementTop = elementRect.top + window.pageYOffset;
  const middle = absoluteElementTop - window.innerHeight / 2;
  window.scrollTo(0, middle);
};

const setWorkDaysConfig = (gantt: GanttStatic, workDays?: Array<boolean>) => {
  gantt.config.work_time = !!workDays;
  workDays?.forEach((active: boolean, idx: number) =>
    gantt.setWorkTime({ day: (idx + 1) % 7, hours: active })
  );
};

class InteractiveGanttChart<
  AdditionalGanttProps,
  AdditionalNodeAttributes
> extends GanttChart<
  AdditionalGanttProps,
  AdditionalNodeAttributes,
  GanttState
> {
  state = {
    zoom: null as number | null,
    openTaskId: null as number | string | null,
    showCriticalPath: false,
  } as GanttState;

  componentDidMount() {
    super.componentDidMount();
    this.zoomToFit();
    const { openTaskId, omitOpenTaskId } = this.props as {
      openTaskId?: string;
      omitOpenTaskId?: () => void;
    };
    if (openTaskId) {
      scrollToMidScreen(this.myGantt.getTaskRowNode(openTaskId));
      this.setState({ openTaskId });
      omitOpenTaskId?.();
    }
  }

  componentDidUpdate(
    prevProps: GanttBasicProps & AdditionalGanttProps,
    prevState: GanttState
  ) {
    const { zoom, showCriticalPath, filteredMemberId } = this.state;
    const { workDays } = this.props;
    // The gantt object's zoom must always be consistent with the component's zoom prop attribute.
    // If zoom was previously null, that means it's just being initialized, so we should set things
    // up as well.
    if (zoom !== null) {
      if (
        prevState.zoom === null ||
        zoom !== this.myGantt.ext.zoom.getCurrentLevel()
      ) {
        this.myGantt.ext.zoom.setLevel(zoom);
      }
      // Agregar un poco de amplitud al gantt para poder scrollear horizontalmente
      // como las fechas de inicio y fin del proyecto cambian constantemente,
      // es importante asegurar que las propiedades de start_date y end_date del dhtmlx gantt
      // esten actualizadas.
      const extraAmplitude = 1; // Cantidad de espacios extra a los lados
      const [projectStart, projectEnd] = this.getProjectDates();
      const scaleList = ganttZoomConfig.levels[zoom as number].scales;
      const scaleUnit = scaleList[0].unit;
      const scrollAreaStart = this.myGantt.date.add(
        projectStart,
        -extraAmplitude,
        scaleUnit
      );
      const scrollAreaEnd = this.myGantt.date.add(
        projectEnd,
        extraAmplitude,
        scaleUnit
      );
      if (scrollAreaStart !== this.myGantt.config.start_date)
        this.myGantt.config.start_date = scrollAreaStart;
      if (scrollAreaEnd !== this.myGantt.config.end_date)
        this.myGantt.config.end_date = scrollAreaEnd;
    }

    if (showCriticalPath !== prevState.showCriticalPath) {
      // Para colorear las ligas en la ruta critica
      this.myGantt.config.highlight_critical_path = showCriticalPath;

      // Rerenderear barras para que se oculte/muestre la ruta critica
      this.myGantt.render();
    }
    if (filteredMemberId !== prevState.filteredMemberId) {
      this.myGantt.render();
    }
    if (workDays !== prevProps.workDays) {
      setWorkDaysConfig(this.myGantt, workDays);
      this.myGantt.render();
    }
  }

  getProjectDates(): Array<Date> {
    throw Error("Attempted to run an abstract method");
  }

  // El tooltip que se se enseña cuando una task en el gantt chart es hovereada.
  // Fuente relevante: https://docs.dhtmlx.com/gantt/desktop__tooltips.html
  getStyledTooltip(
    start: Date,
    end: Date,
    task: GanttNode & AdditionalNodeAttributes
  ): string {
    const duration = differenceInDays(end, start);
    const dias = duration === 1 ? "día" : "días";
    return jsxToStr(
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100px",
            margin: 0,
          }}
        >
          <b> {task.text} </b>
        </div>
        <hr style={{ margin: "2px" }} />
        <b>Del</b> {briefFormat(start)}
        <br />
        <b>Al</b> {briefFormat(addDays(end, -1))} <br />
        <b>Dura</b> {duration} {dias} lab.
        <hr style={{ margin: "2px" }} />
        <b>Click para más</b>
      </div>
    );
  }

  openPopper(id: number | string) {
    // Antes de abrir el Popper, hay que asegurar que el tooltip esté oculto para que no estorbe
    // al Popper. La siguiente funcion no hace nada si el tooltip ya esta oculto.
    this.myGantt.ext.tooltips.tooltip.hide();
    this.setState({ openTaskId: id });
  }

  zoomToFit() {
    // Adjust zoom the the best fit. Look at src/project/Gantt/zoom.ts for details
    const [projectStart, projectEnd] = this.getProjectDates();
    // @ts-expect-error offsetWidth missing in third library type specification
    const areaWidth = this.myGantt.$task.offsetWidth;
    const bestZoomLevel = getBestZoomLevel(projectStart, projectEnd, areaWidth);
    this.setState({ zoom: bestZoomLevel });
  }

  configureGantt() {
    super.configureGantt();
    // When gantt instance (this.myGantt) is rendered, we have to make sure the component is up to date with it.
    // This is specially important for the Popper. If gantt rerenders while a
    // Popper is active, the HTMLElement it's anchored to will be destroyed, making
    // the tooltip crash.
    this.myGantt.attachEvent("onAfterTaskDrag", () => this.setState({}), {});
    this.myGantt.attachEvent("onGanttRender", () => this.setState({}), {});
    this.myGantt.attachEvent(
      "onEmptyClick",
      () => {
        // Cerrar Popper cuando esta abierto y hay click en el exterior.
        this.setState({ openTaskId: null });
      },
      {}
    );

    this.myGantt.attachEvent(
      "onTaskClick",
      (id, e) => {
        const { openTaskId } = this.state;
        // Documentacion de la siguiente fucionalidad:
        // https://docs.dhtmlx.com/gantt/api__gantt_utils_other.html
        const ganttDomHelper = this.myGantt.utils.dom;
        // Si el click fue para abrir/cerrar el contenido del proyecto o de un entregable, se
        // regresa true para no bloquear la accion.
        if (ganttDomHelper.closest(e.target, ".gantt_tree_icon")) {
          return true;
        }
        if (openTaskId !== id) {
          this.openPopper(id);
        } else {
          // Cerrar el Popper si el click fue en la task que ya tenia el Popper abierto
          this.setState({ openTaskId: null });
        }
        return false;
      },
      {}
    );

    this.myGantt.attachEvent(
      "onBeforeTaskDisplay",
      (id, task: GanttNode) => {
        const { filteredMemberId } = this.state;
        if (!filteredMemberId) return true;
        return task?.duties?.some(
          ({ memberId }: Duty) => memberId === filteredMemberId
        );
      },
      {}
    );
    this.myGantt.plugins({
      tooltip: true,
      auto_scheduling: true,
      undo: true,
    });

    this.myGantt.templates.tooltip_text = (start, end, task) => {
      const { openTaskId } = this.state;
      // If popper is open for a task, don't display tooltip, it will block the popper's visibility
      if (openTaskId === task.id) return "";
      // Otherwise, display the tooltip specified in src/project/Gantt/Style.tsx
      return this.getStyledTooltip(start, end, task);
    };
    this.myGantt.config.tooltip_timeout = 1000;
    this.myGantt.ext.zoom.init(ganttZoomConfig);
    this.myGantt.config.auto_scheduling = true;
    this.myGantt.config.auto_scheduling_strict = true;
    this.myGantt.config.auto_scheduling_initial = false;
    this.myGantt.config.auto_scheduling_compatibility = true;
    const { workDays } = this.props;
    setWorkDaysConfig(this.myGantt, workDays);
  }

  renderActEditor(task: GanttNode & AdditionalNodeAttributes): React.ReactNode {
    throw Error("Attempted to run abstract method");
  }

  render() {
    const setZoom = (zoom: number) => this.setState({ zoom });
    const { zoom, openTaskId, showCriticalPath } = this.state;
    const getArrowColor = ({ level }: GanttNode & AdditionalNodeAttributes) =>
      ["#0086ba", "coral", "#999900"][level];
    const getPopper = (taskId: string | number) => {
      const task = this.myGantt.getTask(taskId);
      const { text, level } = task;
      const anchorEl = this.myGantt.getTaskRowNode(taskId);
      return (
        <CustomPopper
          title={text}
          color={getArrowColor(task)}
          close={() => this.setState({ openTaskId: null })}
          anchorEl={anchorEl}
        >
          {level === 2 ? (
            this.renderActEditor(task)
          ) : (
            <GanttNodeDescription node={task} />
          )}
        </CustomPopper>
      );
    };
    const {
      fullscreenHandler,
      criticalPathDisabled,
      members,
      workDaysHandler,
    } = this.props;
    const getMemberOptions = () => {
      if (!members) return null;
      const onChange = (e: React.BaseSyntheticEvent) => {
        const memberId = e.target.value;
        // TODO: Manejar los demas tipos de enlaces
        if (memberId === "-1") this.setState({ filteredMemberId: undefined });
        else this.setState({ filteredMemberId: memberId });
      };
      return (
        <div>
          <span> Mostrar tareas de </span>
          <Form.Control
            style={{ height: "30px", fontSize: "inherit" }}
            as="select"
            onChange={onChange}
            defaultValue="-1"
          >
            <option value="-1">Todo el equipo</option>
            {members.map((member: Member) => (
              <option key={member.id} value={member.id}>
                {member?.user?.handle || member.name}
              </option>
            ))}
          </Form.Control>
        </div>
      );
    };
    const onClick = () => {
      this.setState({ showCriticalPath: !showCriticalPath });
    };

    const optionsPane = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          fontSize: "14px",
        }}
      >
        {zoom !== null && <GanttZoomPane zoom={zoom} setZoom={setZoom} />}
        {fullscreenHandler}
        <Button
          title="Deshacer"
          variant="light"
          style={{ border: "1px solid lightgrey" }}
          onClick={() => this.myGantt.undo()}
        >
          <FaUndo />
        </Button>
        <Button
          title="Rehacer"
          variant="light"
          style={{ border: "1px solid lightgrey" }}
          onClick={() => this.myGantt.redo()}
        >
          <FaRedo />
        </Button>
        {getMemberOptions()}
        <Dropdown>
          <Dropdown.Toggle
            variant="light"
            id="dropdown-basic"
            style={{ border: "1px solid lightgrey" }}
          >
            <FaCog />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {!criticalPathDisabled && (
              <Dropdown.Item variant="light" onClick={onClick}>
                {showCriticalPath ? "Ocultar" : "Mostrar"} ruta crítica
              </Dropdown.Item>
            )}
            {workDaysHandler}
            <Dropdown.Item
              variant="light"
              onClick={() => {
                this.myGantt.exportToMSProject({});
              }}
            >
              Exportar a MS Project
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
    return (
      <div>
        {optionsPane}
        {openTaskId && getPopper(openTaskId)}
        {super.render()}
      </div>
    );
  }
}
export default InteractiveGanttChart;
