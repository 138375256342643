import React from "react";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { Row, Collapse } from "react-bootstrap";
import styled from "styled-components";

type Props = {
  text: React.ReactNode;
  detail: React.ReactNode;
};

const StyledButton = styled.button`
  background: inherit;
  border-radius: 5px;
  font-size: 15px;
  border: none;
  user-select: none;
  outline: none;
  color: #053d56;
  text-decoration: underline;
  height: 25px;
  :focus {
    outline: none !important;
  }
`;

const Explanation = ({ text, detail }: Props) => {
  const [open, setOpen] = React.useState(false);
  if (!detail || detail === "")
    return (
      <Row className="justify-content-md-center">
        <div style={{ minHeight: "35px" }}>{text}</div>{" "}
      </Row>
    );
  return (
    <>
      <Row className="justify-content-md-center">
        <div style={{ minHeight: "35px" }}>{text}</div>{" "}
        <span style={{ display: "inline" }}>
          <StyledButton
            type="button"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              setOpen(!open);
            }}
          >
            {" "}
            {open ? (
              <p>
                Ah, ya veo
                <FaCaretUp />
              </p>
            ) : (
              <p>
                ¿Por qué?
                <FaCaretDown />
              </p>
            )}{" "}
          </StyledButton>
        </span>
      </Row>
      <Collapse in={open}>
        <div
          id="example-collapse-text"
          style={{ lineHeight: "30px", background: "inherit" }}
        >
          {" "}
          {detail}{" "}
        </div>
      </Collapse>
    </>
  );
};

export default Explanation;
