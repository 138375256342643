import React from "react";
import { useQuery } from "@apollo/client";
import {
  VerticalGridLines,
  VerticalBarSeries,
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
} from "react-vis";
import { GET_TRAFFIC } from "../graphql-client/queries/admin";
import type {
  ProjectTrafficInput,
  ProjectTrafficEntry,
} from "../graphql-client/codegen-types";
import Loader from "./Loader";

type Props = {
  input: ProjectTrafficInput;
};

const UseChart = ({ input }: Props) => {
  const periodName = "días";
  const periodLength = 1 * 24 * 60;
  const { data, loading, error } = useQuery(GET_TRAFFIC, {
    variables: { periodLength, input },
    errorPolicy: "all",
  });
  if (loading) return <Loader size={60} message="Cargando uso" />;
  if (error)
    return (
      <span>
        {" "}
        Error al cargar. Asegúrate de que tu cuenta tenga derechos de
        administrador.{" "}
      </span>
    );
  const { getProjectTraffic } = data.asAdmin;
  const entries = getProjectTraffic;

  return (
    <div
      style={{
        margin: "20px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "whitesmoke",
      }}
    >
      <b style={{ textAlign: "center" }}> Uso General (Operaciones) </b>
      <XYPlot margin={{ bottom: 70 }} xType="ordinal" width={300} height={300}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis tickLabelAngle={-45} />
        <YAxis />
        <VerticalBarSeries
          barWidth={1}
          data={entries.map((entry: ProjectTrafficEntry) => ({
            x: `hace ${entry.periodsSince} ${periodName}`,
            y: entry.frequency,
          }))}
        />
      </XYPlot>
    </div>
  );
};

export default UseChart;
