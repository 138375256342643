import React from "react";
import { FaChartLine } from "react-icons/fa";
import { Alert } from "react-bootstrap";
import { Reference, FetchResult } from "@apollo/client";
import {
  ADD_BENEFIT,
  EDIT_BENEFIT,
  DELETE_BENEFIT,
  REORDER_BENEFITS,
} from "../../graphql-client/queries/project";
import type {
  Scalars,
  Benefit,
  AddBenefitMutation,
  Project,
} from "../../graphql-client/codegen-types";
import { useProjectMutation } from "./project-context";
import DynamicList from "./DynamicList";
import Explanation from "../../components/Explanation";
import EntradaTexto from "../../components/EntradaTexto";
import client from "../../graphql-client/connection";

type ID = Scalars["ID"];

type Props = {
  project: Project;
  readOnly: boolean;
};

function addBenefitCallback({
  data,
}: FetchResult<AddBenefitMutation>): Benefit {
  const ret = data?.asUser?.editProject?.addBenefit?.newBenefit;
  if (!ret) throw new Error("Error creating benefit");
  return ret;
}

const Benefits = ({ project, readOnly }: Props) => {
  const { cache } = client;
  const addBenefit = useProjectMutation<AddBenefitMutation, { projectId: ID }>(
    ADD_BENEFIT
  );
  const editBenefit = useProjectMutation(EDIT_BENEFIT);
  const deleteBenefit = useProjectMutation(DELETE_BENEFIT);
  const reorderBenefits = useProjectMutation(REORDER_BENEFITS);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorDetail, setErrorDetail] = React.useState("");
  return (
    <div>
      <DynamicList
        title={
          <span>
            {" "}
            <FaChartLine /> Beneficios{" "}
          </span>
        }
        deleteInfo={
          readOnly
            ? undefined
            : {
                onDelete: ({ id }: Benefit) => {
                  cache.modify({
                    id: cache.identify(project),
                    fields: {
                      benefits: (oldBenefits, { readField }) =>
                        oldBenefits.filter(
                          (benefit: Reference) =>
                            id !== readField("id", benefit)
                        ),
                    },
                  });
                  return deleteBenefit({
                    variables: { projectId: project.id, id },
                  }).then(() => true);
                },
                deleteWarnTitle: "¿Eliminar beneficio?",
                deleteWarnMsg: "No se podrá recuperar.",
              }
        }
        renderItem={(benefit: Benefit, isNew: boolean) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              background: "aliceblue",
              borderRadius: "5px",
              padding: "5px",
            }}
          >
            <FaChartLine style={{ margin: "15px 5px 5px 5px" }} />
            <div style={{ width: "100%" }}>
              <EntradaTexto
                ejemplo="Se mejorará la calidad en el servicio. Se incrementarán las ganancias."
                maxLetras={280}
                autoFocus={isNew}
                saveText={
                  readOnly
                    ? () => {}
                    : (text: string) =>
                        editBenefit({
                          variables: {
                            projectId: project.id,
                            input: { id: benefit.id, text },
                          },
                          update() {
                            cache.modify({
                              id: cache.identify(benefit),
                              fields: {
                                text: () => text,
                              },
                            });
                          },
                        })
                }
                textoInicial={benefit.text ?? ""}
                rows={2}
                readOnly={readOnly}
              />
            </div>
          </div>
        )}
        getItemId={({ id }: Benefit) => id}
        initial={project.benefits || []}
        newItem={
          readOnly
            ? undefined
            : () =>
                addBenefit({
                  variables: { projectId: project.id },
                })
                  .then(addBenefitCallback)
                  .then((newBenefit: Benefit) => {
                    cache.modify({
                      id: cache.identify(project),
                      fields: {
                        benefits: (oldBenefits) =>
                          oldBenefits.concat({
                            __ref: cache.identify(newBenefit),
                          }),
                      },
                    });
                    return newBenefit;
                  })
                  .catch((e) => {
                    const applicationErrorDetail =
                      e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail;
                    const projectError = applicationErrorDetail?.projectError;
                    if (projectError?.benefitsLimitExceeded) {
                      setErrorMessage(
                        `No más de ${projectError.benefitsLimitExceeded} beneficios por proyecto`
                      );
                      setErrorDetail(
                        "Demasiados beneficios son difíciles de monitorear"
                      );
                      setTimeout(() => {
                        setErrorMessage("");
                        setErrorDetail("");
                      }, 7000);
                      return null;
                    }
                    throw e;
                  })
        }
        onReorder={
          readOnly
            ? undefined
            : (benefits: Array<Benefit>) =>
                reorderBenefits({
                  variables: {
                    projectId: project.id,
                    idList: benefits.map(({ id }: Benefit) => id),
                  },
                  update() {
                    cache.modify({
                      id: cache.identify(project),
                      fields: {
                        benefits: () =>
                          benefits.map((benefit) => ({
                            __ref: cache.identify(benefit),
                          })),
                      },
                    });
                  },
                })
        }
      />
      {errorMessage !== "" && (
        <Alert variant="warning">
          <Explanation text={errorMessage} detail={errorDetail} />
        </Alert>
      )}
    </div>
  );
};

export default Benefits;
