import React from "react";
import styled from "styled-components";
import { Form, Alert } from "react-bootstrap";
import { FaExclamationTriangle } from "react-icons/fa";
import Explanation from "./Explanation";

type Props = {
  ejemplo: string;
  maxLetras: number;
  saveText: (text: string) => void;
  textoInicial?: string;
  autoFocus?: boolean;
  rows?: number;
  readOnly?: boolean;
};

type State = {
  texto: string;
  showWarning: boolean;
};

const Input = styled.div`
  .form-control {
    border-radius: 3px;
    background: #fafafa;
  }
`;

const initialState = (props: Props) => ({
  texto: props.textoInicial ?? "",
  showWarning: false,
});

const StyledExplanation = styled(Explanation)`
  background: lemonchiffon;
`;

class EntradaTexto extends React.Component<Props, State> {
  state = initialState(this.props);

  textChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const textoNuevo = event.target.value;
    const { maxLetras } = this.props;
    if (textoNuevo.length <= maxLetras) {
      this.setState({ texto: textoNuevo });
    } else {
      this.setState({ showWarning: true });
      setTimeout(() => this.setState({ showWarning: false }), 10000);
    }
  };

  render() {
    const {
      ejemplo,
      saveText,
      maxLetras,
      autoFocus = false,
      rows = 3,
      readOnly = false,
      textoInicial = "",
    } = this.props;
    const { texto, showWarning } = this.state;

    const warning = (
      <Alert
        variant="warning"
        style={{ marginTop: "5px" }}
        dismissible
        onClose={() => this.setState({ showWarning: false })}
      >
        <StyledExplanation
          text={
            <span>
              <FaExclamationTriangle /> {maxLetras} caracteres máximo
            </span>
          }
          detail={
            <span>
              <hr />
              <b>Para mantener claridad y simplicidad.</b>
            </span>
          }
        />
      </Alert>
    );
    const onBlur = () => {
      if (texto !== textoInicial) saveText(texto);
    };
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Input>
          <Form.Control
            as="textarea"
            rows={rows}
            value={texto}
            onChange={this.textChange}
            placeholder={ejemplo}
            onBlur={onBlur}
            autoFocus={autoFocus}
            readOnly={readOnly}
          />
        </Input>
        {showWarning ? (
          <div>{warning}</div>
        ) : (
          <Form.Text muted>
            Quedan {maxLetras - texto.length} caracteres.
          </Form.Text>
        )}
      </div>
    );
  }
}

export default EntradaTexto;
