import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Alert, Button, Card, Collapse, Form, Modal } from "react-bootstrap";
import { isBefore } from "date-fns";
import ReCAPTCHA from "react-google-recaptcha";
import type { ReCAPTCHA as ReCAPTCHAType } from "react-google-recaptcha";
import { FaEnvelopeOpenText, FaAngleRight, FaAngleDown } from "react-icons/fa";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { useUserContext } from "./components/user-context";
import Loader from "./components/Loader";
import { parseStartDate } from "./project/components/gantt/utils";
import type { Plan } from "./graphql-client/codegen-types";
import {
  REVALIDATE_EMAIL,
  GET_PLAN,
  GET_REVALIDATION_EMAIL,
  CREATE_PAYMENT_SESSION,
} from "./graphql-client/queries/account";

const StyledDiv = styled.div`
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const ProModalContext = React.createContext<{
  show: string | undefined;
  setShow: (show: string | undefined) => void;
}>({ show: undefined, setShow: () => {} });

const isPlanPro = (plan: Plan) => {
  /* eslint no-underscore-dangle: 0 */
  if (plan.__typename === "Basic") return false;
  /* eslint no-underscore-dangle: 0 */
  if (plan.__typename === "Individual") return true;
  return isBefore(new Date(), parseStartDate((plan as any).activeUntil));
};

export type ProVerifier = [
  (functionality?: string | undefined) => boolean,
  boolean
];

export const useProVerifier = (): ProVerifier => {
  const { setShow } = React.useContext(ProModalContext);
  const { data, loading, error } = useQuery(GET_PLAN, {
    fetchPolicy: "cache-first",
  });
  const plan = data?.asUser?.plan;
  const confirmProAccess = (functionality: string | undefined) => {
    // If null, means isn't in cache. Load modal to execute fetch.
    if (!plan || !isPlanPro(plan)) {
      setShow(functionality || "Funcionalidad solo disponible en pro");
      return false;
    }
    return true;
  };
  const planPro: boolean = plan && isPlanPro(plan);
  return [confirmProAccess, planPro];
};

export const InstitutionOptions = ({ plan }: { plan: Plan }) => {
  const captchaRef = React.useRef<ReCAPTCHAType | null>(null);
  const [revalidateEmail] = useMutation(GET_REVALIDATION_EMAIL);
  const [captcha, setCaptcha] = React.useState<string | null>(null);
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const user = useUserContext();
  /* eslint no-underscore-dangle: 0 */
  if (plan.__typename !== "Enterprise") return null;
  const { id, name, logoUrl } = plan.institution;

  const onClick = () => {
    setLoading(true);
    revalidateEmail({ variables: { captchaKey: captcha } })
      .then(({ data }) => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((e) => {
        const accountError =
          e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail
            ?.accountError;
        setLoading(false);
        captchaRef.current?.reset();
        if (accountError) {
          const { invalidCaptcha } = accountError;
          if (invalidCaptcha)
            setErrorMessage("El captcha expiró, vuélvelo a llenar");
        } else {
          console.log("Unknown error");
          console.log(e);
        }
      });
  };

  const validateEmailMsg = (
    <Card.Body className="d-flex flex-column align-items-center">
      <Card.Text>
        Valida que tu correo institucional sigue activo, y automáticamente
        tendrás acceso completo a <b>Coordinate Pro</b>.
      </Card.Text>
      <ReCAPTCHA
        ref={captchaRef}
        sitekey={
          // TODO: Handle keys in a better way
          process.env.NODE_ENV === "production"
            ? "6LcR6dkZAAAAAJyV5Q-_8O8Ij2lcmPpVx_9iWVpJ"
            : "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
        }
        onChange={setCaptcha}
      />
      <br />
      {loading ? (
        <Loader message="Mandando correo" size={40} />
      ) : (
        <Button variant="primary" onClick={onClick} disabled={captcha === null}>
          Mandar correo de validación
        </Button>
      )}
      {errorMessage !== "" && (
        <Alert variant="danger" style={{ marginTop: "20px" }}>
          {"  "}
          {errorMessage}
        </Alert>
      )}
    </Card.Body>
  );
  const successMsg = (
    <div style={{ margin: "1px" }}>
      <Alert variant="success" style={{ textAlign: "center" }}>
        Correo de revalidación enviado a <b>{user?.email || "tu correo"}</b>.
        Completa las instrucciones incluídas ahí, y entonces tendrás acceso
        completo a Coordinate Pro.
      </Alert>
    </div>
  );
  return (
    <Card>
      <StyledDiv onClick={() => setExpanded(!expanded)}>
        <Card.Header as="h5" className="d-flex align-items-center">
          {expanded ? (
            <FaAngleDown onClick={() => setExpanded(false)} />
          ) : (
            <FaAngleRight onClick={() => setExpanded(true)} />
          )}
          <img
            src={logoUrl || ""}
            alt={`Logo de ${name}`}
            style={{
              maxWidth: "50px",
              maxHeight: "50px",
              margin: "0 10px 0 0",
            }}
          />
          <div>
            Gratis para miembros de <b>{name}</b>
          </div>
        </Card.Header>
      </StyledDiv>
      <Collapse in={expanded}>
        <div>{success ? successMsg : validateEmailMsg}</div>
      </Collapse>
    </Card>
  );
};

export const SubscriptionOptions = () => {
  const [sessionUrl, setSessionUrl] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [createPaymentSession] = useMutation(CREATE_PAYMENT_SESSION);
  const onClick = () => {
    if (sessionUrl !== "") {
      window.open(sessionUrl, "_blank");
    } else {
      setLoading(true);
      // TODO: handle errors
      createPaymentSession().then(({ data }) => {
        setLoading(false);
        const url = data?.asUser?.createPaymentSession?.portalSessionUrl;
        if (url) setSessionUrl(url);
        window.open(url);
      });
    }
  };
  return (
    <Card>
      {loading ? (
        <Loader message="Cargando portal de pagos" size={40} />
      ) : (
        <StyledDiv onClick={onClick}>
          <Card.Header as="h5" className="d-flex align-items-center">
            <img
              src="/favicon.ico"
              alt="Logo de Coordinate"
              style={{
                maxWidth: "50px",
                maxHeight: "50px",
                margin: "0 10px 0 15px",
              }}
            />
            <div style={{ marginRight: "10px" }}>
              Sólo <b>$99</b> pesos al mes
            </div>
            <Button variant="info"> Pagar ahora </Button>
          </Card.Header>
        </StyledDiv>
      )}
    </Card>
  );
};

const ADDITIONAL_OPTIONS_LINK = "https://coordinate.mx/index.php/contacto";
export const AdditionalOptions = () => {
  const onClick = () => {
    window.open(ADDITIONAL_OPTIONS_LINK);
  };
  return (
    <Card>
      <StyledDiv onClick={onClick}>
        <Card.Header as="h5" className="d-flex align-items-center">
          <img
            src="/favicon.ico"
            alt="Logo de Coordinate"
            style={{
              maxWidth: "50px",
              maxHeight: "50px",
              margin: "0 10px 0 15px",
            }}
          />
          <div style={{ marginRight: "10px" }}>
            Para más opciones de pago y planes empresariales contáctanos por{" "}
            <b>aquí</b>
          </div>
        </Card.Header>
      </StyledDiv>
    </Card>
  );
};

const FREE_FORM_LINK = "https://forms.gle/VQ6raCZcyBLJt4Mp9";
export const FreeForm = () => {
  const onClick = () => {
    window.open(FREE_FORM_LINK);
  };
  return (
    <Card>
      <StyledDiv onClick={onClick}>
        <Card.Header as="h5" className="d-flex align-items-center">
          <img
            src="/favicon.ico"
            alt="Logo de Coordinate"
            style={{
              maxWidth: "50px",
              maxHeight: "50px",
              margin: "0 10px 0 15px",
            }}
          />
          <div style={{ marginRight: "10px" }}>
            Puedes ganar una licencia de <b>Coordinate Pro</b>. Solicítala{" "}
            <b>aquí</b>
          </div>
        </Card.Header>
      </StyledDiv>
    </Card>
  );
};

export const ProModal = ({
  show,
  setShow,
}: {
  show: string | undefined;
  setShow: (show: string | undefined) => void;
}) => {
  const { data, loading, error } = useQuery(GET_PLAN, {
    fetchPolicy: "cache-first",
  });
  const handleClose = () => setShow(undefined);
  if (loading) {
    return (
      <Modal show={show !== undefined && show !== null} onHide={handleClose}>
        <Loader size={60} message="Cargando tu plan" />
      </Modal>
    );
  }
  if (error) console.log(error);
  const { plan } = data.asUser;

  return (
    <Modal show={show && !isPlanPro(plan)} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Coordinate Pro</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: "18px" }}>
        <div style={{ textAlign: "center", marginBottom: "12px" }}>{show}</div>
        <hr />
        <div style={{ textAlign: "center", marginBottom: "12px" }}>
          Para acceder a todas las funcionalidades de Coordinate, ¡Consigue{" "}
          <b>Pro</b> ahora!
        </div>
        <SubscriptionOptions />
        {
          /* volver a agregar en caso de revalidacion de correo institucional */ false && (
            <InstitutionOptions plan={plan} />
          )
        }
        <FreeForm />
        <AdditionalOptions />
      </Modal.Body>
    </Modal>
  );
};

export const ProModalWrapper = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const [show, setShow] = React.useState<string | undefined>(undefined);
  const value = { show, setShow };
  return (
    <ProModalContext.Provider value={value}>
      {" "}
      <ProModal show={show} setShow={setShow} /> {children}{" "}
    </ProModalContext.Provider>
  );
};

export const PlanDescription = () => {
  const { data, loading, error } = useQuery(GET_PLAN, {
    fetchPolicy: "cache-first",
  });
  const [sessionUrl, setSessionUrl] = React.useState("");
  const [loadingSession, setLoadingSession] = React.useState(false);
  const [createPaymentSession] = useMutation(CREATE_PAYMENT_SESSION);
  const [confirmPro] = useProVerifier();
  if (loading) {
    return <Loader size={60} message="Cargando tu plan" />;
  }
  if (error) console.log(error);
  const { plan } = data.asUser;

  if (isPlanPro(plan)) {
    const onClick = () => {
      if (sessionUrl !== "") {
        window.open(sessionUrl, "_blank");
      } else {
        setLoadingSession(true);
        // TODO: handle errors
        createPaymentSession().then((res) => {
          setLoadingSession(false);
          const url = res?.data?.asUser?.createPaymentSession?.portalSessionUrl;
          if (url) setSessionUrl(url);
          window.open(url);
        });
      }
    };
    const subscriptionMsg = loadingSession ? (
      <Loader message="Cargando portal de pagos" size={40} />
    ) : (
      <Button variant="info" onClick={onClick}>
        {" "}
        Actualizar suscripción{" "}
      </Button>
    );
    /* eslint no-underscore-dangle: 0 */
    if (plan.__typename === "Enterprise") {
      const { name, logoUrl } = plan.institution;
      return (
        <div className="d-flex flex-column align-items-center">
          <h5>
            Tienes acceso ilimitado a Coordinate <b> Pro </b>
          </h5>
          <p className="text-muted">
            {" "}
            Gracias a que perteneces a la institución asociada:{" "}
          </p>
          <div className="d-flex align-items-center">
            <img
              src={logoUrl || ""}
              alt={`Logo de ${name}`}
              style={{
                maxWidth: "50px",
                maxHeight: "50px",
                margin: "0 10px 0 0",
              }}
            />
            <h5>{name}</h5>
          </div>
        </div>
      );
    }
    return (
      <div>
        <h5>
          {" "}
          Gracias por ser <b> Usuario Pro </b> de Coordinate{" "}
        </h5>
        {subscriptionMsg}
      </div>
    );
  }
  return (
    <div>
      <h5>
        {" "}
        Tienes un <b>plan básico</b>
      </h5>
      <div style={{ marginBottom: "5px" }}>
        {" "}
        <i>
          Algunas funcionalidades estarán limitadas hasta que te actualices a{" "}
          <b>Pro</b>{" "}
        </i>
      </div>
      <Button
        variant="info"
        onClick={() => confirmPro("Actualmente tienes un plan básico")}
      >
        {" "}
        Actualizar a <b>Pro</b>{" "}
      </Button>
    </div>
  );
};

const CustomEnvelopeIcon = styled(FaEnvelopeOpenText)`
  margin: 0 30px 10px 10px;
  border: 1px;
`;

export const RevalidateEmail = () => {
  const [revalidateEmail] = useMutation(REVALIDATE_EMAIL);
  const params = useParams<{ token: string }>();
  const history = useHistory();
  const { token } = params;
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const submit = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    setErrorMessage("");
    setLoading(true);
    revalidateEmail({ variables: { token } })
      .then(() => {
        setLoading(false);
        history.push("/home");
      })
      .catch((e) => {
        const accountError =
          e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail
            ?.accountError;
        setLoading(false);
        if (accountError) {
          const { expiredToken, invalidToken } = accountError;
          if (expiredToken)
            setErrorMessage(
              "El enlace ha expirado, vuelve a solicitar el correo de confirmación"
            );
          if (invalidToken)
            setErrorMessage(
              "El enlace es inválido o ha expirado. Asegura que lo recibiste de una cuenta autorizada de Coordinate"
            );
        } else {
          console.log("Unexpected error");
          console.log(e);
        }
      });
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <Card className="text-center" style={{ maxWidth: "800px" }}>
        <Card.Header as="h5">Revalidación de cuenta</Card.Header>
        <Card.Body>
          <Form>
            <Form.Label style={{ fontSize: "18px" }}>
              {" "}
              Haz click en el siguiente botón para terminar el proceso de
              revalidación de cuenta. Después de eso, serás oficialmente un
              usuario Pro y tendrás acceso a todas las funcionalidades de
              Coordinate.
            </Form.Label>
            <hr />
            {!loading && (
              <Button variant="info" type="submit" onClick={submit}>
                Revalidar cuenta
              </Button>
            )}
            {loading && <Loader size={60} message="Revalidando" />}
            {errorMessage !== "" && (
              <Alert variant="danger" style={{ marginTop: "20px" }}>
                {"  "}
                {errorMessage}
              </Alert>
            )}
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};
