import React, { PropsWithChildren, ReactElement } from "react";
import { Tooltip } from "@material-ui/core";
import { FaQuestionCircle } from "react-icons/fa";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

type Props = {
  pregunta: React.ReactNode;
  descripcion: string;
  detailLink?: string;
};

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "15px",
      },
    },
  },
});

// Esto necesita no ser una arrow function
// https://github.com/yannickcr/eslint-plugin-react/issues/2353

export default function Pregunta({
  pregunta,
  descripcion,
  detailLink,
  children,
}: PropsWithChildren<Props>): ReactElement {
  const getTooltipContent = () => {
    if (detailLink)
      return (
        <Tooltip title={`${descripcion}. Click para más.`} arrow>
          <a
            href={detailLink}
            target="_blank"
            rel="noreferrer"
            style={{ color: "#053d56" }}
          >
            <FaQuestionCircle />
          </a>
        </Tooltip>
      );
    return (
      <Tooltip title={descripcion} arrow>
        <span>
          <FaQuestionCircle />
        </span>
      </Tooltip>
    );
  };
  return (
    <div>
      <div>
        {pregunta}
        {
          " " /* Este espacio es importante para separar el icono de la pregunta */
        }
        <MuiThemeProvider theme={theme}>{getTooltipContent()}</MuiThemeProvider>
      </div>
      {children !== undefined && (
        <div style={{ marginTop: "5px" }}> {children} </div>
      )}
    </div>
  );
}
