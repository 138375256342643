import React from "react";
import { Page, Text, View, Document } from "@react-pdf/renderer";
import type { WbsNode, Project } from "../../../graphql-client/codegen-types";
import PDFQuestion from "../../components/pdf/PDFQuestion";
import PDFHeader from "../../components/pdf/PDFHeader";
import PDFTitle from "../../components/pdf/PDFTitle";

type Props = {
  project: Project;
};

const PDFReport = ({ project }: Props) => {
  const { name, wbsRoot } = project;
  return (
    <Document>
      <Page size="A4" style={{ padding: "40px", color: "#053d56" }}>
        <PDFHeader name={name || "[Proyecto sin nombre]"} />
        <PDFTitle title="¿Cómo?" />
        <View style={{ fontSize: "15px", lineHeight: "1.3" }}>
          <PDFQuestion question="¿Qué actividades son necesarias para completar los entregables?">
            {(wbsRoot?.children || []).map(
              (deliv: WbsNode, delivIdx: number) => (
                <View key={deliv.id}>
                  <Text>
                    {delivIdx + 1}. {deliv.text}
                  </Text>
                  {(deliv.children || []).map(
                    (act: WbsNode, actIdx: number) => (
                      <Text key={act.id} style={{ marginLeft: "7px" }}>
                        {" "}
                        {delivIdx + 1}. {actIdx + 1}. {act.text}{" "}
                      </Text>
                    )
                  )}
                </View>
              )
            )}
          </PDFQuestion>
        </View>
      </Page>
    </Document>
  );
};

export default PDFReport;
