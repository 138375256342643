import React from "react";
import type { GanttStatic } from "dhtmlx-gantt";
import { isSameDay, addDays } from "date-fns";
import { Tab, Tabs } from "react-bootstrap";
import type { ComovaNode } from "./types";
import GanttNodeDescription from "../../components/gantt/GanttNodeDescription";
import { getEarliestStart } from "../../components/gantt/utils";
import GanttDateEditor from "../../components/gantt/GanttDateEditor";
import { ActLabel } from "../../../components/utils";
import Pregunta from "../../../components/Pregunta";
import StatusEditor from "./StatusEditor";

type Props = {
  task: ComovaNode;
  readOnly: boolean;
  gantt: GanttStatic;
  statusDate: Date;
  workDays?: Array<boolean>;
};

const ComovaActEditor = ({
  task,
  readOnly,
  gantt,
  workDays,
  statusDate,
}: Props) => {
  const [key, setKey] = React.useState("status");
  const { id, start_date, end_date } = task;
  if (readOnly) {
    return <GanttNodeDescription node={task} />;
  }

  const startDate = start_date;
  const endDate = addDays(end_date, -1);
  const save = (start: Date, end: Date) => {
    if (
      !isSameDay(start, task.start_date) ||
      !isSameDay(addDays(end, 1), task.end_date)
    ) {
      task.start_date = start;
      task.end_date = addDays(end, 1);
      gantt.autoSchedule(id);
      gantt.updateTask(id);
    }
  };
  const earliestStartDate = getEarliestStart(id, gantt);
  const pregunta = (
    <span>
      Si las fechas de esta <ActLabel /> cambiaron, <br />
      actualízalas aquí.
    </span>
  );
  const descripcion =
    "Si las fechas para realizar la actividad cambiaron, repórtalo aquí.";
  return (
    <Tabs
      defaultActiveKey="status"
      activeKey={key}
      onSelect={(k: string | null) => setKey(k || "status")}
    >
      <Tab eventKey="status" title="1. Estatus">
        <div>
          <StatusEditor
            node={task}
            gantt={gantt}
            statusDate={statusDate}
            switchToDates={() => setKey("dates")}
          />
        </div>
      </Tab>
      <Tab eventKey="dates" title="2. Fechas Actuales">
        <div style={{ margin: "20px" }}>
          <Pregunta pregunta={pregunta} descripcion={descripcion}>
            <GanttDateEditor
              startDate={startDate}
              endDate={endDate}
              earliestStartDate={earliestStartDate}
              save={save}
              workDays={workDays}
            />
          </Pregunta>
        </div>
      </Tab>
    </Tabs>
  );
};

export default ComovaActEditor;
