import React from "react";
import styled from "styled-components";
import { Container, Dropdown, Button, Row, Col } from "react-bootstrap";
import { FaPlus, FaGlobeAmericas, FaTasks } from "react-icons/fa";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  useProjectMutation,
  useProjectQuery,
} from "../components/project-context";
import {
  SET_DUTY,
  GET_MEMBERS_WBS,
} from "../../graphql-client/queries/project";
import {
  Step,
  Access,
  Member,
  WbsNode,
  Job,
  Duty,
} from "../../graphql-client/codegen-types";
import {
  ProjectLabel,
  DelivLabel,
  ActLabel,
  Rhombus,
} from "../../components/utils";
import Pregunta from "../../components/Pregunta";
import Loader from "../../components/Loader";
import { InlineList, InlineListItem } from "../../components/InlineList";
import PDFReport from "./components/PDFReport";
import ExcelReport from "./components/ExcelReport";
import client from "../../graphql-client/connection";

const StyledToggle = styled(Dropdown.Toggle)`
  background: aliceblue;
  border: 1px solid steelblue;
  padding: 0 5px 0 5px;
  :after {
    display: none;
  }
`;

const RespArea = ({
  projectId,
  node,
  members,
  level,
  access,
}: {
  projectId: string | number;
  node: WbsNode;
  members: Array<Member>;
  level: number;
  access: Access;
}) => {
  const setDuty = useProjectMutation(SET_DUTY);
  const { cache } = client;
  const readOnly = access === Access.Read;
  const { duties } = node;
  if (!duties) throw Error("Duties is null");

  const availableMembers = members.filter(
    (m) => !duties?.some((d) => d.memberId === m.id)
  );

  const getTitle = (plural: boolean) => {
    if (level === 0) return `Coordinador${plural ? "es" : ""}`;
    if (level === 1) return `Responsable${plural ? "s" : ""}`;
    return `Ejecutor${plural ? "es" : ""}`;
  };
  const title = `Agregar ${duties.length > 0 ? "otro " : " "}${getTitle(
    false
  )}`;
  const getMemberText = (m: Member) => {
    if (m.user) {
      if (!m.user.handle) throw Error("a user's handle can't be null");
      return m.user.handle;
    }
    return m.name || "";
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {duties.length > 0 && (
        <b style={{ paddingRight: "3px" }}> {getTitle(duties.length > 1)}: </b>
      )}
      <InlineList>
        {duties.map((duty) => {
          const member = members.find((m) => m.id === duty.memberId);
          if (!member) {
            // do not render null members
            return null;
          }
          return (
            <InlineListItem
              key={`${duty.memberId}-${duty.job}`}
              onDelete={
                readOnly
                  ? undefined
                  : () => {
                      setDuty({
                        variables: {
                          projectId,
                          memberId: member.id,
                          wbsNodeId: node.id,
                          job: null, // null job removes this duty from the node duties
                        },
                      });
                      cache.modify({
                        id: cache.identify(node),
                        fields: {
                          duties: (oldDuties) =>
                            oldDuties.filter(
                              (currentDuty: Duty) =>
                                duty.memberId !== currentDuty.memberId
                            ),
                        },
                      });
                    }
              }
            >
              {getMemberText(member)}
            </InlineListItem>
          );
        })}
      </InlineList>
      {duties.length === 0 && (
        <span style={{ paddingRight: "5px" }}>
          {" "}
          Escoge al menos un <b>{getTitle(false)}</b>{" "}
        </span>
      )}
      {!readOnly && (
        <Dropdown title={title}>
          <StyledToggle variant="light" id="dropdown-basic">
            <FaPlus style={{ width: "12px", height: "12px" }} />
          </StyledToggle>
          <Dropdown.Menu>
            {availableMembers &&
              availableMembers.map((m) => (
                <Dropdown.Item
                  key={m.id}
                  onClick={() => {
                    cache.modify({
                      id: cache.identify(node),
                      fields: {
                        duties: (oldDuties) =>
                          oldDuties.concat({
                            memberId: m.id,
                            __typename: "Duty",
                          }),
                      },
                    });
                    setDuty({
                      variables: {
                        projectId,
                        memberId: m.id,
                        wbsNodeId: node.id,
                        job: Job.Execute, // just a random job since it's going away
                      },
                    }).catch((e: Error) => {
                      console.error(e);
                    });
                  }}
                >
                  {getMemberText(m)}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};

const WbsRow = ({
  wbs,
  projectId,
  members,
  level,
  access,
}: {
  wbs: WbsNode;
  projectId: string | number;
  members: Array<Member>;
  level: number;
  access: Access;
}) => {
  const getColor = () => {
    if (level === 0) return "#0086ba";
    if (level === 1) return "coral";
    return "#999900";
  };
  const getBackground = () => {
    if (level === 0) return "#E6E8FA";
    if (level === 1) return "#fff1d8";
    return "white";
  };

  const getLabel = () => {
    if (level === 0) return <FaGlobeAmericas style={{ color: "#0086ba" }} />;
    if (level === 1) return <Rhombus />;
    return <FaTasks style={{ color: "#999900" }} />;
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          background: getBackground(),
          borderRadius: "5px",
          border: `1px solid ${getColor()}`,
          marginBottom: "5px",
          padding: "7px",
        }}
      >
        <Container fluid>
          <Row>
            <Col>
              <b>
                {getLabel()} {wbs.text}
              </b>
            </Col>
            <Col>
              {" "}
              <RespArea
                projectId={projectId}
                node={wbs}
                members={members}
                level={level}
                access={access}
              />{" "}
            </Col>
          </Row>
        </Container>
      </div>
      {(wbs.children?.length ?? []) > 0 && level < 2 && (
        <div>
          <div style={{ paddingLeft: "20px" }}>
            {(wbs.children ?? []).map((child: WbsNode) => (
              <WbsRow
                key={child.id}
                wbs={child}
                projectId={projectId}
                members={members}
                level={level + 1}
                access={access}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

type Props = {
  projectId: string;
};

const Quien = ({ projectId }: Props) => {
  const { loading, error, data, refetch } = useProjectQuery(GET_MEMBERS_WBS, {
    variables: { id: projectId, step: Step.Quien },
  });

  if (loading) return <Loader size={60} message="Cargando el proyecto" />;
  if (error) return <p> Error al cargar la WBS </p>;
  const project = data.asUser.getProject;

  const { members, wbsRoot, id } = project;
  if (!members || !wbsRoot) throw new Error("Members or wbsRoot is null");

  return (
    <div>
      <div>
        {" "}
        Ya definiste los <DelivLabel plural /> necesarios para completar el
        proyecto, junto con sus <ActLabel plural /> respectivas. Ahora, toca
        asignar roles a los miembros del equipo.
      </div>
      <hr />
      <div style={{ marginBottom: "10px" }}>
        <Pregunta
          pregunta={
            <span>
              1. Escoge cuál miembro del equipo será el <b>Coordinador</b> de
              este <ProjectLabel />.
            </span>
          }
          descripcion={`Todo Proyecto tiene un Coordinador. Esta persona
        tiene el rol de líder y motivante para el equipo de trabajo.`}
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Pregunta
          pregunta={
            <span>
              2. Para cada <DelivLabel />, escoge quién será su{" "}
              <b>Responsable</b>.
            </span>
          }
          descripcion="Cada Entregable tiene un Responsable, encargado de supervisar que sus actividades se completen a tiempo."
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Pregunta
          pregunta={
            <span>
              3. Para cada <ActLabel />, define quién la <b>Ejecutará</b>.
            </span>
          }
          descripcion="Las Actividades son tareas indivisibles (si se pueden subdividir, deberían ser Entregables). Por lo tanto, cada una tiene un Ejecutor, encargado de realizarla."
        />
      </div>
      <br />
      {wbsRoot !== null && (
        <WbsRow
          wbs={wbsRoot}
          level={0}
          projectId={id}
          members={members}
          access={project.access!}
        />
      )}
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <PDFDownloadLink
          document={<PDFReport project={project} />}
          fileName="Quien.pdf"
        >
          <Button variant="info"> Imprimir reporte en PDF </Button>
        </PDFDownloadLink>
        {wbsRoot && <ExcelReport wbsRoot={wbsRoot} members={members} />}
      </div>
    </div>
  );
};

export default Quien;
