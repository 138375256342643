import {
  HeadingLevel,
  AlignmentType,
  VerticalAlign,
  ImageRun,
  WidthType,
  TableCell,
  TableRow,
  Table,
  Header,
  Paragraph,
  TextRun,
  BorderStyle,
} from "docx";
import { completeFormat } from "../../../components/utils";
import type { Project } from "../../../graphql-client/codegen-types";

const noBorder = {
  style: BorderStyle.NONE,
  size: 0,
  color: "FFFFFF",
};

const onlyBottomBorder = {
  top: noBorder,
  bottom: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: "053D56",
  },
  left: noBorder,
  right: noBorder,
};

const docxHeader = (project: Project, logoBlob: any) =>
  new Header({
    children: [
      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new ImageRun({
                        // typescript no acepta blobs
                        data: logoBlob as any,
                        transformation: {
                          width: 137,
                          height: 40,
                        },
                      }),
                    ],
                  }),
                ],
                borders: onlyBottomBorder,
                verticalAlign: VerticalAlign.CENTER,
                width: {
                  size: 25,
                  type: WidthType.PERCENTAGE,
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: project.name || "",
                        bold: true,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                    heading: HeadingLevel.HEADING_3,
                  }),
                ],
                borders: onlyBottomBorder,
                verticalAlign: VerticalAlign.CENTER,
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: completeFormat(new Date()),
                    alignment: AlignmentType.RIGHT,
                    heading: HeadingLevel.HEADING_3,
                  }),
                ],
                borders: onlyBottomBorder,
                verticalAlign: VerticalAlign.CENTER,
                width: {
                  size: 20,
                  type: WidthType.PERCENTAGE,
                },
              }),
            ],
          }),
        ],
      }),
    ],
  });

export default docxHeader;
