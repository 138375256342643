import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const LogoWrap = styled.div`
  display: flex;
  flex-direction: row;
  color: #053d56;
  padding: 10px 20px 10px 20px;
  align-items: center;
  background: rgba(240, 248, 255, 0.6);
  margin: 20px;
  border-radius: 50px;
  justify-content: center;
  border: 2px solid lightgrey;
`;

const Vr = styled.hr`
  border: none;
  border-left: 1px solid steelblue;
  height: 100%;
  width: 1px;
  margin: 0 8px 0 0;
`;

const Logo = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const image = <img src="/coordinate-logo.png" alt="coordinate" />;
  const label = (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <b>
        {" "}
        Software <span style={{ color: "coral" }}>sencillo</span> y{" "}
        <span style={{ color: "coral" }}>poderoso</span> para
      </b>
      <h5 style={{ margin: "0", color: "#0086ba" }}>
        {" "}
        <b> Administración de Proyectos </b>
      </h5>
      <b
        style={{
          fontSize: "11px",
          textAlign: "center",
          background: "#0086ba",
          borderRadius: "8px",
          color: "#ecc700",
          padding: "0 10px 0 10px",
        }}
      >
        {" "}
        Nueva versión{" "}
      </b>
    </div>
  );
  if (isTabletOrMobile)
    return (
      <LogoWrap style={{ flexDirection: "column", borderRadius: "30px" }}>
        {image}
        {label}
      </LogoWrap>
    );
  return (
    <LogoWrap>
      {image}
      <Vr />
      {label}
    </LogoWrap>
  );
};

export default Logo;
