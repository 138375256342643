import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";
import { Navbar, Dropdown } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { FaGlobeAmericas } from "react-icons/fa";
import steps from "./constants";
import type { Step } from "./types";
import { useProjectQuery } from "./project-context";
import ProjectSaveStatus from "./ProjectSaveStatus";
import { PROJECT_NAME } from "../../graphql-client/queries/project";
import client from "../../graphql-client/connection";

const { cache } = client;

const StyledDiv = styled.div`
  background: aliceblue;
  padding: 4px;
  border-radius: 7px;
  margin: 5px;
  border: 1px solid #cccc00;
`;

const ProjectNameLabel = () => {
  const { projectId, stepPath } = useParams<{
    projectId: string;
    stepPath: string;
  }>();
  const { data } = useProjectQuery(PROJECT_NAME, {
    variables: { id: projectId, step: stepPath.toUpperCase() },
    fetchPolicy: "cache-only",
  });
  const project = data?.asUser?.getProject;
  return <b style={{ marginLeft: "7px", color: "#0086ba" }}>{project?.name}</b>;
};

const StepPane = ({
  redirRoute,
  stepIdx,
  step,
}: {
  redirRoute: string;
  stepIdx: number;
  step: Step;
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const history = useHistory();

  if (isTabletOrMobile) {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      history.push(event.target.value);
    };
    return (
      <Dropdown>
        <Dropdown.Toggle variant="info" id="dropdown-basic">
          <span
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "19px",
              whiteSpace: "nowrap",
            }}
          >
            {stepIdx + 1}. {step.title}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {steps.map((currentStep, idx) => (
            <Dropdown.Item
              key={currentStep.path}
              onClick={() => history.push(`${redirRoute}/${currentStep.path}`)}
            >
              {idx + 1}. {currentStep.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  const stepList = steps.map((myStep: Step, myIdx: number) => {
    if (stepIdx === myIdx) {
      return (
        <StyledDiv
          key={myStep.path}
          style={{ padding: "2px 8px 2px 8px", margin: "2px" }}
        >
          <a href={step.articleLink} target="_blank" rel="noreferrer">
            <b
              style={{
                color: "#0484bc",
                fontSize: "19px",
                whiteSpace: "nowrap",
              }}
            >
              {stepIdx + 1}. {step.title}
            </b>
          </a>
        </StyledDiv>
      );
    }
    return (
      <Link key={myStep.path} to={`${redirRoute}/${steps[myIdx].path}`}>
        <Tooltip title={myStep.title} arrow>
          <StyledDiv
            style={{
              padding: "0",
              textAlign: "center",
              height: "30px",
              width: "30px",
              borderRadius: "20px",
              margin: "2px",
            }}
          >
            <b style={{ color: "coral", fontSize: "19px" }}>{myIdx + 1}</b>
          </StyledDiv>
        </Tooltip>
      </Link>
    );
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      {stepList}
    </div>
  );
};

const ProjectNameArea = () => {
  return (
    <span style={{ fontSize: "15px", fontWeight: "normal", lineHeight: "1.1" }}>
      {" "}
      <FaGlobeAmericas style={{ color: "#0086ba" }} />
      <ProjectNameLabel />{" "}
    </span>
  );
};

const ProjectNavbar = () => {
  const { projectId, stepPath } = useParams<{
    projectId: string;
    stepPath: string;
  }>();

  const redirRoute = `/projects/${projectId}`;
  const idx = steps.findIndex(({ path }) => path === stepPath);
  const step = steps[idx];

  return (
    <Navbar
      fixed="top"
      style={{
        height: "60px",
        border: "1px solid gainsboro",
        background: "#e8f0f7",
        marginTop: "50px",
        padding: "0",
        zIndex: 19,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            maxWidth: "20%",
            display: "flex",
            flexDirection: "row",
            padding: "4px",
            alignItems: "center",
          }}
        >
          <ProjectNameArea />
        </div>
        <div
          style={{
            maxWidth: "60%",
            padding: "4px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <StepPane redirRoute={redirRoute} stepIdx={idx} step={step} />
        </div>
        <div
          style={{
            width: "20%",
            padding: "4px",
            display: "flex",
            textAlign: "right",
            justifyContent: "flex-end",
            alignItems: "center",
            lineHeight: "1.1",
          }}
        >
          <ProjectSaveStatus />
        </div>
      </div>
    </Navbar>
  );
};

export default ProjectNavbar;
