const docxStyles = {
  default: {
    heading2: {
      paragraph: {
        spacing: {
          line: 350,
        },
      },
      run: {
        size: 20,
        color: "777777",
      },
    },
    heading3: {
      run: {
        color: "053D56",
      },
    },
    listParagraph: {
      run: {
        color: "053D56",
      },
    },
  },
};

export default docxStyles;
