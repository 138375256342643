import React from "react";
import { FaCompress, FaExpand } from "react-icons/fa";
import { Button } from "react-bootstrap";

const ESCAPE_KEY = 27;

export const useFullscreen = (): [
  boolean,
  (active: boolean) => void,
  React.ReactNode
] => {
  const [active, setActive] = React.useState(false);
  const onClick = () => setActive(!active);
  React.useEffect(() => {
    const handleKeyDown = ({ keyCode }: { keyCode: number }) => {
      if (keyCode === ESCAPE_KEY) setActive(false);
    };
    document.addEventListener("keydown", handleKeyDown);
  }, [setActive]);

  const fullscreenHandler = (
    <Button
      onClick={onClick}
      variant="light"
      style={{ border: "1px solid lightgrey" }}
    >
      {active ? <FaCompress /> : <FaExpand />}
    </Button>
  );
  return [active, setActive, fullscreenHandler];
};

export const Fullscreen = ({
  active,
  children,
}: React.PropsWithChildren<{ active: boolean }>) => {
  if (active) {
    return (
      <div
        style={{
          position: "fixed",
          zIndex: 30,
          overflowY: "scroll",
          overflowX: "hidden",
          top: "0",
          left: "0",
          bottom: "0",
          right: "0",
          background: "aliceblue",
          width: "100%",
          height: "100%",
        }}
      >
        {children}
      </div>
    );
  }
  return <div>{children}</div>;
};
