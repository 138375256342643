import React from "react";
import { Row, Button, Form, Card, Alert } from "react-bootstrap";
import { FaRegUser } from "react-icons/fa";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useUserContext } from "./components/user-context";
import type { EditProfileMutation } from "./graphql-client/codegen-types";
import { EDIT_PROFILE } from "./graphql-client/queries/account";
import { textInputChange } from "./components/utils";
import Loader from "./components/Loader";

const FILE_SIZE_LIMIT_IN_MB = 4;

const EditProfile = () => {
  const history = useHistory();
  const [editProfile] = useMutation<EditProfileMutation>(EDIT_PROFILE);
  const userInfo = useUserContext();
  if (!userInfo) throw Error("No user info");
  const { id, handle, name, lastNames, profilePictureLink } = userInfo;
  const [newName, setNewName] = React.useState(name || "");
  const [newLastNames, setNewLastNames] = React.useState(lastNames || "");
  const [newHandle, setNewHandle] = React.useState(handle || "");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const profilePictureRef = React.createRef<HTMLInputElement>();
  const submit = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (newHandle === "") {
      setErrorMessage("Escoge un nombre de usuario");
    } else if (newName === "") {
      setErrorMessage("Ingresa tu nombre");
    } else if (newLastNames === "") {
      setErrorMessage("Ingresa tus apellidos");
    } else if (
      (profilePictureRef?.current?.files?.[0]?.size || 0) >
      FILE_SIZE_LIMIT_IN_MB * 1000000
    ) {
      setErrorMessage(
        `La imagen pesa demasiado. Máximo ${FILE_SIZE_LIMIT_IN_MB} MB, por favor.`
      );
    } else {
      setLoading(true);
      setErrorMessage("");
      const profilePicture = profilePictureRef.current?.files?.[0] || null;
      editProfile({
        variables: {
          input: {
            id,
            handle: newHandle,
            name: newName,
            lastNames: newLastNames,
          },
          profilePicture,
        },
      })
        .then(() => {
          setLoading(false);
          setErrorMessage("");
          history.push(`/profile/${id}`);
        })
        .catch((e) => {
          const errorDetail =
            e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail;
          const accountError = errorDetail?.accountError;
          const fileError = errorDetail?.fileError;
          const exceededLimit = errorDetail?.exceededLimit;
          setLoading(false);
          if (accountError) {
            const { handleAlreadyExists } = accountError;
            if (handleAlreadyExists)
              setErrorMessage(
                "El nombre de usuario ya existe. Por favor, escoge otro."
              );
          } else if (fileError) {
            const { sizeLimitExceeded, invalidFileType } = fileError;
            if (sizeLimitExceeded)
              setErrorMessage(
                `La imagen pesa demasiado. Máximo ${FILE_SIZE_LIMIT_IN_MB} MB, por favor.`
              );
            if (invalidFileType)
              setErrorMessage("El archivo seleccionado no es una imagen.");
          } else {
            console.log("Unexpected error");
            console.log(e);
          }
        });
    }
  };
  return (
    <Row
      sm={1}
      md={2}
      style={{
        padding: "0 20px 0 20px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Card>
        <Card.Body>
          {profilePictureLink ? (
            <img
              style={{ maxWidth: "90%" }}
              src={profilePictureLink}
              alt="Foto de perfil"
            />
          ) : (
            <FaRegUser style={{ width: "100px", height: "100px" }} />
          )}
          <br />
          <br />
          <Form.Group className="form-row">
            <Form.Label className="col-sm-3">Cambiar foto </Form.Label>
            <Form.File
              className="col-sm-9"
              id="exampleFormControlFile1"
              ref={profilePictureRef}
            />
          </Form.Group>

          <hr />
          <Form>
            <Form.Group className="form-row">
              <Form.Label className="col-sm-3">Nombre</Form.Label>
              <Form.Control
                className="col-sm-9"
                placeholder="Juan"
                value={newName}
                onChange={textInputChange(setNewName)}
              />
            </Form.Group>
            <Form.Group className="form-row">
              <Form.Label className="col-sm-3">Apellidos</Form.Label>
              <Form.Control
                className="col-sm-9"
                placeholder="Pérez"
                value={newLastNames}
                onChange={textInputChange(setNewLastNames)}
              />
            </Form.Group>
            <Form.Group className="form-row">
              <Form.Label className="col-sm-3">Usuario</Form.Label>
              <Form.Control
                className="col-sm-9"
                placeholder="juan_perez"
                value={newHandle}
                onChange={textInputChange(setNewHandle)}
              />
            </Form.Group>
            {!loading && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="light"
                  onClick={() => history.push(`/profile/${id}`)}
                >
                  Cancelar
                </Button>
                <Button variant="info" type="submit" onClick={submit}>
                  Guardar cambios
                </Button>
              </div>
            )}
            {loading && <Loader size={60} message="Procesando tus datos" />}
            {errorMessage !== "" && (
              <Alert variant="danger" style={{ marginTop: "20px" }}>
                {"  "}
                {errorMessage}
              </Alert>
            )}
          </Form>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default EditProfile;
