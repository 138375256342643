import React from "react";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { useHistory, useParams, Link } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import { Packer } from "docx";
import {
  useProjectMutation,
  useProjectQuery,
} from "../components/project-context";
import {
  DELETE_LINK,
  ADD_LINK,
  GET_DATES_COMOVA,
  EDIT_WBS_NODE,
  UPDATE_PROJECT,
} from "../../graphql-client/queries/project";
import ComovaGanttChart from "./components/ComovaGanttChart";
import Pregunta from "../../components/Pregunta";
import type {
  Link as WbsNodeLink,
  Project,
  ProjectInput,
  WbsNode,
} from "../../graphql-client/codegen-types";
import { Step, Access } from "../../graphql-client/codegen-types";
import { SingleDatePicker } from "../../components/DatePicker";
import {
  readableFormat,
  ActLabel,
  todayWithoutTime,
  functionify,
} from "../../components/utils";
import Loader from "../../components/Loader";
import PDFReport from "./components/PDFReport";
import Assumptions from "../components/Assumptions";
import Restrictions from "../components/Restrictions";
import ComovaGanttPNGExporter from "./components/ComovaGanttPNGExporter";
import {
  differenceInWorkDays,
  parseStartDate,
} from "../components/gantt/utils";
import { Fullscreen, useFullscreen } from "../components/fullscreen";
import client from "../../graphql-client/connection";
import getDocxReportPromise from "./components/docx-report";
import { useProVerifier } from "../../plan";
import EntradaTexto from "../../components/EntradaTexto";

const { cache } = client;

type Props = {
  projectId: string;
};

const isComplete = (node: WbsNode): boolean => {
  const { plannedStart, plannedEnd, children } = node;
  if (!plannedStart || !plannedEnd) return false;
  return (children || []).every(isComplete);
};

const daysLabel = (days: number) => (days === 1 ? "día" : "días");

const Comova = ({ projectId }: Props) => {
  const [statusDate, setStatusDate] = React.useState(todayWithoutTime());
  const { loading, error, data, refetch } = useProjectQuery(GET_DATES_COMOVA, {
    variables: { id: projectId, step: Step.Comova },
  });
  const proVerifier = useProVerifier();
  const history = useHistory();
  const { openTaskId } = useParams<{ openTaskId?: string }>();
  const [initialOpenTaskId, setInitialOpenTaskId] = React.useState<
    string | undefined
  >(openTaskId);
  const omitOpenTaskId = () => setInitialOpenTaskId(undefined);
  const editProject = useProjectMutation(UPDATE_PROJECT);
  const editWbsNode = useProjectMutation(EDIT_WBS_NODE);
  const addLink = useProjectMutation(ADD_LINK);
  const deleteLink = useProjectMutation(DELETE_LINK);
  const ganttContainerRef = React.createRef<HTMLDivElement>();
  const [activeFullscreen, , fullscreenHandler] = useFullscreen();
  const onTaskUpd = (wbsNode: WbsNode) => {
    const { id, startDate, endDate, status } = wbsNode;
    return editWbsNode({
      variables: {
        projectId,
        input: {
          id,
          startDate,
          endDate,
          status,
        },
      },
    }).then(() => {});
  };
  const updateTaskInCache = (wbsNode: WbsNode) => {
    const { id, startDate, endDate, status } = wbsNode;
    cache.modify({
      id: cache.identify({ id, __typename: "WbsNode" }),
      fields: {
        startDate: () => startDate,
        endDate: () => endDate,
        status: () => status,
      },
    });
  };

  if (loading) return <Loader size={60} message="Cargando el proyecto" />;
  if (error) return <p> Error al cargar el Gantt </p>;
  const project = data.asUser.getProject;
  const readOnly = project.access === Access.Read;
  const updateAttribute = (input: ProjectInput) => {
    editProject({
      variables: { projectId: project.id, input },
      update() {
        cache.modify({
          id: cache.identify(project),
          fields: functionify(input),
        });
      },
    }).catch((e: Error) => {
      console.log(e.message);
    });
  };

  const onLinkAdd = (link: WbsNodeLink) =>
    addLink({
      variables: { projectId, link },
      update() {
        cache.modify({
          id: cache.identify(project),
          fields: {
            links: (oldLinks) => oldLinks.concat(link),
          },
        });
      },
    });

  const onLinkDelete = (id: string | number) =>
    deleteLink({
      variables: { projectId, id },
      update() {
        cache.modify({
          id: cache.identify(project),
          fields: {
            links: (oldLinks) =>
              oldLinks.filter((link: WbsNodeLink) => id !== link.id),
          },
        });
      },
    });

  const { wbsRoot, links, members, workDays } = project;

  const getProjectPromise = () =>
    refetch().then((res) => res?.data?.asUser?.getProject);
  const onGanttExport = (ganttScreenshot: any, updatedProject: Project) =>
    pdf(
      <PDFReport
        project={updatedProject}
        ganttPNGScreenshot={ganttScreenshot}
      />
    )
      .toBlob()
      .then((blob) => {
        saveAs(blob, "Como va.pdf");
      });

  if (!isComplete(project.wbsRoot)) {
    return (
      <div>
        <h4> No has terminado de planear las fechas del proyecto </h4>
        Hay al menos una <ActLabel /> sin fechas planeadas. Vuelve al paso{" "}
        <Link to={`/projects/${project.id}/cuando`}>
          <i>4. ¿Cuándo?</i>
        </Link>{" "}
        y termina de asignarlas.
      </div>
    );
  }
  const getEndDateAnalogy = () => {
    const delay = differenceInWorkDays(
      workDays,
      parseStartDate(wbsRoot?.plannedEnd),
      parseStartDate(wbsRoot?.endDate)
    );
    if (delay === 0)
      return (
        <tr>
          <td>
            El <b>Proyecto</b> va <b>Justo a tiempo</b>
          </td>
        </tr>
      );
    if (delay <= 0)
      return (
        <tr>
          <td>
            El <b>Proyecto</b>
          </td>
          <td>
            va <b>Adelantado</b>
          </td>
          <td>
            {-delay} {daysLabel(-delay)} lab.
          </td>
        </tr>
      );
    return (
      <tr>
        <td>
          El <b>Proyecto</b>
        </td>
        <td>
          va <b>Atrasado</b>
        </td>
        <td>
          {delay} {daysLabel(delay)} lab.
        </td>
      </tr>
    );
  };
  const maybeReadableFormat = (dateStr?: string) =>
    dateStr ? readableFormat(parseStartDate(dateStr)) : null;
  const ganttChart = (
    <Fullscreen active={activeFullscreen}>
      <ComovaGanttChart
        ganttContainerRef={ganttContainerRef}
        wbsRoot={project.wbsRoot}
        links={links}
        onTaskUpdate={onTaskUpd}
        proVerifier={proVerifier}
        updateTaskInCache={updateTaskInCache}
        onLinkAdd={onLinkAdd}
        onLinkDelete={onLinkDelete}
        statusDate={statusDate}
        readOnly={readOnly}
        fullscreenHandler={fullscreenHandler}
        members={members}
        openTaskId={initialOpenTaskId}
        omitOpenTaskId={omitOpenTaskId}
        workDays={workDays}
      />
    </Fullscreen>
  );
  if (activeFullscreen) return ganttChart;
  return (
    <div>
      <div>
        Has terminado de <b> planear </b> tu proyecto, ahora toca{" "}
        <b> monitorearlo</b>
        .
        <br />
      </div>
      <hr />
      <Pregunta
        pregunta="1. Mira cómo van las fechas del proyecto, comparado con las planeadas originalmente"
        descripcion="Las fechas planeadas son las definidas en el paso 4. ¿Cuándo?"
      >
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th> </th>
              <th>Planeado</th>
              <th>Real</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b>Inicio</b>
              </td>
              <td>{maybeReadableFormat(wbsRoot?.plannedStart)}</td>
              <td>{maybeReadableFormat(wbsRoot?.startDate)}</td>
            </tr>
            <tr>
              <td>
                <b>Terminación</b>
              </td>
              <td>{maybeReadableFormat(wbsRoot?.plannedEnd)}</td>
              <td>{maybeReadableFormat(wbsRoot?.endDate)}</td>
            </tr>
          </tbody>
        </Table>
        <Table responsive striped bordered hover>
          <tbody>{getEndDateAnalogy()}</tbody>
        </Table>
      </Pregunta>
      <br />
      <Pregunta
        pregunta={
          <span>
            2. ¿Cómo van las <ActLabel plural /> al día de hoy?
          </span>
        }
        descripcion="Para cada actividad, marca si está terminada, en progreso, o aún no iniciada."
      >
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <SingleDatePicker
            initialDate={statusDate}
            onChange={(date) => setStatusDate(date)}
            text="Hoy es:"
          />
        </div>
        <br />
        <span style={{ fontSize: "18px" }}>
          Haz click en cada <ActLabel /> y actualiza su <b>Estatus</b>
        </span>
        <br />
        <br />
        <span
          style={{
            opacity: "0.6",
            background: "#ffd180",
            borderRadius: "3px",
            padding: "2px",
            border: "1px solid rgb(255, 153, 0)",
          }}
        >
          Línea base:
        </span>{" "}
        Fechas planeadas
        <br />
        {ganttChart}
      </Pregunta>
      <br />
      <div style={{ marginBottom: "30px" }}>
        <Pregunta
          pregunta="2. (Opcional) Comentarios/notas sobre el estatus del proyecto"
          descripcion="Algún evento que lo afectó, algo para hacer notar a los compañeros, etc"
        >
          <EntradaTexto
            ejemplo="Las fechas van acorde al plan pero nos podría afectar el atraso de un proveedor"
            maxLetras={400}
            saveText={
              readOnly
                ? () => {}
                : (text: string) => updateAttribute({ statusComments: text })
            }
            textoInicial={project.statusComments || ""}
            readOnly={readOnly}
          />
        </Pregunta>
      </div>
      <Pregunta
        pregunta={<span>3. ¿Han cambiado las suposiciones del proyecto?</span>}
        descripcion=""
      >
        <Assumptions project={project} readOnly={readOnly} />
      </Pregunta>
      <br />
      <Pregunta
        pregunta={<span>4. ¿Han cambiado las restricciones del proyecto?</span>}
        descripcion=""
      >
        <Restrictions project={project} readOnly={readOnly} />
      </Pregunta>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        <ComovaGanttPNGExporter
          text="Imprimir reporte en PDF"
          onGanttExport={onGanttExport}
          project={project}
          statusDate={statusDate}
        />
        <Button
          variant="info"
          onClick={() => {
            getDocxReportPromise(project)
              .then((docxReport) => Packer.toBlob(docxReport))
              .then((blob) => {
                saveAs(blob, "Como va.docx");
              });
          }}
        >
          Exportar a Word (.docx)
        </Button>
      </div>
    </div>
  );
};

export default Comova;
