import React from "react";
import { useParams } from "react-router-dom";
import { getMonth, getYear } from "date-fns";
import TaskCalendar from "./components/TaskCalendar";

const CalendarPage = () => {
  const params = useParams<{ month?: string; year?: string }>();
  const today = new Date();
  const month = params.month ? parseInt(params.month, 10) : getMonth(today);
  const year = params.year ? parseInt(params.year, 10) : getYear(today);
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          paddingBottom: "10px",
          color: "grey",
        }}
      >
        <i>
          Aparece la fecha de terminación de tus actividades donde <br />
          eres Ejecutor. Puedes revisar los roles en el paso 3 ¿Quién?{" "}
        </i>
      </div>
      <TaskCalendar month={month} year={year} />
    </div>
  );
};

export default CalendarPage;
