import React from "react";
import styled from "styled-components";
import { Button, Dropdown, Alert } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import type { Reference } from "@apollo/client";
import { InlineList, InlineListItem } from "../../../components/InlineList";
import Explanation from "../../../components/Explanation";
import type { Tag, Project } from "../../../graphql-client/codegen-types";
import {
  ASSIGN_TAG,
  UNASSIGN_TAG,
} from "../../../graphql-client/queries/project";
import { useProjectMutation } from "../../components/project-context";
import client from "../../../graphql-client/connection";
import Loader from "../../../components/Loader";

type Props = {
  project: Project;
  tags: Array<Tag>;
};

const StyledToggle = styled(Dropdown.Toggle)`
  border: 1px solid lightgrey;
  padding: 5px 10px 5px 10px;
`;

const ProjectTags = ({ project, tags }: Props) => {
  const { cache } = client;
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorDetail, setErrorDetail] = React.useState("");
  const [adding, setAdding] = React.useState(false);
  const history = useHistory();
  const assignTag = useProjectMutation(ASSIGN_TAG);
  const unassignTag = useProjectMutation(UNASSIGN_TAG);
  const remainingTags = React.useMemo(
    () =>
      tags.filter(
        (tag) => !project.tags?.some((projectTag) => tag.id === projectTag.id)
      ),
    [tags, project.tags]
  );

  const updateAssignTag = (tag: Tag) => {
    setAdding(true);
    assignTag({ variables: { projectId: project.id, tagId: tag.id } })
      .then(() => {
        cache.modify({
          id: cache.identify(project),
          fields: {
            tags: (oldTags) => oldTags.concat({ __ref: cache.identify(tag) }),
          },
        });
        setAdding(false);
      })
      .catch((e) => {
        setAdding(false);
        const applicationErrorDetail =
          e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail;
        if (applicationErrorDetail?.tagLimitExceeded) {
          setErrorMessage(
            `No más de ${applicationErrorDetail?.tagLimitExceeded} grupos por proyecto`
          );
          setErrorDetail(
            "Recomendamos mantener los proyectos lo más simple posible."
          );
          setTimeout(() => {
            setErrorMessage("");
            setErrorDetail("");
          }, 7000);
        } else {
          console.log("Unexpected error", e);
        }
      });
  };

  const updateUnassignTag = (tag: Tag) => {
    cache.modify({
      id: cache.identify(project),
      fields: {
        tags: (oldTags, { readField }) =>
          oldTags.filter(
            (tagRef: Reference) => tag.id !== readField("id", tagRef)
          ),
      },
    });
    unassignTag({ variables: { projectId: project.id, tagId: tag.id } });
  };

  const title = project.tags?.length === 0 ? "Agregar grupo" : "Agregar otro";
  const dropdown = (
    <Dropdown title={title}>
      <StyledToggle variant="light" id="dropdown-basic">
        {project.tags?.length === 0 ? (
          <span> Elegir grupo </span>
        ) : (
          <span>
            {" "}
            <FaPlus style={{ width: "12px", height: "12px" }} /> Agregar otro{" "}
          </span>
        )}
      </StyledToggle>
      <Dropdown.Menu>
        {remainingTags.map((tag) => (
          <Dropdown.Item key={tag.id} onClick={() => updateAssignTag(tag)}>
            {tag.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <div>
      {tags.length === 0 ? (
        <div className="text-muted">
          {" "}
          Aún no creas ningún grupo de proyecto{" "}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <InlineList>
            {project.tags?.map((projectTag) => (
              <InlineListItem
                key={projectTag.id}
                onDelete={() => updateUnassignTag(projectTag)}
              >
                {" "}
                {projectTag.name}{" "}
              </InlineListItem>
            ))}
          </InlineList>
          {adding ? <Loader size={30} message="Agregando..." /> : dropdown}
        </div>
      )}
      {errorMessage !== "" && (
        <Alert variant="warning">
          {" "}
          <Explanation text={errorMessage} detail={errorDetail} />{" "}
        </Alert>
      )}
      <br />
      <Button
        variant="light"
        style={{ border: "1px solid lightgrey" }}
        onClick={() => history.push("/tags")}
      >
        {" "}
        Agregar/editar mis grupos de proyecto{" "}
      </Button>
    </div>
  );
};

export default ProjectTags;
