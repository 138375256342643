import React from "react";
import { Modal, Button } from "react-bootstrap";
import styled from "styled-components";
import { UPDATE_PROJECT } from "../../graphql-client/queries/project";
import type { Project } from "../../graphql-client/codegen-types";
import { useProjectMutation } from "./project-context";
import client from "../../graphql-client/connection";

const StyledModal = styled(Modal)`
  .work-day-modal {
    width: 300px;
  }
`;

type Props = {
  project: Project;
  isOpen: boolean;
  close: () => void;
};

const labels = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];

const WorkDaysModal = ({ project, isOpen, close }: Props) => {
  const updateWorkDays = useProjectMutation(UPDATE_PROJECT);
  const completeUpdate = (newWorkDays: Array<boolean>) => {
    const { cache } = client;
    cache.modify({
      id: cache.identify(project),
      fields: {
        workDays: () => newWorkDays,
      },
    });
    updateWorkDays({
      variables: { projectId: project.id, input: { workDays: newWorkDays } },
    });
  };
  const { workDays } = project;
  if (!workDays) throw Error("workdays undefined in work days modal");
  const flipWorkDay = (day: number) => {
    const newWorkDays = workDays.map((active, idx) => {
      if (idx === day) return !active;
      return active;
    });
    completeUpdate(newWorkDays);
  };
  return (
    <StyledModal dialogClassName="work-day-modal" show={isOpen} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Días Laborales</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ display: "flex", flexDirection: "column", fontSize: "20px" }}
        >
          {workDays.map((active, idx) => (
            <span key={labels[idx]}>
              {" "}
              <input
                checked={active}
                type="checkbox"
                onChange={() => flipWorkDay(idx)}
              />{" "}
              {labels[idx]}{" "}
            </span>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={close}>
          Aceptar
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
};

export default WorkDaysModal;
