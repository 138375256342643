import React from "react";
import { Page, Text, View, Document } from "@react-pdf/renderer";
import type {
  Member,
  WbsNode,
  Project,
  User,
} from "../../../graphql-client/codegen-types";
import { completeFormat } from "../../../components/utils";
import {
  differenceInWorkDays,
  parseStartDate,
} from "../../components/gantt/utils";
import PDFQuestion from "../../components/pdf/PDFQuestion";
import PDFHeader from "../../components/pdf/PDFHeader";
import PDFTitle from "../../components/pdf/PDFTitle";

type Props = {
  project: Project;
};

const getUserFullName = (user?: User | null) => {
  if (!user) return "Usuario sin nombre";
  const { name, lastNames } = user;
  if (!name || !lastNames) return `@${user.handle}`;
  return `${name} ${lastNames}`;
};

const PDFReport = ({ project }: Props) => {
  const {
    name,
    problems,
    objective,
    benefits,
    client,
    wbsRoot,
    startEstimate,
    endEstimate,
    members,
    assumptions,
    restrictions,
    workDays,
  } = project;
  const dateQuestion = () => {
    if (!startEstimate || !endEstimate) return null;
    const parsedStart = parseStartDate(startEstimate);
    const parsedEnd = parseStartDate(endEstimate);
    const duration =
      differenceInWorkDays(workDays || [], parsedStart, parsedEnd) + 1;
    const daysLabel = duration === 1 ? "día laboral" : "días laborales";
    return (
      <PDFQuestion question="¿Cuándo estimas empezar y terminar el proyecto?">
        <View
          style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}
        >
          <Text style={{ color: "steelblue" }}> Empieza: </Text>
          <Text>{completeFormat(parsedStart)}</Text>
        </View>
        <View
          style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}
        >
          <Text style={{ color: "steelblue" }}> Termina: </Text>
          <Text>{completeFormat(parsedEnd)}</Text>
        </View>
        <View
          style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}
        >
          <Text style={{ color: "steelblue" }}> Dura: </Text>
          <Text>
            {duration} {daysLabel}.
          </Text>
        </View>
      </PDFQuestion>
    );
  };
  return (
    <Document>
      <Page
        size="A4"
        style={{ padding: "40px", color: "#053d56", fontSize: "13px" }}
      >
        <PDFHeader name={name || "[Proyecto sin nombre]"} />
        <PDFTitle title="¿Qué y Por qué?" />
        <View>
          <PDFQuestion question="¿Qué problemas, necesidades o requerimientos dan origen al proyecto?">
            <Text> {problems} </Text>
          </PDFQuestion>
          <PDFQuestion question="¿Cuál es el objetivo del proyecto que resuelve el problema o la necesidad?">
            <Text> {objective} </Text>
          </PDFQuestion>
          <PDFQuestion question="¿Cuáles son los beneficios esperados?">
            {benefits?.map(({ id, text }) => (
              <Text style={{ marginBottom: "2px" }} key={id}>
                - {text}
              </Text>
            ))}
          </PDFQuestion>
          <PDFQuestion question="¿Quién es el cliente del proyecto y los usuarios del producto o de los entregables?">
            <Text> {client}</Text>
          </PDFQuestion>
          <PDFQuestion question="¿Qué vas a entregar o presentar para lograr el objetivo, durante y al final del proyecto?">
            {(wbsRoot?.children || []).map(
              ({ id, text }: WbsNode, idx: number) => (
                <Text key={id} style={{ marginBottom: "8px" }}>
                  {idx + 1}. {text}
                </Text>
              )
            )}
          </PDFQuestion>
          {dateQuestion()}
          <PDFQuestion question="¿Quiénes van a trabajar en el proyecto?">
            {(members || []).map((member: Member) => (
              <Text key={member.id} style={{ margin: "5px" }}>
                {member.name || getUserFullName(member?.user)}
              </Text>
            ))}
          </PDFQuestion>
          <PDFQuestion question="¿Qué suposiciones hay para que el proyecto avance?">
            {assumptions?.map(({ id, text }) => (
              <Text style={{ marginBottom: "2px" }} key={id}>
                - {text}
              </Text>
            ))}
          </PDFQuestion>
          <PDFQuestion question="¿Qué restricciones existen que pueden limitar el avance del proyecto?">
            {restrictions?.map(({ id, text }) => (
              <Text style={{ marginBottom: "2px" }} key={id}>
                - {text}
              </Text>
            ))}
          </PDFQuestion>
        </View>
      </Page>
    </Document>
  );
};

export default PDFReport;
