import React from "react";
import { FaGraduationCap } from "react-icons/fa";
import { Alert } from "react-bootstrap";
import { Reference, FetchResult } from "@apollo/client";
import {
  ADD_LESSON,
  EDIT_LESSON,
  DELETE_LESSON,
  REORDER_LESSONS,
} from "../../../graphql-client/queries/project";
import type {
  Scalars,
  Lesson,
  Project,
  AddLessonMutation,
} from "../../../graphql-client/codegen-types";
import { useProjectMutation } from "../../components/project-context";
import DynamicList from "../../components/DynamicList";
import Explanation from "../../../components/Explanation";
import EntradaTexto from "../../../components/EntradaTexto";
import client from "../../../graphql-client/connection";

const { cache } = client;

type ID = Scalars["ID"];

type Props = {
  project: Project;
  readOnly: boolean;
};

function addLessonCallback({ data }: FetchResult<AddLessonMutation>): Lesson {
  const ret = data?.asUser?.editProject?.addLesson?.newLesson;
  if (!ret) throw new Error("Error creating lesson");
  return ret;
}

const Lessons = ({ project, readOnly }: Props) => {
  const addLesson = useProjectMutation<AddLessonMutation, { projectId: ID }>(
    ADD_LESSON
  );
  const editLesson = useProjectMutation(EDIT_LESSON);
  const deleteLesson = useProjectMutation(DELETE_LESSON);
  const reorderLessons = useProjectMutation(REORDER_LESSONS);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorDetail, setErrorDetail] = React.useState("");
  return (
    <div>
      <DynamicList
        title={
          <span>
            {" "}
            <FaGraduationCap /> Lecciones Aprendidas{" "}
          </span>
        }
        deleteInfo={
          readOnly
            ? undefined
            : {
                onDelete: ({ id }: Lesson) => {
                  cache.modify({
                    id: cache.identify(project),
                    fields: {
                      lessons: (oldLessons, { readField }) =>
                        oldLessons.filter(
                          (lessonRef: Reference) =>
                            id !== readField("id", lessonRef)
                        ),
                    },
                  });
                  return deleteLesson({
                    variables: { projectId: project.id, id },
                  }).then(() => true);
                },
                deleteWarnTitle: "¿Eliminar beneficio?",
                deleteWarnMsg: "No se podrá recuperar.",
              }
        }
        renderItem={(lesson: Lesson, isNew: boolean) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              background: "whitesmoke",
              borderRadius: "5px",
              padding: "5px",
            }}
          >
            <FaGraduationCap style={{ margin: "15px 5px 5px 5px" }} />
            <div style={{ width: "100%" }}>
              <EntradaTexto
                ejemplo="Confiar más en las personas pero con un acompañamiento."
                maxLetras={280}
                autoFocus={isNew}
                saveText={
                  readOnly
                    ? () => {}
                    : (text: string) =>
                        editLesson({
                          variables: {
                            projectId: project.id,
                            input: { id: lesson.id, text },
                          },
                          update() {
                            cache.modify({
                              id: cache.identify(lesson),
                              fields: {
                                text: () => text,
                              },
                            });
                          },
                        })
                }
                textoInicial={lesson.text ?? ""}
                rows={2}
                readOnly={readOnly}
              />
            </div>
          </div>
        )}
        getItemId={({ id }: Lesson) => id}
        initial={project.lessons || []}
        newItem={
          readOnly
            ? undefined
            : () =>
                addLesson({
                  variables: { projectId: project.id },
                })
                  .then(addLessonCallback)
                  .then((newLesson: Lesson) => {
                    cache.modify({
                      id: cache.identify(project),
                      fields: {
                        lessons: (oldLessons) =>
                          oldLessons.concat({
                            __ref: cache.identify(newLesson),
                          }),
                      },
                    });
                    return newLesson;
                  })
                  .catch((e) => {
                    const applicationErrorDetail =
                      e?.graphQLErrors?.[0]?.extensions?.applicationErrorDetail;
                    const projectError = applicationErrorDetail?.projectError;
                    if (projectError?.lessonsLimitExceeded) {
                      setErrorMessage(
                        `No más de ${projectError.lessonsLimitExceeded} beneficios por proyecto`
                      );
                      setErrorDetail(
                        "Demasiados beneficios son difíciles de monitorear"
                      );
                      setTimeout(() => {
                        setErrorMessage("");
                        setErrorDetail("");
                      }, 7000);
                      return null;
                    }
                    throw e;
                  })
        }
        onReorder={
          readOnly
            ? undefined
            : (lessons: Array<Lesson>) =>
                reorderLessons({
                  variables: {
                    projectId: project.id,
                    idList: lessons.map(({ id }: Lesson) => id),
                  },
                  update() {
                    cache.modify({
                      id: cache.identify(project),
                      fields: {
                        lessons: () =>
                          lessons.map((lesson) => ({
                            __ref: cache.identify(lesson),
                          })),
                      },
                    });
                  },
                })
        }
      />
      {errorMessage !== "" && (
        <Alert variant="warning">
          <Explanation text={errorMessage} detail={errorDetail} />
        </Alert>
      )}
    </div>
  );
};

export default Lessons;
