import React from "react";
import { Button } from "react-bootstrap";
import { useProjectMutation } from "../../components/project-context";
import { SUBMIT_FEEDBACK } from "../../../graphql-client/queries/project";
import client from "../../../graphql-client/connection";
import type { Project } from "../../../graphql-client/codegen-types";
import EntradaTexto from "../../../components/EntradaTexto";
import { textInputChange } from "../../../components/utils";
import Pregunta from "../../../components/Pregunta";
import Loader from "../../../components/Loader";

const { cache } = client;
const oneToTen: Array<number> = Array.from(Array(10).keys());

const Feedback = ({ project }: { project: Project }) => {
  const [comment, setComment] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [recommendable, setRecommendable] = React.useState<string | null>(null);
  const [reusable, setReusable] = React.useState<string | null>(null);
  const [learnable, setLearnable] = React.useState<string | null>(null);
  const submitFeedback = useProjectMutation(SUBMIT_FEEDBACK);
  const onClick = () => {
    if (!recommendable || !reusable || !learnable) return;
    setLoading(true);
    submitFeedback({
      variables: {
        projectId: project.id,
        input: {
          recommendable: parseInt(recommendable, 10),
          reusable: parseInt(reusable, 10),
          learnable: parseInt(learnable, 10),
          comment,
        },
      },
    }).then(() => {
      cache.modify({
        id: cache.identify(project),
        fields: {
          hasSubmittedFeedback: () => true,
        },
      });
    });
  };
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Pregunta
        pregunta={
          <span>
            {" "}
            ¿Qué tan probable es que vuelvas a usar <b>Coordinate</b> en tu
            siguiente proyecto?
          </span>
        }
        descripcion="1 = para nada, 10 = totalmente"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          onChange={textInputChange(setReusable)}
        >
          {oneToTen.map((num: number) => (
            <div
              key={num}
              style={{
                margin: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <input type="radio" value={(num + 1).toString()} /> {num + 1}
            </div>
          ))}
        </div>
      </Pregunta>
      <Pregunta
        pregunta={
          <span>
            {" "}
            ¿Qué tan probable es que recomiendes <b>Coordinate</b> a tus
            colegas?
          </span>
        }
        descripcion="1 = para nada, 10 = totalmente"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          onChange={textInputChange(setRecommendable)}
        >
          {oneToTen.map((num: number) => (
            <div
              key={num}
              style={{
                margin: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <input type="radio" value={(num + 1).toString()} /> {num + 1}
            </div>
          ))}
        </div>
      </Pregunta>
      <Pregunta
        pregunta={
          <span>
            {" "}
            ¿Qué tanto te ayudó Coordinate a aprender sobre administración de
            proyectos?
          </span>
        }
        descripcion="1 = nada, 10 = mucho"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          onChange={textInputChange(setLearnable)}
        >
          {oneToTen.map((num: number) => (
            <div
              key={num}
              style={{
                margin: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <input type="radio" value={(num + 1).toString()} /> {num + 1}
            </div>
          ))}
        </div>
      </Pregunta>
      <Pregunta
        pregunta={
          <span>
            {" "}
            <b>(Opcional)</b> ¿Por qué lo usarías/no lo usarías de nuevo? ¿Por
            qué lo recomendarías/no lo recomendarías? ¿Te ayudó a organizar tu
            proyecto? ¿Qué aprendiste? ¡Danos tu opinión!
          </span>
        }
        descripcion="En Coordinate valoramos al máximo la opinión de nuestros usuarios. Cualquier comentario es bienvenido y apreciado."
      >
        <EntradaTexto
          ejemplo="Me gustó..., pero cambiaría..."
          maxLetras={500}
          saveText={(text: string) => setComment(text)}
          textoInicial=""
          rows={3}
          readOnly={loading}
        />
      </Pregunta>
      {loading ? (
        <Loader size={60} message="Mandando retro..." />
      ) : (
        <Button
          variant="info"
          onClick={onClick}
          disabled={
            reusable === null || recommendable === null || learnable === null
          }
        >
          {" "}
          Mandar Retro{" "}
        </Button>
      )}
    </div>
  );
};

export default Feedback;
