import React from "react";
import { Text, Image, Page, View, Document } from "@react-pdf/renderer";
import type { Project } from "../../../graphql-client/codegen-types";
import PDFQuestion from "../../components/pdf/PDFQuestion";
import PDFHeader from "../../components/pdf/PDFHeader";
import PDFTitle from "../../components/pdf/PDFTitle";
import {
  differenceInWorkDays,
  parseStartDate,
} from "../../components/gantt/utils";
import { readableFormat } from "../../../components/utils";

type Props = {
  project: Project;
  ganttPNGScreenshot: string;
};

const Table = ({ project }: { project: Project }) => {
  if (!project?.wbsRoot?.plannedEnd || !project?.wbsRoot?.endDate) {
    return <Text> Fechas aún no definidas. </Text>;
  }
  const plannedEnd = parseStartDate(project.wbsRoot.plannedEnd);
  const endDate = parseStartDate(project.wbsRoot.endDate);
  const delay = differenceInWorkDays(
    project.workDays || [],
    endDate,
    plannedEnd
  );
  const daysLabel = (days: number) =>
    days === 1 ? "día laboral" : "días laborales";
  const getDelayHeader = () => {
    if (delay < 0) return <Text> Se atrasó </Text>;
    return <Text> Se entregó </Text>;
  };
  const getDelayCell = () => {
    if (delay < 0)
      return (
        <Text>
          {" "}
          {Math.abs(delay)} {daysLabel(Math.abs(delay))}{" "}
        </Text>
      );
    if (delay === 0) return <Text> ¡Justo a tiempo! </Text>;
    return (
      <Text>
        {" "}
        ¡{delay} {daysLabel(delay)} antes de lo planeado!{" "}
      </Text>
    );
  };

  return (
    <View
      style={{
        fontSize: "12px",
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          fontFamily: "Helvetica-Bold",
        }}
      >
        <View
          style={{ border: "1px solid black", width: "33%", padding: "3px" }}
        >
          <Text>Planeaba terminarse</Text>
        </View>
        <View
          style={{ border: "1px solid black", width: "33%", padding: "3px" }}
        >
          <Text>Se terminó</Text>
        </View>
        <View
          style={{ border: "1px solid black", width: "33%", padding: "3px" }}
        >
          <Text>{getDelayHeader()}</Text>
        </View>
      </View>
      <View style={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <View
          style={{ border: "1px solid black", width: "33%", padding: "3px" }}
        >
          <Text>{readableFormat(plannedEnd)}</Text>
        </View>
        <View
          style={{ border: "1px solid black", width: "33%", padding: "3px" }}
        >
          <Text>{readableFormat(endDate)}</Text>
        </View>
        <View
          style={{ border: "1px solid black", width: "33%", padding: "3px" }}
        >
          <Text>{getDelayCell()}</Text>
        </View>
      </View>
    </View>
  );
};

const PDFReport = ({ project, ganttPNGScreenshot }: Props) => {
  const { name, members, benefits, lessons } = project;

  return (
    <Document>
      <Page size="A4" style={{ padding: "40px", color: "#053d56" }}>
        <PDFHeader name={name || "[Proyecto sin nombre]"} />
        <PDFTitle title="¿Cómo terminó?" />
        <Table project={project} />
        <View style={{ display: "flex", flexDirection: "row" }}>
          <PDFQuestion question="Diagrama de Gantt: Cierre del Proyecto">
            <Image
              src={ganttPNGScreenshot}
              style={{
                marginTop: "10px",
                maxHeight: "750px",
                width: "auto",
                objectFit: "contain",
              }}
            />
          </PDFQuestion>
        </View>
        <View style={{ fontSize: "13px" }}>
          <PDFQuestion question="¿Cuáles fueron los beneficios obtenidos?">
            {benefits?.map(({ id, text }) => (
              <Text style={{ marginBottom: "2px" }} key={id}>
                - {text}
              </Text>
            ))}
          </PDFQuestion>
          <PDFQuestion question="¿Qué aprendizajes te dejó este proyecto?">
            {lessons?.map(({ id, text }) => (
              <Text style={{ marginBottom: "2px" }} key={id}>
                - {text}
              </Text>
            ))}
          </PDFQuestion>
        </View>
      </Page>
    </Document>
  );
};

export default PDFReport;
