import React from "react";
import ReactExport from "react-data-export";
import { Button } from "react-bootstrap";
import { Member, WbsNode } from "../../../graphql-client/codegen-types";

const { ExcelFile, ExcelColumn } = ReactExport;
const { ExcelSheet } = ExcelFile;

const borderStyle = (style = "thin") => ({ style, color: { rgb: "FF000000" } });
const completeBorder = (style = "thin") => ({
  top: borderStyle(style),
  bottom: borderStyle(style),
  left: borderStyle(style),
  right: borderStyle(style),
});

const ExcelReport = ({
  members,
  wbsRoot,
}: {
  members: Array<Member>;
  wbsRoot: WbsNode;
}) => {
  const getData = (
    node: WbsNode,
    level = 0,
    code = "",
    dataSet: Array<any> = []
  ) => {
    const respLabel = ["Coordinador", "Responsable", "Ejecutor"][level];
    const rgb = ["FFE6E8FA", "FFFFF1D8", "FFFFFFED"][level];
    const fill = { patternType: "solid", fgColor: { rgb } };
    const style = { fill, border: completeBorder() };
    const getMemberCell = (member: Member) => {
      const duty = node.duties?.find(({ memberId }) => memberId === member.id);
      if (!duty) return { value: "-", style };
      return { value: respLabel, style };
    };
    const textCell = {
      value: `${code} ${node.text}`,
      style: {
        fill,
        border: completeBorder(),
        font: { name: "Calibri", bold: true },
      },
    };
    const cells = [textCell, ...members.map(getMemberCell)];
    dataSet.push(cells);
    node.children?.forEach((child: WbsNode, idx: number) =>
      getData(child, level + 1, `${code}${idx + 1}.`, dataSet)
    );
    return dataSet;
  };
  const columns = [
    { title: "" },
    ...members.map((member) => ({
      title: member?.user?.handle || member.name,
      style: {
        border: completeBorder("medium"),
        font: { name: "Calibri", bold: true },
      },
    })),
  ];
  const dataSet = [{ columns, data: getData(wbsRoot) }];
  return (
    <ExcelFile
      filename="Quien"
      element={<Button variant="info">Exportar a Excel</Button>}
    >
      <ExcelSheet dataSet={dataSet} name="Matriz de responsabilidades" />
    </ExcelFile>
  );
};

export default ExcelReport;
