import {
  WidthType,
  HeadingLevel,
  TextRun,
  AlignmentType,
  TableCell,
  TableRow,
  Table,
  Paragraph,
} from "docx";
import type { WbsNode, Project } from "../../../graphql-client/codegen-types";
import getDocxTemplatePromise from "../../components/docx/docx-template";
import { readableFormat } from "../../../components/utils";
import {
  differenceInWorkDays,
  parseStartDate,
} from "../../components/gantt/utils";

const daysLabel = (days: number) => (days === 1 ? "día" : "días");

const getTableCell = (text: string, alignCenter = false, bold = false) =>
  new TableCell({
    children: [
      new Paragraph({
        children: [new TextRun({ text, bold })],
        alignment: alignCenter ? AlignmentType.CENTER : AlignmentType.LEFT,
        heading: HeadingLevel.HEADING_3,
        spacing: { before: 50, after: 50 },
      }),
    ],
  });

const displayWbs = (
  { text, children, endDate, plannedEnd }: WbsNode,
  workDays: Array<boolean>,
  wbsCode = ""
): Array<TableRow> => {
  const displayedChildren = (children || [])
    .map((child, idx) => displayWbs(child, workDays, `${wbsCode}${idx + 1}.`))
    .flat();
  const formattedEndDate =
    endDate || plannedEnd
      ? readableFormat(parseStartDate(endDate || plannedEnd))
      : "";
  const formattedPlannedEnd = plannedEnd
    ? readableFormat(parseStartDate(plannedEnd))
    : "";
  const delay = differenceInWorkDays(
    workDays,
    parseStartDate(plannedEnd),
    parseStartDate(endDate)
  );
  const getDelayLabel = () => {
    if (delay === 0) return "Justo a tiempo";
    if (delay <= 0) return `Adelantado ${-delay} ${daysLabel(-delay)} lab.`;
    return `Atrasado ${delay} ${daysLabel(delay)} lab.`;
  };
  const nodeRow = new TableRow({
    children: [
      getTableCell(`${wbsCode} ${text}`),
      getTableCell(formattedPlannedEnd, true),
      getTableCell(formattedEndDate, true),
      getTableCell(getDelayLabel(), true),
    ],
  });
  return [nodeRow, ...displayedChildren];
};

const getMainTable = (node: WbsNode, workDays: Array<boolean>) =>
  new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    rows: [
      new TableRow({
        children: [
          getTableCell(""),
          getTableCell("Inicio real", true, true),
          getTableCell("Terminación real", true, true),
          getTableCell("Se entregó", true, true),
        ],
        tableHeader: true,
      }),
      ...displayWbs(node, workDays),
    ],
  });

const getProjectInfo = (
  { startDate, endDate, plannedStart, plannedEnd }: WbsNode,
  workDays: Array<boolean>
) => {
  const formattedPlannedStart = plannedStart
    ? readableFormat(parseStartDate(plannedStart))
    : "";
  const formattedPlannedEnd = plannedEnd
    ? readableFormat(parseStartDate(plannedEnd))
    : "";
  const formattedStartDate =
    startDate || plannedStart
      ? readableFormat(parseStartDate(startDate || plannedStart))
      : "";
  const formattedEndDate =
    endDate || plannedEnd
      ? readableFormat(parseStartDate(endDate || plannedEnd))
      : "";
  const getEndDateAnalogy = () => {
    const delay = differenceInWorkDays(
      workDays,
      parseStartDate(plannedEnd),
      parseStartDate(endDate)
    );
    if (delay === 0) return "El Proyecto se terminó Justo a tiempo";
    if (delay <= 0)
      return `El Proyecto se adelantó ${-delay} ${daysLabel(-delay)} lab.`;
    return `El Proyecto se atrasó ${delay} ${daysLabel(delay)} lab.`;
  };
  return [
    new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        new TableRow({
          children: [
            getTableCell(""),
            getTableCell("Planeado", true, true),
            getTableCell("Real", true, true),
          ],
          tableHeader: true,
        }),
        new TableRow({
          children: [
            getTableCell("Inicio", true, true),
            getTableCell(formattedPlannedStart, true),
            getTableCell(formattedStartDate, true),
          ],
        }),
        new TableRow({
          children: [
            getTableCell("Terminación", true, true),
            getTableCell(formattedPlannedEnd, true),
            getTableCell(formattedEndDate, true),
          ],
        }),
      ],
    }),
    new Paragraph({
      children: [new TextRun({ text: getEndDateAnalogy(), bold: true })],
      alignment: AlignmentType.CENTER,
      heading: HeadingLevel.HEADING_3,
      spacing: { before: 100, after: 400 },
    }),
  ];
};

const getBenefits = (project: Project) => [
  new Paragraph({
    children: [
      new TextRun({
        text: "¿Cuáles fueron los beneficios obtenidos?",
        break: 1,
      }),
    ],
    heading: HeadingLevel.HEADING_2,
  }),
  ...(project.benefits || []).map(
    ({ text }) =>
      new Paragraph({
        text: text || "",
        bullet: { level: 0 },
        heading: HeadingLevel.HEADING_3,
      })
  ),
];

const getLessons = (project: Project) => [
  new Paragraph({
    children: [
      new TextRun({
        text: "¿Qué aprendizajes te dejó este proyecto?",
        break: 1,
      }),
    ],
    heading: HeadingLevel.HEADING_2,
  }),
  ...(project.lessons || []).map(
    ({ text }) =>
      new Paragraph({
        text: text || "",
        bullet: { level: 0 },
        heading: HeadingLevel.HEADING_3,
      })
  ),
];

const getDocxReportPromise = (project: Project) => {
  const { wbsRoot } = project;
  if (!wbsRoot) throw Error("Wbs is undefined");
  return getDocxTemplatePromise(project, [
    ...getProjectInfo(wbsRoot, project.workDays || []),
    getMainTable(wbsRoot, project.workDays || []),
    ...getBenefits(project),
    ...getLessons(project),
  ]);
};

export default getDocxReportPromise;
