import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isBefore, getDay } from "date-fns";
import { Row, InputGroup, Form, Col } from "react-bootstrap";
import styled from "styled-components";
import es from "date-fns/locale/es";
import { FaCalendar } from "react-icons/fa";
import { textInputChange, blurOnEnter } from "./utils";
import {
  addWorkDays,
  differenceInWorkDays,
} from "../project/components/gantt/utils";

registerLocale("es", es);

type SingleProps = {
  onChange: (date: Date) => void;
  initialDate?: Date | null;
  text?: string;
  readOnly?: boolean;
  workDays?: Array<boolean>;
};

const StyledDatePicker = styled(DatePicker)`
  height: 100%;
  text-align: center;
  border: 1px solid gainsboro;
  min-height: 35px;
  max-width: 150px;
`;

const ThinFormControl = styled(Form.Control)`
  text-align: center;
  width: auto;
  max-width: 70px;
  max-height: 35px;
`;

export const SingleDatePicker = ({
  onChange,
  initialDate = null,
  text = "Fecha",
  readOnly = false,
  workDays = Array(7).fill(true),
}: SingleProps) => {
  const filterDate = (date: Date) => {
    const dayIndex = (getDay(date) + 6) % 7; // Lunes es 0, domingo es 6
    return workDays[dayIndex];
  };
  return (
    <div
      style={{
        maxWidth: "150px",
        display: "flex",
        flexDirection: "column",
        margin: "2px",
      }}
    >
      <div
        className="input-group-text"
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "30px",
        }}
      >
        <span>
          <FaCalendar /> {text}
        </span>
      </div>

      <StyledDatePicker
        locale="es"
        onChange={(newDate: Date) => {
          onChange(newDate);
        }}
        selected={initialDate}
        dateFormat="E d/LLL/yyyy"
        className="text-dark"
        placeholderText="Seleccionar fecha"
        readOnly={readOnly}
        filterDate={filterDate}
      />
    </div>
  );
};

type RangeProps = {
  onRangeChange: (start: Date, end: Date) => void;
  initialStart?: Date | null;
  initialEnd?: Date | null;
  propsMayChange?: boolean;
  readOnly?: boolean;
  workDays?: Array<boolean>;
};

const StyledRow = styled.div`
  max-height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

// TODO: Handle scenario when start date is weekend
export const DateRangePicker = ({
  onRangeChange,
  initialStart = null,
  initialEnd = null,
  propsMayChange = false,
  readOnly = false,
  workDays = Array(7).fill(true),
}: RangeProps) => {
  const [startDate, setStartDate] = useState<Date | null>(initialStart);
  const [endDate, setEndDate] = useState<Date | null>(initialEnd);
  const [duration, setDuration] = useState<string | null>(null); // string porque si no, no se puede borrar y escribir otro numero
  const [message, setMessage] = useState<string>("");
  React.useEffect(() => {
    if (propsMayChange) setStartDate(initialStart);
  }, [initialStart, propsMayChange]);
  React.useEffect(() => {
    if (propsMayChange) setEndDate(initialEnd);
  }, [initialEnd, propsMayChange]);
  React.useEffect(() => {
    setDuration(
      startDate !== null && endDate !== null
        ? (differenceInWorkDays(workDays, startDate, endDate) + 1).toString(10)
        : null
    );
  }, [startDate, endDate, workDays]);
  const updRange = (start: Date | null, end: Date | null) => {
    setStartDate(start);
    setEndDate(end);
    if (start && end) onRangeChange(start, end);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Form style={{ maxWidth: "300px" }}>
        <Row className="justify-content-md-center">
          <SingleDatePicker
            onChange={(newStart: Date) => {
              if (duration) {
                updRange(
                  newStart,
                  addWorkDays(workDays, newStart, parseInt(duration, 10) - 1)
                );
              } else {
                updRange(newStart, null);
              }
            }}
            initialDate={startDate}
            text="Inicia"
            readOnly={readOnly}
            workDays={workDays}
          />{" "}
          {startDate !== null && (
            <SingleDatePicker
              onChange={(newEnd: Date) => {
                updRange(
                  isBefore(newEnd, startDate) ? newEnd : startDate,
                  newEnd
                );
              }}
              initialDate={endDate}
              text="Termina"
              readOnly={readOnly}
              workDays={workDays}
            />
          )}
        </Row>
        {startDate !== null && (
          <div
            className="input-group-text"
            style={{
              justifyContent: "center",
              width: "100%",
              margin: "2px",
              marginBottom: "4px",
              height: "30px",
            }}
          >
            {" "}
            por lo tanto{" "}
          </div>
        )}
        <StyledRow>
          <Col xs="auto">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text
                  style={{ maxHeight: "35px" }}
                  id="basic-addon1"
                >
                  Dura
                </InputGroup.Text>
              </InputGroup.Prepend>
              <ThinFormControl
                className="col-sm-9"
                type="number"
                min="1"
                max="10000"
                placeholder="-"
                value={duration || ""}
                onChange={textInputChange(setDuration)}
                onFocus={() => {
                  if (!readOnly && endDate) {
                    setMessage(
                      "Si cambias la duración, se actualizará en automático la fecha de terminación"
                    );
                  }
                }}
                onBlur={() => {
                  if (duration === null || duration === "") {
                    // El usuario dejó la duracion vacia, entonces hay que reescribirla
                    if (startDate && endDate) {
                      setDuration(
                        (
                          differenceInWorkDays(workDays, startDate, endDate) + 1
                        ).toString(10)
                      );
                    } else {
                      setDuration(null);
                    }
                  } else if (startDate) {
                    updRange(
                      startDate,
                      addWorkDays(
                        workDays,
                        startDate,
                        parseInt(duration, 10) - 1
                      )
                    );
                  }
                  setMessage("");
                }}
                onKeyDown={blurOnEnter}
                readOnly={readOnly}
              />
              <InputGroup.Append>
                <InputGroup.Text
                  style={{ maxHeight: "35px" }}
                  id="basic-addon1"
                >
                  {duration !== null && parseInt(duration, 10) === 1
                    ? "Día Laboral"
                    : "Días Laborales"}
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </StyledRow>
        {message !== "" && (
          <div
            style={{
              background: "white",
              maxWidth: "300px",
              wordBreak: "break-word",
              justifyContent: "center",
              border: "1px solid gainsboro",
              borderRadius: "5px",
              padding: "4px",
              margin: "2px",
              marginTop: "4px",
            }}
          >
            {" "}
            <p style={{ maxWidth: "300px", wordBreak: "break-word" }}>
              <b>Nota:</b> {message}{" "}
            </p>{" "}
          </div>
        )}
      </Form>
    </div>
  );
};
