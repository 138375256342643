import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faTwitterSquare,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons";

const StyledFooterWrapper = styled.footer`
  background-image: url("/fondo.png");
  flex-shrink: 0;
  font-size: 10px;
  padding: 5px 0 5px 0;
  text-align: center;
`;

const Footer = () => (
  <StyledFooterWrapper>
    <div className="text-center">
      <a
        className="btn"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/CoordinateMx"
      >
        <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
      </a>
      <a
        className="btn"
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/coordinate_si"
      >
        <FontAwesomeIcon icon={faTwitterSquare} size="2x" />
      </a>
      <a
        className="btn"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.youtube.com/user/SuperCoordinate"
      >
        <FontAwesomeIcon icon={faYoutubeSquare} size="2x" />
      </a>
      <a
        className="btn"
        target="_blank"
        rel="noopener noreferrer"
        href="mailto:contacto@coordinate.si"
      >
        <FontAwesomeIcon icon={faMailBulk} size="2x" />
      </a>
    </div>
    © Copyright 2021 Coordinate
  </StyledFooterWrapper>
);

export default Footer;
