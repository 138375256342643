import React from "react";
import ReactDOMServer from "react-dom/server";
import { addDays } from "date-fns";
import { Button } from "react-bootstrap";
import { FaTasks, FaGlobeAmericas } from "react-icons/fa";
import ReactDOM from "react-dom";
import ComoterminoGanttChart from "./ComoterminoGanttChart";
import type { ComoterminoNode } from "./types";
import type { Project } from "../../../graphql-client/codegen-types";
import { briefFormat, Rhombus } from "../../../components/utils";
import { getGanttPNGScreenshot } from "../../components/gantt/utils";
import Loader from "../../../components/Loader";

// Transforma JSX (React Components) en strings de html.
// Esto es útil porque dhtmlx usa html crudo para renderear.
// Documentación: https://reactjs.org/docs/react-dom-server.html
const jsxToStr = ReactDOMServer.renderToString;

class PrintableGanttChart extends ComoterminoGanttChart {
  setUpColumns() {
    super.setUpColumns();
    const getIcon = ({ level }: ComoterminoNode) =>
      [
        <FaGlobeAmericas style={{ color: "#0086ba" }} />,
        <Rhombus />,
        <FaTasks style={{ color: "#999900" }} />,
      ][level];
    const textColumn = {
      name: "text",
      label: " ",
      width: "160",
      template: (task: ComoterminoNode) => {
        const charLimit = 20;
        const multilineAttributes =
          task.text.length > charLimit ? { lineHeight: "18px" } : {};
        const getMultilineText = () => {
          if (task.text.length <= charLimit) return task.text;
          return (
            <span>
              {" "}
              {task.text.substring(0, charLimit)} <br />{" "}
              {task.text.substring(charLimit)}{" "}
            </span>
          );
        };
        return jsxToStr(
          <div
            style={{
              fontSize: "14px",
              textAlign: "left",
              color: task.readonly ? "grey" : "inherit",
              ...multilineAttributes,
            }}
          >
            {getIcon(task)} {getMultilineText()}
          </div>
        );
      },
    };
    const plannedEndColumn = {
      name: "planned_end",
      label: jsxToStr(
        <div style={{ margin: "5px", lineHeight: "19px" }}>
          Terminación <br />
          planeada
        </div>
      ),
      width: "90",
      template: (task: ComoterminoNode) =>
        jsxToStr(
          <div
            style={{
              fontSize: "14px",
              color: task.readonly ? "grey" : "inherit",
            }}
          >
            {briefFormat(addDays(task.planned_end, -1))}
          </div>
        ),
    };
    const endDateColumn = {
      name: "end",
      label: jsxToStr(
        <div style={{ margin: "5px", lineHeight: "19px" }}>
          Terminación
          <br />
          Real
        </div>
      ),
      width: "90",
      template: (task: ComoterminoNode) =>
        jsxToStr(
          <div
            style={{
              fontSize: "14px",
              color: task.readonly ? "grey" : "inherit",
            }}
          >
            {briefFormat(addDays(task.end_date, -1))}
          </div>
        ),
    };

    this.myGantt.config.columns = [textColumn, plannedEndColumn, endDateColumn];
  }

  configureGantt() {
    super.configureGantt();
    this.myGantt.config.row_height = 40;
  }
}

type Props = {
  text: string;
  project: Project;
  onGanttExport: (png: any, updatedProject: Project) => Promise<void>;
};

const ComoterminoGanttPNGExporter = ({
  text,
  project,
  onGanttExport,
}: Props) => {
  const ganttContainerRef = React.createRef<HTMLDivElement>();
  const hiddenElementRef = React.createRef<HTMLDivElement>();
  const [loading, setLoading] = React.useState(false);

  const onClick = () => {
    setLoading(true);
    if (!hiddenElementRef.current) throw Error("hidden element is undefined");
    const hiddenElement = hiddenElementRef.current;
    const onAfterRender = () => {
      setTimeout(() => {
        if (!ganttContainerRef.current) throw Error("gantt is undefined");
        getGanttPNGScreenshot(ganttContainerRef.current)
          .then((ganttPNG) => onGanttExport(ganttPNG, project))
          .then(() => {
            ReactDOM.unmountComponentAtNode(hiddenElement);
            setLoading(false);
          });
      }, 0);
    };
    const { wbsRoot } = project;
    if (!wbsRoot) throw Error("wbs root is null");
    ReactDOM.render(
      <PrintableGanttChart
        ganttContainerRef={ganttContainerRef}
        wbsRoot={wbsRoot}
        links={[]}
        onTaskUpdate={() => Promise.resolve()}
        proVerifier={[() => false, false]}
        onLinkAdd={() => Promise.resolve()}
        onLinkDelete={() => Promise.resolve()}
        readOnly
      />,
      hiddenElement,
      onAfterRender
    );
  };

  return (
    <div>
      {loading ? (
        <Loader size={60} message="Imprimiendo..." />
      ) : (
        <Button onClick={onClick} variant="info">
          {" "}
          {text}{" "}
        </Button>
      )}
      <div
        ref={hiddenElementRef}
        style={{
          position: "absolute",
          top: "0",
          left: "-10000px",
          width: "800px",
        }}
      />
    </div>
  );
};

export default ComoterminoGanttPNGExporter;
