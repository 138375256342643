import React from "react";
import { useParams } from "react-router-dom";
import { FaEye, FaCheck } from "react-icons/fa";
import { useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { useProjectContext } from "./project-context";
import { Access } from "../../graphql-client/codegen-types";
import { PROJECT_ACCESS } from "../../graphql-client/queries/project";

const ProjectSaveStatus = () => {
  const { state } = useProjectContext();
  const { activeProcessCount } = state;
  const { projectId, stepPath } = useParams<{
    projectId: string;
    stepPath: string;
  }>();
  const { data } = useQuery(PROJECT_ACCESS, {
    variables: { id: projectId, step: stepPath.toUpperCase() },
    fetchPolicy: "cache-only",
  });
  const access = data?.asUser?.getProject?.access;

  if (access === Access.Read) {
    return (
      <span style={{ color: "grey" }}>
        <FaEye /> Puedes ver pero no editar
      </span>
    );
  }
  if (activeProcessCount === 0) {
    return (
      <span style={{ color: "grey" }}>
        <FaCheck /> Cambios guardados
      </span>
    );
  }
  return (
    <span style={{ color: "grey" }}>
      <Loader size={30} message="" /> Guardando cambios
    </span>
  );
};

export default ProjectSaveStatus;
