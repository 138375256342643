import React from "react";
import { addDays, min, max } from "date-fns";
import ReactDOMServer from "react-dom/server";
import { FaExclamationTriangle } from "react-icons/fa";
import styled from "styled-components";
import type { WbsNode } from "../../../graphql-client/codegen-types";
import type { GanttState, GanttBasicProps } from "../../components/gantt/types";
import type { ComovaNodeExtraAttributes, ComovaNode } from "./types";
import { WbsNodeStatus } from "../../../graphql-client/codegen-types";
import BaselineGanttChart from "../../components/gantt/BaselineGanttChart";
import {
  statusIcons,
  getSupposedStatus,
  parseStartDate,
  parseEndDate,
  formatStartDate,
  formatEndDate,
  getBasicGanttNodeAttributes,
} from "../../components/gantt/utils";
import ComovaActEditor from "./ComovaActEditor";

type ComovaGanttExtraProps = {
  statusDate: Date;
};

type ComovaGanttProps = GanttBasicProps & ComovaGanttExtraProps;

type MarkersInfo = { statusStartId: string; statusEndId: string };

// Transforma JSX (React Components) en strings de html.
// Esto es útil porque dhtmlx usa html crudo para renderear.
// Documentación: https://reactjs.org/docs/react-dom-server.html
const jsxToStr = ReactDOMServer.renderToString;

const ComovaGanttStyleWrapper = styled.div`
  .status_line {
    background: grey;
    width: 3px;
  }
  .gantt_marker_content {
    color: black;
    background: white;
    font-size: 14px;
    width: 60px;
  }
`;

class ComovaGanttChart extends BaselineGanttChart<
  ComovaGanttExtraProps,
  ComovaNodeExtraAttributes
> {
  markers: MarkersInfo;

  constructor(props: ComovaGanttProps) {
    super(props);
    const { statusDate } = this.props;
    const statusEndId = this.myGantt.addMarker({
      start_date: addDays(statusDate, 1),
      css: "status_line",
      text: "", // the marker title
      title: "", // the marker's tooltip
    });
    const statusStartId = this.myGantt.addMarker({
      start_date: statusDate, // a Date object that sets the marker's date
      css: "status_line",
      text: "Hoy", // the marker title
      title: "Hoy", // the marker's tooltip
    });
    this.markers = { statusStartId, statusEndId };
  }

  toWbsNode({
    id,
    text,
    start_date,
    end_date,
    planned_start,
    planned_end,
    status,
  }: ComovaNode) {
    return {
      id,
      text,
      startDate: formatStartDate(start_date),
      endDate: formatEndDate(end_date),
      plannedStart: formatStartDate(planned_start),
      plannedEnd: formatEndDate(planned_end),
      status,
    };
  }

  toGanttNode(node: WbsNode, parent: string, level: number) {
    const {
      startDate,
      endDate,
      plannedStart,
      plannedEnd,
      status,
      duties,
    } = node;
    if (!status) throw Error("status is null");
    return {
      ...getBasicGanttNodeAttributes(node, parent, level),
      start_date: parseStartDate(startDate || plannedStart),
      end_date: parseEndDate(endDate || plannedEnd),
      planned_start: parseStartDate(plannedStart),
      planned_end: parseEndDate(plannedEnd),
      status,
      duties,
    };
  }

  componentDidUpdate(prevProps: ComovaGanttProps, prevState: GanttState) {
    super.componentDidUpdate(prevProps, prevState);
    const { statusDate } = this.props;
    if (statusDate !== prevProps.statusDate) {
      const { statusStartId, statusEndId } = this.markers;
      this.myGantt.getMarker(statusStartId).start_date = statusDate;
      this.myGantt.updateMarker(statusStartId);
      this.myGantt.getMarker(statusEndId).start_date = addDays(statusDate, 1);
      this.myGantt.updateMarker(statusEndId);
      this.zoomToFit();
      this.myGantt.render();
    }
  }

  getProjectDates(): Array<Date> {
    const [projectStart, projectEnd] = super.getProjectDates();
    const { statusDate } = this.props;
    return [
      min([projectStart, statusDate]),
      max([projectEnd, addDays(statusDate, 1)]),
    ];
  }

  // El tooltip que se se enseña cuando una task en el gantt chart es hovereada.
  // Fuente relevante: https://docs.dhtmlx.com/gantt/desktop__tooltips.html
  getStyledTooltip(start: Date, end: Date, task: ComovaNode): string {
    return super.getStyledTooltip(start, end, task);
  }

  setUpColumns() {
    super.setUpColumns();
    const getStatusColHtml = (node: ComovaNode) => {
      // Es importante acceder a las props adentro de la funcion y no afuera,
      // para tomar en cuenta las props actuales cada vez que se renderee esta columna.
      const { statusDate } = this.props;
      const { level, status } = node;
      const getStatusIcon = () => {
        if (status === WbsNodeStatus.Pending) return statusIcons.pending;
        if (status === WbsNodeStatus.InProgress) return statusIcons.inProgress;
        return statusIcons.done;
      };
      if (level === 2)
        return jsxToStr(
          <span className="status-col">
            {" "}
            {getStatusIcon()}{" "}
            {status !== getSupposedStatus(node, statusDate) && (
              <FaExclamationTriangle
                style={{ color: "#EED202", width: "17px", height: "17px" }}
              />
            )}
          </span>
        );
      return jsxToStr(
        <span className="status-col" style={{ opacity: 0.4 }}>
          {" "}
          {getStatusIcon()}{" "}
        </span>
      );
    };
    const statusColumn = {
      name: "status",
      label: "Estatus",
      width: "50",
      template: getStatusColHtml,
    };
    this.myGantt.config.columns.push(statusColumn);
  }

  configureGantt() {
    super.configureGantt();
    const setStatusBasedOnChildren = (task: ComovaNode) => {
      const children = this.myGantt
        .getChildren(task.id)
        .map((childId) => this.myGantt.getTask(childId));
      if (children.every((child) => child.status === WbsNodeStatus.Pending))
        task.status = WbsNodeStatus.Pending;
      else if (children.every((child) => child.status === WbsNodeStatus.Done))
        task.status = WbsNodeStatus.Done;
      else task.status = WbsNodeStatus.InProgress;
    };
    this.myGantt.attachEvent(
      "onAfterTaskUpdate",
      (id: number, task: ComovaNode) => {
        if (task.parent !== "0") {
          const parentNode = this.myGantt.getTask(task.parent);
          setStatusBasedOnChildren(parentNode);
          this.myGantt.updateTask(task.parent);
          const { updateTaskInCache } = this.props;
          updateTaskInCache?.(this.toWbsNode(parentNode));
        }
      },
      {}
    );
  }

  render() {
    return <ComovaGanttStyleWrapper>{super.render()}</ComovaGanttStyleWrapper>;
  }

  renderActEditor(task: ComovaNode) {
    const { id, start_date, end_date } = task;
    const { readOnly } = this.props;
    const { statusDate, workDays } = this.props;
    return (
      <ComovaActEditor
        task={task}
        readOnly={readOnly || false}
        gantt={this.myGantt}
        statusDate={statusDate}
        workDays={workDays}
      />
    );
  }
}
export default ComovaGanttChart;
