import {
  WidthType,
  HeadingLevel,
  TextRun,
  AlignmentType,
  TableCell,
  TableRow,
  Table,
  Paragraph,
} from "docx";
import type {
  Risk,
  Member,
  WbsNode,
  Project,
} from "../../../graphql-client/codegen-types";
import getDocxTemplatePromise from "../../components/docx/docx-template";
import { parseStartDate } from "../../components/gantt/utils";
import { readableFormat } from "../../../components/utils";

const getTableCell = (text: string, alignCenter = false, bold = false) =>
  new TableCell({
    children: [
      new Paragraph({
        children: [new TextRun({ text, bold })],
        alignment: alignCenter ? AlignmentType.CENTER : AlignmentType.LEFT,
        heading: HeadingLevel.HEADING_3,
        spacing: { before: 50, after: 50 },
      }),
    ],
  });

const displayWbs = (
  { text, children, plannedStart, plannedEnd }: WbsNode,
  wbsCode = ""
): Array<TableRow> => {
  const displayedChildren = (children || [])
    .map((child, idx) => displayWbs(child, `${wbsCode}${idx + 1}.`))
    .flat();
  const formattedPlannedStart = plannedStart
    ? readableFormat(parseStartDate(plannedStart))
    : "";
  const formattedPlannedEnd = plannedEnd
    ? readableFormat(parseStartDate(plannedEnd))
    : "";
  const nodeRow = new TableRow({
    children: [
      getTableCell(`${wbsCode} ${text}`),
      getTableCell(formattedPlannedStart, true),
      getTableCell(formattedPlannedEnd, true),
    ],
  });
  return [nodeRow, ...displayedChildren];
};

const impactLabels = ["", "Muy Bajo", "Bajo", "Medio", "Alto", "Muy Alto"];
const probabilityLabels = ["", "Muy Baja", "Baja", "Media", "Alta", "Muy Alta"];

const displayRisk = (
  { text, impact, probability, actions, responsibleId }: Risk,
  members: Array<Member>
) => {
  const member = members.find(
    (currentMember) => currentMember.id === responsibleId
  );
  return new TableRow({
    children: [
      getTableCell(text || "", true),
      getTableCell(impactLabels[impact || 0], true),
      getTableCell(probabilityLabels[probability || 0], true),
      getTableCell(actions || "", true),
      getTableCell(member?.user?.handle || member?.name || "", true),
    ],
  });
};

const getRisksTable = (project: Project) => [
  new Paragraph({
    children: [
      new TextRun({
        text: "¿Cuáles son los riesgos que pueden afectar el proyecto?",
        break: 1,
      }),
    ],
    heading: HeadingLevel.HEADING_2,
    spacing: { before: 100, after: 100 },
  }),
  new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    rows: [
      new TableRow({
        children: [
          getTableCell("Riesgo", true, true),
          getTableCell("Impacto", true, true),
          getTableCell("Probabilidad", true, true),
          getTableCell("Acciones", true, true),
          getTableCell("Responsable", true, true),
        ],
        tableHeader: true,
      }),
      ...(project.risks || []).map((risk) =>
        displayRisk(risk, project.members || [])
      ),
    ],
  }),
];

const getTable = (node: WbsNode) =>
  new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    rows: [
      new TableRow({
        children: [
          getTableCell(""),
          getTableCell("Inicio planeado", true, true),
          getTableCell("Terminación planeada", true, true),
        ],
        tableHeader: true,
      }),
      ...displayWbs(node),
    ],
  });

const getAssumptions = (project: Project) => [
  new Paragraph({
    children: [
      new TextRun({
        text: "¿Qué suposiciones hay para que el proyecto avance?",
        break: 1,
      }),
    ],
    heading: HeadingLevel.HEADING_2,
  }),
  ...(project.assumptions || []).map(
    ({ text }) =>
      new Paragraph({
        text: text || "",
        bullet: { level: 0 },
        heading: HeadingLevel.HEADING_3,
      })
  ),
];

const getRestrictions = (project: Project) => [
  new Paragraph({
    children: [
      new TextRun({
        text:
          "¿Qué restricciones existen que pueden limitar el avance del proyecto?",
        break: 1,
      }),
    ],
    heading: HeadingLevel.HEADING_2,
  }),
  ...(project.restrictions || []).map(
    ({ text }) =>
      new Paragraph({
        text: text || "",
        bullet: { level: 0 },
        heading: HeadingLevel.HEADING_3,
      })
  ),
];

const getDocxReportPromise = (project: Project) => {
  const { wbsRoot } = project;
  if (!wbsRoot) throw Error("Wbs is undefined");
  return getDocxTemplatePromise(project, [
    getTable(wbsRoot),
    ...getRisksTable(project),
    ...getAssumptions(project),
    ...getRestrictions(project),
  ]);
};

export default getDocxReportPromise;
