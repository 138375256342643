import React from "react";
import styled from "styled-components";
import { FaTasks, FaGlobeAmericas } from "react-icons/fa";
import { BsFillDiamondFill } from "react-icons/bs";
import { curry } from "ramda";
import { format } from "date-fns";
import es from "date-fns/locale/es";

type StyledSpanProps = {
  color: string;
  background: string;
};

export const functionify = (obj: any) => {
  const keys = Object.keys(obj);
  const pairList = keys.map((key: string) => [key, () => obj[key]]);
  return Object.fromEntries(pairList);
};

export const Rhombus = styled(BsFillDiamondFill)`
  color: coral;
`;

const StyledSpan = styled.span`
  border-radius: 10px;
  border: 1px solid ${(props: StyledSpanProps) => props.color};
  padding: 2px;
  color: ${(props: StyledSpanProps) => props.color};
  background: ${(props: StyledSpanProps) => props.background};
`;

export const blurOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.target instanceof HTMLInputElement && e.keyCode === 13) e.target.blur();
};

export const textInputChange = curry(
  (
    setText: (newText: string) => void,
    event: React.ChangeEvent<HTMLInputElement>
  ) => setText(event.target.value)
);

export const textInputChangeRegexFilter = curry(
  (
    setText: (newText: string) => void,
    regex: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;
    const cleaned = (newValue.match(regex) || []).join("");
    setText(cleaned);
  }
);

export const ProjectLabel = ({ plural = false }: { plural?: boolean }) => (
  <StyledSpan color="#0086ba" background="#E6E8FA">
    <FaGlobeAmericas /> Proyecto
    {plural ? "s" : ""}
  </StyledSpan>
);

export const DelivLabel = ({ plural = false }: { plural?: boolean }) => (
  <StyledSpan color="coral" background="#fff1d8">
    <Rhombus /> Entregable
    {plural ? "s" : ""}
  </StyledSpan>
);

export const ActLabel = ({ plural = false }: { plural?: boolean }) => (
  <StyledSpan color="#999900" background="white">
    <FaTasks /> Actividad
    {plural ? "es" : ""}
  </StyledSpan>
);

export const briefFormat = (date: Date) =>
  format(date, "d/LLL/yyyy", { locale: es });

export const readableFormat = (date: Date) =>
  format(date, "E d/LLL/yyyy", { locale: es });

export const completeFormat = (date: Date) =>
  format(date, "EEEE d 'de' LLLL 'del' yyyy", { locale: es });

export const todayWithoutTime = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
};
